import React from "react";

export default React.memo(function EloBlock({ elo, className }) {
  let ratingClass = "noBg";
  if (typeof elo === "number") {
    if (elo > 30000) {
      ratingClass = "unusual";
    } else if (elo >= 30000) {
      ratingClass = "unusual";
    } else if (elo >= 25000) {
      ratingClass = "ancient";
    } else if (elo >= 20000) {
      ratingClass = "legendary";
    } else if (elo >= 15000) {
      ratingClass = "mythical";
    } else if (elo >= 10000) {
      ratingClass = "rare";
    } else if (elo >= 5000) {
      ratingClass = "uncommon";
    } else {
      ratingClass = "common";
    }
  }
  return (
    <span className={`cs2rating ${ratingClass} ${className || ""}`}>
      {elo >= 10000 ? (
        <span>
          {Math.floor(elo / 1000)}
          <small>,{(elo % 1000).toString().padStart(3, "0")}</small>
        </span>
      ) : (
        <span>{elo}</span>
      )}
    </span>
  );
});
