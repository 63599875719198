import React from "react";

const SimpleAsset = React.memo(function SimpleAsset({ asset, classNames, index, game }) {
  const appId = asset[Object.keys(asset).find((k) => k.toLowerCase() === "appID".toLowerCase())];
  const market_hash_name = asset[Object.keys(asset).find((k) => k.toLowerCase() === "market_hash_name".toLowerCase())];
  const icon_url = asset.image || `https://community.akamai.steamstatic.com/economy/image/${asset[Object.keys(asset).find((k) => k.toLowerCase() === "icon_url".toLowerCase())]}`;
  const name = asset.name || asset.item_name;

  return (
    <>
      <a className={`name ${classNames || ""}`} href={`https://steamcommunity.com/market/listings/${appId}/${encodeURIComponent(market_hash_name)}`} target="_blank" rel="noreferrer">
        <img src={icon_url} width={50} height="auto" />
        {index !== undefined && <span className="font-bold">{index}.</span>}
        <span className="pl-1">
          {name}
          {game ? " - " + game : ""}
        </span>
      </a>
    </>
  );
});

export default SimpleAsset;
