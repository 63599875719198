import React, { forwardRef, useMemo } from "react";
import "./ChatLineTextUr.scss";
import moment from "moment";
import Avatar from "chat/Avatar";
import { generateMenuItems, isSteamImgUrl, SwalSelectPlayer } from "utils";
import { Dropdown, Image } from "antd";
import classNames from "classnames";
import useStore, { useAccountBySteamId, useFriend } from "store";
import api from "api";

const ChatLineTextUr = React.memo(
  forwardRef(function ChatLineTextUr({ line, room, index }, ref) {
    const scamLink = useStore((state) => state.scamLink);

    const author = useFriend(line.author_id);
    const receiver = useFriend(line.receiver_id);
    const receiverAccount = useAccountBySteamId(receiver?.steamId);

    const menuItems = useMemo(
      () =>
        [
          {
            label: "Report scam",
            async onClick({ line, room }) {
              const results = (await api.reportScamMessage({ links: line.content }))?.data;
              useStore.getState().updateScamLink(results);
            },
          },
          !!line.content?.startsWith?.("https://s.team/p/") && {
            label: "Accept Friend",
            onClick({ line, room }) {
              SwalSelectPlayer({
                options: useStore.getState().accountList,
                optionLabelField: ["personaName", "username"],
                optionValueField: "steamId",
                optionIconField: (a) => `https://avatars.akamai.steamstatic.com/${a.avatarHash}_full.jpg`,
                title: "Select my account",
                key: "myaccount_select_addfriend",
                callback(selectedAccount) {
                  if (!selectedAccount) {
                    return;
                  }
                  api.acceptQuickInviteLink({
                    steamId: selectedAccount.steamId,
                    quickInviteLink: line.content,
                  });
                },
                confirmButtonText: "Accept",
              });
            },
          },
        ].filter(Boolean),
      [],
    );

    const { handleMenuClick, items } = useMemo(() => generateMenuItems(menuItems), []);
    const messageEl = isSteamImgUrl(line.content) || line.isImage ? <Image className="w-full" src={line.content} /> : <span>{line.content}</span>;
    const isScam = scamLink.some((l) => line.content?.includes(l));

    return (
      <React.Fragment key={"Line" + line.timestamp}>
        <Dropdown
          key={"Dropdown_" + line.timestamp}
          menu={{
            items: items,
            onClick: (e) => handleMenuClick(e, { line, room }),
          }}
          trigger={["contextMenu"]}
        >
          <div className="ChatLineTextUr pt-3 py-2" ref={ref}>
            <div className="flex items-center gap-x-1">
              <div className="w-6 h-6">
                <Avatar diameter={"100%"} hash={author?.avatarHash} />
              </div>
              <div className="flex items-end">
                <div
                  className={classNames("p-2 overflow-y-hidden break-words rounded-xl rounded-bl-none text-sm max-w-[60%]", {
                    "bg-rose-500": isScam,
                    "bg-slate-300": !isScam,
                  })}
                >
                  {isScam ? "⚠️ " : ""}
                  {messageEl}
                </div>
                <div className="text-xs leading-3">{moment(line.timestamp).fromNow()}</div>
                &nbsp;&nbsp;
                <div className="flex justify-center items-center" onClick={() => useStore.getState().setSelectedAccountID(receiver.steamId)}>
                  <span className="w-6 h-6 block">
                    <Avatar diameter={"100%"} hash={receiverAccount?.avatarHash || receiver?.avatarHash} />
                  </span>
                  <span className="text-xs">{[receiverAccount?.personaName || receiver?.personaName, receiverAccount?.username].filter(Boolean).join(" - ")}</span>
                </div>
              </div>
            </div>
          </div>
        </Dropdown>
      </React.Fragment>
    );
  }),
);

export default ChatLineTextUr;
