import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input, InputNumber, Modal, Radio, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { filterObjectInput } from "../utils";
import _ from "lodash";
import useStore from "store";

export function RealSimpleConfirmModal({ handleOk, handleCancel, fields, title, visible = true, defaultValue }) {
  const formRef = useRef();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const formItemsRef = useRef([]);
  const [loadingBtns, setLoadingBtns] = useState([]);
  const form = fields.map(function (field, index) {
    switch (field.type?.toLowerCase()) {
      case "Checkbox".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} name={field.name} valuePropName="checked" key={index}>
            <Checkbox ref={(el) => (formItemsRef.current[index] = el)}>{field.label}</Checkbox>
          </Form.Item>
        );
      }
      case "Radio".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} name={field.name} key={index}>
            <Radio.Group
              options={field.options?.map?.((option) =>
                typeof option === "string" || typeof option === "number"
                  ? {
                      label: option,
                      value: option,
                    }
                  : option,
              )}
            />
          </Form.Item>
        );
      }
      case "Input".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} key={index}>
            <div className="flex">
              <Form.Item name={field.name}>
                <Input
                  onKeyDown={(event) => {
                    if (event.code === "Enter") {
                      event.preventDefault();
                      formRef.current.submit();
                    }
                  }}
                  ref={(el) => (formItemsRef.current[index] = el)}
                  allowClear
                />
              </Form.Item>
              {!!field.afterBtn && (
                <>
                  <Button
                    loading={!!loadingBtns[index]}
                    onClick={async function () {
                      setLoadingBtns(function (_loadingBtns) {
                        const __loadingBtns = _loadingBtns.slice();
                        __loadingBtns[index] = true;
                        return __loadingBtns;
                      });
                      await field.afterBtn.onClick?.(field);
                      setLoadingBtns(function (_loadingBtns) {
                        const __loadingBtns = _loadingBtns.slice();
                        __loadingBtns[index] = false;
                        return __loadingBtns;
                      });
                    }}
                  >
                    {field.afterBtn.label}
                  </Button>
                </>
              )}
              {!!field.afterSelect && (
                <>
                  <Select
                    onChange={field.afterSelect.onChange}
                    options={_.uniq(field.afterSelect.options || []).map((o) =>
                      typeof o === "string" || typeof o === "number"
                        ? {
                            value: o,
                            label: o,
                          }
                        : o,
                    )}
                    placeholder="History Name"
                  ></Select>
                </>
              )}
            </div>
          </Form.Item>
        );
      }
      case "InputNumber".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} name={field.name} key={index}>
            <InputNumber
              onKeyDown={(event) => {
                if (event.code === "Enter") {
                  event.preventDefault();
                  formRef.current.submit();
                }
              }}
              ref={(el) => (formItemsRef.current[index] = el)}
            />
          </Form.Item>
        );
      }
      case "Select".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} name={field.name} key={index}>
            <div className="flex">
              <Form.Item className="min-w-[50%]" name={field.name}>
                <Select ref={(el) => (formItemsRef.current[index] = el)} showSearch filterOption={(input, option) => filterObjectInput(option.data, input)} allowClear>
                  {field.options?.map?.(function (option) {
                    let value = option.value;
                    let name = option.name || option.value;
                    if (typeof option === "string" || typeof option === "number" || typeof option === "boolean") {
                      name = value = option;
                    }
                    return (
                      <Select.Option key={value} data={option.data} value={value}>
                        {name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {!!field.afterBtn && (
                <>
                  <Button
                    loading={!!loadingBtns[index]}
                    onClick={async function () {
                      setLoadingBtns(function (_loadingBtns) {
                        const __loadingBtns = _loadingBtns.slice();
                        __loadingBtns[index] = true;
                        return __loadingBtns;
                      });
                      await field.afterBtn.onClick?.(field);
                      setLoadingBtns(function (_loadingBtns) {
                        const __loadingBtns = _loadingBtns.slice();
                        __loadingBtns[index] = false;
                        return __loadingBtns;
                      });
                    }}
                  >
                    {field.afterBtn.label}
                  </Button>
                </>
              )}
            </div>
          </Form.Item>
        );
      }
      case "SelectMulti".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} name={field.name} key={index}>
            <Select mode="tags" ref={(el) => (formItemsRef.current[index] = el)}>
              {field.options?.map?.(function (option) {
                let value = option.value;
                let name = option.name || option.value;
                if (typeof option === "string" || typeof option === "number" || typeof option === "boolean") {
                  name = value = option;
                }
                return (
                  <Select.Option key={value} value={value}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        );
      }
      case "TextArea".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} name={field.name} key={index}>
            <TextArea ref={(el) => (formItemsRef.current[index] = el)} rows={4} />
          </Form.Item>
        );
      }
      case "Switch".toLowerCase(): {
        return (
          <Form.Item className="w-full" label={field.label} name={field.name} valuePropName="checked" key={index}>
            <Switch ref={(el) => (formItemsRef.current[index] = el)} />
          </Form.Item>
        );
      }
      default:
        return <></>;
    }
  });

  const onFinish = useCallback(
    async (value) => {
      if (typeof handleOk === "function") {
        setConfirmLoading(true);
        await handleOk(value);
        setConfirmLoading(false);
      }
    },
    [handleOk],
  );

  const onHandleCancel = useCallback(() => {
    handleCancel?.();
    useStore.getState().setConfirmModalData(null);
  }, [handleCancel]);

  useEffect(() => {
    if (fields.length === 1) {
      formItemsRef.current?.[0]?.focus();
    }
  }, [fields]);

  return (
    <React.Fragment>
      <Modal
        width={700}
        style={{
          maxHeight: "80vh",
          maxWidth: "80vw",
        }}
        open={visible}
        title={title}
        onOk={() => {
          formRef.current.submit();
        }}
        onCancel={onHandleCancel}
        confirmLoading={confirmLoading}
      >
        <Form className="max-w-[70vw] max-h-[70vh] overflow-y-auto" ref={formRef} initialValues={defaultValue} onFinish={onFinish} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} layout="horizontal" disabled={confirmLoading} autoComplete="off">
          {form}
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export function SimpleConfirmModal() {
  const confirmModalData = useStore((state) => state.confirmModalData);
  if (!confirmModalData) return null;

  return <RealSimpleConfirmModal visible={true} title={confirmModalData.title} fields={confirmModalData.fields} handleOk={confirmModalData.handleOk} handleCancel={confirmModalData.handleCancel} />;
}
