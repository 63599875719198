import _ from "lodash";
import io, { Socket } from "socket.io-client";
import { v4 } from "uuid";
import api from "api";
import { SocketRouteMapping } from "./SocketRouteMapping";
import { getQuickInviteLink, getReplyMsg, invite2PlayCustomMessasge } from "./utils";
import moment from "moment/moment";
import Toast from "./toast";
import Swal from "sweetalert2";
import useStore, { ModalType } from "./store";
import mesageReceiveSound from "./friend-request-14878.mp3";
import { fetchMyAccount } from "./store/UserSlice";

export let socket = null;
const listeners = {}; //eventname: {id: listener, id: listener}
let mesageReceiveSoundAudio = null;

const IgnoreEventNameLog = ["FriendState", "myAccounts_friendsIDList", "friendList", "myAccounts", "friendFollowIDs", "FriendInfo", "updateFriends", "updateQRScan", "ReceiveMessage"];

const localMethod = {
  setSelectedRoomID: null,
};

export const delayMessageJustStartGame = {};
const clientEventd = {};

for (const method in SocketRouteMapping.C2S) {
  Socket.prototype[method] = function (data) {
    const alterdData = typeof clientEventd[method] === "function" ? clientEventd[method](data) : data;
    console.log(`Socket emit ${method}`, alterdData);
    this.emit("Socket_C2S", {
      method,
      data: alterdData,
    });
  };
}

for (const method in localMethod) {
  Socket.prototype[method] = function (data) {
    if (listeners[method]) {
      for (const id in listeners[method]) {
        const listener = listeners[method][id];
        listener(data);
      }
    }
  };
}

addListener("updateDelayMessageJustStartGame", async function (data) {
  if (!Array.isArray(data)) {
    data = [data];
  }
  for (const player of data) {
    if (player.steamId) {
      if (!player.message) {
        delete delayMessageJustStartGame[player.steamId];
      } else {
        delayMessageJustStartGame[player.steamId] = player;
      }
    }
  }
});

const events = {
  toastError(data) {
    Toast.error(data);
  },
  toastSuccess(data) {
    Toast.success(data);
  },
  myAccounts(data) {
    useStore.getState().updateAccounts(data);
  },
  updateMyAccounts(data) {
    useStore.getState().updateMyAccounts(data);
  },
  friendFollowIDs(data) {
    if (Array.isArray(data)) {
      useStore.setState({ friendFollowIDs: data });
    }
  },
  getCustomLobby(data) {
    useStore.getState().setCustomLobby(data);
  },
  updateWatchSteamPlayerGroup(data) {
    useStore.getState().setWatchGroup(data);
  },
  async friendList(data) {
    if (!data) {
      console.error(`friend list empty`);
    } else {
      const { fetchGameApp, updateFriendList } = useStore.getState();
      updateFriendList(data);
      await fetchGameApp(
        data.map(function (friend) {
          return friend?.state?.gameid;
        }),
      );
    }
  },
  async updateFriends(data) {
    const { fetchGameApp, updateFriends } = useStore.getState();
    updateFriends(data);
    await fetchGameApp(data.map((change) => change?.updatedFields?.["state.gameid"]));
  },
  myAccounts_friendsIDList(data) {
    useStore.getState().updateUserAccountFriendIDList(data);
  },
  FriendInfo(data) {
    if (Array.isArray(data)) {
      const _friendsInfo = {};
      for (const friendInfo of data) {
        _friendsInfo[friendInfo.steamId] = friendInfo;
      }
      data = _friendsInfo;
    }

    useStore.setState({
      friendsInfo: data,
    });
  },
  ReceiveMessage(data) {
    const { scamLink, updateUnreadMessageNewMessage, friendList, addNewMessage, accountList, isShowingChat } = useStore.getState();
    addNewMessage(data);

    if (["Invited you to play a game!", "Đã mời bạn chơi một trò chơi!"].includes(data.content) || scamLink.some((l) => data.content.includes(l)) || data.channels.every((c) => c.mute)) {
      return;
    }
    const friend = !!data.author_id && friendList.find(({ _id }) => _id === data.author_id);
    if (friend?.steamId && accountList.find((a) => a.steamId === friend.steamId)?.config?.isPrivate) {
      return;
    }

    updateUnreadMessageNewMessage();
    if (!mesageReceiveSoundAudio) {
      mesageReceiveSoundAudio = new Audio(mesageReceiveSound);
    }
    mesageReceiveSoundAudio.play();
    // playAudio()

    if (!isShowingChat) {
      Toast.success({
        text: `${["Receive Message", friend?.personaName]
          .filter(Boolean)
          .map((txt) => `[${txt}]`)
          .join(" ")}: ${data.content}`,
        duration: 15000,
        ...(!!friend && {
          onClick() {
            useStore.getState().readMessage(data._id);
            openQuickMessage({
              roomId: data.channels[0],
              friend,
            });
          },
        }),
      });
    }
  },
  SendMessageResponse(data) {
    const { selectedRoomID, addNewMessage } = useStore.getState();
    addNewMessage({
      ...data,
      isMe: true,
      local: false,
    });

    if (data.channel_ids.includes(selectedRoomID)) {
      document.getElementById("Chat_Room_Content_Bottom_El")?.scrollIntoView({ behavior: "smooth" });
    }
  },
  RoomList(data) {
    useStore.getState().updateRoomList(data);
  },
  authenticated(data) {
    console.log("authenticated", data.username);
    Toast.success(`Login success ${data.username}`);
  },
  submitSteamGuardCode(data) {
    const { hideModal, showModal } = useStore.getState();
    hideModal(ModalType.AddAccount);
    showModal(ModalType.SubmitSteamGuard, {
      username: data.username,
      code: data.code,
    });
  },
  InvalidPassword(data) {
    Toast.error(`${data} Invalid Password`);
  },
  enable_twofactor_question_phone(data) {
    useStore.getState().setConfirmModalData({
      title: "Please input phone number",
      fields: [
        {
          type: "Input",
          name: "phone",
          label: "Phone number",
        },
      ],
      handleOk({ phone }) {
        data.phone = phone;
        socket.enable_twofactor(data);
        useStore.getState().setConfirmModalData();
      },
    });
  },
  enable_twofactor_email_verification(data) {
    useStore.getState().setConfirmModalData({
      title: `Please confirm email URL sent to ${data.email}`,
      fields: [],
      handleOk() {
        data.addPhoneResult_email_verification = true;
        socket.enable_twofactor(data);
        useStore.getState().setConfirmModalData();
      },
    });
  },
  enable_twofactor_question_opt_code(data) {
    useStore.getState().setConfirmModalData({
      title: "Please input opt code",
      fields: [
        {
          type: "Input",
          name: "opt_code",
          label: "Opt code",
        },
      ],
      handleOk({ opt_code }) {
        data.addPhoneOptCode = opt_code;
        socket.enable_twofactor(data);
        useStore.getState().setConfirmModalData();
      },
    });
  },
  enable_twofactor_finalizeTwoFactorCode(data) {
    useStore.getState().setConfirmModalData({
      title: "Please input opt code",
      fields: [
        {
          type: "Input",
          name: "opt_code",
          label: "Opt code",
        },
      ],
      handleOk({ opt_code }) {
        data.finalizeTwoFactorCode = opt_code;
        socket.enable_twofactor(data);
        useStore.getState().setConfirmModalData();
      },
    });
  },
  historymatches(data) {
    useStore.getState().setMatchHistory(data);
  },
  updateUser(data) {
    useStore.setState(data);
  },
  updateQRScan(next) {
    useStore.getState().updateQRScan(next);
  },
  updateUserInventory({ steamId, inventory, gameId }) {
    useStore.setState(function (user) {
      return {
        inventory: {
          ...user.inventory,
          [steamId + "_" + gameId]: inventory,
        },
      };
    });
  },
  xxxxx(data) {},
};

export function initSocket() {
  if (socket) return;
  console.log("initSocket");
  const apiURL = window.location.href.includes("http://localhost") ? "http://localhost:8099" : `${window.location.protocol}//${process.env.REACT_APP_API_URL}`;
  socket = io(apiURL, {
    forceNew: false,
    extraHeaders: {
      "Bypass-Tunnel-Reminder": "1",
    },
  });

  socket.removeAllListeners("connect");
  socket.on("connect", async () => {
    Toast.info("Socket connected");

    socket.off("Socket_S2C");
    socket.on("Socket_S2C", async ({ method, data }) => {
      let dataLog = null;
      if (IgnoreEventNameLog.includes(method)) {
        if (Array.isArray(data)) {
          dataLog = data.length;
        } else if (typeof data === "string" || typeof data === "number") {
          dataLog = data;
        } else if (typeof data === "object") {
          dataLog = Object.keys(data).length;
        } else {
          console.log("dataLog", dataLog);
        }
      } else {
        dataLog = _.clone(data);
        console.log(`[Socket_S2C] Receive`, method, dataLog);
      }

      if (typeof events[method] === "function") {
        events[method](data);
      }

      if (listeners[method]) {
        callListener(method, data);
      }
    });

    socket.on("disconnect", (reason) => {
      console.log("socket disconnected");
      socket.off("Socket_S2C");
      Toast.error("Socket disconnected");
    });

    socket.up2Date(localStorage.getItem("FriendList_up2Date") === "true");

    const { getSteamPlayerGroup, getScamList, getConfig } = useStore.getState();

    await fetchMyAccount();
    await getSteamPlayerGroup();
    await getScamList();
    await getConfig();
    const partyResult = await api.getParty();
    if (Array.isArray(partyResult.data)) {
      useStore.setState({ party: partyResult.data });
    }
    const discordFriendResult = await api.getDiscordFriend();
    if (Array.isArray(discordFriendResult.data)) {
      useStore.setState({ discordFriend: discordFriendResult.data });
    }
  });
}

function callListener(method, data) {
  for (const id in listeners[method]) {
    const listener = listeners[method][id];
    listener(data);
    if (listener.once) {
      delete listeners[method][id];
    }
  }
}

export function disconnect() {
  socket?.disconnect();
  socket = null;
}

export function addListener(event, listener, once) {
  if (typeof listener === "function") {
    const id = v4();
    if (!listeners[event]) {
      listeners[event] = {};
    }
    listeners[event][id] = listener;
    listeners[event][id].once = !!once;
    return id;
  }
}

export function addListeners(options, paths = []) {
  const ids = [];
  for (const eventName in options) {
    const listener = options[eventName];
    const eventNames = eventName.split(",");
    for (const _eventName of eventNames) {
      if (typeof listener === "object" && Object.keys(listener).length) {
        const _ids = addListeners(listener, [...paths, _eventName]);
        _ids.forEach((_id) => ids.push(_id));
      } else if (typeof listener === "function") {
        ids.push(addListener([...paths, _eventName].join("."), listener));
      }
    }
  }
  return ids;
}

export function removeListener(listenerIDs) {
  if (!Array.isArray(listenerIDs)) {
    listenerIDs = [listenerIDs];
  }
  for (let eventName in listeners) {
    for (const listenerID of listenerIDs) {
      delete listeners[eventName][listenerID];
    }
  }
}

export function sendCustomRequest(steamId) {
  const inviteName = getInviteName(steamId);
  const content = invite2PlayCustomMessasge(inviteName);
  const reply = content.includes("custom") ? "custom nha" : getReplyMsg();

  api.sendSteamMessage({
    messages: {
      content,
      reply,
    },
    receivers: {
      friendSteamId: steamId,
    },
  });
  return [getPersonaName(steamId), inviteName].filter(Boolean).join(" - ");
}

export function scheduleCustomRequest20h(steamId) {
  const inviteName = getInviteName(steamId);

  let timeMsgs = ["tối 8h", "8h", "tối"];
  if (moment().hours(20).minutes(0).seconds(0).diff(moment(), "hours") <= 2) {
    timeMsgs = [
      ["lát", "xíu", "tý"],
      ["nữa", ""],
      ["8h", "8 giờ", ""],
    ]
      .map((txt) => (Array.isArray(txt) ? _.sample(txt) : txt.trim()))
      .filter(Boolean)
      .join(" ")
      .trim();
  }

  const content = [timeMsgs, ["chơi", "bắn"], ["ko", "không", "k"], inviteName]
    .map((txt) => (Array.isArray(txt) ? _.sample(txt) : txt.trim()))
    .filter(Boolean)
    .join(" ")
    .trim();

  api.sendSteamMessage({
    messages: {
      content,
      reply: getReplyMsg(),
    },
    receivers: {
      friendSteamId: steamId,
    },
  });
  return [getPersonaName(steamId), inviteName].filter(Boolean).join(" - ");
}

export function getInviteName(steamId) {
  const info = useStore.getState().friendsInfo[steamId];
  const inviteName = info?.inviteName;
  const relationship = info?.relationship;
  let name;

  if (inviteName) {
    name = inviteName;
  } else {
    switch (relationship) {
      case "younger brother":
        name = ["em zai", "em dai", "em giai", "em"];
        break;
      case "younger sister":
        name = ["em"];
        break;
      case "older brother":
        name = ["anh zai", "anh"];
        break;
      case "older sister":
        name = ["chị"];
        break;
      default:
        name = ["ông", "ong", "bro", "b", "bn", "pro"];
    }
  }

  if (!Array.isArray(name)) {
    name = [name];
  }
  return _.sample(name);
}

export function getMyName(steamId) {
  const info = useStore.getState().friendsInfo[steamId];
  const relationship = info?.relationship;
  let name;
  switch (relationship) {
    case "younger brother":
    case "younger sister":
      name = ["anh", "a", "ah"];
      break;
    case "older brother":
    case "older sister":
      name = ["e", "em"];
      break;
    default:
      name = ["t", "tui", "toi", "tôi"];
  }
  return _.sample(name);
}

export function getPersonaName(steamId) {
  return useStore.getState().friendList.find((friend) => friend.steamId === steamId)?.personaName || "";
}

export async function sendMessageInviteAddFriendOtherAccount(myAccountSteamId, friendSteamId, invite = false) {
  const invite_link = await getQuickInviteLink(myAccountSteamId);
  if (invite_link) {
    const content = [!!invite && `${_.sample(["kết bạn", "add friend", "add", "thêm bạn"])} nick ${_.sample(["mới", "khác", "này"])} của ${await getMyName(friendSteamId)} ${_.sample(["đi", "đê", "ik", "với"])}`, invite_link].filter(Boolean);
    api.sendSteamMessage({
      messages: {
        content,
      },
      receivers: {
        friendSteamId,
      },
    });
    Toast.success("Send invite link to " + getPersonaName(friendSteamId));
  } else {
    Toast.error("Cannot get invite link");
  }
}

export function getFriendObj(friend_id) {
  const { accountList, friendList } = useStore.getState();
  let author = accountList.find(({ _id }) => _id === friend_id);
  if (author) {
    return author;
  }
  author = friendList.find(({ _id }) => _id === friend_id);
  if (author) {
    const account = accountList.find(({ steamId }) => steamId === author.steamId);
    if (account) {
      author = account;
    }
    return author;
  }
}

export function openQuickMessage({ roomId, steamId, friend }) {
  let room = null;
  let callback = null;
  const { friendList, roomList, updateRoomList } = useStore.getState();
  if (!friend && steamId) {
    friend = friendList.find((friend) => friend.steamId === steamId);
  }
  if (roomId) {
    room = roomList.find((r) => r._id === roomId);
    if (!room) {
      api.getRoomList({ roomId }).then(function (result) {
        const _room = result.data?.find((r) => r._id === roomId);
        if (_room) {
          updateRoomList(_room);
          room = _room;
          if (typeof callback === "function") {
            callback();
          }
        } else {
          Toast.error("Cannot find this room");
        }
      });
    } else {
      if (!friend) {
        friend = room.recipients?.[0];
      }
    }
  } else if (steamId) {
    api.findOrCreateChatRoom(friend?._id ? friend : { steamId }).then(function (result) {
      const _room = result?.data?.room;
      if (_room) {
        updateRoomList(_room);
        room = _room;
        if (typeof callback === "function") {
          callback();
        }
      } else {
        Toast.error("Cannot find this room");
      }
    });
  }

  Swal.fire({
    title: "Send quick message to " + friend.personaName,
    input: "text",
    inputPlaceholder: "Messages...",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: "Send",
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      if (room) return;
      await new Promise((resolve) => {
        callback = resolve;
      });
    },
  }).then((result) => {
    if (result.isConfirmed) {
      if (room) {
        api.sendSteamMessage({
          messages: result.value?.trim(),
          receivers: { room },
        });
      } else {
        Swal.showLoading();
        callback = function () {
          Swal.hideLoading();
          api.sendSteamMessage({
            messages: result.value?.trim(),
            receivers: { room },
          });
        };
      }
    }
  });
  // eventEmitter.ShowingChat(friend.steamId)
}

initSocket();
