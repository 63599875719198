import React from "react";

const XpBonus = React.memo(function XpBonus({ xp, className }) {
  let color = "";
  if (xp < 4500) {
    color = "text-green-500";
  } else if (xp < 7500) {
    color = "text-blue-600";
  } else if (xp < 11200) {
    color = "text-orange-600";
  } else {
    color = "text-red-500";
  }

  return <span className={`${color} ${className || ""}`}>{xp}xp</span>;
});

export default XpBonus;
