import React, { useCallback, useState } from "react";
import { ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";

const ContextMenu = React.memo(({ id, data, menu, children, ...rest }) => {
  const [isOpen, setOpen] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onContextMenu = useCallback((e) => {
    if (typeof document.hasFocus === "function" && !document.hasFocus()) return;
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    setOpen(true);
  }, []);

  return (
    <div onContextMenu={onContextMenu}>
      {children}
      <ControlledMenu anchorPoint={anchorPoint} state={isOpen ? "open" : "closed"} direction="right" onClose={onClose}>
        {menu.map(({ onClick, label, key, children }) => {
          key = key ?? label;

          if (Array.isArray(children)) {
            if (children.length === 1) {
              const child = children[0];
              label = [label, child.label].join(" - ");
              return (
                <MenuItem onClick={() => child.onClick(data)} key={key}>
                  {label}
                </MenuItem>
              );
            } else if (children.length > 1) {
              return (
                <SubMenu key={key} label={label}>
                  {children.map(function ({ onClick, key: childKey, label }) {
                    childKey = [key, childKey ?? label].join("_");

                    return (
                      <MenuItem onClick={() => onClick(data)} key={childKey}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              );
            }
          }
          return (
            <MenuItem onClick={() => onClick(data)} key={key}>
              {label}
            </MenuItem>
          );
        })}
      </ControlledMenu>
    </div>
  );
});

export default ContextMenu;
