import React, { useCallback, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { useLocalStorage } from "@uidotdev/usehooks";

const options = (() => {
  return [
    "Ignore my account",
    "Ignore my boosting account",
    "Double Friend",
    "Not friend of limited account",
    {
      icon: <FontAwesomeIcon icon={faMicrophone} />,
      value: "Mic",
    },
  ].map(function (op) {
    const label = typeof op === "string" ? op : op.label;
    const value = typeof op === "string" ? op : op.value;
    const icon = op.icon;

    return {
      label: icon ? (
        <span>
          {icon}
          &nbsp;Mic
        </span>
      ) : (
        label
      ),
      value,
    };
  });
})();

const useMultipleFilterCriteria = function () {
  const [values, setValues] = useLocalStorage("FriendList_multipleFilterCriteria", []);
  const [valueObj, setValueObj] = useState(function () {
    return options.reduce(function (previousValue, currentValue) {
      if (values.includes(currentValue.value)) {
        previousValue[currentValue.value] = true;
      }
      return previousValue;
    }, {});
  });
  const [value, setValue] = useState(function () {
    return options.filter(({ value }) => values.includes(value));
  });

  const onChange = useCallback(
    (values) => {
      const valueList = values.map(({ value }) => value);
      setValues(valueList);
      setValueObj(
        values.reduce((acc, currentValue) => {
          acc[currentValue.value] = true;
          return acc;
        }, {}),
      );
      setValue(options.filter(({ value }) => valueList.includes(value)));
    },
    [setValues],
  );

  const MultipleFilterCriteria = useCallback(() => {
    return <MultiSelect className="w-52" allowClear options={options} value={value} onChange={onChange} hasSelectAll={false} disableSearch={true} labelledBy="MultipleFilterCriteria" />;
  }, [onChange, value]);

  return {
    valueObj,
    MultipleFilterCriteria,
  };
};
export default useMultipleFilterCriteria;
