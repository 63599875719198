import { Checkbox, Select } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import copy from "copy-to-clipboard";
import useStore, { inventoryHistorySortIteratees } from "store";
import SimpleButton from "shared/SimpleButton";
import InventoryHistoryItem from "myaccount/tabs/InventoryHistoryItem";
import Toast from "toast";
import moment from "moment";
import { calcItemsPrice, getbonus_xp_timestamp_refresh } from "../../utils";
import { useLocalStorage } from "@uidotdev/usehooks";

const ItemDrops = ["Got an item drop", "Earned a new rank and got a drop"];

const defaultDateRange = "7 days";
const DateRangeList = [
  {
    label: "1 day",
    getValue() {
      return moment().subtract(1, "days");
    },
  },
  {
    label: "7 days",
    getValue() {
      return moment().subtract(7, "days");
    },
  },
  {
    label: "this week",
    getValue() {
      return moment().startOf("week");
    },
  },
  {
    label: "this drop week",
    getValue() {
      return getbonus_xp_timestamp_refresh();
    },
  },
  {
    label: "30 days",
    getValue() {
      return moment().subtract(30, "days");
    },
  },
  {
    label: "90 days",
    getValue() {
      return moment().subtract(90, "days");
    },
  },
];
const DateRangeOptions = DateRangeList.map(({ label }) => ({ label, value: label }));
export default function MyInventoryHistory() {
  const mergeAllAccount = useStore((state) => state.mergeAllAccount);
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const inventoryHistories = useStore((state) => state.inventoryHistories);
  const [inventoryHistoryDescriptionFilter, setInventoryHistoryDescriptionFilter] = useState();
  const tableRef = useRef();
  const subToolRef = useRef();
  const [selectedHistoryItems, setSelectedHistoryItems] = useState({});
  const inventoryHistoryData = useMemo(() => {
    return (mergeAllAccount ? _.sortBy(Object.values(inventoryHistories).flat(), inventoryHistorySortIteratees).reverse() : inventoryHistories[selectedAccountID]) || [];
  }, [mergeAllAccount, inventoryHistories, selectedAccountID]);
  const [inventoryHistoryTag, setInventoryHistoryTag] = useState();
  const [dateRange, setDateRange] = useLocalStorage("MyInventoryHistory_dateRange", defaultDateRange);

  const inventoryHistoryTagOptions = useMemo(() => {
    let options = inventoryHistoryData
      .map(function (item) {
        return (
          item.tradehistory_items?.map?.((tradehistory_item) => {
            return tradehistory_item?.tags?.map?.((tag) => tag.name) || [];
          }) || []
        );
      })
      .flat(Infinity)
      .filter(Boolean);
    options = _.uniq(options);
    options = _.sortBy(options, (option) => (option === "Container" ? -1 : 0));
    return options.map(function (item) {
      return {
        key: item,
        value: item,
        label: item,
      };
    });
  }, [inventoryHistoryData]);

  const inventoryHistoryDisplayData = useMemo(() => {
    let result = inventoryHistoryData;
    if (inventoryHistoryDescriptionFilter) {
      result = result.filter(function (historyItem) {
        if (ItemDrops.includes(inventoryHistoryDescriptionFilter)) {
          return ItemDrops.includes(historyItem.description);
        } else {
          return historyItem.description === inventoryHistoryDescriptionFilter;
        }
      });
    }
    if (inventoryHistoryTag) {
      result = result
        .filter(function (historyItem) {
          return historyItem.tradehistory_items?.some?.(function (tradehistory_item) {
            return Array.isArray(tradehistory_item.tags) && tradehistory_item.tags.some((tag) => tag.name === inventoryHistoryTag);
          });
        })
        .map(function (historyItem) {
          const tradehistory_items = historyItem.tradehistory_items.filter(function (tradehistory_item) {
            return Array.isArray(tradehistory_item.tags) && tradehistory_item.tags.some((tag) => tag.name === inventoryHistoryTag);
          });
          return {
            ...historyItem,
            tradehistory_items,
          };
        });
    }

    const dateRangeMoment = DateRangeList.find((range) => range.label === (dateRange || defaultDateRange))?.getValue();
    result = result.filter(function (historyItem) {
      return moment(historyItem.timestamp * 1000).isAfter(dateRangeMoment);
    });

    return result.map(function (item, index) {
      return {
        ...item,
        index,
      };
    });
  }, [inventoryHistoryData, inventoryHistoryDescriptionFilter, inventoryHistoryTag, dateRange]);

  const inventoryHistoryOptions = useMemo(() => {
    let options = inventoryHistoryData
      .map(function (item) {
        return ItemDrops.includes(item.description) ? ItemDrops[0] : item.description;
      })
      .filter(Boolean);
    options = _.uniq(options);
    options = _.sortBy(options, function (option) {
      return ItemDrops.includes(option) ? -1 : 0;
    });
    return options.map(function (option) {
      return { value: option, label: option };
    });
  }, [inventoryHistoryData]);

  useEffect(() => {
    const dateRangeMoment = DateRangeList.find((range) => range.label === (dateRange || defaultDateRange))?.getValue();
    useStore.getState().getInventoryHistories(dateRangeMoment.unix());
  }, [dateRange, selectedAccountID]);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }
    tableRef.current.style.height = `calc(100vh - ${tableRef.current.getBoundingClientRect().y + 1}px)`;
  }, [tableRef]);

  const handleOnClickGetInventoryHistory = useCallback(async () => {
    const dateRangeMoment = DateRangeList.find((range) => range.label === (dateRange || defaultDateRange))?.getValue();
    await useStore.getState().getInventoryHistories(dateRangeMoment.unix());
  }, [dateRange]);

  const handleOnClickCalcSelectedItemsPrice = useCallback(async () => {
    const items = Object.values(selectedHistoryItems).filter((item) => inventoryHistoryDisplayData?.some?.((historyItem) => historyItem.tradehistory_items?.some?.((_item) => _item.id === item.id)));
    await calcItemsPrice(items);
  }, [selectedHistoryItems, inventoryHistoryDisplayData]);

  const handleOnClickCopySelectedItemsInfo = useCallback(async () => {
    const itemByGroup = _.groupBy(Object.values(selectedHistoryItems), "classid");
    const text = Object.keys(itemByGroup)
      .map(function (classid) {
        const total = itemByGroup[classid].length;
        const name = itemByGroup[classid][0].market_hash_name;
        return `${total} ${name}`;
      })
      .join("\n");
    Toast.success(text);
    copy(text);
  }, [selectedHistoryItems]);

  const handleOnClickFetchInventoryHistory = useCallback(async () => {
    const { fetchInventoryHistories, selectedAccountID } = useStore.getState();
    await fetchInventoryHistories(
      selectedAccountID,
      DateRangeList.find((p) => p.label === (dateRange || defaultDateRange))
        .getValue()
        .valueOf(),
    );
  }, [dateRange]);

  const fetchInventoryHistoryMenu = useMemo(() => {
    return [
      {
        label: "Fetch All",
        async onClick() {
          const { accountList, fetchInventoryHistories } = useStore.getState();
          await fetchInventoryHistories(
            accountList.map((a) => a.steamId),
            DateRangeList.find((p) => p.label === (dateRange || defaultDateRange))
              .getValue()
              .valueOf(),
          );
          useStore.getState().getMyAccount();
        },
      },
    ];
  }, [dateRange]);

  return (
    <div className="relative">
      <div className="relative" ref={subToolRef}>
        <div className="py-1">
          <span className="px-3">
            {inventoryHistoryDisplayData.length}/{inventoryHistoryData.length}
          </span>
          <Select placeholder="Description" value={inventoryHistoryDescriptionFilter} onChange={setInventoryHistoryDescriptionFilter} className="w-64 mx-1" options={inventoryHistoryOptions} allowClear />
          <Select placeholder="Select type" value={inventoryHistoryTag} onChange={setInventoryHistoryTag} className="w-64 mx-1" options={inventoryHistoryTagOptions} allowClear />
          <Select placeholder="Select Date Range" value={dateRange} onChange={setDateRange} className="w-48 mx-1" options={DateRangeOptions} allowClear />

          <Checkbox
            onChange={function (e) {
              const checked = e.target.checked;
              setSelectedHistoryItems(function (_selectedHistoryItems) {
                if (checked) {
                  const prev = { ..._selectedHistoryItems };
                  for (const inventoryHistory of inventoryHistoryDisplayData) {
                    inventoryHistory.tradehistory_items.forEach(function (tradehistory_item) {
                      prev[tradehistory_item.id] = tradehistory_item;
                    });
                  }
                  return prev;
                } else {
                  return {};
                }
              });
            }}
          >
            Select All
          </Checkbox>
        </div>
        <div className="py-1 flex flex-nowrap justify-start">
          <SimpleButton className="mx-1" onClick={handleOnClickGetInventoryHistory} type="primary">
            Get Inventory History
          </SimpleButton>

          <SimpleButton className="mx-1" onClick={handleOnClickFetchInventoryHistory} type="primary" menu={fetchInventoryHistoryMenu}>
            Fetch Inventory History
          </SimpleButton>

          <SimpleButton className="mx-1" onClick={handleOnClickCalcSelectedItemsPrice} type="primary">
            Calc selected items price
          </SimpleButton>

          <SimpleButton onClick={handleOnClickCopySelectedItemsInfo} type="primary">
            Copy selected items info
          </SimpleButton>
        </div>
      </div>
      <div ref={tableRef} className="w-full overflow-y-auto">
        <table className="w-full m-h-full">
          <tbody>
            {inventoryHistoryDisplayData.map((historyItem, index) => (
              <InventoryHistoryItem historyItem={historyItem} selectedHistoryItems={selectedHistoryItems} setSelectedHistoryItems={setSelectedHistoryItems} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
