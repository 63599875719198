import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Input, Modal } from "antd";
import useStore, { useAccountBySteamId } from "store";
import api from "api";
import Toast from "../toast";
import SimpleButton from "../shared/SimpleButton";

function ReakConfirmCodeForAddPhoneModal({ steamId }) {
  const formRef = useRef();
  const formItemsRef = useRef();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const handleCancel = useCallback(() => {
    const { confirmCodeForAddPhoneModal, setConfirmCodeForAddPhoneModal } = useStore.getState();
    confirmCodeForAddPhoneModal?.callback?.(false);
    setConfirmCodeForAddPhoneModal(null);
  }, []);

  const handleResendOpt = useCallback(() => {
    console.log("handleResendOpt");
  }, []);

  const handleOk = useCallback(
    async ({ opt_code }) => {
      const confirmResult = (
        await api.confirmPhoneCodeForAdd({
          steamId,
          optCode: opt_code,
        })
      ).data;
      if (!confirmResult?.success) {
        setShowResend(true);
        Toast.error(`Invalid optCode (${opt_code})`);
        formRef.current.resetFields();
      } else {
        Toast.success(`Added phone number successfully`);

        const { confirmCodeForAddPhoneModal, setConfirmCodeForAddPhoneModal } = useStore.getState();
        confirmCodeForAddPhoneModal?.callback?.(true);
        setConfirmCodeForAddPhoneModal(null);
      }
    },
    [steamId],
  );

  useEffect(() => {
    setTimeout(function () {
      formItemsRef.current?.focus();
    }, 500);
  }, []);

  const onFinish = useCallback(async () => {
    if (typeof handleOk === "function") {
      setConfirmLoading(true);
      await handleOk(formRef.current.getFieldsValue());
      setConfirmLoading(false);
    }
  }, []);

  return (
    <Modal
      width={700}
      style={{
        maxHeight: "80vh",
        maxWidth: "80vw",
      }}
      open
      title="Please input opt code"
      onOk={() => {
        formRef.current.submit();
      }}
      confirmLoading={confirmLoading}
      footer={[
        !!showResend && (
          <SimpleButton key="resend" onClick={handleResendOpt}>
            Resend OPT
          </SimpleButton>
        ),
        <SimpleButton key="back" onClick={handleCancel}>
          Cancel
        </SimpleButton>,
        <SimpleButton key="submit" type="primary" onClick={onFinish}>
          Submit
        </SimpleButton>,
      ].filter(Boolean)}
    >
      <Form className="max-w-[70vw] max-h-[70vh] overflow-y-auto" ref={formRef} onFinish={onFinish} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} layout="horizontal" disabled={confirmLoading} autoComplete="off">
        <Form.Item name="opt_code">
          <Input
            onKeyDown={(event) => {
              if (event.code === "Enter") {
                event.preventDefault();
                formRef.current.submit();
              }
            }}
            ref={(el) => (formItemsRef.current = el)}
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function ConfirmCodeForAddPhoneModal() {
  const steamId = useStore((state) => state.confirmCodeForAddPhoneModal?.steamId);
  if (!steamId) return;
  return <ReakConfirmCodeForAddPhoneModal steamId={steamId} />;
}
