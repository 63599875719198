import React, { useMemo } from "react";
import classNames from "classnames";
import { Dropdown } from "antd";
import copy from "copy-to-clipboard";
import FriendBlock from "features/friend/friendblock/FriendBlock";
import Toast from "toast";
import useStore from "store";
import Swal from "sweetalert2";
import api from "api";
import { useCustomLobby } from "../store/UserSlice";

const FriendGroup = React.memo(({ steamPlayerGroup, groupMembers }) => {
  const { isCustomLobby, customLobby } = useCustomLobby(steamPlayerGroup);
  const myAccountSteamIds = useStore.getState().myAccountSteamIds;
  const isVisible = steamPlayerGroup && steamPlayerGroup !== "0" && !steamPlayerGroup.startsWith("_");
  let dropDownEl = (
    <span
      className={classNames("flex relative items-center font-bold w-14 -ml-2 pl-1 border border-r-0 border-solid rounded-2xl my-4 bg-transparent translate-x-2/4", {
        "border-transparent": !isVisible,
        "border-sky-500 cursor-pointer": isVisible,
      })}
    >
      {isVisible ? groupMembers.length : ""}
    </span>
  );
  if (isVisible) {
    const menu = [
      !isCustomLobby && {
        label: "Mark as custom lobby",
        onClick() {
          copy(steamPlayerGroup);
          Toast.success(steamPlayerGroup);
          useStore.getState().markAsCustomLobby(steamPlayerGroup);
        },
      },
      !!isCustomLobby && {
        label: "Unmark as custom lobby",
        onClick() {
          copy(steamPlayerGroup);
          Toast.success(steamPlayerGroup);
          useStore.getState().unmarkAsCustomLobby(steamPlayerGroup);
        },
      },
      {
        label: "Copy steam_player_group",
        onClick() {
          copy(steamPlayerGroup);
          Toast.success(steamPlayerGroup);
        },
      },
      {
        label: "Create chat room",
        onClick() {
          // if (uuidValidate(steam_player_group)) {
          //     const steamIds = group.filter(g => g.lobby_id === steam_player_group).map(g => g.member_steamid)
          //     if (steamIds.length > 1) {
          //         socket.createChatRoom(steamIds)
          //     } else {
          //         toast.error("steamIds size: " + steamIds.length)
          //     }
          // } else {
          //     const steamIds = Object.values(playerState).filter(playerState => playerState.steam_player_group === steam_player_group).map(({steamId}) => steamId);
          //     if (steamIds.length > 1) {
          //         socket.createChatRoom(steamIds)
          //     } else {
          //         toast.error("steamIds size: " + steamIds.length)
          //     }
          // }
        },
      },
      {
        label: "Delete group",
        onClick() {
          api.removeParty({ lobby_id: steamPlayerGroup }).then(function (result) {
            if (Array.isArray(result.data)) {
              useStore.setState({ party: result.data });
            }
          });
        },
      },
      {
        label: "Copy members name",
        onClick() {
          const { friendList } = useStore.getState();
          const nameList = groupMembers
            .map(function (member) {
              const friend = friendList.find(({ steamId }) => steamId === member.steamId);
              return friend?.state?.name || friend?.state?.player_name || friend?.personaName;
            })
            .join(", ");
          copy(nameList);
          Toast.success(nameList);
        },
      },
      {
        label: "Send mesages",
        async onClick() {
          const { friendList } = useStore.getState();
          const nameList = groupMembers
            .map(function (member) {
              const friend = friendList.find(({ steamId }) => steamId === member.steamId);
              return friend?.state?.name || friend?.state?.player_name || friend?.personaName;
            })
            .join(", ");
          Swal.fire({
            title: "Send quick message to " + nameList,
            input: "text",
            inputPlaceholder: "Messages...",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Send",
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (!result.isConfirmed) {
              return;
            }
            const messages = result.value?.trim();
            if (!messages) {
              return;
            }
            api.sendSteamMessage({
              messages: messages,
              receivers: groupMembers.map(function (friend) {
                return {
                  friendId: friend._id,
                  friendSteamId: friend.steamId,
                };
              }),
            });
          });
        },
      },
    ];

    dropDownEl = (
      <Dropdown
        menu={{
          items: menu.map(function (item) {
            return {
              key: item.label,
              label: item.label,
            };
          }),
          onClick(e) {
            menu.find(({ label }) => label === e.key)?.onClick();
          },
        }}
        arrow
        trigger={"contextMenu"}
      >
        {dropDownEl}
      </Dropdown>
    );
  }

  const watchers = useMemo(() => {
    if (!Array.isArray(customLobby?.watchers) || !customLobby.watchers.length) {
      return;
    }
    const { friendList } = useStore.getState();

    return (
      <div className="grid">
        {customLobby.watchers.map(function (watcher, index) {
          return (
            <div
              key={watcher}
              onClick={() => {
                useStore.getState().showChat(watcher);
              }}
              className="_tag"
            >
              {index + 1} {friendList.find(({ steamId }) => steamId === watcher)?.personaName}
            </div>
          ); //friendList[watcher]?.personaName
        })}
      </div>
    );
  }, [customLobby]);

  return (
    <div className="flex h-min relative p-2" key={steamPlayerGroup}>
      {dropDownEl}

      <div>
        {watchers}
        {groupMembers.map(function (friend) {
          return <FriendBlock key={`FriendBlock_${friend.steamId}`} friend={friend} isMyAccount={myAccountSteamIds.includes(friend.steamId)} />;
        })}
      </div>
    </div>
  );
}, getFriendGroupSortValue);

export default FriendGroup;

export function getFriendGroupSortValue(group1, group2) {
  if (group1 === group2) {
    return true;
  }

  if (!!group1 !== !!group2) {
    return false;
  }

  if (group1.steam_player_group !== group2.steam_player_group) {
    return false;
  }
  if (group1.groupMembers.length !== group2.groupMembers.length) {
    return false;
  }

  const group2MembersSet = new Set(group2.groupMembers);
  for (const member of group1.groupMembers) {
    if (!group2MembersSet.has(member)) {
      return false;
    }
  }
  return true;
}
