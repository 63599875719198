import React, { useRef } from "react";
import { Input, Modal } from "antd";
import useStore, { ModalType } from "../store";
import api from "api";
import Toast from "../toast";

function RealAddSteamGuardModal() {
  const data = useStore((state) => state.currentModal[ModalType.SubmitSteamGuard]);
  const { username, code } = data || {};
  const inputRef = useRef();

  return (
    <React.Fragment>
      <Modal
        title={`Submit Steam Guard ${username}`}
        open={true}
        onOk={async () => {
          const result = (
            await api.submitSteamGuardCode({
              steamGuard: inputRef.current.input.value?.trim(),
              username,
            })
          )?.data;
          if (result && !result.error) {
            useStore.getState().hideModal(ModalType.SubmitSteamGuard);
          } else {
            Toast.error(result?.error || `Invalid SteamGuard Code`);
          }
        }}
        onCancel={function () {
          useStore.getState().hideModal(ModalType.SubmitSteamGuard);
        }}
      >
        <Input ref={inputRef} placeholder="Steam Guard" defaultValue={code || ""} />
      </Modal>
    </React.Fragment>
  );
}

const AddSteamGuardModal = React.memo(function AddSteamGuardModal() {
  const currentModal = useStore((store) => store.currentModal);
  if (!currentModal.hasOwnProperty(ModalType.SubmitSteamGuard)) return;
  return <RealAddSteamGuardModal />;
});

export default AddSteamGuardModal;
