import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, InputNumber, Modal, Select } from "antd";
import useStore from "store";
import api from "api/api";
import _ from "lodash";
import { useLocalStorage } from "@uidotdev/usehooks";
import classNames from "classnames";
import useInterval from "../useInterval";
import ringtone from "../ringtone-193209.mp3";
import { getAvatarURLfromHash } from "../utils";
import { addListener, removeListener } from "../socket";

const typeOptions = ["Deathmatch", "Casual", "Competitive", "Premier"].map((o) => ({
  label: o,
  value: o,
}));

const RealFakeFriendStateModal = React.memo(({ steamId }) => {
  const friendList = useStore((state) => state.friendList);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [type, setType] = useLocalStorage("FakeFriendState_Type");
  const [prime, setPrime] = useLocalStorage("FakeFriendState_Prime");
  const [ignoreMyAccount, setIgnoreMyAccount] = useLocalStorage("FakeFriendState_ignoreMyAccount", true);
  const [intervalMs, setIntervalMs] = useLocalStorage("FakeFriendState_intervalMs", 30);
  const setFakeFriendStateModal = useStore((state) => state.setFakeFriendStateModal);
  const [avaiableState, setAvaiableState] = useState({});
  const [startIntervalDelay, setStartIntervalDelay] = useState(0);
  const [requestingJoinFriendData, setRequestingJoinFriendData] = useState(false);

  useEffect(() => {
    const id = addListener("requestJoinFriendResult", function (data) {
      setAvaiableState(function (state) {
        return {
          ...state,
          ...data,
        };
      });

      const aviablePlayerSteamId = Object.keys(data).find((steamId) => !data[steamId].errormsg);
      if (aviablePlayerSteamId) {
        setSelectedPlayer(aviablePlayerSteamId);
        setStartIntervalDelay(0);
        new Audio(ringtone).play();
        api.fakeFriendState({
          myAccountID: steamId,
          steamId: aviablePlayerSteamId,
        });
      }
    });

    return function () {
      removeListener(id);
    };
  }, [steamId]);

  const list = useMemo(
    function () {
      let _list = friendList;
      if (type) {
        _list = _list.filter((p) => p.state?.score?.startsWith?.(type));
      }
      if (prime) {
        const _prime = prime.toLowerCase();
        _list = _list.filter(function (p) {
          return p?.primeStatus?.toLowerCase() === _prime;
        });
      }
      if (ignoreMyAccount) {
        const myAccountSteamIds = useStore.getState().myAccountSteamIds;
        _list = _list.filter(function (p) {
          return !myAccountSteamIds.includes(p?.steamId);
        });
      }
      return _.sortBy(_list, function (p) {
        return p.score?.startsWith?.("Deathmatch") ? -1 : 0;
      }).map(function (p) {
        const avatar = getAvatarURLfromHash(p.avatarHash);
        const primeLabel = !prime && p?.primeStatus ? `[${p?.primeStatus}]` : "";
        const label = [p?.personaName, useStore.getState().friendsInfo[p.steamId]?.inviteName, p.state?.score].filter(Boolean).join(" - ");

        return {
          label: (
            <span
              className={classNames({
                "text-green-500": avaiableState[p.steamId] && !avaiableState[p.steamId].errormsg,
                "text-rose-700": !!avaiableState[p.steamId]?.errormsg,
              })}
            >
              <img src={avatar} className="w-6 h-6 mr-2.5" />
              {[primeLabel, label].filter(Boolean).join(" ")}
            </span>
          ),
          value: p.steamId,
        };
      });
    },
    [friendList, type, prime, avaiableState, ignoreMyAccount],
  );

  const requestJoinFriendData = async () => {
    if (requestingJoinFriendData || !list.length) return;
    setRequestingJoinFriendData(true);
    const result = (await api.requestJoinFriendData({ steamIds: list.map(({ value }) => value) })).data;
    setRequestingJoinFriendData(false);
    if (!result) return;
    const newState = {};
    for (const stateList of result) {
      Object.assign(newState, stateList);
    }
    setAvaiableState(function (state) {
      return {
        ...state,
        ...newState,
      };
    });
  };

  useInterval(requestJoinFriendData, startIntervalDelay);

  const handleSubmit = useCallback(() => {
    api.fakeFriendState({
      myAccountID: steamId,
      steamId: selectedPlayer,
    });
  }, [selectedPlayer, steamId]);

  return (
    <React.Fragment>
      <Modal
        width={700}
        style={{
          maxHeight: "80vh",
          maxWidth: "80vw",
        }}
        open={true}
        title={"Fake friend state of " + useStore.getState().friendList.find((friend) => friend.steamId === steamId)?.personaName}
        footer={[
          <span className="mr-2">
            <span className="mr-1">interval</span>
            <InputNumber value={intervalMs} onChange={setIntervalMs} min={5} step={5}></InputNumber>
          </span>,
          <Button
            key="check_available"
            loading={requestingJoinFriendData}
            disabled={!!startIntervalDelay}
            onClick={function () {
              requestJoinFriendData();
              setStartIntervalDelay(intervalMs * 1000);
            }}
          >
            Check available
          </Button>,
          <Button
            key="reset_available_state"
            onClick={function () {
              setAvaiableState({});
              setStartIntervalDelay(0);
            }}
          >
            Clear state
          </Button>,
          <Button
            key="close"
            onClick={function () {
              setFakeFriendStateModal();
            }}
          >
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        <div>
          <Checkbox checked={ignoreMyAccount} onChange={(e) => setIgnoreMyAccount(e.target.checked)}>
            Ignore My Account
          </Checkbox>
        </div>

        <div className="flex w-full flex-row items-center">
          <div className="w-1/2">
            <span>Type</span>
            <Select className="w-full" value={type} options={typeOptions} onChange={setType} allowClear placeholder="Type"></Select>
          </div>

          <div className="w-1/2">
            <span>Prime</span>
            <Select
              className="w-full"
              value={prime}
              options={["Prime", "Non-prime"].map((o) => ({
                label: o,
                value: o,
              }))}
              onChange={setPrime}
              allowClear
              placeholder="Prime"
            ></Select>
          </div>
        </div>

        <div>
          <span>List</span>
          <Select className="w-full" value={selectedPlayer} options={list} onChange={setSelectedPlayer} allowClear></Select>
        </div>
      </Modal>
    </React.Fragment>
  );
});

export default function FakeFriendStateModal() {
  const fakeFriendStateModal = useStore((state) => state.fakeFriendStateModal);
  if (!fakeFriendStateModal) return null;
  return <RealFakeFriendStateModal steamId={fakeFriendStateModal} />;
}
