import useStore from "store";
import React, { useMemo, useState } from "react";
import { Select } from "antd";
import { toNonAccentVietnameseLowerCase } from "alpha-common-utils/StringUtils";
import { useDebounce } from "@uidotdev/usehooks";
import SteamAvatar from "./components/SteamAvatar";

function FriendPicker({ selectedFriend, setSelectedFriend }) {
  const friendList = useStore((state) => state.friendList);
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const options = useMemo(() => {
    let query = debouncedSearchTerm?.trim()?.replaceAll(" ", "");

    let options = friendList;
    if (query) {
      query = toNonAccentVietnameseLowerCase(query);
      options = friendList.filter(function (friend) {
        const values = Object.values(friend).filter((v) => v && typeof v === "string");
        return values.some(function (v) {
          return toNonAccentVietnameseLowerCase(v)?.replaceAll(" ", "").includes(query);
        });
      });
    }

    return options.map(function (friend) {
      return {
        label: (
          <span>
            <SteamAvatar avatarHash={friend.avatarHash} className="w-7 h-7 mr-2" />
            {friend.personaName}
          </span>
        ),
        value: friend.steamId,
      };
    });
  }, [debouncedSearchTerm, friendList]);

  return <Select allowClear showSearch onSearch={setSearchTerm} filterOption={false} value={selectedFriend} className="w-64" options={options} onChange={setSelectedFriend}></Select>;
}

export default FriendPicker;
