import React, { useCallback, useEffect, useMemo, useState } from "react";
import api from "api";
import { RealSimpleConfirmModal } from "../modal/SimpleConfirmModal";
import useStore from "store";
import _ from "lodash";

export function UpdateFriendInfoModal() {
  const UpdateFriendInfoModalData = useStore((state) => state.UpdateFriendInfoModalData);
  if (!UpdateFriendInfoModalData?.steamId) {
    return;
  }
  return <RealUpdateFriendInfoModal steamId={UpdateFriendInfoModalData.steamId} name={UpdateFriendInfoModalData.name} />;
}

function RealUpdateFriendInfoModal({ steamId, name }) {
  const [data, setdata] = useState(null);
  const discordFriend = useStore((state) => state.discordFriend);
  const NatriGuildMembers = useStore((state) => state.NatriGuildMembers);

  const options = useMemo(() => {
    const friends = NatriGuildMembers?.length
      ? discordFriend.filter(function (friend) {
          return NatriGuildMembers.every(({ id }) => id !== friend.id);
        })
      : discordFriend;
    return [...(NatriGuildMembers || []), ...friends];
  }, [NatriGuildMembers, discordFriend]);

  useEffect(() => {
    (async () => {
      let result = await api.getFriendInfo({ steamId });
      result?.data && setdata(result?.data);
    })();
  }, [steamId]);

  const handleCancel = useCallback(() => {
    useStore.setState({
      UpdateFriendInfoModalData: null,
    });
  }, []);

  const onOk = useCallback(
    async (data) => {
      const result = (
        await api.updateFriendInfo({
          steamId,
          ...data,
        })
      )?.data;
      if (result?._id && result?.steamId) {
        useStore.getState().updateFriendsInfo(result);
      }
      useStore.setState({
        UpdateFriendInfoModalData: null,
      });
    },
    [steamId],
  );

  return (
    data && (
      <>
        <RealSimpleConfirmModal
          handleOk={onOk}
          handleCancel={handleCancel}
          fields={[
            {
              type: "Checkbox",
              name: "customInvite",
              label: "Custom Invite",
            },
            {
              type: "Checkbox",
              name: "ignoreFakeInvite",
              label: "Ignore fake invite",
            },
            {
              type: "Checkbox",
              name: "cheater",
              label: "Cheater",
            },
            {
              type: "Checkbox",
              name: "mic",
              label: "Mic",
            },
            {
              type: "Radio",
              name: "skill",
              label: "Skill",
              options: ["Easy", "Medium", "Good"],
            },
            {
              type: "Radio",
              name: "relationship",
              label: "Relationship",
              options: ["younger brother", "younger sister", "older brother", "older sister"],
            },
            {
              type: "Input",
              name: "inviteName",
              label: "Invite Name",
            },
            {
              type: "Input",
              name: "realName",
              label: "Real Name",
            },
            {
              type: "SelectMulti",
              name: "otherNames",
              label: "Other Name",
            },
            {
              type: "InputNumber",
              name: "yearOfBirth",
              label: "Year of birth",
            },
            {
              type: "Input",
              name: "location",
              label: "Location",
            },
            {
              type: "Input",
              name: "facebook",
              label: "Facebook",
              afterBtn: {
                label: "go",
                async onClick(field) {
                  window.open(data[field.name], "_blank").focus();
                },
              },
            },
            {
              type: "Select",
              name: "discord",
              label: "Discord",
              options: options.map((friend) => ({
                value: friend.id,
                data: friend,
                name: (
                  <div>
                    <img width={24} height={24} src={friend.avatar?.startsWith?.("http") ? friend.avatar : `https://cdn.discordapp.com/avatars/${friend.id}/${friend.avatar}.webp?size=32`} alt="" />
                    {[friend.username, friend.nickname].filter(Boolean).join(" - ")}
                  </div>
                ),
              })),
              afterBtn: {
                label: "Load friend list",
                async onClick() {
                  const result = (await api.getNatriGuildMembers())?.data;
                  const members =
                    result?.map(function (member) {
                      return {
                        ...member,
                        ...member.user,
                      };
                    }) || [];

                  useStore.setState({
                    NatriGuildMembers: members,
                  });
                },
              },
            },
            {
              type: "Input",
              name: "phone",
              label: "Phone Number",
            },
            {
              type: "Input",
              name: "tradeURL",
              label: "Trade URL",
            },
          ]}
          title={`Update friend info ${name}`}
          visible={true}
          defaultValue={data}
        />
      </>
    )
  );
}
