import classNames from "classnames";
import React, { useMemo } from "react";
import Avatar from "../../Avatar";
import { Dropdown } from "antd";
import { getPersonaName } from "../../../socket";
import { generateMenuItems, getAvatarURLfromHash, getQuickInviteLink, invite2PlayCustom } from "../../../utils";
import _ from "lodash";
import api from "api";
import moment from "moment";
import useStore, { usePlayerState } from "store";
import Toast from "toast";
import MenuItem from "../../../shared/MenuItem";

const SearchPeopleResult = React.memo(function SearchPeopleResult({ result, index, prime, onClick, style }) {
  const tempGroup = useStore((state) => state.tempGroup);
  const selectedRoomID = useStore((state) => state.selectedRoomID);
  const avatar = getAvatarURLfromHash(result.recipients?.[0]?.avatarHash || result.avatarHash);

  const {
    playerStateList: [playerState],
  } = usePlayerState(result.recipients?.[0]?.steamId);

  const isOnline = playerState?.onlineStatus === "online" || playerState?.onlineStatus === "ingame";
  const lastMessage = Array.isArray(result.messages) ? result.messages[result.messages.length - 1] : {};
  const roomName = useMemo(
    function () {
      return _.uniq([result.name, ...(result.recipients?.map?.((recipient) => recipient.personaName) || []), useStore.getState().friendList.find(({ steamId }) => steamId === result.steamId)?.personaName].filter(Boolean)).join(" - ");
    },
    [result.name, result.recipients, result.steamId],
  );
  const menuItems = useMemo(
    () => [
      MenuItem.OpenSteamProfile,
      MenuItem.CopySteamProfile,
      MenuItem.CopySteamID,
      {
        label: "Invite to play custom",
        async onClick({ steamId }) {
          invite2PlayCustom(steamId);
        },
      },
      {
        label: "Invite to add friend other account",
        children: useStore.getState().accountList.map((account) => ({
          label: account.username,
          async onClick({ steamId }) {
            const invite_link = await getQuickInviteLink(account);
            if (!invite_link) {
              return Toast.error("Cannot get invite link");
            }
            const content = [`${_.sample(["kết bạn", "add bạn", "add friend", "thêm bạn"])} nick mới của t ${_.sample(["đi", "nào", "đê", "với"])}`, invite_link];
            api.sendSteamMessage({
              receivers: {
                friendSteamId: steamId,
              },
              messages: {
                content,
              },
            });
            Toast.success("Send invite link to " + getPersonaName(steamId));
          },
        })),
      },
      result.recipients?.length === 1 && {
        label: "Update friend info",
        onClick: async ({ steamId }) => {
          useStore.setState({
            UpdateFriendInfoModalData: {
              steamId,
              name: getPersonaName(steamId),
            },
          });
        },
      },
      result.recipients?.length > 1 || tempGroup?._id === result._id
        ? {
            label: "Delete group chat",
            onClick: () => {
              if (tempGroup?._id === result._id) {
                useStore.getState().deleteTempGroup();
              } else {
                useStore.getState().removeRoomID(result._id);
              }
            },
          }
        : {
            label: "Delete chat room",
            async onClick() {
              useStore.getState().removeRoomID(result._id);
            },
          },
      !result.mute && {
        label: "Mute",
        onClick: async ({ steamId }) => {
          const room = (await api.muteChatRoom({ roomId: result._id }))?.data;
          if (room?._id) {
            useStore.getState().updateRoomList([room]);
          }
        },
      },
      !!result.mute && {
        label: "Unmute",
        onClick: async ({ steamId }) => {
          const room = (await api.unmuteChatRoom({ roomId: result._id }))?.data;
          if (room?._id) {
            useStore.getState().updateRoomList([room]);
          }
        },
      },
      !!tempGroup?.name &&
        result._id !== tempGroup?._id &&
        !tempGroup.recipients.some((recipient) => recipient.steamId === result.recipients?.[0]?.steamId) && {
          label: "Add to group " + tempGroup.name,
          onClick: async ({ steamId }) => {
            useStore.getState().addTempGroupMember(steamId);
          },
        },
      !!tempGroup?.name &&
        result._id !== tempGroup?._id &&
        tempGroup.recipients.some((recipient) => recipient.steamId === result.recipients?.[0]?.steamId) && {
          label: "Remove from group " + tempGroup.name,
          onClick: async ({ steamId }) => {
            useStore.getState().removeTempGroupMember(steamId);
          },
        },
    ],
    [result, tempGroup],
  );
  const { handleMenuClick, items } = generateMenuItems(menuItems);

  return (
    <div key={result._id} style={style}>
      <Dropdown
        menu={{
          items: items,
          onClick: function (e) {
            return handleMenuClick(e, {
              ...result,
              steamId: result?.recipients?.[0]?.steamId || result.steamId,
            });
          },
        }}
        trigger={["contextMenu"]}
      >
        <div style={{ paddingBottom: 5 }} data-testid="mwthreadlist-item-open" onClick={() => onClick(result)}>
          <div className="l9j0dhe7 p8fzw8mz pcp91wgn" data-testid="mwthreadlist-item">
            <div>
              <a
                aria-current="page"
                className={classNames("oajrlxb2 gs1a9yip mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 mg4g778l pfnyh3mw p7hjln8o tgvbjcpo hpfvmrgz l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb btwxx1t3 abiwlrkh p8dawk7l lzcic4wl ue3kfks5 pw54ja7n uo3d90p7 l82x9zwi a8c37x1j kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jktsbyx5 rv4hoivh osnr6wyh h4z51re5", {
                  i224opu6: selectedRoomID === result._id,
                })}
              >
                <div className="rq0escxv l9j0dhe7 du4w35lb gs1a9yip j83agx80 cbu4d94t buofh1pr taijpn5t hpfvmrgz">
                  <div className="rq0escxv l9j0dhe7 du4w35lb j83agx80 pfnyh3mw i1fnvgqd bp9cbjyn owycx6da btwxx1t3">
                    <div className="rq0escxv l9j0dhe7 du4w35lb cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz j83agx80">
                      <div className="j83agx80 rv4hoivh">
                        <div data-visualcompletion="ignore-dynamic" className="rq0escxv l9j0dhe7 du4w35lb q9uorilb">
                          <div className="rq0escxv l9j0dhe7 du4w35lb m7zwrmfr tmrshh9y">
                            <div className="rq0escxv du4w35lb pmk7jnqg datstx6m k4urcfbm">
                              <div className="rq0escxv l9j0dhe7 du4w35lb cwj9ozl2 a8c37x1j ni8dbmo4 stjgntxs s45kfl79 emlxlaya bkmhp75w spb7xbtv">
                                <div className="rq0escxv l9j0dhe7 du4w35lb">
                                  <div className="do00u71z l9j0dhe7 k4urcfbm" style={{ paddingTop: "100%" }}>
                                    <div className="gs1a9yip goun2846 mk2mc5f4 ccm00jje s44p3ltw rt8b4zig sk4xxmp2 n8ej3o3l agehan2d j83agx80 cbu4d94t buofh1pr g5gj957u i1fnvgqd kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso du4w35lb i09qtzwb rq0escxv n7fi1qx3 pmk7jnqg j9ispegn kr520xx4">
                                      <Avatar diameter={"100%"} src={avatar} />
                                      <div className="rq0escxv du4w35lb i09qtzwb oaz4zybt n7fi1qx3 pmk7jnqg j9ispegn kr520xx4 s45kfl79 emlxlaya bkmhp75w spb7xbtv" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isOnline && (
                            <div className="flex-col z-0 bottom-0 flex right-0 absolute box-border">
                              <span
                                className={classNames(`w-4 h-4 rounded-full border-2 border-solid border-white`, {
                                  "bg-blue-600": playerState?.onlineStatus === "online",
                                  "bg-lime-600": playerState?.onlineStatus === "ingame",
                                })}
                                data-visualcompletion="ignore"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="cursor-pointer items-center flex-row flex-grow flex-shrink justify-between flex relative flex-wrap box-border">
                      <div className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz buofh1pr">
                        <div>
                          <span
                            className={classNames("block relative leading-5 font-bold text-base", {
                              "line-through": !!result.mute,
                            })}
                          >
                            {index + 1 + ". " + (prime ? `[${prime}] ` : "") + roomName}
                          </span>
                          <div data-testid="threadlist-last-message" className="bp9cbjyn j83agx80 m9osqain frgo5egb">
                            <span className="cursor-pointer box-border block font-normal max-w-full text-sm">
                              <span
                                className={classNames("a8c37x1j ni8dbmo4 stjgntxs l9j0dhe7 ltmttdrg g0qnabr5 ojkyduve", {
                                  "font-normal": lastMessage.isMe || lastMessage.read,
                                  "font-bold": lastMessage.isMe && !lastMessage.read,
                                  "text-sky-300": !lastMessage.isMe && lastMessage.read,
                                  "text-sky-400": !lastMessage.isMe && !lastMessage.read,
                                })}
                              >
                                {lastMessage.content}
                              </span>
                            </span>
                            <span className="kvgmc6g5 kady6ibp oygrvhab dwxx2s2f">
                              <span className="a8c37x1j hihg3u9x ggxiycxj l9j0dhe7 d2edcug0 hpfvmrgz qv66sw1b c1et5uql fe6kdd0r mau55g9w c8b282yb keod5gw0 nxhoafnm aigsh9s9 lr9zc1uh e9vueds3 knj5qynh m9osqain d9wwppkn mdeji52x dkezsu63" dir="auto" style={{ lineHeight: "16px" }}>
                                <span>
                                  <span className="rfua0xdk pmk7jnqg stjgntxs ni8dbmo4 ay7djpcl q45zohi1">&nbsp;</span>
                                  <span aria-hidden="true"> · </span>
                                </span>
                              </span>
                            </span>
                            <span style={{ display: "none" }} data-testid="timestamp" className="g0qnabr5">
                              4 d
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-col max-w-full z-0 min-w-0 flex relative flex-shrink-0 box-border text-sm">{!!lastMessage?.timestamp && moment(lastMessage.timestamp).fromNow()}</div>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <div className="mrt03zmi pmk7jnqg rk01pc8j ke6wolob rm21btxo s2gjlfnz svngc6pa kz676c5b ay7djpcl ni8dbmo4 stjgntxs rfua0xdk">
                <div className="kujm000c spb7xbtv bkmhp75w emlxlaya s45kfl79">
                  <div aria-label="Menu" className="oajrlxb2 qu0x051f esr5mh6w e9989ue4 r7d6kgcz nhd2j8a9 p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x i1ao9s8h esuyzwwr f1sip0of abiwlrkh p8dawk7l lzcic4wl bp9cbjyn s45kfl79 emlxlaya bkmhp75w spb7xbtv rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv j83agx80 taijpn5t jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso l9j0dhe7 k7cz35w2 bsnbvmp4 hn33210v m7msyxje m9osqain">
                    <i
                      data-visualcompletion="css-img"
                      className="hu5pjgll m6k467ps"
                      style={{
                        backgroundImage: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yY/r/U-eCEEYMNBK.png")',
                        backgroundPosition: "0px -1251px",
                        backgroundSize: "auto",
                        width: "20px",
                        height: "20px",
                        backgroundRepeat: "no-repeat",
                        display: "inline-block",
                      }}
                    />
                    <div className="i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s s45kfl79 emlxlaya bkmhp75w spb7xbtv" data-visualcompletion="ignore" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
});
export default SearchPeopleResult;
