import React from "react";
import moment from "moment";
import { Checkbox } from "antd";
import useStore from "store";
import classNames from "classnames";
import SimpleAsset from "../../components/SimpleAsset";

const InventoryHistoryItem = React.memo(function InventoryHistoryItem({ selectedHistoryItems, setSelectedHistoryItems, historyItem }) {
  const account = useStore.getAccount(historyItem.steamId);
  const timestamp = historyItem.timestamp * 1000;

  return (
    <tr className="historyItem" key={historyItem.id}>
      <td className="index">{historyItem.index + 1}</td>
      <td className="historyItemOwner">
        <div className="historyItemOwnerAvatar" style={{ backgroundImage: 'url("https://avatars.akamai.steamstatic.com/' + account?.avatarHash + '_full.jpg")' }}></div>
        <div className="historyItemOwnerName">
          {account?.personaName} - {account?.username}
        </div>
      </td>
      <td className="historyItemTimestamp">
        <div>{moment(timestamp).fromNow()}</div>
        <div>{timestamp}</div>
        <div>{moment(timestamp).toString()}</div>
      </td>
      <td className="historyItemContent">
        <div>
          {!!(Array.isArray(historyItem.tradehistory_items) && historyItem.tradehistory_items.length) && (
            <>
              <Checkbox
                checked={Array.isArray(historyItem.tradehistory_items) && historyItem.tradehistory_items.length && historyItem.tradehistory_items.every((item) => selectedHistoryItems[item.id])}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelectedHistoryItems(function (_selectedHistoryItems) {
                    if (checked) {
                      const __selectedHistoryItems = { ..._selectedHistoryItems };
                      for (const item of historyItem.tradehistory_items) {
                        __selectedHistoryItems[item.id] = item;
                      }
                      return __selectedHistoryItems;
                    } else {
                      const __selectedHistoryItems = { ..._selectedHistoryItems };
                      for (const item of historyItem.tradehistory_items) {
                        delete __selectedHistoryItems[item.id];
                      }
                      return __selectedHistoryItems;
                    }
                  });
                }}
              />
              &nbsp;
            </>
          )}
          <span
            className={classNames({
              "text-violet-600": ["Got an item drop", "Earned a new rank and got a drop"].includes(historyItem.description),
            })}
          >
            {historyItem.description}
          </span>
          {!!historyItem.tradePeople && (
            <a href={historyItem.tradePeople.url} target="_blank" rel="noreferrer">
              &nbsp;{historyItem.tradePeople.name}
            </a>
          )}
        </div>

        <div className="tradehistory_items_wrap">
          {historyItem.tradehistory_items?.map(function (item, index) {
            return (
              <div className="tradehistory_items" key={item.id}>
                <Checkbox
                  checked={!!selectedHistoryItems[item.id]}
                  onClick={(e) => {
                    const checked = e.target.checked;
                    setSelectedHistoryItems(function (_selectedHistoryItems) {
                      if (checked) {
                        const __selectedHistoryItems = { ..._selectedHistoryItems };
                        __selectedHistoryItems[item.id] = item;
                        return __selectedHistoryItems;
                      } else {
                        const __selectedHistoryItems = { ..._selectedHistoryItems };
                        delete __selectedHistoryItems[item.id];
                        return __selectedHistoryItems;
                      }
                    });
                  }}
                />
                <div className="plusminus">{item.plusminus}</div>
                <SimpleAsset asset={item} />
                {!!item.lowest_price && <span className="pl-1 italic">({item.lowest_price})</span>}
              </div>
            );
          })}
        </div>
      </td>
    </tr>
  );
});

export default InventoryHistoryItem;
