import React, { useCallback, useMemo, useState } from "react";
import { Switch } from "antd";
import ContextMenu from "../shared/ContextMenu";
import Swal from "sweetalert2";
import { invite2PlayCustom, invitePlayNow } from "../utils";
import api from "../api/api";

const FriendGroupHeader = React.memo(({ onlineState, size, realSize, defaultChecked, setSwitchOnOff, members }) => {
  const [checked, setChecked] = useState();

  const rawMenuItems = useMemo(
    function () {
      const groupMembers = members.flatMap((member) => member.groupMembers);

      const nameList = groupMembers
        .map(function (friend) {
          return friend?.state?.name || friend?.state?.player_name || friend?.personaName;
        })
        .join(", ");

      const receivers = groupMembers.map((friend) => ({
        friendId: friend._id,
        friendSteamId: friend.steamId,
      }));

      return [
        {
          label: "Send mesages",
          async onClick() {
            const result = await Swal.fire({
              title: "Send quick message to " + nameList,
              input: "text",
              inputPlaceholder: "Messages...",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Send",
              showLoaderOnConfirm: true,
            });
            if (!result.isConfirmed) {
              return;
            }
            const messages = result.value?.trim();
            if (!messages) {
              return;
            }
            await api.sendSteamMessage({
              messages: messages,
              receivers,
            });
          },
        },
        {
          label: "Invite",
          children: [
            {
              label: "Invite play now",
              async onClick() {
                for (const groupMember of groupMembers) {
                  await invitePlayNow(groupMember.steamId);
                }
              },
            },
            {
              label: "Invite to play custom",
              async onClick() {
                for (const groupMember of groupMembers) {
                  await invite2PlayCustom(groupMember.steamId);
                }
              },
            },
          ],
        },
        {
          label: "Invite to lobby CS2",
          async onClick() {
            await api.invite2LobbyCs2({
              steamIds: groupMembers.map(({ steamId }) => steamId),
              inviteTime: 1,
            });
          },
        },
      ];
    },
    [members],
  );

  const handleOnChange = useCallback(
    (_checked) => {
      setChecked(_checked);
      setSwitchOnOff((prevSwitchOnOff) => {
        if (_checked) {
          return !prevSwitchOnOff.includes(onlineState) ? [...prevSwitchOnOff, onlineState] : prevSwitchOnOff;
        } else {
          return prevSwitchOnOff.filter((state) => state !== onlineState);
        }
      });
    },
    [onlineState, setSwitchOnOff],
  );

  return (
    <>
      <ContextMenu id={`DropDown_${onlineState}`} menu={rawMenuItems} trigger={["contextMenu"]}>
        <span className="uppercase text-base font-bold cursor-pointer">
          {onlineState} ({size}/{realSize})
        </span>
      </ContextMenu>
      <Switch defaultChecked={!!defaultChecked} checked={checked} onChange={handleOnChange} />
    </>
  );
});

export default FriendGroupHeader;
