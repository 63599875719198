import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "antd";

const useTrippleCheckbox = (data, id, getRealSize) => {
  const getRealSizeRef = useRef(function (item) {
    return 1;
  });
  const [selectedItems, setSelectedItems] = useState({});
  const selectedItemList = useMemo(() => {
    return new Set(Object.keys(selectedItems));
  }, [selectedItems]);

  const allSelected = useMemo(() => {
    return selectedItemList.size > 0 && data.length === selectedItemList.size;
  }, [data.length, selectedItemList.size]);

  const someSelected = useMemo(() => {
    return selectedItemList.size > 0 && data.length > selectedItemList.size;
  }, [data.length, selectedItemList.size]);

  const handleOnCheckAll = useCallback(
    (checked) => {
      if (checked) {
        setSelectedItems(
          data?.reduce?.(function (previousValue, currentValue) {
            return {
              ...previousValue,
              [currentValue[id]]: true,
            };
          }, {}) || {},
        );
      } else {
        setSelectedItems({});
      }
    },
    [data, id, setSelectedItems],
  );

  const handleCheckboxChange = useCallback(
    (id, checked) => {
      setSelectedItems((prevState) => {
        const newState = { ...prevState };
        if (checked) {
          newState[id] = true;
        } else {
          delete newState[id];
        }
        return newState;
      });
    },
    [setSelectedItems],
  );

  useEffect(() => {
    getRealSizeRef.current = getRealSize;
  }, [getRealSize]);

  const TrippleCheckbox = useCallback(
    ({ children }) => {
      const { selectedSize, totalSize } = data.reduce(
        function (previousValue, currentValue) {
          const currentSize = getRealSizeRef.current(currentValue);

          if (selectedItemList.has(currentValue[id])) {
            previousValue.selectedSize += currentSize;
          }
          previousValue.totalSize += currentSize;

          return previousValue;
        },
        {
          selectedSize: 0,
          totalSize: 0,
        },
      );

      return (
        <Checkbox checked={allSelected} indeterminate={someSelected} onChange={(e) => handleOnCheckAll(e.target.checked)}>
          {children}
          <span className="ml-1">
            ({selectedSize}/{totalSize})
          </span>
        </Checkbox>
      );
    },
    [allSelected, data, handleOnCheckAll, id, selectedItemList, someSelected],
  );

  return {
    selectedItems,
    setSelectedItems,
    selectedItemList,
    handleCheckboxChange,
    TrippleCheckbox,
  };
};

export default useTrippleCheckbox;
