import React from "react";

const SteamAvatar = React.memo(function SteamAvatar({ avatarHash, className, ...rest }) {
  return (
    <>
      <img className={`${className || ""}`} src={`https://avatars.akamai.steamstatic.com/${avatarHash || "fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb"}_full.jpg`} {...rest} />
    </>
  );
});
export default SteamAvatar;
