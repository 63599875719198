import React, { useEffect } from "react";
import ChatLineTextMine from "./text/ChatLineTextMine";
import ChatLineTextUr from "./text/ChatLineTextUr";
import { useInView } from "react-intersection-observer";
import { isImgUrl } from "../../utils";
import useStore from "store";
import api from "api";

const ChatLine = React.memo(function ChatLine({ line, room, index }) {
  const readMessage = useStore((state) => state.readMessage);

  useEffect(() => {
    if (typeof line.isImage === "boolean") {
      return;
    }
    isImgUrl(line.content).then(function (isImage) {
      api.updateLineImage({
        _id: line._id,
        isImage,
      });
    });
  }, [line._id, line.content, line.isImage]);

  const [ref] = useInView({
    onChange: (inView, entry) => {
      if (!line.read && inView && line._id) {
        readMessage(line._id);
      }

      // if (inView && index === 10) {
      //     socket.loadMoreMessage({
      //         timestamp: line.timestamp, roomId: room._id
      //     })
      // }
    },
  });

  if (line.isMe) {
    return <ChatLineTextMine ref={ref} line={line} room={room} index={index} key={"ChatLine" + line._id} />;
  } else {
    return <ChatLineTextUr ref={ref} line={line} room={room} index={index} key={"ChatLine" + line._id} />;
  }
});

export default ChatLine
