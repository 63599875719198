import { Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import api from "api";
import useStore from "store";
import SimpleButton from "../../shared/SimpleButton";

export default function MyHelpRequest() {
  const [helpRequests, setHelpRequests] = useState([]);

  useEffect(() => {
    const steamId = useStore.getState().selectedAccountID;
    api.getHelpRequests({ steamId }).then(function (result) {
      if (!Array.isArray(result?.data)) return;
      setHelpRequests(
        result.data.map(function (item) {
          return { ...item, steamId };
        }),
      );
    });
  }, []);

  const updateHelpRequest = useCallback((id, update) => {
    setHelpRequests(function (prevState) {
      const index = prevState.findIndex((request) => request.id === id);
      if (index > -1) {
        prevState[index] = {
          ...prevState[index],
          ...update,
        };
        return prevState.slice();
      } else {
        return prevState;
      }
    });
  }, []);

  return (
    <>
      <div className="MyHelpRequest_Body">
        {helpRequests.map(function (helpRequest, index) {
          return (
            <div className="flex gap-5 items-start justify-start" key={helpRequest.id}>
              <span>{index + 1}. </span>
              <div>{helpRequest.title}</div>
              <div>
                <div>{helpRequest.status}</div>
                <div>{helpRequest.date_created}</div>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <SimpleButton
                  onClick={async function () {
                    const result = await api.getHelpRequestDetail({ steamId: helpRequest.steamId, id: helpRequest.id });
                    if (typeof result?.data !== "string") return;
                    updateHelpRequest(helpRequest.id, {
                      detail: result.data,
                    });
                  }}
                >
                  Detail
                </SimpleButton>
                {!!helpRequest.detail && (
                  <Switch
                    defaultChecked={!!helpRequest.showDetail}
                    onChange={function (checked) {
                      updateHelpRequest(helpRequest.id, {
                        showDetail: checked,
                      });
                    }}
                  />
                )}
              </div>
              {!!helpRequest.detail && !!helpRequest.showDetail && <div dangerouslySetInnerHTML={{ __html: helpRequest.detail }} />}
            </div>
          );
        })}
      </div>
    </>
  );
}
