import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import api from "api";
import useStore, { useAccountBySteamId } from "store";
import moment from "moment";
import uap from "ua-parser-js";
import _ from "lodash";
import SimpleButton from "../../shared/SimpleButton";
import Toast from "../../toast";
import classNames from "classnames";
import Loading from "../../components/Loading";
import { copy2Clipboard } from "../../utils";
import { Checkbox } from "antd";
import { useLocalStorage } from "@uidotdev/usehooks";

export const EAuthTokenPlatformType = {
  Unknown: 0,
  SteamClient: 1,
  WebBrowser: 2,
  MobileApp: 3,
  0: "Unknown",
  1: "SteamClient",
  2: "WebBrowser",
  3: "MobileApp",
};

const EPlatformType = {
  Unknown: 0,
  Win32: 1,
  Win64: 2,
  Linux: 3,
  OSX: 4,
  PS3: 5,
  Linux32: 6,
  0: "Unknown",
  1: "Win32",
  2: "Win64",
  3: "Linux",
  4: "OSX",
  5: "PS3",
  6: "Linux32",
};

const EOSType = {
  Unknown: -1,

  Web: -700,

  IOSUnknown: -600,
  IOS1: -599,
  IOS2: -598,
  IOS3: -597,
  IOS4: -596,
  IOS5: -595,
  IOS6: -594,
  IOS6_1: -593,
  IOS7: -592,
  IOS7_1: -591,
  IOS8: -590,
  IOS8_1: -589,
  IOS8_2: -588,
  IOS8_3: -587,
  IOS8_4: -586,
  IOS9: -585,
  IOS9_1: -584,
  IOS9_2: -583,
  IOS9_3: -582,
  IOS10: -581,
  IOS10_1: -580,
  IOS10_2: -579,
  IOS10_3: -578,
  IOS11: -577,
  IOS11_1: -576,
  IOS11_2: -575,
  IOS11_3: -574,
  IOS11_4: -573,
  IOS12: -572,
  IOS12_1: -571,

  AndroidUnknown: -500,
  Android6: -499,
  Android7: -498,
  Android8: -497,
  Android9: -496,

  UMQ: -400,

  PS3: -300,

  MacOSUnknown: -102,
  MacOS104: -101,
  MacOS105: -100,
  MacOS1058: -99,
  MacOS106: -95,
  MacOS1063: -94,
  MacOS1064_slgu: -93,
  MacOS1067: -92,
  MacOS107: -90,
  MacOS108: -89,
  MacOS109: -88,
  MacOS1010: -87,
  MacOS1011: -86,
  MacOS1012: -85,
  Macos1013: -84,
  Macos1014: -83,
  Macos1015: -82,
  MacOS1016: -81,
  MacOS11: -80,
  MacOS111: -79,
  MacOS1017: -78,
  MacOS12: -77,
  MacOS1018: -76,
  MacOS13: -75,
  MacOS1019: -74,
  MacOS14: -73,
  MacOS1020: -72,
  MacOS15: -71,

  MacOSMax: -1,

  LinuxUnknown: -203,
  Linux22: -202,
  Linux24: -201,
  Linux26: -200,
  Linux32: -199,
  Linux35: -198,
  Linux36: -197,
  Linux310: -196,
  Linux316: -195,
  Linux318: -194,
  Linux3x: -193,
  Linux4x: -192,
  Linux41: -191,
  Linux44: -190,
  Linux49: -189,
  Linux414: -188,
  Linux419: -187,
  Linux5x: -186,
  Linux54: -185,
  Linux6x: -184,
  Linux7x: -183,
  Linux510: -182,
  LinuxMax: -101,

  WinUnknown: 0,
  Win311: 1,
  Win95: 2,
  Win98: 3,
  WinME: 4,
  WinNT: 5,
  Win2000: 6,
  WinXP: 7,
  Win2003: 8,
  WinVista: 9,
  Windows7: 10,
  Win2008: 11,
  Win2012: 12,
  Windows8: 13,
  Windows81: 14,
  Win2012R2: 15,
  Windows10: 16,
  Win2016: 17,
  Win2019: 18,
  Win2022: 19,
  Win11: 20,
  WinMAX: 21,
};

export default function MyLoginHistory() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const account = useAccountBySteamId(selectedAccountID);
  const [tokens, _setTokens] = useState([]);
  const [requestingToken, setRequestingToken] = useState();
  const [loading, setLoading] = useState();
  const subToolRef = useRef();
  const bodyRef = useRef();
  const [selectedToken, setSelectedToken] = useState({});
  const [hideMySession, setHideMySession] = useLocalStorage("MyLoginHistory_hideMySession", true);
  const selectedTokenList = useMemo(() => {
    return Object.keys(selectedToken);
  }, [selectedToken]);

  const setTokens = useCallback((refresh_tokens, requesting_token) => {
    if (!refresh_tokens) {
      _setTokens([]);
      setRequestingToken("");
      Toast.error("No refresh_tokens");
    } else {
      _setTokens(
        _.sortBy(refresh_tokens, function (token) {
          return -token.last_seen?.time ?? 0;
        }),
      );
      setRequestingToken(requesting_token);
    }
  }, []);

  const enumerateTokens = useCallback(
    async (steamId) => {
      if (!steamId) return;

      const result = (await api.enumerateTokens({ steamId }))?.data;
      setTokens(result?.refresh_tokens, result?.requesting_token);
    },
    [setTokens],
  );

  useEffect(() => {
    setLoading(true);
    enumerateTokens(selectedAccountID).then(function () {
      setLoading(false);
    });
  }, [enumerateTokens, selectedAccountID]);

  const revokeAccessToken = useCallback(
    async ({ steamId, tokenId, action, type }) => {
      if (type === "Now") {
        setLoading(true);
        const result = (
          await api.revokeLoginToken({
            steamId,
            tokenIds: tokenId,
          })
        )?.data;
        if (Array.isArray(result?.tokens?.refresh_tokens)) {
          Toast.success("revokeLoginToken successfully");
          setTokens(
            _.sortBy(result.tokens.refresh_tokens, function (token) {
              return -token.last_seen?.time ?? 0;
            }),
          );
          setRequestingToken(result.tokens.requesting_token);
        } else {
          Toast.error("revokeLoginToken failed");
        }
        setLoading(false);
      } else {
        setLoading(true);
        const result = (
          await api.revokeLoginTokenWhenXpExceed({
            steamId,
            tokenId,
            action,
            type,
          })
        )?.data;
        console.log(result);
        Toast.success(`${action} revokeLoginTokenWhenXpExceed ${type} successfully`);
        setLoading(false);
      }
    },
    [setTokens],
  );

  useEffect(() => {
    if (!bodyRef.current || !subToolRef.current) return;
    const subToolBoundingClientRect = subToolRef.current.getBoundingClientRect();
    bodyRef.current.style.height = `calc(100vh - ${subToolBoundingClientRect.y + subToolBoundingClientRect.height + 1}px)`;
  }, [bodyRef, subToolRef]);

  const handleOnCheck = useCallback((tokenId, checked) => {
    if (checked) {
      setSelectedToken(function (prevState) {
        return {
          ...prevState,
          [tokenId]: true,
        };
      });
    } else {
      setSelectedToken(function (prevState) {
        const newState = { ...prevState };
        delete newState[tokenId];
        return newState;
      });
    }
  }, []);

  const handleOnCheckAll = useCallback(
    (e) => {
      const checked = e.target.checked;
      if (checked) {
        setSelectedToken(
          tokens.reduce(
            (previousValue, currentValue) => ({
              ...previousValue,
              [currentValue.token_id]: true,
            }),
            {},
          ),
        );
      } else {
        setSelectedToken({});
      }
    },
    [tokens],
  );

  const displayTokens = useMemo(() => {
    if (hideMySession) {
      return tokens?.filter(function (token) {
        return token.platform_type !== EAuthTokenPlatformType.MobileApp;
      });
    } else {
      return tokens;
    }
  }, [hideMySession, tokens]);

  return (
    <div className="relative h-full">
      <div ref={subToolRef} className="flex items-center gap-2">
        <SimpleButton
          onClick={function () {
            api.steamLogin({ steamId: useStore.getState().selectedAccountID, requireClientAuth: true });
          }}
        >
          Login
        </SimpleButton>
        <SimpleButton
          onClick={async function () {
            setLoading(true);
            await enumerateTokens(useStore.getState().selectedAccountID);
            setLoading(false);
          }}
        >
          Refresh
        </SimpleButton>
        <SimpleButton
          onClick={async function () {
            if (!selectedTokenList.length) {
              return Toast.error("Select first");
            }
            await revokeAccessToken(useStore.getState().selectedAccountID, selectedTokenList);
          }}
        >
          Deauthorize All Devices
        </SimpleButton>
        <Checkbox checked={hideMySession} onChange={(e) => setHideMySession(e.target.checked)}>
          Hide my session
        </Checkbox>
        {!!account?.lastTimeLoggedOn && (
          <span className="flex items-center gap-2 mx-2">
            <span>Last time logged on: </span>
            <span>{moment(account.lastTimeLoggedOn).fromNow()}</span>
          </span>
        )}
      </div>
      <div ref={bodyRef} className="overflow-y-auto mt-2">
        <table>
          <thead>
            <tr className="sticky">
              <th>
                <span className="mr-2">
                  <Checkbox checked={selectedTokenList.length && selectedTokenList.length === tokens.length} indeterminate={selectedTokenList.length && selectedTokenList.length !== tokens.length} onChange={handleOnCheckAll} />
                </span>
                <span>Description</span>
              </th>
              <th>logged_in</th>
              <th>os_platform</th>
              <th>auth_type</th>
              <th>os_type</th>
              <th>first_seen</th>
              <th>last_seen</th>
            </tr>
          </thead>
          <tbody>
            {displayTokens?.map(function (token, index) {
              return <MyLoginHistoryItem token={token} key={token.token_id} index={index} steamId={selectedAccountID} handleOnDeauthorize={account.enableTwoFactor ? revokeAccessToken : null} isOurToken={token.token_id === requestingToken} handleOnCheck={handleOnCheck} checked={!!selectedToken[token.token_id]} revokeTokens={account.revokeTokens} />;
            })}
          </tbody>
        </table>
      </div>
      <Loading loading={loading} />
    </div>
  );
}

const MyLoginHistoryItem = React.memo(({ token, index, steamId, handleOnDeauthorize, isOurToken, checked, handleOnCheck, revokeTokens }) => {
  const firstSeenMoment = moment(token.first_seen.time * 1000);
  const lastSeenMoment = token.last_seen?.time ? moment(token.last_seen?.time * 1000) : null;
  const ua = uap(token.token_description);
  let token_description = token.token_description;
  if (ua?.browser?.name) {
    token_description = `Browser ${ua.browser.name} ${ua.os.name} ${ua.os.version}`;
  }

  const osType = Object.keys(EOSType).find((k) => EOSType[k] === token.os_type);
  const lastSeenLocation = [token.last_seen?.city, token.last_seen?.state, token.last_seen?.country].filter(Boolean);
  const types = ["Now", "FirstXpExceed", "XpExceed", "AtNight", "3HourIdle", "PostXpExceed", "GameEnd"];
  const deauthorizeButtons =
    !!token.token_id &&
    typeof handleOnDeauthorize === "function" &&
    types
      .map(function (type) {
        if (type === "Now") {
          return (
            <SimpleButton
              type="primary"
              key={type}
              onClick={async () => {
                await handleOnDeauthorize({
                  steamId,
                  tokenId: token.token_id,
                  action: "added",
                  type,
                });
              }}
            >
              Deauthorize {type}
            </SimpleButton>
          );
        }

        return revokeTokens?.[type]?.includes?.(token.token_id) ? (
          <SimpleButton
            key={type}
            onClick={async () => {
              await handleOnDeauthorize({
                steamId,
                tokenId: token.token_id,
                action: "reset",
                type,
              });
            }}
          >
            Reset Deauthorize {type}
          </SimpleButton>
        ) : (
          <SimpleButton
            type="primary"
            key={type}
            onClick={async () => {
              await handleOnDeauthorize({
                steamId,
                tokenId: token.token_id,
                action: "added",
                type,
              });
            }}
          >
            Deauthorize {type}
          </SimpleButton>
        );
      })
      .filter(Boolean);

  const onCheck = useCallback(
    (e) => {
      handleOnCheck(token.token_id, e.target.checked);
    },
    [handleOnCheck, token.token_id],
  );

  return (
    <tr className="border border-solid border-gray-500 border-l-0 border-r-0 border-t-0">
      <td
        className={classNames({
          "text-blue-600": isOurToken,
        })}
      >
        <span className="mr-2">
          <Checkbox checked={checked} onChange={onCheck} />
        </span>
        <span>{index + 1}. </span>
        <span className="font-bold">{EAuthTokenPlatformType[token.platform_type]} </span>
        <span>{token_description}</span>
      </td>

      <td>
        <div>{token.logged_in ? "logged" : "not logged"}</div>
      </td>
      <td>
        <div>{EPlatformType[token.os_platform]}</div>
      </td>
      <td>authorized by {EAuthTokenPlatformType[token.auth_type]}</td>
      <td>{osType}</td>
      <td>
        <div>{firstSeenMoment.fromNow()}</div>
        <div>{firstSeenMoment.format("DD/MM/YYYY HH:mm")}</div>
      </td>
      <td>
        {!!lastSeenMoment && (
          <>
            <div>{lastSeenMoment.fromNow()}</div>
            <div>{lastSeenMoment.format("DD/MM/YYYY HH:mm")}</div>
          </>
        )}

        {!!lastSeenLocation.length && <div>{lastSeenLocation.join(" - ")}</div>}
      </td>
      <td>
        <div className="flex gap-1 flex-wrap">
          {deauthorizeButtons}
          <SimpleButton
            onClick={() => {
              copy2Clipboard(token.token_id);
            }}
          >
            Copy TokenID
          </SimpleButton>
        </div>
      </td>
    </tr>
  );
});
