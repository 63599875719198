import "./Header.scss";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimeUpgrade from "../primeupgrade/PrimeUpgrade";

const Header = React.memo(function Header({ routes }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {routes.map(function ({ path, title }) {
        return (
          <span onClick={() => navigate("/" + path)} className={`header-link ${location.pathname === "/" + path && "active"}`} key={path}>
            {title}
          </span>
        );
      })}
    </>
  );
});

export default Header;
