import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Select } from "antd";
import { toNonAccentVietnameseLowerCase } from "alpha-common-utils/StringUtils";
import { useDebounce } from "@uidotdev/usehooks";
import SimpleButton from "./shared/SimpleButton";
import api from "./api/api";
import useStore from "store";

function DiscordFriendPicker({ selectedUser, setSelectedUser }) {
  const onChange = useCallback((value) => setSelectedUser(value), [setSelectedUser]);
  const [searchTerm, setSearchTerm] = useState();
  const discordFriend = useStore((state) => state.discordFriend);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const displayOptions = useMemo(() => {
    let options = [];
    let query = debouncedSearchTerm?.trim();
    if (!query) {
      options = discordFriend;
    } else {
      query = toNonAccentVietnameseLowerCase(query);
      options = discordFriend.filter(function (account) {
        return Object.values(account)
          .filter((v) => v && typeof v === "string")
          .map((v) => toNonAccentVietnameseLowerCase(v))
          .some(function (v) {
            return v.indexOf(query) > -1;
          });
      });
    }
    return options.map((friend) => ({
      value: friend.id,
      label: (
        <div>
          <img width={24} height={24} src={friend.avatar?.startsWith?.("http") ? friend.avatar : `https://cdn.discordapp.com/avatars/${friend.id}/${friend.avatar}.webp?size=32`} alt="" />
          {[friend.username, friend.nickname, friend.discriminator !== "0" ? friend.global_name + "#" + friend.discriminator : friend.global_name].filter(Boolean).join(" - ")}
        </div>
      ),
    }));
  }, [discordFriend, debouncedSearchTerm]);

  useEffect(() => {}, [debouncedSearchTerm]);

  const fetchDiscordFriend = useCallback(async () => {
    const discordFriendResult = await api.getDiscordFriend();
    if (Array.isArray(discordFriendResult.data)) {
      useStore.setState({ discordFriend: discordFriendResult.data });
    }
  }, []);

  return (
    <div>
      <Select allowClear showSearch onSearch={setSearchTerm} filterOption={false} value={selectedUser} className="w-64" options={displayOptions} onChange={onChange}></Select>
      <SimpleButton onClick={fetchDiscordFriend}>Refresh</SimpleButton>
      <SimpleButton
        onClick={function () {
          onChange(null);
        }}
      >
        Reset
      </SimpleButton>
    </div>
  );
}

export default React.memo(DiscordFriendPicker);
