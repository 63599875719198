import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

const Loading = function Loading({ loading }) {
  if (!loading) return;

  return (
    <div className="absolute left-0 top-0 w-full h-full bg-slate-400 opacity-70 z-10 cursor-progress">
      <div className="absolute left-0 top-0 translate-x-1/2 translate-y-1/2 w-full h-full">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 48,
              }}
              spin
            />
          }
        />
      </div>
    </div>
  );
};
export default Loading;
