import "./FollowList.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Button, Dropdown, Input, Space } from "antd";
import FriendBlock from "./friendblock/FriendBlock";
import api from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import useStore from "store";
import { useLocalStorage } from "@uidotdev/usehooks";

export const piority = {
  ingame: 2,
  online: 1,
  offline: 0,
};

const FILTER_TYPE = {
  ALL: 1,
  PRIME: 2,
  NON_PRIME: 3,
  UNKNOWN_PRIME: 4,
  ONLINE: 5,
  INGAME: 6,
};

function getFilterTypeName(value) {
  for (let key in FILTER_TYPE) {
    if (FILTER_TYPE[key] === value) {
      return key;
    }
  }
  return "";
}

function getDisplayFriendList(friendList) {
  const _friendListObj = _.groupBy(friendList, "isFollow");
  _friendListObj["true"]?.sort?.(function (f1, f2) {
    const onlineStatusValue1 = f1.onlineStatusValue;
    const onlineStatusValue2 = f2.onlineStatusValue;
    if (onlineStatusValue1 > onlineStatusValue2) {
      return -1;
    }
    if (onlineStatusValue1 < onlineStatusValue2) {
      return 1;
    }

    const lastOnline1 = f1.lastSendMessage;
    const lastOnline2 = f2.lastSendMessage;

    if (lastOnline1) {
      if (lastOnline2) {
        if (lastOnline1 > lastOnline2) {
          return -1;
        }
        if (lastOnline1 < lastOnline2) {
          return 1;
        }
        return 0;
      } else {
        return -1;
      }
    } else {
      if (lastOnline2) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const newFriendList = [];
  newFriendList.push({
    list: _friendListObj[true],
    label: "Following",
  });
  newFriendList.push({
    list: _friendListObj[false],
    label: "Other",
  });
  return newFriendList;
}

export default function FollowList() {
  const updateFriendList = useStore((state) => state.updateFriendList);
  const myAccountSteamIds = useStore((state) => state.myAccountSteamIds);
  const steamInput = useRef();
  const [friendList, setFriendList] = useState([]);
  const displayFriendList = useMemo(() => getDisplayFriendList(friendList), [friendList]);
  const [filterType, setFilterType] = useLocalStorage("FILTER_TYPE", FILTER_TYPE.ALL);
  const [isRefreshing, setIsRefreshing] = useState(false);

  /*    function doFilter(item) {
            if (!item) {
                return false
            }

            switch (filterType) {
                case FILTER_TYPE.ALL:
                    return true
                case FILTER_TYPE.PRIME:
                    return item.primeStatus === "prime"
                case FILTER_TYPE.NON_PRIME:
                    return item.primeStatus === "non-prime"
                case FILTER_TYPE.UNKNOWN_PRIME:
                    return !item.primeStatus
                case FILTER_TYPE.ONLINE:
                    return item.onlineStatus === "online"
                case FILTER_TYPE.INGAME:
                    return item.onlineStatus === "ingame"
                default:
                    return true
            }
        }*/

  async function onCLickFollow(steamId) {
    const result = (await api.follow({ link: steamId }))?.data;
    if (result?._id) {
      updateFriendList(result);
    }
  }

  async function onClickRefollowAll() {
    await api.refollowAll();
  }

  async function onClickRefresh() {
    setIsRefreshing(true);
    doSetFriendList((await api.getFollowFriends())?.data || []);
    setIsRefreshing(false);
  }

  function doSetFriendList(_friendList) {
    _friendList =
      _friendList
        ?.filter(function (friend) {
          return friend.isFollow;
        })
        ?.map?.(function (_f) {
          _f.onlineStatusValue = piority[_f.state?.onlineStatus || "offline"] === undefined ? piority.offline : piority[_f.onlineStatus];
          return _f;
        }) || [];

    if (!_friendList.length) {
      return;
    }

    setFriendList(function (oldFriendList) {
      _.remove(_friendList, function (__friend) {
        return oldFriendList.some((f) => _.isEqual(f, __friend));
      });
      const slice = oldFriendList.slice();
      slice.push(..._friendList);
      return slice;
    });
  }

  useEffect(() => {
    api.getFollowFriends().then(function (result) {
      if (Array.isArray(result?.data)) {
        doSetFriendList(result?.data);
      }
    });
    let searchResults = document.querySelector(".search_results");
    let rect = searchResults.getBoundingClientRect();
    searchResults.style.height = `calc(100vh - ${rect.y}px)`;
    searchResults.style["overflow-y"] = "auto";
  }, []);

  return (
    <React.Fragment>
      <div>
        <Space.Compact style={{ width: "40%" }}>
          <Input.Search
            ref={steamInput}
            onSearch={(value, event) => {
              onCLickFollow(value);
            }}
            allowClear
            style={{ width: "100%" }}
          />
        </Space.Compact>
        <Button onClick={() => onClickRefollowAll()}>Refollow All</Button>
        <Button onClick={() => onClickRefresh()}>
          {isRefreshing ? <FontAwesomeIcon className={isRefreshing ? "icon-spinner" : ""} icon={faSpinner} /> : ""}
          Refresh
        </Button>
        <Space wrap>
          <Dropdown
            menu={{
              items: Object.keys(FILTER_TYPE).map((_filterType) => ({
                key: _filterType,
                label: _filterType,
                icon: <UserOutlined />,
              })),
              onClick: (e) => setFilterType(FILTER_TYPE[e.key]),
            }}
          >
            <Button>
              <Space>
                Filter - {getFilterTypeName(filterType)}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      </div>
      <div className="search_results">
        {displayFriendList
          ?.filter(({ label, list }) => list?.length)
          ?.map(({ label, list }) => (
            <React.Fragment key={`Fragment_${label}`}>
              <div key={label} data-key={label} className="state_block flex w-full">
                {label} ({list?.length})
              </div>
              {list?.map(function (friend) {
                return <FriendBlock key={`FriendBlock_${friend.steamId}`} friend={friend} following={true} isMyAccount={myAccountSteamIds.includes(friend.steamId)} />;
              })}
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  );
}
