import React, { useEffect, useState } from "react";
import api from "api";
import useStore from "store";

export default function MyPurchaseHistory() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  useEffect(() => {
    (async () => {
      setPurchaseHistory((await api.getPurchaseHistory({ steamId: selectedAccountID }))?.data || []);
    })();
  }, [selectedAccountID]);

  return (
    <table>
      <thead>
        <tr className="sticky">
          <th>Date</th>
          <th>Items</th>
          <th>Type</th>
          <th>Total</th>
          <th>wallet_change</th>
          <th>wallet_balance</th>
        </tr>
      </thead>
      <tbody>
        {purchaseHistory?.map(function (item) {
          return (
            <tr>
              <td>{item.date}</td>
              <td className="font-bold">{item.items}</td>
              <td>
                <div>{item.type.type}</div>
                <div>{item.type.payment}</div>
              </td>
              <td>
                <div>{item.total.total}</div>
                <div>{item.total.payment}</div>
              </td>
              <td>{item.wallet_change}</td>
              <td>{item.wallet_balance}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
