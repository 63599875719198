import _ from "lodash";
import api from "api";
import { dateFromObjectId, sleep } from "../utils";
import { ArrayUtils } from "alpha-common-utils";
import { useEffect, useMemo, useRef, useState } from "react";
import useStore from "store";
import Toast from "../toast";
import { produce } from "immer";

export const ModalType = {
  AddAccount: "AddAccount",
  Rename: "Rename",
  ApproveLoginQR: "ApproveLoginQR",
  FakeFriendState: "FakeFriendState",
  SubmitSteamGuard: "SubmitSteamGuard",
};

const MatchHistoryTypes = {
  matchhistoryscrimmage: "matchhistoryscrimmage",
  matchhistorycompetitive: "matchhistorycompetitive",
  matchhistorypremier: "matchhistorypremier",
  matchhistorycompetitivepermap: "matchhistorycompetitivepermap",
};

export const createUserSlice = function (set, get) {
  return {
    config: {
      shouldSendFakeInvite: true,
      shouldSendFakeInvite_LimitHour: true,
      shouldSendFakeInvite_MinHour: 2,
      shouldSendFakeInvite_MaxHour: 5,
      shouldSendFakeInvite_LastMessageTime: 7,
      shouldSendFakeInvite_Reply: false,
    },
    isShowingChat: false,
    accountList: [],
    accountPins: [],
    displayMyAccounts: [],
    friendFollowIDs: [],
    friendsSteamIdList: [],
    myAccountSteamIds: [],
    friendList: [],
    friendsInfo: {},
    scamLink: [],
    delayMessageJustStartGame: {}, //steamId, message,
    inventory: {}, //steamId ~ inventory
    steamAssets: {},
    watchGroup: {}, //steamId ~ steam_player_group/steamId
    customLobby: {}, //steam_player_group ~ steam_player_group
    party: [],
    mergeAllAccount: false,
    offers: {},
    selectedAccountID: null,
    myAccountFilterCriteria: {},
    partyRegisterGroup: [],
    qrScanList: [],
    workers: [],
    discordFriend: [],
    marketOldestHistory: {},
    assetPriceInCents: {},
    gameApp: {},

    async fetchGameApp(appIds) {
      const existed = get().gameApp;
      appIds = _.uniq(
        appIds
          .map(function (appId) {
            return parseInt(appId);
          })
          .filter(function (appId) {
            return appId && !existed[appId];
          }),
      );

      if (!appIds.length) {
        return;
      }

      const appList = (
        await api.getGameNameByAppIds({
          appIds,
        })
      ).data;
      if (Array.isArray(appList)) {
        set(
          produce(function (state) {
            for (const app of appList) {
              state.gameApp[app.appId] = app.name;
            }
          }),
        );
      }
    },

    async fetchAssetPriceInCents(assets) {
      const marketHashNames = Object.keys(_.groupBy(assets, "market_hash_name"));
      const results = (
        await api.getItemOrdersHistogram({
          appID: 730,
          marketHashNames,
        })
      )?.data;

      return set(
        produce(function (state) {
          for (const current of results) {
            if (typeof current.highest_buy_order === "string" && typeof current.lowest_sell_order === "string") {
              state.assetPriceInCents[current.marketHashName] = { marketHashName: current.marketHashName, highest_buy_order: parseInt(current.highest_buy_order), lowest_sell_order: parseInt(current.lowest_sell_order) };
            }
          }
        }),
      );
    },

    setMarketOldestHistory(steamId, list) {
      return set(function (prev) {
        return {
          marketOldestHistory: {
            ...prev.marketOldestHistory,
            [steamId]: list,
          },
        };
      });
    },

    setMyAccountFilterCriteria(getNewValue) {
      return set(function (prev) {
        return {
          myAccountFilterCriteria: getNewValue(prev.myAccountFilterCriteria || {}),
        };
      });
    },

    updateFriendsInfo(data) {
      if (!Array.isArray(data)) {
        data = [data];
      }
      return set(function (prev) {
        return {
          friendsInfo: data.reduce(
            function (previousValue, currentValue) {
              if (currentValue && !_.isEqual(previousValue[currentValue.steamId], currentValue)) {
                previousValue[currentValue.steamId] = currentValue;
              }
              return previousValue;
            },
            { ...prev.friendsInfo },
          ),
        };
      });
    },

    async getLoginQR({ timestamp, wokerId }) {
      const qrLoginList = _.sortBy((await api.getLoginQR({ timestamp, wokerId }))?.data || [], function (item) {
        return dateFromObjectId(item._id);
      }).reverse();
      return set({
        qrScanList: qrLoginList,
      });
    },
    async deleteLoginQR(_id) {
      await api.deleteLoginQR({ _id });
    },
    addNewQRScan(data) {
      return set(function (prev) {
        return {
          qrScanList: [data, ...prev.qrScanList],
        };
      });
    },
    updateQRScan(data) {
      switch (data?.operationType) {
        case "insert":
          const doc = data.fullDocument;
          Toast.success(`newLoginQR ${[doc.author.global_name, doc.author.username].join(" - ")}`);
          return set(function (prev) {
            return {
              qrScanList: [doc, ...prev.qrScanList],
            };
          });
        case "update":
          const _id = data.documentKey._id;
          const { updatedFields, removedFields } = data.updateDescription;
          return set(function (prev) {
            const index = prev.qrScanList.findIndex((item) => item._id === _id);
            if (index === -1) {
              return prev;
            }

            const item = { ...prev.qrScanList[index] };
            if (Array.isArray(removedFields) && removedFields.length) {
              for (const removedField of removedFields) {
                _.unset(item, removedField);
              }
            }
            if (updatedFields && Object.keys(updatedFields).length) {
              for (const updatedFieldsKey in updatedFields) {
                _.set(item, updatedFieldsKey, updatedFields[updatedFieldsKey]);
              }
            }

            return {
              qrScanList: prev.qrScanList.toSpliced(index, 1, item),
            };
          });
      }
    },

    async getWorkers(id) {
      const workers = (await api.getWorkers({ id }))?.data || [];

      return set(
        produce(function (state) {
          if (!state.workers) {
            state.workers = [];
          }
          for (const worker of workers) {
            const index = state.workers.findIndex((w) => w.id === worker.id);
            if (index !== -1) {
              state.workers[index] = worker;
            } else {
              state.workers.push(worker);
            }
          }

          state.workers.sort(function (w1, w2) {
            return (w2.timestamp || 0) - (w1.timestamp || 0);
          });
        }),
      );
    },

    async deleteWorkers(id) {
      const result = (await api.deleteWorker({ id }))?.data;
      if (result?.deletedCount === 1) {
        set(function (prev) {
          return {
            workers: prev.workers.filter((worker) => worker.id !== id),
          };
        });
      }
    },

    async updateWorkerConfig({ id, key, value }) {
      if (!id) {
        return Toast.error("Missing id");
      }

      if (!key) {
        return Toast.error("Missing key");
      }

      const result = (
        await api.updateWorkerConfig({
          id,
          key,
          value,
        })
      )?.data;
      if (!result?.id) return;

      set(
        produce(function (state) {
          const index = state.workers.findIndex((r) => r.id === result.id);
          if (index > -1) {
            state.workers[index] = result;
          } else {
            state.workers.push(result);
          }
        }),
      );
    },

    inventoryHistories: {},
    setInventoryHistories: function (tradehistory) {
      if (!Array.isArray(tradehistory) || !tradehistory.length) {
        return;
      }
      for (const historyItem of tradehistory) {
        if (Array.isArray(historyItem.tradehistory_items)) {
          for (const [index, item] of historyItem.tradehistory_items.entries()) {
            item.id = [item.instanceid || "0", item.classid || "0", historyItem.timestamp || "0", historyItem.steamId, index].join("_");
          }
        }
      }
      const tradehistoryBySteamId = _.groupBy(tradehistory, "steamId");
      const prev = get().inventoryHistories;

      for (const steamId in tradehistoryBySteamId) {
        _.remove(tradehistoryBySteamId[steamId], function (item) {
          return prev[steamId]?.some?.((_item) => _item.id === item.id);
        });
        if (!tradehistoryBySteamId[steamId].length) {
          delete tradehistoryBySteamId[steamId];
        }
      }

      if (!Object.keys(tradehistoryBySteamId).length) {
        return;
      }
      const newInventoryHistories = { ...prev };
      for (const steamId in tradehistoryBySteamId) {
        newInventoryHistories[steamId] = Array.isArray(newInventoryHistories[steamId]) ? newInventoryHistories[steamId].slice() : [];
        newInventoryHistories[steamId].push(...tradehistoryBySteamId[steamId]);
        ArrayUtils.removeDuplicate(newInventoryHistories[steamId], "id");
        ArrayUtils.sortBy(newInventoryHistories[steamId], inventoryHistorySortIteratees).reverse();
      }
      set({ inventoryHistories: newInventoryHistories });
    },
    fetchInventoryHistories: async function (steamIds, timestamp) {
      if (!steamIds) return;
      if (!Array.isArray(steamIds)) {
        steamIds = [steamIds];
      }
      const { setInventoryHistories } = get();
      for (const steamId of steamIds) {
        let cursor = null;
        do {
          const result = (
            await api.fetchInventoryHistory({
              steamId,
              cursor,
            })
          )?.data;
          setInventoryHistories(result?.tradehistory);
          if (result.cursor) {
            cursor = result.cursor;
          } else {
            break;
          }
        } while (cursor.time * 1000 > timestamp);
      }
    },
    getInventoryHistories: async function (timestamp) {
      const { selectedAccountID, mergeAllAccount } = get();
      const rerult = await api.getInventoryHistory({ steamIds: mergeAllAccount ? null : selectedAccountID, timestamp });
      get().setInventoryHistories(rerult.data);
    },

    matchHistory: [],
    matchHistoryPlayer: {},
    setMatchHistory: async function (history) {
      if (!history) return;
      if (!Array.isArray(history)) {
        history = [history];
      }
      const { matchHistoryPlayer, friendList } = get();
      const playerSteamIdList = history
        .map((m) => m.players)
        .flat()
        .map((player) => player.steamId)
        .filter((steamId) => !matchHistoryPlayer[steamId]);
      const players =
        playerSteamIdList.length &&
        (
          await api.getMatchHistoryPlayer({
            steamIds: _.uniq(playerSteamIdList),
          })
        )?.data;

      set(function (prev) {
        const prevMatchHistory = prev.matchHistory.slice();
        if (prevMatchHistory.length) {
          _.remove(prevMatchHistory, function (match) {
            return history.some((m) => m.matchID === match.matchID);
          });
        }

        const update = {
          matchHistory: _.sortBy([...prevMatchHistory, ...history], "timestamp").reverse(),
        };

        if (Array.isArray(players) && players.length) {
          update.matchHistoryPlayer = players.reduce(
            function (previousValue, currentValue) {
              previousValue[currentValue.steamId] = {
                ...currentValue,
                isFriend: !!friendList.some(({ steamId }) => steamId === currentValue.steamId),
              };
              return previousValue;
            },
            {
              ...prev.matchHistoryPlayer,
            },
          );
        }
        return update;
      });
    },
    getMatchHistory: async function (steamId, startTime, endTime) {
      const result = (await api.getMatchesHistory({ steamId, startTime, endTime }))?.data;
      await get().setMatchHistory(result);
    },
    fetchMatchHistory: async function (steamIds, startTime) {
      const { accountList, setMatchHistory, getMatchHistory } = get();
      for (const type of Object.values(MatchHistoryTypes)) {
        for (const steamId of steamIds) {
          const account = accountList.find((a) => a.steamId === steamId);

          Toast.success(`Fetching ${type} ${account.username} ${account.personaName}`);
          const result = (
            await api.fetchMyMatchHistory({
              steamId,
              startTime,
              types: type,
            })
          )?.data;
          await setMatchHistory(result);
          await getMatchHistory(steamId, startTime);
        }
      }
    },
    deleteMyMatchHistory: async function (matchID) {
      if (!matchID) return;

      const success = (await api.deleteMyMatchHistory({ matchID }))?.data?.deletedCount === 1;
      if (success) {
        set((prev) => ({
          matchHistory: prev.matchHistory.filter((m) => m.matchID !== matchID),
        }));
      }
      return success;
    },

    currentModal: {},
    showModal: (type, data) =>
      set(function (prev) {
        return {
          currentModal: {
            ...prev.currentModal,
            [type]: data,
          },
        };
      }),
    hideModal: (type) =>
      set(function (prev) {
        const currentModal = { ...prev.currentModal };
        delete currentModal[type];
        return { currentModal };
      }),

    renameModal: null,
    setRenameSteamModal: (steamId) => set(() => ({ renameModal: steamId })),

    confirmCodeForAddPhoneModal: null,
    setConfirmCodeForAddPhoneModal: (data) => set(() => ({ confirmCodeForAddPhoneModal: data })),

    fakeFriendStateModal: null,
    setFakeFriendStateModal: (fakeFriendStateModal) => set(() => ({ fakeFriendStateModal })),

    updateFriendList: function (newFriendList) {
      if (!newFriendList) return;
      if (!Array.isArray(newFriendList)) {
        if (newFriendList.steamId) {
          newFriendList = [newFriendList];
        } else {
          return;
        }
      } else {
        newFriendList = newFriendList.slice();
      }

      return set(
        produce(function (state) {
          // const mapFriendList = new Map();
          // for (const friend of state.friendList) {
          //   mapFriendList.set(friend.steamId, friend);
          // }

          // _.remove(newFriendList, function (friend) {
          //   const oldFriend = state.friendList.find(({ steamId }) => friend.steamId === steamId);
          //   return _.isEqual(oldFriend, friend);
          // });

          if (!newFriendList.length) {
            return;
          }

          for (const friend of newFriendList) {
            const index = state.friendList.findIndex(({ steamId }) => friend.steamId === steamId);
            if (index > -1) {
              state.friendList[index] = friend;
            } else {
              state.friendList.push(friend);
            }
          }
        }),
      );
    },
    updateFriends: function (data) {
      if (!Array.isArray(data)) {
        return;
      }

      set(
        produce((state) => {
          for (const { updatedFields, removedFields, _id } of data) {
            const friend = state.friendList.find((friend) => friend._id === _id);
            if (!friend) {
              continue;
            }
            if (Array.isArray(removedFields) && removedFields.length) {
              for (const removedField of removedFields) {
                _.unset(friend, removedField);
              }
            }
            if (updatedFields && Object.keys(updatedFields).length) {
              for (const updatedFieldsKey in updatedFields) {
                _.set(friend, updatedFieldsKey, updatedFields[updatedFieldsKey]);
              }
            }
          }
        }),
      );
    },
    reoderMyAccounts: function (accounts) {
      const accountList = _.sortBy(get().accountList, (account) => accounts.findIndex((a) => a.steamId === account.steamId));
      set({
        accountList: accountList,
      });

      api.reoderMyAccounts({ accounts: accountList.map((a) => a._id) }).then(function (result) {
        if (!Array.isArray(result?.data)) return;
        set({
          accountList: result.data,
        });
      });
    },
    updateAccounts: function (newAccountList) {
      if (!newAccountList) return;
      if (!Array.isArray(newAccountList)) {
        if (newAccountList.steamId) {
          newAccountList = [newAccountList];
        } else {
          return;
        }
      } else {
        newAccountList = newAccountList.slice();
      }
      if (!newAccountList.length) {
        return;
      }

      const newPartyRegisterGroup = _.uniq(newAccountList.map((a) => a.config?.partyRegisterGroup).filter(Boolean));

      set(
        produce(function (state) {
          state.partyRegisterGroup = _.uniq([...(state.partyRegisterGroup || []), ...newPartyRegisterGroup]).sort();

          if (Array.isArray(state.accountList)) {
            for (const account of newAccountList) {
              const index = state.accountList.findIndex(({ steamId }) => steamId === account.steamId);
              if (index > -1) {
                state.accountList[index] = account;
              } else {
                state.accountList.push(account);
              }
            }
          } else {
            state.accountList = newAccountList;
          }
        }),
      );
    },
    updateMyAccounts: function (data) {
      set(
        produce(function (state) {
          for (const { updatedFields, removedFields, _id } of data) {
            const account = state.accountList.find((a) => a._id === _id);
            if (!account) {
              continue;
            }

            if (Array.isArray(removedFields) && removedFields.length) {
              for (const removedField of removedFields) {
                _.unset(account, removedField);
              }
            }
            if (updatedFields && Object.keys(updatedFields).length) {
              for (const updatedFieldsKey in updatedFields) {
                _.set(account, updatedFieldsKey, updatedFields[updatedFieldsKey]);
              }
            }
          }
        }),
      );
    },
    updateAccountConfig: function (id, config) {
      api
        .updateAccountConfig({
          _id: id,
          config,
        })
        .then(function (result) {
          const account = result.data;
          if (account?._id) {
            get().updateAccounts(account);
          }
        });
    },
    async getMyAccount(filter) {
      if (api.gettingMyAccount) return;
      api.gettingMyAccount = true;
      const result = await api.getMyAccount(filter);
      delete api.gettingMyAccount;
      get().updateAccounts(result?.data);
      return result?.data;
    },
    updateAccountPersonalGameDataAccountInformation: async function (steamId) {
      const result = (
        await api.fetchMyAccountInfo_personalGameDataAccountInformation({
          steamId,
        })
      )?.data;
      if (!result) {
        return;
      }

      return set(
        produce(function (state) {
          const account = state.accountList.find((account) => account.steamId === steamId);
          if (account) {
            account.personalGameDataAccountInformation = result;
          }
        }),
      );
    },
    updateAccountCompetitiveCooldownLevel: async function (steamId) {
      const result = (
        await api.fetchAccountCompetitiveCooldownLevel({
          steamId,
        })
      )?.data;
      if (!result) {
        return;
      }

      return set(
        produce(function (state) {
          const account = state.accountList.find((account) => account.steamId === steamId);
          if (account) {
            account.competitiveCooldownLevel = result;
          }
        }),
      );
    },
    updateUserAccountFriendIDList: function ({ steamId, friendsIDList }) {
      return set(
        produce(function (state) {
          const account = state.accountList.find((account) => account.steamId === steamId);
          if (account) {
            account.friendsIDList = friendsIDList;
          }
        }),
      );
    },
    updateUnreadMessageNewMessage: () =>
      set(function (user) {
        let unreadMessageCount = user.unreadMessageCount || 0;
        if (!user.isShowingChat || user.minimiseChat) {
          unreadMessageCount++;
        } else {
          unreadMessageCount = 0;
        }

        return {
          unreadMessageCount,
        };
      }),
    getScamList: async function () {
      const result = await api.getScamList();
      get().updateScamLink(result.data);
    },
    updateScamLink: (link) =>
      set(function (prev) {
        if (!link) return;
        if (typeof link === "string") {
          link = [link];
        }
        if (Array.isArray(link)) {
          return {
            scamLink: _.uniq([...prev.scamLink, ...link]).sort(),
          };
        } else {
          return prev;
        }
      }),
    addFriendId: function (steamId, friendId) {
      set(
        produce(function (state) {
          const account = state.accountList.find((account) => account.steamId === steamId);
          if (account) {
            account.friendsIDList = [...(account.friendsIDList || []), friendId];
          }
        }),
      );
    },
    setInventory: (steamId, inventory) =>
      set(function (user) {
        return {
          inventory: {
            ...user.inventory,
            [steamId]: inventory,
          },
        };
      }),
    getUserInventory: async function (steamId, gameId = 730) {
      if (!steamId) {
        steamId = get().selectedAccountID;
      }
      if (!steamId) {
        return;
      }

      const assets = (await api.getUserInventory({ steamId, gameId }))?.data;
      if (!Array.isArray(assets)) {
        return;
      }

      const steamAssets = get().steamAssets;
      const market_hash_name_list = _.uniq(assets.map(({ market_hash_name }) => market_hash_name).filter(Boolean)).filter((market_hash_name) => !steamAssets[market_hash_name] && !market_hash_name.startsWith("Sealed Graffiti"));
      const market_hash_name_result = (await api.getSteamAssetsPrice({ market_hash_name_list }))?.data || [];
      const newSteamAssets = market_hash_name_result.reduce(
        function (previousValue, currentValue) {
          return {
            ...previousValue,
            [currentValue.market_hash_name]: currentValue,
          };
        },
        { ...get().steamAssets },
      );

      for (const asset of assets) {
        if (asset.lowest_price) {
          continue;
        }
        const lowest_price = newSteamAssets[asset.market_hash_name]?.lowest_price;
        if (lowest_price) {
          asset.lowest_price = lowest_price;
        }
      }

      set(function (user) {
        return {
          inventory: {
            ...user.inventory,
            [steamId + "_" + gameId]: assets,
          },
          steamAssets: newSteamAssets,
        };
      });
    },
    updateWatchGroup: (steamId, steam_player_group) =>
      set(function (user) {
        return {
          watchGroup: {
            ...user.watchGroup,
            [steamId]: steam_player_group,
          },
        };
      }),
    setWatchGroup: function (data) {
      const watchGroup = {};
      for (const steam_player_group in data) {
        if (Array.isArray(data[steam_player_group]?.watchers)) {
          for (const watcher of data[steam_player_group]?.watchers) {
            watchGroup[watcher] = steam_player_group;
          }
        }
      }

      return set({
        watchGroup,
      });
    },
    getSteamPlayerGroup: async function (data) {
      const result = await api.getSteamPlayerGroup(data);
      if (result?.data) {
        get().setWatchGroup(result.data);
      }
    },
    watchSteamPlayerGroup: function (data) {
      api.watchSteamPlayerGroup(data).then(function (result) {
        if (result?.data) {
          get().setWatchGroup(result.data);
        }
      });
    },
    setCustomLobby: function (customLobby) {
      return set({
        customLobby,
      });
    },
    watchCustomLobby: function ({ steamId, steam_player_group }) {
      api.watchCustomLobby({ steamId, steam_player_group }).then(function (result) {
        if (result?.data) {
          return set({
            customLobby: result.data,
          });
        }
      });
    },
    unwatchCustomLobby: function ({ steamId, steam_player_group }) {
      api.unwatchCustomLobby({ steamId, steam_player_group }).then(function (result) {
        if (result?.data) {
          return set({
            customLobby: result.data,
          });
        }
      });
    },
    markAsCustomLobby: function (steam_player_group) {
      api.markAsCustomLobby({ steam_player_group }).then(function (result) {
        if (result?.data) {
          set({ customLobby: result.data });
        }
      });
    },
    unmarkAsCustomLobby: function (steam_player_group) {
      api.unmarkAsCustomLobby({ steam_player_group }).then(function (result) {
        if (result?.data) {
          set({ customLobby: result.data });
        }
      });
    },
    setSelectedAccountID: function (selectedAccountID) {
      return set({
        selectedAccountID,
      });
    },
    setMergeAllAccount: function (value) {
      value = !!value;
      set({
        mergeAllAccount: value,
      });
    },
    fetchOffers: async function (steamId) {
      const result = (await api.getTradeOffer({ steamId }))?.data?.offers?.tradeOffers;
      if (!Array.isArray(result)) {
        return;
      }
      return set(function (user) {
        return {
          offers: {
            ...user.offers,
            [steamId]: result,
          },
        };
      });
    },
    setOffers: (steamId, offers) =>
      set(function (user) {
        return {
          offers: {
            ...user.offers,
            [steamId]: offers,
          },
        };
      }),
    removeOffers: (selectedAccountID, tradeOfferId) =>
      set(function (prev) {
        const offers = {
          ...prev.offers,
        };
        const index = offers[selectedAccountID].findIndex((offer) => offer.tradeofferid === tradeOfferId);
        if (index === -1) {
          return prev;
        }
        offers[selectedAccountID] = offers[selectedAccountID].toSpliced(index, 1);
        return {
          offers,
        };
      }),
    async getConfig() {
      const result = await api.getConfig();
      const response = result?.data;
      if (!Array.isArray(response)) {
        return;
      }
      set({
        config: response.reduce(function (previousValue, currentValue, currentIndex) {
          previousValue[currentValue.key] = currentValue.value;
          return previousValue;
        }, {}),
      });
    },
    async updateConfig(key, value) {
      if (!window.updatingConfigData) {
        window.updatingConfigData = {};
      }
      if (window.updatingConfigTimeout) {
        clearTimeout(window.updatingConfigTimeout);
      }
      window.updatingConfigData[key] = value;
      window.updatingConfigTimeout = setTimeout(async function () {
        let response = null;
        try {
          response = (await api.updateConfig(window.updatingConfigData))?.data;
        } catch (e) {}
        if (Array.isArray(response)) {
          set(function (prev) {
            return {
              config: response.reduce(function (previousValue, currentValue, currentIndex) {
                previousValue[currentValue.key] = currentValue.value;
                return previousValue;
              }, prev.config),
            };
          });
        }
      }, 2000);
      set((prev) => ({
        config: {
          ...prev.config,
          [key]: value,
        },
      }));
    },

    async fetchWalletBalance(steamId) {
      const result = (await api.fetchWalletBalance({ steamId }))?.data;
      if (!result?._id) return;
      get().updateAccounts(result);
    },

    addAccountPin(steamId) {
      const accountPins = get().accountPins;
      if (accountPins.includes(steamId)) {
        return;
      }
      return set({
        accountPins: [steamId, ...accountPins],
      });
    },

    removeAccountPin(steamId) {
      const accountPins = get().accountPins;
      if (!accountPins.includes(steamId)) {
        return;
      }
      const newAccountPins = accountPins.toSpliced(accountPins.indexOf(steamId), 1);
      return set({
        accountPins: newAccountPins,
      });
    },
  };
};

export const userSlicePartialize = ["accountList", "accountPins", "friendsInfo", "scamLink", "steamAssets", "mergeAllAccount", "selectedAccountID", "myAccountFilterCriteria", "qrScanList", "workers", "discordFriend"];
// export const userSlicePartialize = ["accounts", "accountList", "accountPins", "friendsSteamIdList", "friendsInfo", "scamLink", "steamAssets", "mergeAllAccount", "selectedAccountID", "myAccountFilterCriteria", "qrScanList", "workers", "discordFriend"];

function getFriendPlayTimeFromAccountList(accountList) {
  return accountList
    .map((a) => a.friendPlayTime)
    .flat()
    .filter(Boolean)
    .reduce(function (previousValue, currentValue, currentIndex) {
      if (currentValue.playingTime?.minutes_played) {
        previousValue[currentValue.steamId] = currentValue;
      }
      return previousValue;
    }, {});
}

function accountList2Object(accountList) {
  return accountList.reduce(
    (previousValue, currentValue, currentIndex) => ({
      ...previousValue,
      [currentValue.steamId]: { ...currentValue, order: currentIndex },
    }),
    {},
  );
}

// localforage.getItem("friendList").then(function (friendList) {
//   if (!Array.isArray(friendList)) return;
//   useStore.getState().updateFriendList(friendList, false);
// });

export const inventoryHistorySortIteratees = [
  "timestamp",
  function (item) {
    return parseInt(item._id.substring(0, 8), 16) || 0;
  },
];

export const useSteamIds = function (steamIds) {
  const [currentSteamIds, setCurrentSteamIds] = useState([]);

  useEffect(() => {
    const normalizedSteamIds = Array.isArray(steamIds) ? steamIds : [steamIds || []];
    normalizedSteamIds.sort();

    setCurrentSteamIds(function (prevState) {
      return areArraysSoftEqual(normalizedSteamIds, prevState) ? prevState : normalizedSteamIds;
    });
  }, [steamIds]);

  return currentSteamIds;
};

export const useAccounts = function (steamIds) {
  const prevSteamIds = useSteamIds(steamIds);

  const [accountList, setAccountList] = useState([]);

  const selectedAccounts = useMemo(() => {
    return accountList.reduce((previousValue, currentValue) => {
      previousValue[currentValue.steamId] = currentValue;
      return previousValue;
    }, {});
  }, [accountList]);
  const storeAccountList = useStore((state) => state.accountList);

  useEffect(() => {
    const newAccountList = storeAccountList.filter((account) => prevSteamIds.includes(account.steamId));

    setAccountList(function (prevState) {
      return areArraysSoftEqual(newAccountList, prevState) ? prevState : newAccountList;
    });
  }, [prevSteamIds, storeAccountList]);

  return {
    accounts: selectedAccounts,
    accountList: accountList,
  };
};

export const useAccountsFriend = function (steamId) {
  const [accountList, setAccountList] = useState([]);
  const accounts = useMemo(() => {
    return accountList.reduce(function (prev, curr) {
      prev[curr.steamId] = curr;
      return prev;
    }, {});
  }, [accountList]);
  const storeAccountList = useStore((state) => state.accountList);

  useEffect(() => {
    const newAccountList = steamId
      ? storeAccountList
          .filter((account) => account?.friendsIDList?.includes?.(steamId))
          .sort(function (acc1, acc2) {
            return acc1.steamId.localeCompare(acc2.steamId);
          })
      : [];

    setAccountList(function (prevState) {
      return areArraysSoftEqual(prevState, newAccountList) ? prevState : newAccountList;
    });
  }, [steamId, storeAccountList]);

  return {
    accounts,
    accountList,
  };
};
export const useIsFriend = function (steamId) {
  const [isFriend, setIsFriend] = useState(false);
  const accountList = useStore((state) => state.accountList);

  useEffect(() => {
    if (!steamId) {
      return;
    }
    setIsFriend(accountList.some((account) => account?.friendsIDList?.includes?.(steamId)));
  }, [steamId, accountList]);

  return isFriend;
};

export const usePlayerState = function (steamIds) {
  const prevSteamIds = useSteamIds(steamIds);
  const [playerState, setPlayerState] = useState({});
  const playerStateList = useMemo(() => {
    return Object.values(playerState);
  }, [playerState]);
  const friendList = useStore((state) => state.friendList);

  useEffect(() => {
    const newState = prevSteamIds.reduce(function (previousValue, steamId) {
      previousValue[steamId] = friendList.find((friend) => friend.steamId === steamId)?.state || null;
      return previousValue;
    }, {});

    setPlayerState(function (prevState) {
      return !areStatesSoftEqual(newState, prevState) ? newState : prevState;
    });
  }, [friendList, prevSteamIds]);

  return {
    playerState,
    playerStateList,
  };
};
export const useFriendInfo = function (steamIds) {
  const prevSteamIds = useSteamIds(steamIds);
  const [friendsInfo, setFriendsInfo] = useState({});
  const friendsInfoList = useMemo(() => Object.values(friendsInfo), [friendsInfo]);
  const storeFriendsInfo = useStore((state) => state.friendsInfo);

  useEffect(() => {
    const newState = prevSteamIds.reduce(function (previousValue, steamId) {
      previousValue[steamId] = storeFriendsInfo[steamId] || null;
      return previousValue;
    }, {});

    setFriendsInfo((prevState) => {
      return !areStatesSoftEqual(newState, prevState) ? newState : prevState;
    });
  }, [prevSteamIds, storeFriendsInfo]);

  return {
    friendsInfo,
    friendsInfoList,
  };
};

export const useAccountBySteamId = function (steamId) {
  const [account, setAccount] = useState();
  const accountList = useStore((state) => state.accountList);

  useEffect(() => {
    setAccount(function (prevState) {
      if (!steamId) return prevState;
      const acc = accountList.find((a) => a.steamId === steamId);
      if (!acc) return prevState;
      return acc;
    });
  }, [accountList, steamId]);

  return account;
};

export const useFriend = function (id) {
  const [friend, setFriend] = useState();
  const friendList = useStore((state) => state.friendList);

  useEffect(() => {
    setFriend(function (prevState) {
      if (!id) return prevState;
      const friend = friendList.find((friend) => friend._id === id || friend.steamId === id);
      if (!friend) return prevState;
      return friend;
    });
  }, [friendList, id]);

  return friend;
};
export const useFriendsBySteamIds = function (steamIds) {
  const _steamIds = useSteamIds(steamIds);
  const [friendList, setFriendList] = useState([]);
  const storeFriendList = useStore((state) => state.friendList);

  useEffect(() => {
    const newFriends = _steamIds.map(function (steamId) {
      return storeFriendList.find((friend) => friend.steamId === steamId) || null;
    });
    setFriendList(function (prevFriendList) {
      return areArraysSoftEqual(prevFriendList, newFriends) ? prevFriendList : newFriends;
    });
  }, [_steamIds, storeFriendList]);

  return friendList;
};

export const useAccountByCompare = function (compareFn, softEqual = true) {
  const prevAccounts = useRef([]);
  const [accountList, setAccountList] = useState([]);
  const currentAccountList = useStore((state) => state.accountList);

  useEffect(() => {
    const currentAccounts = currentAccountList.filter(compareFn);
    const isEqual =
      currentAccounts.length === prevAccounts.current.length &&
      currentAccounts.every(function (a) {
        if (softEqual) {
          return prevAccounts.current.some(({ steamId }) => a.steamId === steamId);
        } else {
          return prevAccounts.current.includes(a);
        }
      });

    if (!isEqual) {
      prevAccounts.current = currentAccounts;
      setAccountList(currentAccounts);
    }
  }, [currentAccountList, compareFn, softEqual]);

  return accountList;
};

export const useMyParty = function (steamId) {
  const party = useStore((state) => state.party);
  const myPartyRef = useRef([]);
  const [myParty, setMyParty] = useState([]);
  const strangerPartyRef = useRef([]);
  const [strangerParty, setStrangerParty] = useState([]);

  useEffect(() => {
    const _myParty = party
      .filter((g) => g.member_steamid === steamId)
      .map(function (_party) {
        const memberCount = party.filter((g) => g.lobby_id === _party.lobby_id).length;
        return {
          ..._party,
          memberCount,
        };
      });
    const myGroupObj = _myParty.reduce((previousValue, currentValue, currentIndex) => {
      previousValue[currentValue.lobby_id] = currentValue.lobby_name;
      return previousValue;
    }, {});

    const _strangerParty = _.uniqBy(
      party.filter((g) => !myGroupObj?.[g.lobby_id]),
      "lobby_id",
    ).map(function (_party) {
      const memberCount = party.filter((g) => g.lobby_id === _party.lobby_id).length;
      return {
        ..._party,
        memberCount,
      };
    });

    if (!_.isEqual(_myParty, myPartyRef.current)) {
      myPartyRef.current = _myParty;
      setMyParty(_myParty);
    }

    if (!_.isEqual(_strangerParty, strangerPartyRef.current)) {
      strangerPartyRef.current = _strangerParty;
      setStrangerParty(_strangerParty);
    }
  }, [party, steamId]);

  return {
    myParty,
    strangerParty,
    party,
  };
};

export const useParty = () => {
  const party = useStore((state) => state.party);
  const [partyObj, setPartyObj] = useState({});
  const [memberPartyObj, setMemberPartyObj] = useState({});

  useEffect(() => {
    setPartyObj(
      party.reduce(function (previousValue, currentValue) {
        if (!previousValue[currentValue.lobby_id]) {
          previousValue[currentValue.lobby_id] = [];
        }
        previousValue[currentValue.lobby_id].push(currentValue.member_steamid);
        return previousValue;
      }, {}),
    );
    setMemberPartyObj(
      party.reduce(function (previousValue, currentValue) {
        if (!previousValue[currentValue.member_steamid]) {
          previousValue[currentValue.member_steamid] = [];
        }
        previousValue[currentValue.member_steamid].push(currentValue.lobby_id);
        return previousValue;
      }, {}),
    );
  }, [party]);

  return {
    party,
    partyObj,
    memberPartyObj,
  };
};

export async function fetchMyAccount() {
  if (window.fetchingMyAccount) return;
  window.fetchingMyAccount = true;

  if (useStore.persist.hasHydrated()) {
    let state = useStore.getState();
    const accounts = await state.getMyAccount(state.myAccountFilterCriteria);
    await sleep(1000);
    await state.getMyAccount({ ignoreSteamIds: accounts.map((a) => a.steamId) });
    window.fetchingMyAccount = false;
  } else {
    const unsub = useStore.persist.onFinishHydration(async (state) => {
      unsub();
      const accounts = await state.getMyAccount(state.myAccountFilterCriteria);
      await sleep(1000);
      await state.getMyAccount({ ignoreSteamIds: accounts.map((a) => a.steamId) });
      window.fetchingMyAccount = false;
    });
  }
}

export const useFriendPlayTime = () => {
  const [friendPlayTime, setFriendPlayTime] = useState([]);
  const accountList = useStore((state) => state.accountList);

  useEffect(() => {
    setFriendPlayTime(function (prevState) {
      return accountList
        .map((a) => a.friendPlayTime)
        .flat()
        .filter(Boolean)
        .reduce(function (previousValue, currentValue) {
          if (currentValue.playingTime?.minutes_played && !_.isEqual(previousValue[currentValue.steamId], currentValue)) {
            previousValue[currentValue.steamId] = currentValue;
          }
          return previousValue;
        }, prevState);
    });
  }, [accountList]);

  return friendPlayTime;
};

export function areArraysSoftEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

const areStatesSoftEqual = (state1, state2) => {
  const steamId1 = Object.keys(state1).sort();
  const steamId2 = Object.keys(state2).sort();

  if (!areArraysSoftEqual(steamId1, steamId2)) {
    return false;
  }

  for (const steamId in state1) {
    if (state2[steamId] !== state1[steamId]) {
      return false;
    }
  }
  return true;
};

export const useIsFollow = (steamId) => {
  const [isFollow, setIsFollow] = useState(false);
  const friendFollowIDs = useStore((state) => state.friendFollowIDs);

  useEffect(() => {
    if (!steamId || !Array.isArray(friendFollowIDs)) return;
    setIsFollow(friendFollowIDs.includes(steamId));
  }, [friendFollowIDs, steamId]);

  return isFollow;
};

export const useSteamPlayerGroup = (steamId) => {
  //steamId or steam_player_group
  const [steamPlayerGroup, setSteamPlayerGroup] = useState();
  const friendList = useStore((state) => state.friendList);

  useEffect(() => {
    if (!steamId) return;
    const friend = friendList.find((friend) => friend.steamId === steamId || friend.state?.steam_player_group);
    setSteamPlayerGroup(friend?.state?.steam_player_group || null);
  }, [friendList, steamId]);

  return steamPlayerGroup;
};

export const useCustomLobby = (steamId) => {
  //steamId or steam_player_group
  const [customLobby, setCustomLobby] = useState(null);
  const isCustomLobby = useMemo(() => {
    return !!customLobby;
  }, [customLobby]);
  const steamPlayerGroup = useSteamPlayerGroup(steamId);
  const storeCustomLobby = useStore((state) => state.customLobby);

  useEffect(() => {
    if (!steamId) return;
    setCustomLobby(steamPlayerGroup ? storeCustomLobby[steamPlayerGroup] : null);
  }, [steamId, steamPlayerGroup, storeCustomLobby]);

  return { isCustomLobby, customLobby };
};

export const useFriendsIDList = (steamIds) => {
  const { accountList } = useAccounts(steamIds);
  const [friendsIDList, setFriendsIDList] = useState([]);

  useEffect(() => {
    const friendsIDList = _.uniq((accountList || []).map((a) => a?.friendsIDList || []).flat()).sort();
    setFriendsIDList(function (prevState) {
      return areArraysSoftEqual(prevState, friendsIDList) ? prevState : friendsIDList;
    });
  }, [accountList]);

  return friendsIDList;
};
