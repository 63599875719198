import useStore from "store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { toNonAccentVietnameseLowerCase } from "alpha-common-utils/StringUtils";
import { useDebounce } from "@uidotdev/usehooks";
import _ from "lodash";
import classNames from "classnames";
import SteamAvatar from "./components/SteamAvatar";

function MyPlayerPicker({ selectedPlayer, setSelectedPlayer, list }) {
  const matchHistoryPlayer = useStore((state) => state.matchHistoryPlayer);
  const onChange = useCallback((value) => setSelectedPlayer(value), [setSelectedPlayer]);
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [options, setOptions] = useState([]);

  const displayOptions = useMemo(() => {
    const { accountList } = useStore.getState();
    const uniqueOptions = _.groupBy(options, String);
    return _.sortBy(Object.keys(uniqueOptions), function (steamId) {
      return -uniqueOptions[steamId].length;
    }).map((steamId) => ({
      value: steamId,
      label: (
        <span className="flex gap-5">
          <span>
            <SteamAvatar avatarHash={matchHistoryPlayer[steamId]?.avatarHash} className="w-6 h-6" />
          </span>
          <span>
            <span
              className={classNames({
                "font-bold": accountList.some((a) => a.steamId === steamId),
              })}
            >
              {matchHistoryPlayer[steamId].name}
            </span>
            ({uniqueOptions[steamId].length})
          </span>
        </span>
      ),
    }));
  }, [matchHistoryPlayer, options]);

  useEffect(() => {
    let query = debouncedSearchTerm?.trim();
    if (!query) {
      setOptions(list);
    } else {
      const { accountList, matchHistoryPlayer } = useStore.getState();
      query = toNonAccentVietnameseLowerCase(query);
      setOptions(
        list.filter(function (steamId) {
          if (steamId.includes(query)) {
            return true;
          }

          const account = accountList.find((a) => a.steamId === steamId);
          if (account) {
            if (toNonAccentVietnameseLowerCase(account.username).indexOf(query) > -1) {
              return true;
            }
            if (toNonAccentVietnameseLowerCase(account.personaName).indexOf(query) > -1) {
              return true;
            }
          }

          return toNonAccentVietnameseLowerCase(matchHistoryPlayer[steamId].name).indexOf(query) > -1;
        }),
      );
    }
  }, [debouncedSearchTerm, list]);

  return <Select allowClear showSearch onSearch={setSearchTerm} filterOption={false} value={selectedPlayer} className="w-64" options={displayOptions} onChange={onChange}></Select>;
}

export default React.memo(MyPlayerPicker, _.isEqual);
