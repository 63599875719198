import React, { useEffect, useMemo, useRef, useState } from "react";
import api from "api";
import useStore from "store";
import moment from "moment";
import SimpleButton from "../../shared/SimpleButton";
import Toast from "../../toast";
import Loading from "../../components/Loading";
import { Checkbox } from "antd";

const ItemDefinitionIndex = {
  WeaponDeagle: 1,
  WeaponElite: 2,
  WeaponFiveseven: 3,
  WeaponGlock: 4,
  WeaponAk47: 7,
  WeaponAug: 8,
  WeaponAwp: 9,
  WeaponFamas: 10,
  WeaponG3sg1: 11,
  WeaponGalilar: 13,
  WeaponM249: 14,
  WeaponM4a1: 16,
  WeaponMac10: 17,
  WeaponP90: 19,
  WeaponMp5sd: 23,
  WeaponUmp45: 24,
  WeaponXm1014: 25,
  WeaponBizon: 26,
  WeaponMag7: 27,
  WeaponNegev: 28,
  WeaponSawedoff: 29,
  WeaponTec9: 30,
  WeaponTaser: 31,
  WeaponHkp2000: 32,
  WeaponMp7: 33,
  WeaponMp9: 34,
  WeaponNova: 35,
  WeaponP250: 36,
  WeaponScar20: 38,
  WeaponSg556: 39,
  WeaponSsg08: 40,
  WeaponKnifegg: 41,
  WeaponKnife: 42,
  WeaponFlashbang: 43,
  WeaponHegrenade: 44,
  WeaponSmokegrenade: 45,
  WeaponMolotov: 46,
  WeaponDecoy: 47,
  WeaponIncgrenade: 48,
  WeaponC4: 49,
  ItemKevlar: 50,
  ItemAssaultsuit: 51,
  ItemHeavyassaultsuit: 52,
  ItemNvg: 54,
  ItemDefuser: 55,
  ItemCutters: 56,
  WeaponHealthshot: 57,
  MusickitDefault: 58,
  WeaponKnifeT: 59,
  WeaponM4a1Silencer: 60,
  WeaponUspSilencer: 61,
  WeaponCz75a: 63,
  WeaponRevolver: 64,
  WeaponTagrenade: 68,
  WeaponFists: 69,
  WeaponBreachcharge: 70,
  WeaponTablet: 72,
  WeaponMelee: 74,
  WeaponAxe: 75,
  WeaponHammer: 76,
  WeaponSpanner: 78,
  WeaponKnifeGhost: 80,
  WeaponFirebomb: 81,
  WeaponDiversion: 82,
  WeaponFragGrenade: 83,
  WeaponSnowball: 84,
  WeaponBayonet: 500,
  WeaponKnifeFlip: 505,
  WeaponKnifeGut: 506,
  WeaponKnifeKarambit: 507,
  WeaponKnifeM9Bayonet: 508,
  WeaponKnifeTactical: 509,
  WeaponKnifeFalchion: 512,
  WeaponKnifeSurvivalBowie: 514,
  WeaponKnifeButterfly: 515,
  WeaponKnifePush: 516,
  WeaponKnifeUrsus: 519,
  WeaponKnifeGypsyJackknife: 520,
  WeaponKnifeStiletto: 522,
  WeaponKnifeWidowmaker: 523,
  Musickit: 1314,
  StuddedBloodhoundGloves: 5027,
  TGloves: 5028,
  CtGloves: 5029,
  SportyGloves: 5030,
  SlickGloves: 5031,
  LeatherHandwraps: 5032,
  MotorcycleGloves: 5033,
  SpecialistGloves: 5034,
  StuddedHydraGloves: 5035,
};

const CaseDefinitionIndex = [
  {
    id: "crate-4001",
    name: "CS:GO Weapon Case",
    description: null,
    type: "Case",
    first_sale_date: "2013/09/20",
    market_hash_name: "CS:GO Weapon Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_valve_1_png.png",
  },
  {
    id: "crate-4002",
    name: "eSports 2013 Case",
    description: "A portion of the proceeds from the key used to unlock this will help support CS:GO professional tournament prize pools.",
    type: "Case",
    first_sale_date: "2014/07/09",
    market_hash_name: "eSports 2013 Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_esports_2013_png.png",
  },
  {
    id: "crate-4003",
    name: "Operation Bravo Case",
    description: null,
    type: "Case",
    first_sale_date: "2013/09/20",
    market_hash_name: "Operation Bravo Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_operation_ii_png.png",
  },
  {
    id: "crate-4004",
    name: "CS:GO Weapon Case 2",
    description: null,
    type: "Case",
    first_sale_date: "2013/09/20",
    market_hash_name: "CS:GO Weapon Case 2",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_valve_2_png.png",
  },
  {
    id: "crate-4005",
    name: "eSports 2013 Winter Case",
    description: "A portion of the proceeds from the key used to unlock this will help support CS:GO professional tournament prize pools.",
    type: "Case",
    first_sale_date: "2014/07/09",
    market_hash_name: "eSports 2013 Winter Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_esports_2013_14_png.png",
  },
  {
    id: "crate-4009",
    name: "Winter Offensive Weapon Case",
    description: null,
    type: "Case",
    first_sale_date: "2013/12/18",
    market_hash_name: "Winter Offensive Weapon Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_1_png.png",
  },
  {
    id: "crate-4010",
    name: "CS:GO Weapon Case 3",
    description: null,
    type: "Case",
    first_sale_date: "2013/09/20",
    market_hash_name: "CS:GO Weapon Case 3",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_valve_3_png.png",
  },
  {
    id: "crate-4011",
    name: "Operation Phoenix Weapon Case",
    description: null,
    type: "Case",
    first_sale_date: "2014/2/19",
    market_hash_name: "Operation Phoenix Weapon Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_2_png.png",
  },
  {
    id: "crate-4017",
    name: "Huntsman Weapon Case",
    description: null,
    type: "Case",
    first_sale_date: "2014/06/11",
    special_notes: [
      {
        source: "https://steamcommunity.com/games/CSGO/announcements/detail/1751086783896069815",
        text: "Some skins (M4A4 Howl, USP-S Orion, MAC-10 Curse, CZ75-Auto Poison Dart, Dual Berettas Retribution and P90 Desert Warfare) were removed and replaced for others due to art theft.",
      },
    ],
    market_hash_name: "Huntsman Weapon Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_3_png.png",
  },
  {
    id: "crate-4018",
    name: "Operation Breakout Weapon Case",
    description: null,
    type: "Case",
    first_sale_date: "2014/06/23",
    market_hash_name: "Operation Breakout Weapon Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_4_png.png",
  },
  {
    id: "crate-4019",
    name: "eSports 2014 Summer Case",
    description: "A portion of the proceeds from the key used to unlock this will help support CS:GO professional tournament prize pools.",
    type: "Case",
    first_sale_date: "2014/07/09",
    market_hash_name: "eSports 2014 Summer Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_esports_2014_summer_png.png",
  },
  {
    id: "crate-4029",
    name: "Operation Vanguard Weapon Case",
    description: null,
    type: "Case",
    first_sale_date: "2014/11/5",
    market_hash_name: "Operation Vanguard Weapon Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_5_png.png",
  },
  {
    id: "crate-4061",
    name: "Chroma Case",
    description: null,
    type: "Case",
    first_sale_date: "2015/1/8",
    market_hash_name: "Chroma Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_6_png.png",
  },
  {
    id: "crate-4089",
    name: "Chroma 2 Case",
    description: null,
    type: "Case",
    first_sale_date: "2015/4/15",
    market_hash_name: "Chroma 2 Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_7_png.png",
  },
  {
    id: "crate-4091",
    name: "Falchion Case",
    description: null,
    type: "Case",
    first_sale_date: "2015/4/15",
    market_hash_name: "Falchion Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_8_png.png",
  },
  {
    id: "crate-4138",
    name: "Shadow Case",
    description: null,
    type: "Case",
    first_sale_date: "2015/9/15",
    market_hash_name: "Shadow Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_9_png.png",
  },
  {
    id: "crate-4186",
    name: "Revolver Case",
    description: null,
    type: "Case",
    first_sale_date: "2015-11-30",
    market_hash_name: "Revolver Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_10_png.png",
  },
  {
    id: "crate-4187",
    name: "Operation Wildfire Case",
    description: null,
    type: "Case",
    first_sale_date: "2016-01-19",
    market_hash_name: "Operation Wildfire Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_11_png.png",
  },
  {
    id: "crate-4233",
    name: "Chroma 3 Case",
    description: null,
    type: "Case",
    first_sale_date: "2016-04-19",
    market_hash_name: "Chroma 3 Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_12_png.png",
  },
  {
    id: "crate-4236",
    name: "Gamma Case",
    description: null,
    type: "Case",
    first_sale_date: "2016-06-10",
    market_hash_name: "Gamma Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_13_png.png",
  },
  {
    id: "crate-4281",
    name: "Gamma 2 Case",
    description: null,
    type: "Case",
    first_sale_date: "2016-08-08",
    market_hash_name: "Gamma 2 Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_gamma_2_png.png",
  },
  {
    id: "crate-4288",
    name: "Glove Case",
    description: null,
    type: "Case",
    first_sale_date: "2016-11-02",
    market_hash_name: "Glove Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_15_png.png",
  },
  {
    id: "crate-4351",
    name: "Spectrum Case",
    description: null,
    type: "Case",
    first_sale_date: "2017-03-01",
    market_hash_name: "Spectrum Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_16_png.png",
  },
  {
    id: "crate-4352",
    name: "Operation Hydra Case",
    description: null,
    type: "Case",
    first_sale_date: "2017-04-21",
    market_hash_name: "Operation Hydra Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_17_png.png",
  },
  {
    id: "crate-4403",
    name: "Spectrum 2 Case",
    description: null,
    type: "Case",
    first_sale_date: "2017-09-06",
    market_hash_name: "Spectrum 2 Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_18_png.png",
  },
  {
    id: "crate-4471",
    name: "Clutch Case",
    description: null,
    type: "Case",
    first_sale_date: "2017-12-20",
    market_hash_name: "Clutch Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_19_png.png",
  },
  {
    id: "crate-4482",
    name: "Horizon Case",
    description: null,
    type: "Case",
    first_sale_date: "2018-06-07",
    market_hash_name: "Horizon Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_20_png.png",
  },
  {
    id: "crate-4548",
    name: "Danger Zone Case",
    description: null,
    type: "Case",
    first_sale_date: "2018-11-29",
    market_hash_name: "Danger Zone Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_21_png.png",
  },
  {
    id: "crate-4598",
    name: "Prisma Case",
    description: null,
    type: "Case",
    first_sale_date: "2019-03-05",
    market_hash_name: "Prisma Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_22_png.png",
  },
  {
    id: "crate-4620",
    name: "Shattered Web Case",
    description: null,
    type: "Case",
    first_sale_date: "2019-06-12",
    market_hash_name: "Shattered Web Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_23_png.png",
  },
  {
    id: "crate-4669",
    name: "CS20 Case",
    description: "Celebrating the 20th Anniversary of Counter-Strike, this weapon case contains a variety of weapon finishes from Steam Community Workshop and features the Classic Knife as the rare special item, a classic of the Counter-Strike series.",
    type: "Case",
    first_sale_date: "2019-10-14",
    market_hash_name: "CS20 Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_24_png.png",
  },
  {
    id: "crate-4695",
    name: "Prisma 2 Case",
    description: null,
    type: "Case",
    first_sale_date: "2020-03-24",
    market_hash_name: "Prisma 2 Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_25_png.png",
  },
  {
    id: "crate-4698",
    name: "Fracture Case",
    description: null,
    type: "Case",
    first_sale_date: "2020-07-13",
    market_hash_name: "Fracture Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_26_png.png",
  },
  {
    id: "crate-4717",
    name: "Operation Broken Fang Case",
    description: null,
    type: "Case",
    first_sale_date: "2020-10-14",
    market_hash_name: "Operation Broken Fang Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_27_png.png",
  },
  {
    id: "crate-4747",
    name: "Snakebite Case",
    description: null,
    type: "Case",
    first_sale_date: "2021-03-26",
    market_hash_name: "Snakebite Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_28_png.png",
  },
  {
    id: "crate-4790",
    name: "Operation Riptide Case",
    description: null,
    type: "Case",
    first_sale_date: "2021-09-08",
    market_hash_name: "Operation Riptide Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_29_png.png",
  },
  {
    id: "crate-4818",
    name: "Dreams & Nightmares Case",
    description: null,
    type: "Case",
    first_sale_date: "2021-11-17",
    market_hash_name: "Dreams & Nightmares Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_30_png.png",
  },
  {
    id: "crate-4846",
    name: "Recoil Case",
    description: null,
    type: "Case",
    first_sale_date: "2022-05-30",
    market_hash_name: "Recoil Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_31_png.png",
  },
  {
    id: "crate-4880",
    name: "Revolution Case",
    description: null,
    type: "Case",
    first_sale_date: "2023-01-31",
    market_hash_name: "Revolution Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_32_png.png",
  },
  {
    id: "crate-4904",
    name: "Kilowatt Case",
    description: null,
    type: "Case",
    first_sale_date: "2024-01-31",
    market_hash_name: "Kilowatt Case",
    image: "https://raw.githubusercontent.com/ByMykel/counter-strike-image-tracker/main/static/panorama/images/econ/weapon_cases/crate_community_33_png.png",
  },
];

const StickerDefinitionIndex = {
  1209: "Sticker",
  1348: "Sealed Graffiti",
  1349: "Graffiti",
  4609: "Patch",
};

export default function MyPersonalStore() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const [personalStoreItems, setPersonalStoreItems] = useState([]);
  const [loading, setLoading] = useState();
  const subToolRef = useRef();
  const bodyRef = useRef();
  const [selectedItems, setSelectedItems] = useState({});

  const claimItems = useMemo(() => {
    return Object.keys(selectedItems)
      .map(function (def_index) {
        return personalStoreItems.find((item) => item.def_index == def_index)?.name;
      })
      .join(", ");
  }, [personalStoreItems, selectedItems]);

  useEffect(() => {
    if (!bodyRef.current || !subToolRef.current) return;
    const subToolBoundingClientRect = subToolRef.current.getBoundingClientRect();
    bodyRef.current.style.height = `calc(100vh - ${subToolBoundingClientRect.y + subToolBoundingClientRect.height + 1}px)`;
  }, [bodyRef, subToolRef]);

  return (
    <div className="relative h-full">
      <div ref={subToolRef}>
        <SimpleButton
          onClick={async function () {
            setLoading(true);
            const { clientWelcome, clientHello } = (await api.getPersonalStore({ steamId: selectedAccountID }))?.data || {};
            setLoading(false);
            const personalStore = clientWelcome?.personalStore;
            if (!personalStore) return Toast.error("Failed");
            const generation_time = moment(personalStore.generation_time).fromNow();
            switch (personalStore.redeemable_balance) {
              case 0:
                Toast.success(`claimed ${generation_time}`);
                break;
              case 2:
                Toast.success(`not yet claim ${generation_time}`);
                setPersonalStoreItems(
                  personalStore.items.map(function (id) {
                    const item = clientWelcome.items[id];
                    const def_index = item.def_index;
                    const caseItem = CaseDefinitionIndex.find((r) => r.id === `crate-${def_index}`);
                    const sticker = StickerDefinitionIndex[def_index];
                    const otherItem = Object.keys(ItemDefinitionIndex).find((k) => ItemDefinitionIndex[k] === def_index);

                    return {
                      ...item,
                      name: caseItem?.name ?? sticker ?? otherItem,
                    };
                  }),
                );
                break;
              default:
                Toast.error(`Invalid redeemable_balance: ${personalStore.redeemable_balance}`);
            }
          }}
        >
          Fetch
        </SimpleButton>
        <SimpleButton
          onClick={async function () {
            setLoading(true);
            //claim
            setLoading(false);
          }}
        >
          <span className="mr-2">Claim</span>
          {!!claimItems && <span>({claimItems})</span>}
        </SimpleButton>
      </div>
      <div ref={bodyRef} className="overflow-y-auto m-2">
        {personalStoreItems.map(function (item) {
          let itemContent = null;
          const def_index = item.def_index;
          const caseItem = CaseDefinitionIndex.find((r) => r.id === `crate-${def_index}`);
          const sticker = StickerDefinitionIndex[def_index];
          const otherItem = Object.keys(ItemDefinitionIndex).find((k) => ItemDefinitionIndex[k] === def_index);
          if (caseItem) {
            itemContent = (
              <>
                <a href={`https://steamcommunity.com/market/listings/730/${encodeURIComponent(caseItem.market_hash_name)}`} target="_blank" rel="noreferrer">
                  <img src={caseItem.image} width={50} height="auto" />
                  <span className="pl-1">{caseItem.name}</span>
                </a>
              </>
            );
          } else if (sticker) {
            itemContent = (
              <>
                <span className="pl-1">{sticker}</span>
              </>
            );
          } else if (otherItem) {
            itemContent = <>{otherItem}</>;
          }

          return (
            <div key={item.id} className="py-5 border border-solid border-b-gray-300 border-l-0 border-r-0 border-t-0 flex items-center">
              <span className="mr-5">
                <Checkbox
                  value={selectedItems[def_index]}
                  onChange={function (e) {
                    if (e.target.checked) {
                      setSelectedItems(function (prevState) {
                        return { ...prevState, [def_index]: true };
                      });
                    } else {
                      setSelectedItems(function (prevState) {
                        const newState = { ...prevState };
                        delete newState[def_index];
                        return newState;
                      });
                    }
                  }}
                  disabled={Object.keys(selectedItems).length >= 2 && !selectedItems[def_index]}
                />
              </span>
              <span className="mr-5">{def_index}</span>
              <span>{itemContent}</span>
            </div>
          );
        })}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
