import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "api";
import classNames from "classnames";
import useStore from "store";
import SimpleButton from "shared/SimpleButton";
import Toast from "toast";
import { isAccountLimited } from "../../utils";
import Loading from "../../components/Loading";

const FriendInviteBlock = React.memo(({ friend, isFriend, isAcceptable, receiveInvites, accept }) => (
  <div key={"Friend_Pending_" + friend.steamId} className="w-full relative flex gap-2 items-center border-b border-l-0 border-r-0 border-t-0 border-b-blue-500 border-solid p-2">
    <img src={friend.avatar} className="w-10 h-10" />
    <a
      target="_blank"
      href={friend.link}
      className={classNames({
        "line-through text-slate-400 hover:text-slate-400": isFriend,
      })}
      rel="noreferrer"
    >
      {friend.name}
    </a>
    {!!friend.summary?.location && (
      <span className="flex flex-row items-center">
        <img src={`https://community.cloudflare.steamstatic.com/public/images/countryflags/${friend.summary?.location?.toLowerCase()}.gif`} className="w-8 h-auto" />
        <span>{friend.summary.location}</span>
      </span>
    )}
    {isAcceptable && receiveInvites && (
      <>
        &nbsp;&nbsp;
        <SimpleButton
          onClick={async () => {
            await accept(friend.myAccountSteamId, friend.steamId, "accept");
          }}
          type="default"
        >
          Accept
        </SimpleButton>
      </>
    )}
    &nbsp;&nbsp;
    <SimpleButton
      onClick={async () => {
        await accept(friend.myAccountSteamId, friend.steamId, "cancel");
      }}
      type="default"
    >
      Cancel
    </SimpleButton>
  </div>
));

export default function MyFriendPending() {
  const addFriendId = useStore((state) => state.addFriendId);
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const accountList = useStore((state) => state.accountList);
  const bodyRef = useRef();
  const [friendsPending, setFriendsPending] = useState({});
  const [loading, setLoading] = useState(false);

  const accept = useCallback(
    async (myAccountSteamId, steamId, acceptStatus) => {
      const response = (
        await api.doAcceptFriendPending({
          myAccountID: myAccountSteamId,
          steamId,
          acceptStatus,
        })
      )?.data;
      if (response?.error) {
        Toast.error(response.error);
      } else if (response?.result?.success === 1 || response?.result === true) {
        switch (acceptStatus) {
          case "accept":
            Toast.success("Accepted");
            addFriendId(myAccountSteamId, steamId);
            setFriendsPending(function (__friendsPending) {
              const receiveInvites = __friendsPending[myAccountSteamId]?.receiveInvites.slice();
              const sentInvites = __friendsPending[myAccountSteamId]?.sentInvites.slice();
              const indexReceiveInvite = receiveInvites.findIndex((f) => f.steamId === steamId);
              const indexSentInvite = sentInvites.findIndex((f) => f.steamId === steamId);
              if (indexReceiveInvite > -1) {
                receiveInvites.splice(indexReceiveInvite, 1);
              }
              if (indexSentInvite > -1) {
                sentInvites.splice(indexSentInvite, 1);
              }

              return {
                ...__friendsPending,
                [myAccountSteamId]: {
                  ...__friendsPending[myAccountSteamId],
                  receiveInvites,
                  sentInvites,
                },
              };
            });

            break;
          case "cancel":
            setFriendsPending(function (__friendsPending) {
              const sentInvites = __friendsPending[myAccountSteamId]?.sentInvites.slice();
              const receiveInvites = __friendsPending[myAccountSteamId]?.receiveInvites.slice();
              const indexSentInvite = sentInvites.findIndex((f) => f.steamId === steamId);
              if (indexSentInvite > -1) {
                sentInvites.splice(indexSentInvite, 1);
              }
              const indexReceiveInvite = receiveInvites.findIndex((f) => f.steamId === steamId);
              if (indexReceiveInvite > -1) {
                receiveInvites.splice(indexReceiveInvite, 1);
              }

              return {
                ...__friendsPending,
                [myAccountSteamId]: {
                  ...__friendsPending[myAccountSteamId],
                  sentInvites,
                  receiveInvites,
                },
              };
            });
            Toast.success("Canceled");
            break;
          default:
            break;
        }
      }
    },
    [addFriendId],
  );

  const getMyFriendPending = useCallback(async () => {
    setLoading(true);
    const _friendsPending = (await api.getMyFriendPending({ steamId: selectedAccountID }))?.data;
    setLoading(false);

    _friendsPending?.peddingList?.receiveInvites?.forEach((f) => (f.myAccountSteamId = selectedAccountID));
    _friendsPending?.peddingList?.sentInvites?.forEach((f) => (f.myAccountSteamId = selectedAccountID));
    setFriendsPending((__friendsPending) => ({
      ...__friendsPending,
      [selectedAccountID]: _friendsPending?.peddingList,
    }));
  }, [selectedAccountID]);

  useEffect(() => {
    if (!friendsPending[selectedAccountID]) {
      getMyFriendPending();
    }
  }, [friendsPending, getMyFriendPending, selectedAccountID]);

  useEffect(() => {
    if (!bodyRef.current) return;
    const subToolBoundingClientRect = bodyRef.current.getBoundingClientRect();
    bodyRef.current.style.height = `calc(100vh - ${subToolBoundingClientRect.y + 1}px)`;
  }, [bodyRef]);

  const handleOnCancelOutGoingPending = useCallback(async () => {
    const sentInvites = friendsPending[selectedAccountID]?.sentInvites || [];
    for (const sentInvite of sentInvites) {
      await accept(sentInvite.myAccountSteamId, sentInvite.steamId, "cancel");
    }
    await getMyFriendPending();
  }, [accept, friendsPending, getMyFriendPending, selectedAccountID]);

  const handleOnCancelInComingPending = useCallback(async () => {
    const receiveInvites = friendsPending[selectedAccountID]?.receiveInvites || [];
    for (const receiveInvite of receiveInvites) {
      await accept(receiveInvite.myAccountSteamId, receiveInvite.steamId, "cancel");
    }
    await getMyFriendPending();
  }, [accept, friendsPending, getMyFriendPending, selectedAccountID]);

  return (
    <>
      <div className="">
        <div className="flex w-full" ref={bodyRef}>
          <div className="w-1/2 overflow-y-auto">
            <div className="w-full text-center flex items-center justify-center">
              <div className="font-bold">Received Invites ({friendsPending[selectedAccountID]?.receiveInvites?.length || 0})</div>
              <SimpleButton onClick={handleOnCancelInComingPending}>Cancel All</SimpleButton>
            </div>
            {friendsPending[selectedAccountID]?.receiveInvites?.map?.(function (friend) {
              const myAccountList = accountList.filter?.((a) => a.friendsIDList?.includes?.(friend.steamId));
              const isFriend = !!myAccountList?.length;
              const isAcceptable = !isFriend || isAccountLimited(accountList.find((a) => a.steamId === selectedAccountID));
              return <FriendInviteBlock friend={friend} isFriend={isFriend} isAcceptable={isAcceptable} receiveInvites={true} accept={accept} index={friend.steamId} key={friend.steamId} />;
            })}
          </div>
          <div className="w-1/2 overflow-y-auto">
            <div className="w-full text-center flex items-center justify-center">
              <div className="font-bold">Sent Invites ({friendsPending[selectedAccountID]?.sentInvites?.length || 0})</div>
              <SimpleButton onClick={handleOnCancelOutGoingPending}>Cancel All</SimpleButton>
            </div>
            {friendsPending[selectedAccountID]?.sentInvites?.map?.(function (friend) {
              const isFriend = accountList.some?.((a) => a.friendsIDList?.includes?.(friend.steamId));
              return <FriendInviteBlock friend={friend} isFriend={isFriend} receiveInvites={false} accept={accept} index={friend.steamId} key={friend.steamId} />;
            })}
          </div>
        </div>
        <Loading loading={loading} />
      </div>
    </>
  );
}
