import classNames from "classnames";
import { Dropdown } from "antd";
import GuardIcon from "../image/guard.svg";
import moment from "moment/moment";
import React, { useMemo } from "react";
import { copy2Clipboard, generateMenuItems, getbonus_xp_timestamp_refresh, getQuickInviteLink, isAccountBanned, isAccountDropedCaseThisWeek, isAccountLimited, openSteamProfile, SwalSelectPlayer } from "../utils";
import useStore, { ModalType, usePlayerState } from "store";
import { socket } from "../socket";
import api from "api";
import Toast from "toast";
import MenuItem from "../shared/MenuItem";
import copy from "copy-to-clipboard";
import Swal from "sweetalert2";
import EloBlock from "./EloBlock";
import XpBonus from "../components/XpBonus";
import SteamIcon from "../image/steam-logo.svg";
import CS2Icon from "../image/cs2-logo.png";
import _ from "lodash";

export function getMyAccountMenu(account, pin) {
  if (!account) {
    return [];
  }

  return [
    {
      label: "Pin",
      onClick({ steamId }) {
        useStore.getState().addAccountPin(steamId);
      },
      show: () => !pin,
    },
    {
      label: "Copy",
      children: [
        MenuItem.CopySteamProfile,
        MenuItem.CopySteamID,
        MenuItem.CopyMiniProfileID,
        MenuItem.CopyFriendCode,
        {
          label: "Copy trade URL",
          onClick(item) {
            copy2Clipboard(item.tradeURL);
          },
        },
        {
          label: "Copy invite friend URL",
          async onClick(item) {
            const invite_link = await getQuickInviteLink(item);
            if (invite_link) {
              copy2Clipboard(invite_link);
            }
          },
        },
      ],
    },
    {
      label: "Account",
      children: [
        {
          label: "Enable two factor",
          show: () => !account.enableTwoFactor,
          async onClick({ steamId }) {
            socket.enable_twofactor({ steamId });
          },
        },
        {
          label: "Disable 2 Factor",
          onClick({ steamId }) {
            Toast.error("Try again (404)");
            // socket?.disableTwoFactor(steamId);
          },
        },
        {
          label: "Public Privacy Settings",
          async onClick({ steamId }) {
            if (!steamId) {
              return;
            }
            await api.publicPrivacySettings({ steamId });
          },
        },
        {
          label: "Copy SteamGuard code",
          async onClick({ steamId }) {
            let result = (await api.getSteamGuardCode({ steamId }))?.data;
            const code = result?.code;
            copy2Clipboard(code);
          },
        },
        {
          label: "Deauthorize All Devices",
          async onClick({ steamId }) {
            api.deauthorizeAllDevices({ steamId }).then(function (result) {
              if (result?.data?._id) {
                Toast.success(`deauthorizeAllDevices success`);
                useStore.getState().updateAccounts(result.data);
              } else if (result?.data?.error) {
                Toast.error(`deauthorizeAllDevices Failed ${result?.data?.error?.message || ""}`);
              }
            });
          },
        },
        {
          label: "kickMyAccountPlayingSession",
          async onClick({ steamId }) {
            const result = (await api.kickMyAccountPlayingSession({ steamId }))?.data;
            console.log(result);
            if (result?.error) {
              Toast.error(result?.error);
            }
          },
        },
        {
          label: "Cancel all sent friend request",
          async onClick({ steamId }) {
            api.cancelAllSentFriendRequest(steamId);
          },
        },
        {
          label: "Add other myaccount",
          async onClick({ steamId }) {
            const { accountList } = useStore.getState();
            const account = accountList.find((a) => a.steamId === steamId);
            SwalSelectPlayer({
              options: accountList.filter((a) => a.steamId !== steamId),
              optionLabelField: ["personaName", "username"],
              optionValueField: "steamId",
              optionIconField: (a) => `https://avatars.akamai.steamstatic.com/${a.avatarHash}_full.jpg`,
              title: `Add other myaccount ${account.username}`,
              key: "add_other_myaccount",
              callback: async function (selectedAccount) {
                if (!selectedAccount) {
                  return;
                }
                const invite_link = await getQuickInviteLink(selectedAccount);
                if (!invite_link) {
                  return;
                }
                const result = (
                  await api.acceptQuickInviteLink({
                    steamId,
                    quickInviteLink: invite_link,
                  })
                )?.data;
                if (result?.success === 1) {
                  Toast.success(`Add friend success ${selectedAccount.username}`);
                }
              },
              confirmButtonText: "Add friend",
            });
          },
        },
        {
          label: "Set Natri profile",
          icon: <img src="https://i.imgur.com/AJlDFS4.jpg" className="w-5 h-5" />,
          async onClick({ steamId }) {
            api.setProfile({ steamId, profile: "Natri" }).then(function (result) {
              if (!result?.data || result.data.error) {
                Toast.error(result?.data?.error || "Error");
              } else if (result.data.success) {
                Toast.success("Set profile to " + result.data?.profile);
              }
            });
          },
        },
        {
          label: "Set Default profile",
          icon: <img src="https://i.imgur.com/SCFdXDv.jpg" className="w-5 h-5" />,
          async onClick({ steamId }) {
            api.setProfile({ steamId, profile: "Default" }).then(function (result) {
              if (!result?.data || result.data.error) {
                Toast.error(result?.data?.error || "Error");
              } else if (result.data.success) {
                Toast.success("Set profile to " + result.data?.profile);
              }
            });
          },
        },
        {
          label: "Copy cookie",
          async onClick({ steamId }) {
            api.getMyAccountCookie({ steamId }).then(function (result) {
              const cookie = result?.data?.cookie;
              if (cookie) {
                Toast.success("Copied");
                copy(cookie);
              } else {
                Toast.error("Failed");
              }
            });
          },
        },
        {
          label: "Add friend this invite link",
          async onClick({ steamId }) {
            Swal.fire({
              title: "Submit your invite link",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Submit",
              showLoaderOnConfirm: true,
            }).then((result) => {
              if (result.isConfirmed) {
                const inviteLink = result.value;
                api.addFriendInviteLink({ myAccountID: steamId, inviteLink }).then(function (result) {
                  console.log(result?.data);
                });
              }
            });
          },
        },
      ],
    },
    {
      label: "Fake friend state",
      async onClick(item) {
        useStore.getState().setFakeFriendStateModal(item.steamId);

        /*Swal.fire({
                title: 'Submit friend steamId',
                input: 'text',
                inputValue: sessionStorage.getItem("Fake_friend_state"),
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    const friendSteamID = result.value;
                    sessionStorage.setItem("Fake_friend_state", friendSteamID)
                    api.fakeFriendState({myAccountID: item.steamId, steamId: friendSteamID})
                }
            })*/
      },
    },
    {
      label: "Rename",
      onClick({ steamId }) {
        useStore.getState().setRenameSteamModal(steamId);
      },
    },
    {
      label: "Status",
      children: [
        {
          label: "Offline",
          onClick({ steamId }) {
            console.log("onclick offline");
            api.setOnlineStatus({ onlineStatus: "offline", steamId });
          },
        },
        {
          label: "Online",
          onClick({ steamId }) {
            console.log("onclick Online");
            api.setOnlineStatus({ onlineStatus: "online", steamId });
          },
        },
        {
          label: "In Game",
          onClick({ steamId }) {
            console.log("onclick In Game");
            api.setOnlineStatus({ onlineStatus: "ingame", steamId });
          },
        },
      ],
    },
    {
      label: "Persona",
      children: [
        {
          label: "Offline",
          onClick({ steamId }) {
            console.log("onclick set Persona Offline");
            api.setPersona({ steamId, persona: 0 });
          },
        },
        {
          label: "Online",
          onClick({ steamId }) {
            console.log("onclick set Persona Online");
            api.setPersona({ steamId, persona: 1 });
          },
        },
        {
          label: "Busy",
          onClick({ steamId }) {
            console.log("onclick set Persona Busy");
            api.setPersona({ steamId, persona: 2 });
          },
        },
        {
          label: "Away",
          onClick({ steamId }) {
            console.log("onclick set Persona Away");
            api.setPersona({ steamId, persona: 3 });
          },
        },
        {
          label: "Snooze",
          onClick({ steamId }) {
            console.log("onclick set Persona Snooze");
            api.setPersona({ steamId, persona: 4 });
          },
        },
        {
          label: "LookingToTrade",
          onClick({ steamId }) {
            console.log("onclick set Persona LookingToTrade");
            api.setPersona({ steamId, persona: 5 });
          },
        },
        {
          label: "LookingToPlay",
          onClick({ steamId }) {
            console.log("onclick set Persona LookingToPlay");
            api.setPersona({ steamId, persona: 6 });
          },
        },
        {
          label: "Invisible",
          onClick({ steamId }) {
            console.log("onclick set Persona Invisible");
            api.setPersona({ steamId, persona: 7 });
          },
        },
      ],
    },
    {
      label: "fetchMyAccountInfo",
      async onClick({ steamId }) {
        await api.fetchMyAccountInfo({ steamId });
      },
    },
    {
      label: "fetchInventoryHistory",
      async onClick({ steamId }) {
        await api.fetchInventoryHistory({ steamId });
      },
    },
    {
      label: "Login",
      async onClick({ steamId }) {
        api.steamLogin({ steamId, requireClientAuth: true }).then(function (result) {
          if (!result || result.error) {
            return Toast.error(result?.error ?? "Failed");
          }
        });
      },
    },
    {
      label: "ReLogin Steam Client",
      async onClick({ steamId }) {
        api.reloginSteamClient({ steamId });
      },
    },
    {
      label: "get steamclient state",
      async onClick({ steamId }) {
        const result = (await api.getSteamClientState({ steamId }))?.data;
        if (result?.error) {
          Toast.error(result.error);
        } else if (typeof result === "string") {
          Toast.info(result);
        }
      },
    },
    !!account.enableTwoFactor && {
      label: "Approve login QR",
      async onClick({ steamId }) {
        useStore.getState().showModal(ModalType.ApproveLoginQR, { steamId });
      },
    },
    MenuItem.OpenMessage,
    MenuItem.SendQuickMessage,
    {
      label: "Invite to lobby CS2",
      async onClick({ steamId }) {
        api.invite2LobbyCs2({ steamIds: steamId });
      },
    },
  ].filter(Boolean);
}

const MyAccountItem = React.memo(({ account, index, style, selected, pin }) => {
  const {
    playerStateList: [playerState],
  } = usePlayerState(account.steamId);

  let textColor = "text-black";
  if (isAccountBanned(account)) {
    textColor = "text-red-600";
  } else if (playerState?.onlineStatus === "ingame") {
    textColor = "text-green-600";
  } else if (playerState?.onlineStatus === "online") {
    textColor = "text-blue-600";
  } else {
    textColor = "text-black";
  }

  const CSGO_Profile_Rank = account.personalGameDataAccountInformation?.CSGO_Profile_Rank ?? account.ClientHello?.player_level ?? account.PersonaDataPublic?.player_level;

  const { handleMenuClick, items } = useMemo(
    function () {
      return generateMenuItems(getMyAccountMenu(account, pin));
    },
    [account, pin],
  );

  const menu = useMemo(
    () => ({
      items: items,
      // items: account.enableTwoFactor ? myAccountMenuItems.items.filter(function (item) {
      //     return item.key !== "Enable two factor";
      // }) : myAccountMenuItems.items.filter(function (item) {
      //     return item.key !== "Approve login QR" && item.key !== "Copy SteamGuard code";
      // }),
      onClick: (e) => handleMenuClick(e, account),
    }),
    [account, handleMenuClick, items],
  );
  const currentXp = account.currentXp ?? 0;
  const xpBarColor = "bg-stone-600";
  const xpBarWidth = (100 * currentXp) / 5000;
  const bonus_xp_usedflags_map = {
    1: "EarnedXpThisPeriod",
    2: "FirstReward",
    4: "Msg_YourReportGotConvicted",
    8: "Msg_YouPartiedWithCheaters",
    16: "PrestigeEarned",
    32: "ChinaGovernmentCert",
    268435456: "OverwatchBonus",
    536870912: "BonusBoostConsumed",
    1073741824: "ReducedGain",
  };

  const lastTimeCaseDropItem = useMemo(() => {
    if (!account.lastTimeCaseDropItems?.length) {
      return null;
    }
    const resetDay = getbonus_xp_timestamp_refresh();
    const droppedThisWeek = account.lastTimeCaseDropItems
      .flatMap(function (item) {
        return item.tradehistory_items.map(function (tradehistory_item) {
          return {
            ...item,
            ...tradehistory_item,
          };
        });
      })
      .filter(function (item) {
        return moment(item.timestamp * 1000).isAfter(resetDay);
      });

    if (!droppedThisWeek.length) return null;

    const container = droppedThisWeek.find(function (item) {
      return item.tags?.some?.((tag) => tag?.name === "Container");
    });
    return container || droppedThisWeek[0];
  }, [account.lastTimeCaseDropItems]);

  const cooldownExpiration = useMemo(
    function () {
      if (!account?.cooldownExpirationMs) return;
      const cooldownExpirationMoment = moment(account.cooldownExpirationMs);
      if (cooldownExpirationMoment.isBefore(moment())) {
        return;
      }
      return <span className="text-red-500 pl-2">{cooldownExpirationMoment.fromNow()}</span>;
    },
    [account?.cooldownExpirationMs],
  );

  return (
    <Dropdown key={"Dropdown_" + account._id} menu={menu} trigger={["contextMenu"]}>
      <div
        className={classNames(`AccountCard w-full ${textColor} ${playerState?.onlineStatus}`, {
          disable: account.disable,
          selected,
          "border-blue-600": account.prime,
          "border-pink-400": !account.prime,
          "bg-slate-200 opacity-80": isAccountDropedCaseThisWeek(account),
        })}
        onClick={(event) => {
          useStore.getState().setSelectedAccountID(account.steamId);
        }}
        onDoubleClick={function () {
          openSteamProfile(account.steamId);
        }}
        style={style}
        steamid={account.steamId}
      >
        <div className="w-full h-full relative flex gap-2 pb-2">
          <div
            className={classNames("w-14 block h-full border-2 border-solid relative", {
              "border-blue-600": account.prime,
              "border-pink-400": !account.prime,
            })}
          >
            <img className="w-full h-auto" src={`https://avatars.akamai.steamstatic.com/${account.avatarHash}_full.jpg`} />

            {!!account.prime && <EloBlock elo={account.elo}></EloBlock>}
          </div>
          <div className="px-2 flex items-center gap-2 h-full">
            <div className="flex justify-center flex-col items-start">
              {!!account?.steamLevel && (
                <div className="flex items-center gap-2 justify-start">
                  <img src={SteamIcon} className="w-4 h-4" />
                  <span className="w-6 h-6 rounded-full border border-solid border-slate-600 flex items-center justify-center text-sm">{account.steamLevel}</span>
                </div>
              )}
              <div className="flex items-center justify-start gap-2">
                <img src={CS2Icon} className="w-4 h-4" />
                <span>{CSGO_Profile_Rank}</span>
              </div>
            </div>
            <div className="flex flex-col w-full items-start h-full p-1">
              <div className="flex items-center gap-1">
                <span className="text-black">{index + 1}.</span>&nbsp;
                {account.isDropedThisWeek ? <span className="text-green-500">✔ </span> : ""}
                {!!account.enableTwoFactor && <img className="w-4 h-4" src={GuardIcon} />}
                {!!playerState?.numPlayers && <span>&nbsp;+{playerState?.numPlayers - 1}&nbsp;</span>}
                {isAccountLimited(account) ? "[L] " : ""}
                {_.uniq([playerState?.player_name, account.personaName, account.username]).filter(Boolean).join(" - ")}
                {!!cooldownExpiration && cooldownExpiration}
                {account.friendsIDList?.length ? ` (${account.friendsIDList.length})` : ""}
                <XpBonus className="italic text-sm" xp={account.xpEarned} />
                {Array.isArray(account.walletBalance) && <span className="font-bold text-xs italic">{account.walletBalance.join(" - ")}</span>}
              </div>
              <div className="text-sm flex gap-4">
                {!!lastTimeCaseDropItem && (
                  <span>
                    <img src={lastTimeCaseDropItem.icon_url ? `https://community.akamai.steamstatic.com/economy/image/${lastTimeCaseDropItem.icon_url}` : "https://cdn-icons-png.flaticon.com/128/934/934721.png"} className="w-6 mr-2" />
                    <span className="text-indigo-500">{[lastTimeCaseDropItem.name, moment(lastTimeCaseDropItem.timestamp * 1000).fromNow()].filter(Boolean).join("-")}</span>
                  </span>
                )}
                {!!account.totalPlayTimeLastWeek && (
                  <span>
                    <img src="https://cdn.iconscout.com/icon/premium/png-256-thumb/game-time-1642124-1393575.png" className="w-4 h-4 mr-2" />
                    <span>{account.totalPlayTimeLastWeek > 60 * 60 ? parseInt(moment.duration(account.totalPlayTimeLastWeek, "seconds").as("hours")) + " hours" : moment.duration(account.totalPlayTimeLastWeek, "seconds").humanize()}</span>
                  </span>
                )}
              </div>
              {!!(playerState?.score || playerState?.game) && (
                <div className="text-sm">
                  <span>
                    <img src="https://cdn-icons-png.flaticon.com/128/3908/3908534.png" className="w-4 h-4 mr-2" />
                    <span className="score">{[playerState?.game, playerState?.score].filter((g) => g && g !== "Counter-Strike 2").join(" - ")}</span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full absolute left-0 right-0 bottom-0 h-1 bg-slate-300">
          <div
            className={`relative h-full ${xpBarColor}`}
            style={{
              width: `${xpBarWidth}%`,
            }}
          ></div>
        </div>
      </div>
    </Dropdown>
  );
});
export default MyAccountItem;

function roundLevel(level) {
  for (let i = 0; i < 90; i += 10) {
    if (level < i) {
      return i - 10;
    }
  }
  for (let i = 0; i < 5200; i += 100) {
    if (level < i) {
      return i - 100;
    }
  }
  return level;
}
