import SteamAvatar from "./SteamAvatar";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useAccountBySteamId, usePlayerState } from "../store";
import { generateMenuItems, isAccountBanned } from "../utils";
import EloBlock from "../myaccount/EloBlock";
import SteamIcon from "../image/steam-logo.svg";
import CS2Icon from "../image/cs2-logo.png";
import moment from "moment";
import { Dropdown } from "antd";
import { getMyAccountMenu } from "../myaccount/MyAccountItem";

const MyAccountCard = React.memo(function MyAccountCard({ steamId, onClickAvatar, className, xpBar = true }) {
  const account = useAccountBySteamId(steamId);
  const {
    playerStateList: [playerState],
  } = usePlayerState(steamId);
  const xpBarWidth = (100 * (account?.currentXp || 0)) / 5000;

  let textColor = "text-black";
  if (account?.steamId) {
    if (isAccountBanned(account)) {
      textColor = "text-red-600";
    } else if (playerState?.onlineStatus === "ingame") {
      textColor = "text-green-600";
    } else if (playerState?.onlineStatus === "online") {
      textColor = "text-blue-600";
    } else {
      textColor = "text-black";
    }
  }

  const handleOnClickAvatar = useCallback(() => {
    onClickAvatar?.(steamId);
  }, [onClickAvatar, steamId]);

  const menuItems = useMemo(
    function () {
      return getMyAccountMenu(account, false);
    },
    [account],
  );

  const menu = useMemo(
    function () {
      if (!menuItems) return;

      const { handleMenuClick, items } = generateMenuItems(menuItems);

      return {
        items: items,
        onClick: (e) => handleMenuClick(e, account),
      };
    },
    [account, menuItems],
  );

  const content = useMemo(() => {
    if (!account) {
      return;
    }

    return (
      <div className="cursor-pointer hover:bg-gray-100">
        <div className={`relative ${className || ""}`}>
          <div className="w-full h-full flex gap-1">
            <div className="relative w-20 h-16 border border-solid border-gray-200 bg-slate-400">
              <SteamAvatar avatarHash={account.avatarHash} className="w-full h-full" {...(typeof onClickAvatar === "function" && { onClick: handleOnClickAvatar })} />
              {!!account?.prime && !!account.elo && <EloBlock elo={account.elo}></EloBlock>}
            </div>
            <div className="flex justify-center flex-col">
              {!!account?.steamLevel && (
                <div className="flex items-center gap-2 justify-start">
                  <img src={SteamIcon} className="w-4 h-4" />
                  <span className="w-6 h-6 rounded-full border border-solid border-slate-600 flex items-center justify-center text-sm">{account.steamLevel}</span>
                </div>
              )}
              <div className="flex items-center justify-start gap-2">
                <img src={CS2Icon} className="w-4 h-4" />
                <span>{account?.CSGO_Profile_Rank}</span>
              </div>
            </div>
            <div className="w-full flex flex-col items-start justify-center">
              <a className={`${textColor} no-underline opacity-90 hover:opacity-100`} href={`https://steamcommunity.com/profiles/${steamId}`} target="_blank" rel="noreferrer">
                {!!playerState?.numPlayers && <span>&nbsp;+{playerState?.numPlayers - 1}&nbsp;</span>}
                {_.uniq([account.username, account.personaName].filter(Boolean)).join(" - ")}
              </a>
              {!!playerState?.score && <div className={`${textColor} text-sm italic`}>{playerState?.score}</div>}
              {!!account.dropItem && (
                <div className="flex items-start flex-row w-full text-sm">
                  <img src={account.dropItem?.icon_fullurl || "https://cdn-icons-png.flaticon.com/128/934/934721.png"} className="w-6 mr-2" />
                  <span className="text-indigo-500">{[account.dropItem?.name, moment(account.dropItem.timestamp * 1000).fromNow()].filter(Boolean).join("-")}</span>
                </div>
              )}
            </div>
          </div>
          {!!xpBar && (
            <div className="w-full absolute left-0 right-0 bottom-0 h-1 bg-slate-300">
              <div
                className={`relative h-full bg-stone-600`}
                style={{
                  width: `${xpBarWidth}%`,
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  }, [account, className, handleOnClickAvatar, onClickAvatar, playerState, steamId, textColor, xpBar, xpBarWidth]);

  if (!account) {
    return;
  }

  return (
    <div className="relative">
      {menu ? (
        <Dropdown key={"Dropdown_" + account.steamId} menu={menu} trigger={["contextMenu"]}>
          {content}
        </Dropdown>
      ) : (
        content
      )}
    </div>
  );
});
export default MyAccountCard;
