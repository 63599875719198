import { Button, Checkbox, Dropdown, Select, Spin } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import copy from "copy-to-clipboard";
import api from "api";
import { calcItemsPrice, copy2Clipboard, generateMenuItems, GetPriceValueAsInt, isAccountBanned, SwalSelectPlayer } from "utils";
import { SellItemModal } from "modal/SellItemModal";
import Swal from "sweetalert2";
import useStore, { useAccountBySteamId } from "store";
import SimpleButton from "shared/SimpleButton";
import Toast from "toast";
import { useLocalStorage } from "@uidotdev/usehooks";
import classNames from "classnames";
import useTrippleCheckbox from "../../components/useTrippleCheckbox";

const MAIN_ACCOUNTS_STEAMID = ["76561199186152897", "76561199040402348"];
const SORT_ORDERS = ["None", "Price"];
export default function MyInventory() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const selectedAccount = useAccountBySteamId(selectedAccountID);
  const inventory = useStore((state) => state.inventory);
  const mergeAllAccount = useStore((state) => state.mergeAllAccount);
  const [isGroupSameItem, setIsGroupSameItem] = useLocalStorage("MyAccounts_isGroupSameItem");
  const [tradeEachItems, setTradeEachItems] = useLocalStorage("MyAccounts_tradeEachItems");
  const [ignoreMainAccounts, setIgnoreMainAccounts] = useLocalStorage("MyAccounts_ignoreMainAccounts");
  const [privateAccounts, setPrivateAccounts] = useLocalStorage("MyAccounts_privateAccounts", 0);
  const [tradable, setTradable] = useLocalStorage("MyAccounts_tradable");
  const [ignoreGraffiti, setIgnoreGraffiti] = useLocalStorage("MyAccounts_ignoreGraffiti", true);
  const [ignoreMedals, setIgnoreMedals] = useLocalStorage("MyAccounts_ignoreMedals", true);
  const [ignoreMusicKit, setIgnoreMusicKit] = useLocalStorage("MyAccounts_ignoreMusicKit", true);
  const [selectedInventoryCategoryType, setSelectedInventoriyCategoryType] = useLocalStorage("MyAccounts_selectedInventoriyCategoryType");
  const [selectedGame, setSelectedGame] = useLocalStorage("MyAccounts_selectedGame");
  const [sellingItemModal, setSellingItemModal] = useState();
  const [lastChecked, setLastChecked] = useState();
  const [loadingItem, setLoadingItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useLocalStorage("MyAccounts_sort", "None");
  const bodyRef = useRef();

  const gameOptions = useMemo(() => {
    let activeInventories = selectedAccount?.activeInventories?.slice() || [];
    if (activeInventories.every(({ gameId }) => gameId !== 730)) {
      activeInventories.push({
        gameId: 730,
        gameName: "Counter-Strike 2",
        count: 0,
      });
    }

    activeInventories = _.sortBy(activeInventories, function (item) {
      return item.gameId !== 730;
    });

    return activeInventories.map(function (option) {
      return { value: option.gameId, label: `${option.gameName} (${option.count || 0})` };
    });
  }, [selectedAccount?.activeInventories]);

  const fullSelectedGame = useMemo(() => {
    return selectedGame && gameOptions.some((g) => g.value == selectedGame) ? selectedGame : gameOptions[0]?.value;
  }, [gameOptions, selectedGame]);

  const inventoriyCategoryType = useMemo(
    function () {
      const map = Object.values(inventory).filter(Boolean).flat();
      return [
        "All",
        ..._.uniqBy(map.map((option) => option.tags).flat(), "category")
          .map((category) => category?.localized_category_name)
          .filter(Boolean)
          .sort((a, b) => (a === "Type" ? -1 : b === "Type" ? 1 : 0)),
      ];
    },
    [inventory],
  );

  const [selectedInventoryCategoryValue, setSelectedInventoriyCategoryValue] = useLocalStorage("MyAccounts_selectedInventoriyCategoryValue");
  const inventoriyCategoryValue = useMemo(() => {
    if (selectedInventoryCategoryType === "All") {
      return [];
    }
    return [
      "All",
      ..._.uniq(
        Object.values(inventory)
          .filter(Boolean)
          .flat()
          .map((option) => option.tags)
          .flat()
          .filter(function (tag) {
            return tag.localized_category_name === selectedInventoryCategoryType;
          })
          .map(function (tag) {
            return tag.localized_tag_name;
          }),
      ).sort((a, b) => (a === "Container" ? -1 : b === "Container" ? 1 : 0)),
    ];
  }, [inventory, selectedInventoryCategoryType]);

  const displayInventory = useMemo(() => {
    const keys = mergeAllAccount ? Object.keys(inventory).filter((k) => k.endsWith("_" + fullSelectedGame)) : [`${selectedAccountID}_${fullSelectedGame}`];
    let inventories = keys
      .map((key) => inventory[key])
      .filter(Boolean)
      .flat();
    if (mergeAllAccount) {
      if (ignoreMainAccounts) {
        inventories = inventories.filter((a) => !MAIN_ACCOUNTS_STEAMID.includes(a.steamId));
      }

      if (privateAccounts !== 0) {
        const { accountList } = useStore.getState();
        inventories = inventories.filter(function (a) {
          const account = accountList.find(({ steamId }) => steamId === a.steamId);
          if (privateAccounts === -1) {
            return !account?.config?.isPrivate;
          } else {
            return account?.config?.isPrivate;
          }
        });
      }
    }

    if (tradable) {
      inventories = inventories.filter((a) => a.tradable === 1);
    }

    if (ignoreGraffiti) {
      inventories = inventories.filter((a) => a.tags?.every((tag) => tag.internal_name !== "CSGO_Type_Spray"));
    }

    if (ignoreMedals) {
      inventories = inventories.filter((a) => a.tags?.every((tag) => tag.internal_name !== "CSGO_Type_Collectible"));
    }

    if (ignoreMusicKit) {
      inventories = inventories.filter((a) => a.tags?.every((tag) => tag.internal_name !== "CSGO_Type_MusicKit"));
    }

    let _inventorySelectData =
      inventories?.filter?.(function (asset) {
        if (selectedInventoryCategoryType === "All") {
          return true;
        }
        if (selectedInventoryCategoryValue === "All") {
          return asset.tags?.some((tag) => tag.localized_category_name === selectedInventoryCategoryType);
        } else {
          return asset.tags?.some((tag) => tag.localized_category_name === selectedInventoryCategoryType && tag.localized_tag_name === selectedInventoryCategoryValue);
        }
        return true;
      }) || [];

    if (isGroupSameItem) {
      _inventorySelectData = Object.values(_.groupBy(_inventorySelectData, "market_hash_name")).map(function (items) {
        const _groups = _.cloneDeep(items);
        const total = items.length;
        return {
          ...items[0],
          total,
          _groups,
        };
      });
    } else {
      _inventorySelectData.forEach(function (item) {
        delete item._groups;
        delete item.total;
      });
    }

    if (sortOrder === "Price") {
      _inventorySelectData = _.sortBy(_inventorySelectData, function (asset) {
        return -GetPriceValueAsInt(asset.lowest_price) || 0;
      });
    }

    return _inventorySelectData;
  }, [mergeAllAccount, inventory, selectedAccountID, fullSelectedGame, ignoreMainAccounts, privateAccounts, tradable, ignoreGraffiti, ignoreMedals, ignoreMusicKit, isGroupSameItem, sortOrder, selectedInventoryCategoryType, selectedInventoryCategoryValue]);

  const { selectedItems: selectedAssests, TrippleCheckbox, handleCheckboxChange: handleSelectedAssestChange } = useTrippleCheckbox(displayInventory, "assetid", (item) => item.total || 1);

  const calcTotalPrice = useCallback(async () => {
    const marketableData = displayInventory.filter((item) => item.marketable === 1 && selectedAssests[item.assetid]);
    await calcItemsPrice(marketableData);
  }, [selectedAssests, displayInventory]);

  const getAllInventory = useCallback(async () => {
    const accountList = useStore.getState().accountList.filter((a) => a.prime && !isAccountBanned(a));
    for (const { steamId } of accountList) {
      await useStore.getState().getUserInventory(steamId, fullSelectedGame);
    }
  }, [fullSelectedGame]);

  useEffect(() => {
    if (Array.isArray(inventoriyCategoryType) && inventoriyCategoryType.length && !inventoriyCategoryType.includes(selectedInventoryCategoryType)) {
      setSelectedInventoriyCategoryType(inventoriyCategoryType[0]);
    }
  }, [inventoriyCategoryType, selectedInventoryCategoryType]);

  useEffect(() => {
    if (Array.isArray(inventoriyCategoryValue) && inventoriyCategoryValue.length && !inventoriyCategoryValue.includes(selectedInventoryCategoryValue)) {
      setSelectedInventoriyCategoryValue(inventoriyCategoryValue[0]);
    }
  }, [inventoriyCategoryValue, selectedInventoryCategoryValue]);

  const sendTrade = useCallback(
    async function (items, tradeURL) {
      if (items.length > 1) {
        items = items.filter((item) => item.tradable === 1);
      }

      let failCount = 0;
      const itemsByAccount = _.groupBy(items, "steamId");
      for (const steamId in itemsByAccount) {
        const myItems = itemsByAccount[steamId];
        const tradeItems = tradeEachItems ? myItems.map((item) => [item]) : [myItems];
        for (const items of tradeItems) {
          if (failCount < 3) {
            setLoadingItem(function (prev) {
              return items.reduce(
                function (previousValue, currentValue) {
                  previousValue[currentValue.assetid] = true;
                  return previousValue;
                },
                {
                  ...prev,
                },
              );
            });

            const result = (
              await api.sendTradeOffer({
                tradeURL,
                myAssets: items.map(function (item) {
                  return {
                    appid: item.appid,
                    contextid: item.contextid,
                    amount: 1,
                    assetid: item.assetid,
                  };
                }),
                theirAssets: [],
                myAccountID: steamId,
              })
            )?.data;

            setLoadingItem(function (prev) {
              return items.reduce(
                function (previousValue, currentValue) {
                  delete previousValue[currentValue.assetid];
                  return previousValue;
                },
                {
                  ...prev,
                },
              );
            });

            if (result?.warning) {
              Toast.error(result?.warning);
            } else if (result?.error) {
              Toast.error(result?.error);
              failCount++;
            } else if (result?.success) {
              Toast.success(result?.success);
              if (result.success.startsWith("Accept success trade offer from")) {
                await useStore.getState().getUserInventory(steamId, fullSelectedGame);
              }
            } else {
              console.log(result);
            }
          }
        }
        // myItems.forEach(item => toast.success("Sent trade offer " + item.market_hash_name))
      }
    },
    [fullSelectedGame, tradeEachItems],
  );

  const sendScheduleTrade = useCallback(
    async function (items, partnerSteamId) {
      const itemsByAccount = _.groupBy(items, "steamId");
      for (const steamId in itemsByAccount) {
        const myItems = itemsByAccount[steamId];
        const tradeItems = tradeEachItems ? myItems.map((item) => [item]) : [myItems];
        for (const items of tradeItems) {
          const result = (
            await api.sendScheduleTradeOffer({
              partnerSteamId,
              myAssets: items.map(function (item) {
                return {
                  appid: item.appid,
                  contextid: item.contextid,
                  amount: 1,
                  assetid: item.assetid,
                };
              }),
              theirAssets: [],
              myAccountID: steamId,
            })
          ).data;
          if (result?.insertedId) {
            Toast.success("sendScheduleTradeOffer success");
          } else {
            Toast.error("sendScheduleTradeOffer failed");
            console.log(result);
          }
        }
      }
    },
    [tradeEachItems],
  );

  useEffect(() => {
    setLoading(true);
    useStore
      .getState()
      .getUserInventory(selectedAccountID, fullSelectedGame)
      .then(function () {
        setLoading(false);
      });
  }, [fullSelectedGame, selectedAccountID]);

  const rawMenuItems = useMemo(
    () => [
      {
        label: "Sell",
        async onClick(items) {
          setSellingItemModal(items.map((item) => item._groups || [item]).flat(Infinity));
        },
      },
      {
        label: "Send to myaccount",
        async onClick(items) {
          items = items.map((item) => (Array.isArray(item._groups) ? item._groups : [item])).flat();
          SwalSelectPlayer({
            options: useStore.getState().accountList,
            optionLabelField: ["personaName", "username"],
            optionValueField: "steamId",
            optionIconField: (a) => `https://avatars.akamai.steamstatic.com/${a.avatarHash}_full.jpg`,
            title: `Send to myaccount (${items.length} items)`,
            key: "send_trade_select_my_account",
            callback(selectedAccount) {
              if (selectedAccount?.tradeURL) {
                sendTrade(items, selectedAccount.tradeURL);
              } else {
                console.error("Cannot find tradeURL");
                Toast.error("Cannot find tradeURL");
              }
            },
            confirmButtonText: "Send",
          });
        },
      },
      {
        label: "TradeSchedule to myaccount",
        async onClick(items) {
          items = items.map((item) => (Array.isArray(item._groups) ? item._groups : [item])).flat();
          SwalSelectPlayer({
            options: useStore.getState().accountList,
            optionLabelField: ["personaName", "username"],
            optionValueField: "steamId",
            optionIconField: (a) => `https://avatars.akamai.steamstatic.com/${a.avatarHash}_full.jpg`,
            title: `Send to myaccount (${items.length} items)`,
            key: "send_schedule_trade_select_my_account",
            callback(selectedAccount) {
              if (selectedAccount?.tradeURL) {
                sendScheduleTrade(items, selectedAccount.steamId);
              } else {
                console.error("Cannot find tradeURL");
                Toast.error("Cannot find tradeURL");
              }
            },
            confirmButtonText: "Send",
          });
        },
      },
      {
        label: "Send to this trade link",
        onClick: async function (items) {
          items = items.map((item) => (Array.isArray(item._groups) ? item._groups : [item])).flat();
          Swal.fire({
            title: `Submit your trade link to send ${items.length} ${items.length > 1 ? "items" : "item"} (${items.map((item) => item.market_hash_name).join(", ")})`,
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              sendTrade(items, result.value);
            }
          });
        },
      },
      {
        label: "Copy market hash name",
        async onClick(items) {
          copy2Clipboard(items.map((item) => item.market_hash_name).join(", "));
        },
      },
    ],
    [sendTrade],
  );

  const menuItems = useMemo(() => generateMenuItems(rawMenuItems), [rawMenuItems]);

  const onClickItem = useCallback(
    function ({ evt, asset, index }) {
      let inBetween = false;
      if (evt.shiftKey && evt.target.checked) {
        displayInventory.forEach((asset1, newIndex) => {
          if (asset1 === asset || newIndex === lastChecked) {
            inBetween = !inBetween;
          }
          if (inBetween) {
            handleSelectedAssestChange(asset1.assetid, true);
          }
        });
      }
      setLastChecked(index);

      handleSelectedAssestChange(asset.assetid, evt.target.checked);
    },
    [displayInventory, handleSelectedAssestChange, lastChecked],
  );

  const handleOnClickCopySelectedItemsInfo = useCallback(() => {
    const itemByGroup = _.groupBy(Object.values(displayInventory), "market_hash_name");
    const text = Object.keys(itemByGroup)
      .map(function (market_hash_name) {
        const total = itemByGroup[market_hash_name].reduce(function (previousValue, currentValue) {
          return previousValue + (currentValue.total || 1);
        }, 0);
        const name = itemByGroup[market_hash_name][0].market_hash_name;
        return `${total} ${name}`;
      })
      .join("\n");
    Toast.success(text);
    copy(text);
  }, [displayInventory]);

  const getAllInventoryMenu = useMemo(() => {
    return [
      {
        label: "Get All",
        onClick: getAllInventory,
      },
    ];
  }, [getAllInventory]);

  useEffect(() => {
    if (!bodyRef.current) {
      return;
    }
    let boundingClientRect = bodyRef.current.getBoundingClientRect();
    bodyRef.current.style.height = `calc(100vh - ${boundingClientRect.y + 5}px)`;
  }, [bodyRef]);

  return (
    <>
      {!!sellingItemModal && (
        <SellItemModal
          assets={sellingItemModal}
          handleCancel={() => setSellingItemModal()}
          handleOk={function (successCount) {
            setSellingItemModal();
            if (successCount > 0) {
              useStore.getState().getUserInventory(null, fullSelectedGame);
            }
          }}
        />
      )}
      <div className="filter">
        Game&nbsp;&nbsp;
        <Select style={{ width: 200 }} value={fullSelectedGame} onChange={setSelectedGame} options={gameOptions} />
        Lọc&nbsp;&nbsp;
        <Select
          style={{ width: 150 }}
          value={selectedInventoryCategoryType}
          onChange={(e) => setSelectedInventoriyCategoryType(e)}
          options={inventoriyCategoryType.map(function (option) {
            return { value: option, label: option };
          })}
        />
        <Select
          style={{ width: 200 }}
          value={selectedInventoryCategoryValue}
          onChange={setSelectedInventoriyCategoryValue}
          options={inventoriyCategoryValue.map(function (option) {
            return { value: option, label: option };
          })}
        />
        <Select rootClassName="w-32" defaultValue="None" value={sortOrder} placeholder="Sort by" onChange={setSortOrder} options={SORT_ORDERS.map((o) => ({ label: o, value: o }))} />
        &nbsp;
        <Checkbox checked={isGroupSameItem} onChange={(e) => setIsGroupSameItem(e.target.checked)}>
          Group same items
        </Checkbox>
        <Checkbox checked={tradeEachItems} onChange={(e) => setTradeEachItems(e.target.checked)}>
          Trade each items
        </Checkbox>
        <Checkbox checked={tradable} onChange={(e) => setTradable(e.target.checked)}>
          Tradable
        </Checkbox>
        <Checkbox checked={ignoreGraffiti} onChange={(e) => setIgnoreGraffiti(e.target.checked)}>
          Ignore Graffiti
        </Checkbox>
        <Checkbox checked={ignoreMedals} onChange={(e) => setIgnoreMedals(e.target.checked)}>
          Ignore Medals
        </Checkbox>
        <Checkbox checked={ignoreMusicKit} onChange={(e) => setIgnoreMusicKit(e.target.checked)}>
          Ignore MusicKit
        </Checkbox>
        {!!mergeAllAccount && (
          <>
            <Checkbox checked={ignoreMainAccounts} onChange={(e) => setIgnoreMainAccounts(e.target.checked)}>
              Ignore main accounts
            </Checkbox>
            <Checkbox checked={privateAccounts === 1} indeterminate={privateAccounts === 0} onClick={(e) => setPrivateAccounts((prev) => (prev === 1 ? -1 : prev + 1))}>
              Private accounts
            </Checkbox>
          </>
        )}
      </div>
      <div className="Inventory_Header">
        <TrippleCheckbox>All</TrippleCheckbox>
        <SimpleButton onClick={calcTotalPrice}>Calc total price</SimpleButton>
        <SimpleButton
          onClick={async function () {
            await useStore.getState().getUserInventory(null, fullSelectedGame);
          }}
          type="primary"
          menu={getAllInventoryMenu}
        >
          Get Inventory
        </SimpleButton>
        <Button onClick={handleOnClickCopySelectedItemsInfo} type="primary">
          Copy selected items info
        </Button>
      </div>
      <div ref={bodyRef} className="overflow-y-auto">
        {!!loading && (
          <div className="absolute w-full h-full bg-zinc-600/15 left-0 right-0 top-0 bottom-0 flex justify-center items-center select-none">
            <Spin />
          </div>
        )}
        {displayInventory.map?.(function (asset, index) {
          return (
            <Dropdown
              key={"MyItemDropdown_" + asset.assetid}
              menu={{
                items: menuItems.items,
                onClick: (e) =>
                  menuItems.handleMenuClick(
                    e,
                    displayInventory.filter((a) => selectedAssests[a.assetid]),
                  ),
              }}
              onOpenChange={(open) => {
                if (open) {
                  handleSelectedAssestChange(asset.assetid, true);
                  setLastChecked(index);
                }
              }}
              trigger={["contextMenu"]}
            >
              <div className="relative">
                <InventoryItem key={asset.assetid} asset={asset} checked={!!selectedAssests[asset.assetid]} onClick={onClickItem} index={index} loading={!!loadingItem[asset.assetid]} />
              </div>
            </Dropdown>
          );
        })}
      </div>
    </>
  );
}

const InventoryItem = React.memo(function InventoryItem({ asset, checked, index, onClick, loading }) {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const account = useStore.getAccount(asset.steamId);
  const handleOnClickMyAccount = useCallback(() => {
    useStore.getState().setSelectedAccountID(asset.steamId);
  }, [asset.steamId]);

  return (
    <div className="itemHolder tradingItemHolder cursor-pointer relative">
      <a id={`${asset.appid}_${asset.contextid}_${asset.assetid}`} href={`https://steamcommunity.com/market/listings/730/${encodeURIComponent(asset.market_hash_name)}`} target="_blank" className="img" style={{ backgroundImage: `url("https://community.akamai.steamstatic.com/economy/image/${asset.icon_url}")` }} rel="noreferrer" />
      <div onClick={() => copy(asset.market_hash_name)} className="name line-clamp-2">
        {asset.market_hash_name + (asset.total ? ` (${asset.total})` : "")}
      </div>
      {!!asset.lowest_price ? <span className="flex items-center w-full justify-center italic text-xs">{asset.lowest_price}</span> : ""}
      <div
        className={classNames("border border-x-0 border-b-0 border-solid border-slate-300 flex items-center justify-center hover:font-bold", {
          "text-emerald-500": asset.steamId === selectedAccountID,
        })}
        onClick={handleOnClickMyAccount}
      >
        {account?.personaName + " - " + account?.username}
      </div>
      {(asset.marketable === 1 || asset.tradable === 1) && (
        <div className="absolute left-0 top-0">
          <Checkbox checked={checked} onClick={(evt) => onClick?.({ evt, asset, index })} />
        </div>
      )}
      {loading && (
        <div className="absolute w-full h-full bg-zinc-600/15 left-0 right-0 top-0 bottom-0 flex justify-center items-center select-none">
          <Spin />
        </div>
      )}
    </div>
  );
});
