import _ from "lodash";
import { getInviteName, getPersonaName, scheduleCustomRequest20h, sendCustomRequest } from "./socket";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { StringUtils } from "alpha-common-utils/index.js";
import useStore from "./store";
import api from "api";
import Toast from "./toast";
import copy from "copy-to-clipboard";
import FriendCode from "./friendCodeUtils";
import { v4 } from "uuid";

const OKs = ["ok", "oki", "okie", "okay", "👌", "okok"];
export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function invite2PlayCustomMessasge(name) {
  let _name = name || _.sample(["bạn", "bn", "b", "bro", "bở rồ", "pro", "o", "ô", "ôg", "ông", "fen", "friend"]);
  let message = `${_.sample(["chơi", "bắn", "làm trận", "làm game", "làm ván"])} ${_.sample(["csgo", "custom"])} ${_.sample(["không", "k", "ko"])}`.trim();
  return _.shuffle([message, _name]).join(" ");
}

function getLabel(icon, label) {
  return icon ? (
    <span className="flex items-center">
      <span className="mr-2">{icon}</span>
      {label}
    </span>
  ) : (
    label
  );
}

/**
 *
 * @param menu [{label, onClick}]
 * @returns {{handleMenuClick: handleMenuClick, menuItems: *}}
 */

export function generateMenuItems(menuItems, data) {
  const rootItems = menuItems
    .filter(Boolean)
    .map(({ key, label, children, onClick, icon, show }) => {
      if (typeof show === "function" && !show(data)) {
        return null;
      }

      const item = { key: key ?? label, label: getLabel(icon, label), onClick };
      if (!Array.isArray(item.paths)) {
        item.paths = [key ?? label];
      }
      if (Array.isArray(children)) {
        if (children.length === 1) {
          Object.assign(item, {
            ...children[0],
            label:
              (typeof label === "string" || typeof label === "number") && (typeof children[0].label === "string" || children[0].label === "number") ? (
                label + " - " + children[0].label
              ) : (
                <span>
                  {label} - {children[0].label}
                </span>
              ),
          });
        } else if (children.length > 1) {
          item.children = children
            .map(function (child) {
              if (typeof child.show === "function" && !child.show(data)) {
                return null;
              }
              child.paths = [...item.paths, child.key ?? child.label];
              return {
                key: child.paths.join("_"),
                label: getLabel(child.icon, child.label),
                onClick: child.onClick,
              };
            })
            .filter(Boolean);
        }
      }
      return item;
    })
    .filter(Boolean);

  const items = rootItems.map(function (item) {
    let children = item.children;
    if (Array.isArray(children)) {
      children = children.map((child) => ({
        ...child,
        onClick: undefined,
      }));
    }

    return {
      ...item,
      onClick: undefined,
      children,
    };
  });

  const handleMenuClick = (e, ...args) => {
    let menu = rootItems;
    for (const key of e.keyPath.reverse()) {
      menu = (menu.children || menu).find(function (item) {
        return item.key === key;
      });
    }
    menu?.onClick?.apply(null, args);
  };

  return { handleMenuClick, items };
}

export function filterObjectInput(object, input) {
  const inputNonAccent = StringUtils.toNonAccentVietnameseLowerCase(input);

  function isValid(item) {
    if (item === null || item === undefined) return false;
    else if (typeof item === "string" || typeof item === "number") {
      return StringUtils.toNonAccentVietnameseLowerCase(item.toString()).includes(inputNonAccent);
    } else if (Array.isArray(item)) {
      return item.some((_item) => isValid(_item));
    } else if (typeof item === "object") {
      return Object.keys(item).some((k) => isValid(item[k]));
    } else {
      //uncatch case
    }
  }

  return isValid(object);
}

export function isAccountBanned(account) {
  return account.banned === true || account.isAccountBanned === true || account.summary?.isVACBan === 1 || account.summary?.isGameBan === 1 || account.summary?.isTradeBan === 1;
}

export function isAccountLimited(account) {
  if (account?.limitations?.limited === true) {
    return true;
  }
  return !!account?.market_unavailable?.market_restrictions?.some?.((restriction) => restriction.startsWith("Your account is limited."));
}

export function isAccountDropedCaseThisWeek(account) {
  if (!account) return false;
  const resetDay = getbonus_xp_timestamp_refresh();
  return !!(account.lastTimeCaseDrop && resetDay.unix() < account.lastTimeCaseDrop);
}

export function isAccountXpExceedThisWeek(account) {
  if (!account) return false;
  let resetDay;
  const bonus_xp_timestamp_refresh = account.ClientWelcome?.GameAccountClient?.bonus_xp_timestamp_refresh;
  if (bonus_xp_timestamp_refresh) {
    resetDay = moment(bonus_xp_timestamp_refresh * 1000);
  } else {
    //Wednesday 1 AM GMT = 8 AM VN
    resetDay = moment.utc().startOf("isoWeek").add(2, "days").add(1, "hours");
  }
  while (moment().isAfter(resetDay)) {
    resetDay = resetDay.add(7, "days");
  }
  if (moment().isBefore(resetDay)) {
    resetDay = resetDay.subtract(7, "days");
  }
  return !!(account.xpExceed && resetDay.valueOf() < account.xpExceed);
}

export function getAccountCurrentXP(account) {
  return account.profile?.player_cur_xp ? account.profile.player_cur_xp - 327680000 : 0;
}

export function getbonus_xp_timestamp_refresh() {
  let resetDay = moment.utc().startOf("isoWeek").add(2, "days").add(1, "hours");
  while (moment().isAfter(resetDay)) {
    resetDay = resetDay.add(7, "days");
  }
  if (moment().isBefore(resetDay)) {
    resetDay = resetDay.subtract(7, "days");
  }
  return resetDay;
}

export function getFriendName(steamId) {
  return _.uniq([getPersonaName(steamId), getInviteName(steamId)].filter(Boolean)).join(" - ");
}

export function getReplyMsg() {
  return _.sample(["ok", "oki", "okie", "okay", "👌", "👍", "okok", "😊", "😄", "😀", "😃", "😄", "😁", "😆", "😅", "🤣", "😂", "🤗", "🤭"]);
}

export const OnlinePiority = {
  ingame: 2,
  online: 1,
  offline: 0,
};

export function getMaxOnlinePiority(playerState, recipients) {
  let onlineStatusValue = OnlinePiority.offline;
  if (Array.isArray(recipients)) {
    for (const recipient of recipients) {
      onlineStatusValue = Math.max(OnlinePiority[playerState[recipient.steamId || recipient]?.onlineStatus || "offline"], onlineStatusValue);
    }
  }
  return Object.keys(OnlinePiority).find((onlineStatus) => OnlinePiority[onlineStatus] === onlineStatusValue);
}

export async function isImgUrl(url) {
  if (!url || !url.startsWith("http") || url.includes(" ")) {
    return false;
  }

  try {
    const res = await fetch(url, { method: "HEAD" });
    return res.headers.get("Content-Type").startsWith("image");
  } catch (e) {
    return false;
  }
}

export function isSteamImgUrl(url) {
  return url && (url.startsWith("https://steamuserimages-a.akamaihd.net/") || url.startsWith("https://steamusercontent-a.akamaihd.net/")) && !url.includes(" ");
}

export function isOkMessage(msg) {
  return OKs.includes(msg);
}

export function isIncludeOkMessage(messages) {
  return messages
    .toLowerCase()
    .split(" ")
    .some((char) => isOkMessage(char));
}

export function getOkMessage() {
  return OKs[Math.floor(Math.random() * OKs.length)];
}

export const loadScript = (FILE_URL, async = true, type = "text/javascript") => {
  return new Promise((resolve, reject) => {
    if (window.SCRIPTS_LOADED?.includes(FILE_URL)) {
      return resolve({ status: true });
    }

    try {
      const scriptEle = document.createElement("script");
      scriptEle.type = type;
      scriptEle.async = async;
      scriptEle.src = FILE_URL;

      scriptEle.addEventListener("load", (ev) => {
        (window.SCRIPTS_LOADED = window.SCRIPTS_LOADED || []).push(FILE_URL);
        resolve({ status: true });
      });

      scriptEle.addEventListener("error", (ev) => {
        reject({
          status: false,
          message: `Failed to load the script ${FILE_URL}`,
        });
      });

      document.body.appendChild(scriptEle);
    } catch (error) {
      reject(error);
    }
  });
};

export const loadStyle = function (url) {
  return new Promise((resolve, reject) => {
    if (window.STYLES_LOADED?.includes(url)) {
      return resolve();
    }

    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.onload = function () {
      (window.STYLES_LOADED = window.STYLES_LOADED || []).push(url);
      return resolve();
    };
    link.onerror = () => reject();
    link.href = url;

    let headScript = document.querySelector("script");
    headScript.parentNode.insertBefore(link, headScript);
  });
};

export function getAvatarURLfromHash(avatarHash) {
  if (avatarHash === "0000000000000000000000000000000000000000" || !avatarHash) {
    avatarHash = "fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb";
  }
  return `https://avatars.akamai.steamstatic.com/${avatarHash}_full.jpg`;
}

export function searchStringinObject(query, object) {
  query = StringUtils.toNonAccentVietnameseLowerCase(query);
  for (const key in object) {
    const value = object[key];
    if (value && typeof value === "string" && StringUtils.toNonAccentVietnameseLowerCase(value).includes(query)) {
      return true;
    }
  }
  return false;
}

export function steamID64toSteamID32(steamID64) {
  return Number(steamID64.substr(-16, 16)) - 6561197960265728;
}

export function steamID32toSteamID64(accountid) {
  return parseInt(accountid) + 76561197960265728;
}

export function parseTradeURL(tradeURL) {
  try {
    let search = new URL(tradeURL).search;
    if (search.startsWith("?")) {
      search = search.substring(1);
    }
    const tradeURLParams = search.split("&").reduce(
      (accumulator, currentValue, index) => ({
        ...accumulator,
        [currentValue.split("=")[0]]: currentValue.split("=")[1],
      }),
      {},
    );

    return tradeURLParams.token
      ? {
          accountId: tradeURLParams.partner,
          steamId: steamID32toSteamID64(tradeURLParams.partner),
          token: tradeURLParams.token,
        }
      : null;
  } catch (e) {
    console.log(e);
  }
}

export async function SwalSelectPlayer({
  title,
  key,
  callback,
  confirmButtonText = "Confirm",
  denyButtonText = "Cancel",
  options, //[] value, label,img
  optionLabelField = "label",
  optionValueField = "value",
  optionClassNameField = null,
  optionIconField = "img",
  ...rest
}) {
  if (!Array.isArray(optionLabelField)) {
    optionLabelField = [optionLabelField];
  }
  const selectedOption = localStorage.getItem(key);
  const keyTs = key + "_timestamp";
  let optionOrder = [];
  try {
    optionOrder = JSON.parse(localStorage.getItem(keyTs)) || [];
  } catch (e) {}

  const realOptions = options
    .map(function (friend) {
      const label = _.uniq(
        optionLabelField
          .map(function (label) {
            return typeof label === "function" ? label(friend) : friend[label];
          })
          .filter(Boolean),
      ).join(" - ");
      const icon = typeof optionIconField === "function" ? optionIconField(friend) : friend[optionIconField];
      const value = typeof optionValueField === "function" ? optionValueField(friend) : friend[optionValueField];
      const className = typeof optionClassNameField === "function" ? optionClassNameField(friend) : optionClassNameField ? friend[optionClassNameField] : null;
      return {
        label,
        icon,
        value,
        originalData: friend,
        className,
      };
    })
    .sort((a, b) => {
      return optionOrder.indexOf(b.value) - optionOrder.indexOf(a.value);
    });

  const friendOptions = realOptions
    .map(function ({ label, icon, value, className }, index) {
      return `<option class="${className || ""}" value=${value} ${selectedOption === value ? " selected " : ""}>${index + 1}. ${label}</option>`;
    })
    .join("");
  const id = "select_player_id_" + v4();

  Swal.fire({
    title,
    width: Math.min(window.innerWidth, 500) + "px",
    html: `
                <div style="
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(0%);
                    z-index: 9999;
                    width: 100% !important;
                    padding-left: 10px;
                    padding-right: 10px;
                    background: white;">
                    <style>
                        .tail-select{
                            width: 100% !important;
                        }
                    </style>
                    <select id="${id}">${friendOptions}</select>
                </div>
                `,
    didOpen: () => {
      loadStyle("https://cdn.jsdelivr.net/npm/tail.select@0.5.15/css/bootstrap4/tail.select-default.min.css");
      loadScript("https://cdn.jsdelivr.net/npm/tail.select.js@0.5.22/js/tail.select.min.js")
        .then((data) => {
          const container = Swal.getHtmlContainer();
          container.style.zIndex = 99;
          container.style.minHeight = "80px";
          container.querySelector("#" + id).addEventListener("change", function (evt) {
            const value = evt.target.value;
            localStorage.setItem(key, value);
            if (optionOrder.includes(value)) {
              optionOrder.splice(optionOrder.indexOf(value), 1);
            }
            optionOrder.push(value);
            localStorage.setItem(keyTs, JSON.stringify(optionOrder));
          });
          window.tail.select("#" + id, {
            search: true,
            cbLoopItem: function (item, optgroup, search, root) {
              var newItem = document.createElement("li");
              newItem.innerHTML = `<img class="w-8 h-8 mx-4" src="${realOptions.find(({ value }) => value === item.key).icon}"/><span class="${item.option.classList}">${item.value}</span>`;
              return newItem;
            },
          });
        })
        .catch((err) => {
          console.error(id, err);
        });
    },
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText,
    denyButtonText,
    ...rest,
  }).then(async (result) => {
    if (!result.isConfirmed || typeof callback !== "function") {
      return;
    }
    const selectedPlayerSteamId = Swal.getHtmlContainer().querySelector("#" + id).value;
    if (!selectedPlayerSteamId) {
      return;
    }
    const selectedAccount = realOptions.find((p) => p.value === selectedPlayerSteamId)?.originalData;
    if (!selectedAccount) {
      return;
    }
    callback(selectedAccount);
  });
}

export async function calcItemsPrice(items) {
  const market_hash_name_list = Object.keys(_.groupBy(items, "market_hash_name"));
  const priceByItems = items.reduce(function (prev, currentValue) {
    if (currentValue.lowest_price) {
      prev[currentValue.market_hash_name] = currentValue.lowest_price;
    }
    return prev;
  }, {});

  const itemPrices = await Promise.all(
    market_hash_name_list.map(async function (market_hash_name) {
      if (!priceByItems[market_hash_name]) {
        const data = (
          await api.getPriceOverview({
            appID: items.find((item) => item.market_hash_name === market_hash_name)?.appid || 730,
            market_hash_name,
          })
        )?.data;
        priceByItems[market_hash_name] = data?.lowest_price;
      }

      return {
        market_hash_name,
        price: Math.floor(GetPriceValueAsInt(priceByItems[market_hash_name] ?? 0) / 100),
      };
    }),
  );

  const totalPrice =
    items.reduce(function (previousValue, currentValue, currentIndex) {
      const price = itemPrices.find((item) => item.market_hash_name === currentValue.market_hash_name).price;
      return previousValue + (currentValue.total || 1) * price;
    }, 0) * 0.85;
  Toast.success("Total price: " + totalPrice.toLocaleString("it-IT", { style: "currency", currency: "VND" }));

  const basePrice = 375000;
  const superfluousAccount = Math.floor(totalPrice / basePrice);
  const superfluousAmount = totalPrice - superfluousAccount * basePrice;

  const lackingAccount = Math.ceil(totalPrice / basePrice);
  const lackingAmount = lackingAccount * basePrice - totalPrice;

  if (lackingAccount > 0) {
    Toast.success(
      "Lacking " +
        lackingAmount.toLocaleString("it-IT", {
          style: "currency",
          currency: "VND",
        }) +
        " to reach " +
        lackingAccount +
        " accounts",
    );
  }

  if (superfluousAccount > 0) {
    Toast.success(
      "Superfluous " +
        superfluousAmount.toLocaleString("it-IT", {
          style: "currency",
          currency: "VND",
        }) +
        " reach " +
        superfluousAccount +
        " accounts",
    );
  }
}

export function openSteamProfile(steamId) {
  window.open(`https://steamcommunity.com/profiles/${steamId}`, "_blank").focus();
}

export function copySteamProfile(steamId) {
  if (!steamId) return;
  let profileURL = `https://steamcommunity.com/profiles/${steamId}`;
  copy(profileURL);
  Toast.info(profileURL);
}

export function copy2Clipboard(text) {
  if (!text) return;
  copy(text);
  Toast.info(text);
}

export function doUpdateFriendInfo(steamId) {
  if (!steamId) return;
  const { friendList } = useStore.getState();
  useStore.setState({
    UpdateFriendInfoModalData: {
      steamId,
      name: friendList.find((friend) => friend.steamId === steamId)?.personaName || "",
    },
  });
}

export function copyFriendCode(steamId) {
  if (!steamId) return;
  const friendCode = FriendCode.encode(steamId);
  if (friendCode) {
    copy(friendCode);
    Toast.info(friendCode);
  } else {
    Toast.error("No result");
  }
}

export function invite2PlayCustom(steamId) {
  if (!steamId) return;
  const name = sendCustomRequest(steamId);
  Toast.success("Send custom request to " + name);
}

export function invite2PlayCustom20h(steamId) {
  if (!steamId) return;
  const name = scheduleCustomRequest20h(steamId);
  Toast.success("Send custom request to " + name);
}

export async function invitePlayNow(steamId) {
  if (!steamId) return;
  const result = (await api.invitePlayNow({ steamId }))?.data;
  console.log(result);
}

export async function followUser(steamId) {
  if (!steamId) return;
  const result = (await api.follow({ link: steamId }))?.data;
  console.log(result);
}

export async function unfollowUser(steamId) {
  if (!steamId) return;
  const result = (await api.unfollow({ steamid: steamId }))?.data;
  console.log(result);
}

export function objectIdFromDate(date) {
  let milliseconds = null;
  if (date instanceof Date) {
    milliseconds = date.getTime();
  } else if (typeof date === "number") {
    milliseconds = date;
  } else {
    return;
  }

  return Math.floor(milliseconds / 1000).toString(16) + "0000000000000000";
}

export function dateFromObjectId(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}

export async function getQuickInviteLink(account) {
  return (await api.getQuickInviteLink({ steamId: account.steamId ?? account })).data;
}

const g_rgCurrencyData = {
  USD: {
    strCode: "USD",
    eCurrencyCode: 1,
    strSymbol: "$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  GBP: {
    strCode: "GBP",
    eCurrencyCode: 2,
    strSymbol: "\u00a3",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  EUR: {
    strCode: "EUR",
    eCurrencyCode: 3,
    strSymbol: "\u20ac",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ",",
    strThousandsSeparator: " ",
    strSymbolAndNumberSeparator: "",
  },
  CHF: {
    strCode: "CHF",
    eCurrencyCode: 4,
    strSymbol: "CHF",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: " ",
    strSymbolAndNumberSeparator: " ",
  },
  RUB: {
    strCode: "RUB",
    eCurrencyCode: 5,
    strSymbol: "p\u0443\u0431.",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: "",
    strSymbolAndNumberSeparator: " ",
  },
  BRL: {
    strCode: "BRL",
    eCurrencyCode: 7,
    strSymbol: "R$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: " ",
  },
  JPY: {
    strCode: "JPY",
    eCurrencyCode: 8,
    strSymbol: "\u00a5",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  NOK: {
    strCode: "NOK",
    eCurrencyCode: 9,
    strSymbol: "kr",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: " ",
  },
  IDR: {
    strCode: "IDR",
    eCurrencyCode: 10,
    strSymbol: "Rp",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ".",
    strThousandsSeparator: " ",
    strSymbolAndNumberSeparator: " ",
  },
  MYR: {
    strCode: "MYR",
    eCurrencyCode: 11,
    strSymbol: "RM",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  PHP: {
    strCode: "PHP",
    eCurrencyCode: 12,
    strSymbol: "P",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  SGD: {
    strCode: "SGD",
    eCurrencyCode: 13,
    strSymbol: "S$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  THB: {
    strCode: "THB",
    eCurrencyCode: 14,
    strSymbol: "\u0e3f",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  VND: {
    strCode: "VND",
    eCurrencyCode: 15,
    strSymbol: "\u20ab",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: "",
  },
  KRW: {
    strCode: "KRW",
    eCurrencyCode: 16,
    strSymbol: "\u20a9",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  TRY: {
    strCode: "TRY",
    eCurrencyCode: 17,
    strSymbol: "TL",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: " ",
  },
  UAH: {
    strCode: "UAH",
    eCurrencyCode: 18,
    strSymbol: "\u20b4",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: " ",
    strSymbolAndNumberSeparator: "",
  },
  MXN: {
    strCode: "MXN",
    eCurrencyCode: 19,
    strSymbol: "Mex$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  CAD: {
    strCode: "CAD",
    eCurrencyCode: 20,
    strSymbol: "CDN$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  AUD: {
    strCode: "AUD",
    eCurrencyCode: 21,
    strSymbol: "A$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  NZD: {
    strCode: "NZD",
    eCurrencyCode: 22,
    strSymbol: "NZ$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  PLN: {
    strCode: "PLN",
    eCurrencyCode: 6,
    strSymbol: "z\u0142",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ",",
    strThousandsSeparator: " ",
    strSymbolAndNumberSeparator: "",
  },
  CNY: {
    strCode: "CNY",
    eCurrencyCode: 23,
    strSymbol: "\u00a5",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  INR: {
    strCode: "INR",
    eCurrencyCode: 24,
    strSymbol: "\u20b9",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  CLP: {
    strCode: "CLP",
    eCurrencyCode: 25,
    strSymbol: "CLP$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: " ",
  },
  PEN: {
    strCode: "PEN",
    eCurrencyCode: 26,
    strSymbol: "S/.",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  COP: {
    strCode: "COP",
    eCurrencyCode: 27,
    strSymbol: "COL$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: " ",
  },
  ZAR: {
    strCode: "ZAR",
    eCurrencyCode: 28,
    strSymbol: "R",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: " ",
    strSymbolAndNumberSeparator: " ",
  },
  HKD: {
    strCode: "HKD",
    eCurrencyCode: 29,
    strSymbol: "HK$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  TWD: {
    strCode: "TWD",
    eCurrencyCode: 30,
    strSymbol: "NT$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  SAR: {
    strCode: "SAR",
    eCurrencyCode: 31,
    strSymbol: "SR",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  AED: {
    strCode: "AED",
    eCurrencyCode: 32,
    strSymbol: "AED",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  SEK: {
    strCode: "SEK",
    eCurrencyCode: 33,
    strSymbol: "kr",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  ARS: {
    strCode: "ARS",
    eCurrencyCode: 34,
    strSymbol: "ARS$",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: " ",
  },
  ILS: {
    strCode: "ILS",
    eCurrencyCode: 35,
    strSymbol: "\u20aa",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  BYN: {
    strCode: "BYN",
    eCurrencyCode: 36,
    strSymbol: "Br",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
  KZT: {
    strCode: "KZT",
    eCurrencyCode: 37,
    strSymbol: "\u20b8",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: " ",
    strSymbolAndNumberSeparator: "",
  },
  KWD: {
    strCode: "KWD",
    eCurrencyCode: 38,
    strSymbol: "KD",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  QAR: {
    strCode: "QAR",
    eCurrencyCode: 39,
    strSymbol: "QR",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  CRC: {
    strCode: "CRC",
    eCurrencyCode: 40,
    strSymbol: "\u20a1",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: "",
  },
  UYU: {
    strCode: "UYU",
    eCurrencyCode: 41,
    strSymbol: "$U",
    bSymbolIsPrefix: true,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ",",
    strThousandsSeparator: ".",
    strSymbolAndNumberSeparator: "",
  },
  BGN: {
    strCode: "BGN",
    eCurrencyCode: 42,
    strSymbol: "\u043b\u0432",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  HRK: {
    strCode: "HRK",
    eCurrencyCode: 43,
    strSymbol: "kn",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  CZK: {
    strCode: "CZK",
    eCurrencyCode: 44,
    strSymbol: "K\u010d",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  DKK: {
    strCode: "DKK",
    eCurrencyCode: 45,
    strSymbol: "kr.",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  HUF: {
    strCode: "HUF",
    eCurrencyCode: 46,
    strSymbol: "Ft",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  RON: {
    strCode: "RON",
    eCurrencyCode: 47,
    strSymbol: "lei",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: false,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: " ",
  },
  RMB: {
    strCode: "RMB",
    eCurrencyCode: 9000,
    strSymbol: "\u5200\u5e01",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ".",
    strThousandsSeparator: "",
    strSymbolAndNumberSeparator: " ",
  },
  NXP: {
    strCode: "NXP",
    eCurrencyCode: 9001,
    strSymbol: "\uc6d0",
    bSymbolIsPrefix: false,
    bWholeUnitsOnly: true,
    strDecimalSymbol: ".",
    strThousandsSeparator: ",",
    strSymbolAndNumberSeparator: "",
  },
};

const g_rgWalletInfo = {
  wallet_currency: 15,
  wallet_country: "VN",
  wallet_state: "",
  wallet_fee: "1",
  wallet_fee_minimum: "1",
  wallet_fee_percent: "0.05",
  wallet_publisher_fee_percent_default: "0.10",
  wallet_fee_base: "0",
  wallet_balance: "76540490",
  wallet_delayed_balance: "0",
  wallet_max_balance: "4500000000",
  wallet_trade_max_balance: "4049999616",
  success: 1,
  rwgrsn: -2,
};

function GetCurrencyCode(currencyId) {
  for (const code in g_rgCurrencyData) {
    if (g_rgCurrencyData[code].eCurrencyCode == currencyId) {
      return code;
    }
  }
  return "Unknown";
}

function IsCurrencyWholeUnits(currencyCode) {
  return g_rgCurrencyData[currencyCode] && g_rgCurrencyData[currencyCode].bWholeUnitsOnly && currencyCode != "RUB";
}

function IsCurrencySymbolBeforeValue(currencyCode) {
  return g_rgCurrencyData[currencyCode] && g_rgCurrencyData[currencyCode].bSymbolIsPrefix;
}

// Return the symbol to use for a currency
function GetCurrencySymbol(currencyCode) {
  return g_rgCurrencyData[currencyCode] ? g_rgCurrencyData[currencyCode].strSymbol : currencyCode + " ";
}

export function v_currencyformat(valueInCents, currencyCode = GetCurrencyCode(g_rgWalletInfo.wallet_currency), countryCode) {
  if (!valueInCents && valueInCents !== 0) {
    return "";
  }

  let currencyFormat = (valueInCents / 100).toFixed(2);

  if (g_rgCurrencyData[currencyCode]) {
    const currencyData = g_rgCurrencyData[currencyCode];
    if (IsCurrencyWholeUnits(currencyCode)) {
      currencyFormat = currencyFormat.replace(".00", "");
    }

    if (currencyData.strDecimalSymbol != ".") {
      currencyFormat = currencyFormat.replace(".", currencyData.strDecimalSymbol);
    }

    const currencyReturn = IsCurrencySymbolBeforeValue(currencyCode) ? GetCurrencySymbol(currencyCode) + currencyData.strSymbolAndNumberSeparator + currencyFormat : currencyFormat + currencyData.strSymbolAndNumberSeparator + GetCurrencySymbol(currencyCode);

    if (currencyCode == "USD" && typeof countryCode != "undefined" && countryCode != "US") {
      return currencyReturn + " USD";
    } else if (currencyCode == "EUR") {
      return currencyReturn.replace(",00", ",--");
    } else {
      return currencyReturn;
    }
  } else {
    return currencyFormat + " " + currencyCode;
  }
}

export function GetPriceValueAsInt(strAmount) {
  let nAmount;
  if (!strAmount) {
    return 0;
  }

  // Users may enter either comma or period for the decimal mark and digit group separators.
  strAmount = strAmount.replace(/,/g, ".");

  // strip the currency symbol, set .-- to .00
  strAmount = strAmount.replace(GetCurrencySymbol(GetCurrencyCode(g_rgWalletInfo["wallet_currency"])), "").replace(".--", ".00");

  // strip spaces
  strAmount = strAmount.replace(/ /g, "");

  // Remove all but the last period so that entries like "1,147.6" work
  if (strAmount.includes(".")) {
    const splitAmount = strAmount.split(".");
    const strLastSegment = splitAmount[splitAmount.length - 1];

    if (!isNaN(strLastSegment) && strLastSegment.length == 3 && splitAmount[splitAmount.length - 2] != "0") {
      // Looks like the user only entered thousands separators. Remove all commas and periods.
      // Ensures an entry like "1,147" is not treated as "1.147"
      //
      // Users may be surprised to find that "1.147" is treated as "1,147". "1.147" is either an error or the user
      // really did mean one thousand one hundred and forty seven since no currencies can be split into more than
      // hundredths. If it was an error, the user should notice in the next step of the dialog and can go back and
      // correct it. If they happen to not notice, it is better that we list the item at a higher price than
      // intended instead of lower than intended (which we would have done if we accepted the 1.147 value as is).
      strAmount = splitAmount.join("");
    } else {
      strAmount = splitAmount.slice(0, -1).join("") + "." + strLastSegment;
    }
  }

  let flAmount = parseFloat(strAmount) * 100;
  nAmount = Math.floor(isNaN(flAmount) ? 0 : flAmount + 0.000001); // round down

  nAmount = Math.max(nAmount, 0);
  return nAmount;
}

export function getFullWalletBalance(account) {
  const [walletBalance, walletPending] = account?.walletBalance || [];
  const walletPendingInt = walletPending?.startsWith("Pending:") ? GetPriceValueAsInt(walletPending.replace("Pending:", "").trim()) : 0;
  const walletBalanceInt = GetPriceValueAsInt(walletBalance) || 0;
  return walletBalanceInt + walletPendingInt;
}
