import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { generateMenuItems, getFriendName, openSteamProfile } from "utils";
import { Checkbox, Dropdown, Select } from "antd";
import useStore from "store";
import MenuItem from "shared/MenuItem";
import { useLocalStorage } from "@uidotdev/usehooks";
import api from "api";
import moment from "moment";
import classNames from "classnames";
import Swal from "sweetalert2";
import Toast from "../../toast";
import { getPersonaName } from "../../socket";
import SteamAvatar from "../../components/SteamAvatar";
import { useFriendsIDList } from "../../store/UserSlice";

export default function MyFriendList() {
  const storeFriendList = useStore((state) => state.friendList);
  const accountList = useStore((state) => state.accountList);
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const mergeAllAccount = useStore((state) => state.mergeAllAccount);
  const [selectedFriendSteamIds, setSelectedFriendSteamIds] = useState([]);
  const [friendSince, setFriendSince] = useState({});

  //-1 0 1
  const [isMyAccount, setIsMyAccount] = useLocalStorage("MyFriendPlayTime_isMyAccount", 0);
  const [isBanned, setIsBanned] = useLocalStorage("MyFriendPlayTime_isBanned", 0);
  const [primeFilter, setPrimeFilter] = useLocalStorage("MyFriendPlayTime_primeFilter");
  const playerListRef = useRef();
  const selectedMyAccountSteamIds = useMemo(() => {
    return (mergeAllAccount ? accountList : accountList.filter((a) => a.steamId === selectedAccountID)).map((a) => a.steamId);
  }, [accountList, mergeAllAccount, selectedAccountID]);
  const friendsIDList = useFriendsIDList(selectedMyAccountSteamIds);

  const friendList = useMemo(() => {
    const friendsInfo = useStore.getState().friendsInfo;

    let _friendList = friendsIDList
      .map(function (steamId) {
        const friend = storeFriendList.find((friend) => friend.steamId === steamId);
        return friend
          ? {
              ...friend,
              ...(!!friendsInfo[steamId] && { friendsInfo: friendsInfo[steamId] }),
            }
          : null;
      })
      .filter(Boolean);

    _friendList = _.orderBy(
      _friendList,
      [
        function (friend) {
          return friendSince[friend.steamId] || Infinity;
        },
      ],
      ["asc"],
    );

    return _friendList;
  }, [friendSince, friendsIDList, storeFriendList]);

  const displayFriend = useMemo(() => {
    const filterFns = [];

    if (primeFilter) {
      filterFns.push(function (friend) {
        return friend.primeStatus === primeFilter;
      });
    }

    if (isMyAccount !== 0) {
      if (isMyAccount === 1) {
        filterFns.push(function (friend) {
          return accountList.some((a) => a.steamId === friend.steamId);
        });
      } else {
        filterFns.push(function (friend) {
          return accountList.every((a) => a.steamId !== friend.steamId);
        });
      }
    }

    if (isBanned !== 0) {
      filterFns.push(function (friend) {
        const _isBanned = friend.banned;
        if (isBanned === 1) {
          return _isBanned;
        } else {
          return !_isBanned;
        }
      });
    }

    if (!filterFns.length) {
      return friendList;
    }

    return friendList.filter(function (friend) {
      for (const filterFn of filterFns) {
        if (!filterFn(friend)) {
          return false;
        }
      }
      return true;
    });
  }, [accountList, friendList, isBanned, isMyAccount, primeFilter]);

  useEffect(() => {
    setSelectedFriendSteamIds([]);
    setFriendSince({});
    api.getFriendSince({ steamId: selectedAccountID }).then(function (result) {
      const list = result?.data || [];
      setFriendSince(
        list.reduce(function (previousValue, currentValue) {
          previousValue[currentValue.friendSteamId] = currentValue.friendSince;
          return previousValue;
        }, {}),
      );
    });
  }, [selectedAccountID]);

  useEffect(() => {
    if (!playerListRef.current) return;
    playerListRef.current.style["max-height"] = `calc(100vh - ${playerListRef.current.getBoundingClientRect().y + 10}px)`;
  }, [playerListRef]);

  const onSelected = useCallback(
    (steamId, e) => {
      if (e.type === "contextmenu") {
        setSelectedFriendSteamIds(function (prevState) {
          if (prevState.includes(steamId)) {
            return prevState;
          } else {
            return [...prevState, steamId];
          }
        });
      } else if (e.ctrlKey) {
        setSelectedFriendSteamIds(function (prevState) {
          return [...prevState, steamId];
        });
      } else if (e.shiftKey) {
        const lastSelected = selectedFriendSteamIds.at(-1);
        const lastIndex = displayFriend.findIndex((friend) => friend.steamId === lastSelected);
        const currentIndex = displayFriend.findIndex((friend) => friend.steamId === steamId);
        const minIndex = Math.min(lastIndex, currentIndex);
        const maxIndex = Math.max(lastIndex, currentIndex);
        const steamIds = displayFriend.slice(minIndex, maxIndex + 1).map(({ steamId }) => steamId);
        setSelectedFriendSteamIds(function (prevState) {
          const notAddedSteamIds = steamIds.filter((steamId) => !prevState.includes(steamId));
          if (!notAddedSteamIds.length) {
            return prevState;
          } else {
            return [...prevState, ...notAddedSteamIds];
          }
        });
      } else {
        setSelectedFriendSteamIds(function (prevState) {
          if (prevState.includes(steamId)) {
            return prevState.filter(function (_steamId) {
              return _steamId !== _steamId;
            });
          } else {
            return [steamId];
          }
        });
      }
    },
    [displayFriend, selectedFriendSteamIds],
  );

  return (
    <div className="relative">
      <div className="bg-white w-full border-b border-t-0 border-l-0 border-r-0 border-slate-200 border-solid py-1 flex items-center gap-10">
        <div>
          {selectedFriendSteamIds.length}/{displayFriend.length}
        </div>

        <div>
          <span>Prime: </span>
          <Select
            allowClear
            value={primeFilter}
            className="w-48"
            options={[
              {
                value: "prime",
                label: "Prime",
              },
              {
                value: "non-prime",
                label: "Non Prime",
              },
            ]}
            onChange={(value) => setPrimeFilter(value)}
          ></Select>
        </div>
        <div>
          <span>My Account</span>
          <Checkbox
            indeterminate={isMyAccount === 0}
            checked={isMyAccount === 1}
            onClick={function () {
              setIsMyAccount(function (prevState) {
                let nextState = prevState + 1;
                if (nextState > 1) {
                  nextState = -1;
                }
                return nextState;
              });
            }}
          />
        </div>
        <div>
          <span>Banned</span>
          <Checkbox
            indeterminate={isBanned === 0}
            checked={isBanned === 1}
            onClick={function () {
              setIsBanned(function (prevState) {
                let nextState = prevState + 1;
                if (nextState > 1) {
                  nextState = -1;
                }
                return nextState;
              });
            }}
          />
        </div>
      </div>
      <div ref={playerListRef} className="flex flex-wrap gap-1 overflow-y-auto">
        {displayFriend?.map(function (friend, index) {
          return <MyFriendItem friend={friend} friendSince={friendSince[friend.steamId]} index={index + 1} key={friend.steamId} selected={selectedFriendSteamIds.includes(friend.steamId)} onSelected={onSelected} isMyAccount={!!accountList.find(({ steamId }) => steamId === friend.steamId)} selectedFriendSteamIds={selectedFriendSteamIds} />;
        })}
      </div>
    </div>
  );
}

const MyFriendItem = React.memo(function ({ friend, friendSince, index, selected, onSelected, isMyAccount, selectedFriendSteamIds }) {
  let textColor = "";
  switch (friend?.state?.onlineStatus) {
    case "in-game":
      textColor = "text-emerald-500";
      break;
    case "online":
      textColor = "text-blue-700";
      break;
    default:
      break;
  }

  const avatarHash = !friend.avatarHash || friend.avatarHash === "0000000000000000000000000000000000000000" ? "fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb" : friend.avatarHash;

  const handleOnSelected = useCallback(
    (e) => {
      onSelected?.(friend.steamId, e);
    },
    [friend.steamId, onSelected],
  );

  const handleOnDoubleClick = useCallback(() => {
    openSteamProfile(friend.steamId);
  }, [friend.steamId]);

  const menuItems = useMemo(
    function () {
      const { selectedAccountID, accountList } = useStore.getState();
      const myAccountList = _.sortBy(
        accountList.filter((account) => account.friendsIDList?.includes(friend.steamId)),
        function (account) {
          return account.steamId !== selectedAccountID;
        },
      ).slice(0, 15);

      async function doUnfriend(steamIds, myAccountID) {
        const myAccount = useStore.getAccount(myAccountID);
        const myName = myAccount.username + " - " + myAccount.personaName;
        const friendsName = steamIds.length > 1 ? `${steamIds.length} friends` : await getFriendName(steamIds[0]);
        const confirmResult = await Swal.fire({
          title: `Do you want to unfriend: ${friendsName} from account ${myName}`,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Unfriend",
          denyButtonText: `Cancel`,
        });

        if (!confirmResult?.isConfirmed) {
          return;
        }

        const result = (await api.removeFriends({ myAccountID, steamIds }))?.data;
        if (result?.success === 1) {
          Toast.success(`Unfriended ${friendsName} (${myName})`);
        } else {
          Toast.error(`Unfriended ${friendsName} failed (${myName})  ${result?.error}`);
        }
      }

      return [
        MenuItem.OpenSteamProfile,
        MenuItem.CopySteamID,
        MenuItem.OpenMessage,
        MenuItem.SendQuickMessage,
        {
          label: "Fake friend state",
          async onClick({ steamId }) {
            useStore.getState().setFakeFriendStateModal(steamId);
          },
        },
        {
          label: "Update friend info",
          onClick: async ({ steamId }) => {
            useStore.setState({
              UpdateFriendInfoModalData: {
                steamId,
                name: getPersonaName(steamId),
              },
            });
          },
        },
        {
          label: `Unfriend ${selectedFriendSteamIds.length}`,
          children: myAccountList.map((account) => ({
            label: `${account.username} - ${account.personaName}`,
            icon: <SteamAvatar className="w-6 h-6" avatarHash={account.avatarHash} />,
            async onClick() {
              await doUnfriend(selectedFriendSteamIds, account.steamId);
            },
          })),
        },
      ];
    },
    [friend.steamId, selectedFriendSteamIds],
  );

  const { handleMenuClick, items } = useMemo(
    function () {
      return generateMenuItems(menuItems);
    },
    [menuItems],
  );

  return (
    <Dropdown
      key={"Dropdown_" + friend.steamId}
      menu={{
        items: items,
        onClick: (e) => handleMenuClick(e, friend),
      }}
      trigger={["contextMenu"]}
    >
      <div
        key={friend.steamId}
        className={classNames(`w-96 h-12 flex border border-solid cursor-pointer select-none`, {
          "border-blue-500": friend.primeStatus === "prime",
          "border-rose-500": friend.primeStatus !== "prime",
          "bg-green-200": selected,
          "bg-slate-100": !selected,
          "text-red-500": friend.banned,
          "text-black": !friend.banned,
        })}
        onClick={handleOnSelected}
        onContextMenu={handleOnSelected}
        onDoubleClick={handleOnDoubleClick}
      >
        <span>
          <SteamAvatar className="h-full" avatarHash={avatarHash} />
        </span>

        <span className="w-full p-0.5 text-ellipsis">
          <span className="w-full block">
            <span className="text-xs">{index} | </span>
            <span
              className={classNames(textColor, {
                "font-bold": isMyAccount,
              })}
            >
              {isMyAccount ? "==" : ""}
              {[friend.personaName, friend.friendsInfo?.inviteName].filter(Boolean).join(" - ")}
            </span>
          </span>
          <span className="w-full">{friendSince ? `${moment(friendSince).fromNow()} ~ ${moment().diff(moment(friendSince), "days")}days` : ""}</span>
        </span>
      </div>
    </Dropdown>
  );
});
