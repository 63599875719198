import { Button, Checkbox, InputNumber, message, Select, Space, Spin, Upload } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import api from "api";
import moment from "moment";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined } from "@ant-design/icons";
import useStore, { useAccountBySteamId } from "store";
import Creatable from "react-select/creatable";
import Toast from "toast";
import { ObjectUtils } from "alpha-common-utils/index.js";
import SimpleButton from "../../shared/SimpleButton";
import { faTag, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DiscordFriendPicker from "../../DiscordFriendPicker";
import Swal from "sweetalert2";
import copy from "copy-to-clipboard";
import { Profile_Rank, RANKS } from "../../const";
import XpBonus from "../../components/XpBonus";
import SteamAvatar from "../../components/SteamAvatar";
import { dateFromObjectId, getbonus_xp_timestamp_refresh, isAccountLimited, sleep } from "../../utils";
import classNames from "classnames";

function formatMyInfoData(key, value) {
  if (key === "Skill_Group" && typeof value === "number") {
    return RANKS[value];
  } else if (value?.endsWith?.("GMT")) {
    const momentValue = moment.utc(value.replace("GMT", "").trim());
    const displayValue = momentValue.fromNow();

    if (key === "Competitive_Cooldown_Expiration" && momentValue.isAfter(moment())) {
      return <span className="text-red-500">{displayValue}</span>;
    }

    return displayValue;
  } else {
    return value;
  }
}

const AvatarHistoryItem = React.memo(function AvatarHistoryItem({ selectPreviousAvatarLoading, setSelectPreviousAvatarLoading, selectedAccountID, avatarHash, className }) {
  const handleOnClick = useCallback(
    async function () {
      if (selectPreviousAvatarLoading[avatarHash]) {
        return;
      }

      setSelectPreviousAvatarLoading((_selectPreviousAvatarLoading) => ({
        ..._selectPreviousAvatarLoading,
        [avatarHash]: true,
      }));
      const result = (
        await api.selectPreviousAvatar({
          steamId: selectedAccountID,
          avatarHash,
        })
      )?.data;
      if (result?.success === 1) {
        Toast.success("selectPreviousAvatar success");
      } else {
        Toast.error(result?.message || "selectPreviousAvatar failed");
      }
      setSelectPreviousAvatarLoading((_selectPreviousAvatarLoading) => ({
        ..._selectPreviousAvatarLoading,
        [avatarHash]: false,
      }));
    },
    [avatarHash, selectPreviousAvatarLoading, selectedAccountID, setSelectPreviousAvatarLoading],
  );

  const handleOnDelete = useCallback(() => {
    api
      .deleteAvatarHistory({
        steamId: selectedAccountID,
        avatarHash,
      })
      .then(function (result) {
        if (result?.data?._id) {
          useStore.getState().updateAccounts([result.data]);
        }
        console.log("deleteAvatarHistory", result);
      });
  }, [avatarHash, selectedAccountID]);

  const handleOnSelectPrimaryAvatar = useCallback(() => {
    api
      .setDefaultProfile({
        steamId: selectedAccountID,
        avatarHash,
      })
      .then(function (result) {
        if (result?.data?._id) {
          useStore.getState().updateAccounts([result.data]);
        }
        console.log("setAvatarPrimary", result);
      });
  }, [avatarHash, selectedAccountID]);

  return (
    <div className={`w-20 h-20 relative cursor-default ${className || ""}`} key={avatarHash}>
      <SteamAvatar className="w-full h-full button-ripple cursor-default" onClick={handleOnClick} avatarHash={avatarHash} />
      {!!selectPreviousAvatarLoading[avatarHash] && (
        <div className="absolute flex items-center justify-center left-0 top-0 right-0 bottom-0 m-auto w-full h-full bg-slate-500/[.6]">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            }
          />
        </div>
      )}
      <div className="absolute right-0 top-0 flex gap-1">
        <FontAwesomeIcon icon={faTag} size="xs" className=" border border-solid border-gray-100 rounded-full p-2 bg-slate-300 cursor-pointer hover:bg-rose-600" onClick={handleOnSelectPrimaryAvatar} />
        <FontAwesomeIcon icon={faTrash} size="xs" className="  border border-solid border-gray-100 rounded-full p-2 bg-slate-300 cursor-pointer hover:bg-rose-600" onClick={handleOnDelete} />
      </div>
    </div>
  );
});

export const onAvatarPreview = async (file) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

export default function MyAccountInfo() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const accountList = useStore((state) => state.accountList);
  const selectedAccount = useAccountBySteamId(selectedAccountID);
  const partyRegisterGroup = useStore((state) => state.partyRegisterGroup);
  const [editselectedAccount, setEditselectedAccount] = useState(selectedAccount);
  const [avatarUploading, setAvatarUploading] = useState(false);
  const [avatarFileList, setAvatarFileList] = useState([]);
  const [selectPreviousAvatarLoading, setSelectPreviousAvatarLoading] = useState({});
  const [scanQRHistory, setScanQRHistory] = useState([]);
  const displayPartyRegisterGroup = useMemo(
    () =>
      partyRegisterGroup.map((g) => ({
        value: g,
        label: `${g === undefined || g === "undefined" ? "default" : g} (${accountList.filter((a) => a.config?.partyRegisterGroup === g).length})`,
      })),
    [partyRegisterGroup, accountList],
  );
  const historyName = useMemo(() => {
    const historyName = _.uniq([editselectedAccount?.personaName, ...(editselectedAccount?.historyName || [])]);
    historyName.length = Math.min(historyName.length, 20);
    return historyName.join(", ");
  }, [editselectedAccount?.historyName, editselectedAccount?.personaName]);

  const partyRegisterGroupValue = useMemo(
    function () {
      const group = editselectedAccount?.config?.partyRegisterGroup;
      return {
        value: group,
        label: `${group === "undefined" || group === undefined ? "default" : group} (${useStore.getState().accountList.filter((a) => a.config?.partyRegisterGroup === group).length})`,
      };
    },
    [editselectedAccount?.config?.partyRegisterGroup],
  );

  useEffect(() => {
    setEditselectedAccount(function (_selectedAccount) {
      if (_.isEqual(selectedAccount, _selectedAccount)) {
        return _selectedAccount;
      }
      return selectedAccount;
    });
  }, [selectedAccount]);

  useEffect(() => {
    if (!selectedAccountID) return;
    api.getLoginQR({ accountSteamId: selectedAccountID }).then(function (result) {
      const data = result?.data || [];
      setScanQRHistory(data.filter((r) => r.success === true));
    });
  }, [selectedAccountID]);

  const setEditSelectedAccountConfig = useCallback(
    (config) => {
      setEditselectedAccount((prev) => ({
        ...prev,
        config: {
          ...(prev.config || {}),
          ...config,
        },
      }));
    },
    [setEditselectedAccount],
  );

  const uploadAvatar = useCallback(async (steamId, file, defaultAvatar = false) => {
    setAvatarUploading(true);
    const result = (
      await api.uploadAvatar({
        steamId: steamId,
        avatar: file,
        defaultAvatar,
      })
    )?.data;

    setAvatarUploading(false);
    const successExample = {
      success: true,
      images: {
        0: "https://avatars.akamai.steamstatic.com/9b41b86d1e212bc43db9aa75c2c226fec36e8099.jpg",
        full: "https://avatars.akamai.steamstatic.com/9b41b86d1e212bc43db9aa75c2c226fec36e8099_full.jpg",
        medium: "https://avatars.akamai.steamstatic.com/9b41b86d1e212bc43db9aa75c2c226fec36e8099_medium.jpg",
      },
      hash: "9b41b86d1e212bc43db9aa75c2c226fec36e8099",
      message: "",
    };
    if (result.success) {
      message.success("Upload successfully.");
      setAvatarFileList([]);
    } else {
      message.error(typeof result === "string" ? result : result?.error || result?.message || "Upload failed.");
    }
  }, []);

  const personalGameDataAccountInformation = useMemo(
    function () {
      if (!selectedAccount) return;
      const personalInfo = _.cloneDeep(selectedAccount?.personalGameDataAccountInformation || {});

      if (!selectedAccount.prime) {
        delete personalInfo.Experience_points_earned_towards_next_rank;
        delete personalInfo.CSGO_Profile_Rank;
        delete personalInfo.Earned_a_Service_Medal;
      }

      if (personalInfo.Experience_points_earned_towards_next_rank) {
        const xpNeeded = 5000 - personalInfo.Experience_points_earned_towards_next_rank;
        personalInfo["Need Xp to reach next rank"] = (
          <span>
            <span className="text-blue-600 mr-2">{xpNeeded}xp</span>
            <span>({Math.ceil(xpNeeded / 120)} round win)</span>
          </span>
        );
        delete personalInfo.Experience_points_earned_towards_next_rank;
      }
      if (personalInfo.CSGO_Profile_Rank) {
        const rank = Profile_Rank.find((r) => r.rank === personalInfo.CSGO_Profile_Rank);
        if (rank) {
          personalInfo.CSGO_Profile_Rank = (
            <span>
              <img className="w-8 h-8 mx-2" src={rank.img} />
              {rank.name}
            </span>
          );
        }
      }

      personalInfo.xpEarned = <XpBonus xp={selectedAccount.xpEarned} />;

      return personalInfo;
    },
    [selectedAccount],
  );

  const onDiscordBotUserChange = useCallback(
    (discordBotUser) => {
      if (!editselectedAccount?._id) {
        return;
      }

      useStore.getState().updateAccountConfig(editselectedAccount._id, {
        discordBotUser,
      });
      setEditSelectedAccountConfig({
        discordBotUser,
      });
    },
    [editselectedAccount?._id, setEditSelectedAccountConfig],
  );

  const competitiveCooldownLevel = useMemo(() => {
    const competitiveCooldownLevel = _.cloneDeep(selectedAccount?.competitiveCooldownLevel || {});
    const penaltyReason = selectedAccount?.penaltyReason;
    if (typeof penaltyReason === "number" && penaltyReason !== 0) {
      const penaltyReasonMapping = {
        1: "Kicked",
        2: "You killed too many teammates",
        3: "You killed a teammate at round start",
        4: "You failed to reconnect to the last match",
        5: "You abandoned the last match",
        6: "You did too much damage to your teammates",
        7: "You did too much damage to your teammates at round start",
        9: "Kicked Too Much",
        10: "Overwatch (Cheat)",
        11: "Overwatch (Grief)",
        14: "Untrusted",
        8: "Untrusted",
        16: "Failed To Connect",
        17: "Kick Abuse",
        18: "Rank Calibration",
        19: "Rank Calibration",
        20: "Rank Calibration",
        21: "Reports (Grief)",
      };

      try {
        competitiveCooldownLevel.cooldown_table[0].penalty_reason = (
          <span
            className={classNames({
              "text-red-500": [8, 10, 14].includes(penaltyReason),
            })}
          >
            {penaltyReasonMapping[penaltyReason] || penaltyReason}
          </span>
        );
      } catch (e) {}
    }

    if (competitiveCooldownLevel.cooldown_table?.[0]?.Acknowledged === "No") {
      competitiveCooldownLevel.cooldown_table[0].Acknowledged = (
        <span className="flex items-center gap-2">
          <span>No</span>
          <SimpleButton
            onClick={async function () {
              const { updateAccountCompetitiveCooldownLevel, selectedAccountID } = useStore.getState();
              await api.acknowledgePenalty({ steamId: selectedAccountID });
              await updateAccountCompetitiveCooldownLevel(selectedAccountID);
            }}
          >
            do Acknowledge
          </SimpleButton>
        </span>
      );
    }
    return competitiveCooldownLevel;
  }, [selectedAccount?.competitiveCooldownLevel, selectedAccount?.penaltyReason]);

  const fetchMarketUnavailable = useCallback(async () => {
    const account = (await api.fetchMarketUnavailable({ steamId: selectedAccountID }))?.data;
    if (account) {
      useStore.getState().updateAccounts(account);
    }
  }, [selectedAccountID]);

  const disableBotNatriDiscord = useCallback(
    async (timestamp) => {
      const result = await api.disableBotNatriScan({ timestamp: timestamp.valueOf(), steamId: selectedAccountID });
      if (result?.data?._id) {
        useStore.getState().updateAccounts(result?.data);
      }
    },
    [selectedAccountID],
  );

  const disableBotNatriDiscordMenu = useMemo(() => {
    return [
      {
        label: "1 hour",
        async onClick() {
          await disableBotNatriDiscord(moment().add(1, "hours"));
        },
      },
      {
        label: "6 hour",
        async onClick() {
          await disableBotNatriDiscord(moment().add(6, "hours"));
        },
      },
      {
        label: "1 day",
        async onClick() {
          await disableBotNatriDiscord(moment().add(1, "days"));
        },
      },
      {
        label: "3 day",
        async onClick() {
          await disableBotNatriDiscord(moment().add(3, "days"));
        },
      },
      {
        label: "7 day",
        async onClick() {
          await disableBotNatriDiscord(moment().add(7, "days"));
        },
      },
    ];
  }, [disableBotNatriDiscord]);

  const enableBotNatriDiscord = useCallback(
    async (timestamp) => {
      const result = await api.enableBotNatriScan({ timestamp: timestamp.valueOf(), steamId: selectedAccountID });
      if (result?.data?._id) {
        useStore.getState().updateAccounts(result?.data);
      }
    },
    [selectedAccountID],
  );

  const enableBotNatriDiscordMenu = useMemo(() => {
    return [
      {
        label: "1 hour",
        async onClick() {
          await enableBotNatriDiscord(moment().add(1, "hours"));
        },
      },
      {
        label: "6 hour",
        async onClick() {
          await enableBotNatriDiscord(moment().add(6, "hours"));
        },
      },
      {
        label: "1 day",
        async onClick() {
          await enableBotNatriDiscord(moment().add(1, "days"));
        },
      },
      {
        label: "3 day",
        async onClick() {
          await enableBotNatriDiscord(moment().add(3, "days"));
        },
      },
      {
        label: "7 day",
        async onClick() {
          await enableBotNatriDiscord(moment().add(7, "days"));
        },
      },
    ];
  }, [enableBotNatriDiscord]);

  const handleOnEnable2Factor = useCallback(async () => {
    const { selectedAccountID: steamId, accountList } = useStore.getState();
    const selectedAccount = accountList.find((account) => account.steamId === steamId);
    const accountName = [selectedAccount.username, selectedAccount.personaName].join(" - ");

    const email = (await api.getAccountEmail({ steamId })).data;
    if (!email || typeof email !== "string" || !email.includes("@")) {
      return Toast.error("Can not find email of account " + accountName);
    }

    const phoneManage = (await api.getAccountPhoneManage({ steamId })).data;
    if (phoneManage === "none") {
      const phoneNumber = await new Promise((resolve) => {
        useStore.getState().setConfirmModalData({
          title: "Please input phone number",
          fields: [
            {
              type: "Input",
              name: "phone",
              label: "Phone number",
            },
          ],
          handleOk({ phone }) {
            resolve(phone);
            useStore.getState().setConfirmModalData();
          },
          handleCancel() {
            resolve();
          },
        });
      });
      if (!phoneNumber || typeof phoneNumber !== "string") {
        return;
      }
      const phone = phoneNumber.startsWith("0") ? `+84${phoneNumber.substring(1)}` : phoneNumber;
      const addPhoneResult = (
        await api.addAccountPhoneNumber({
          steamId,
          phone,
        })
      ).data;
      if (!addPhoneResult?.success) {
        return Toast.error(`Can not add phone number(${phone}) to account ${accountName}`);
      }
      if (addPhoneResult.state === "email_verification") {
        const macroURL = (await api.getMacroURL({ email })).data;
        if (!macroURL || typeof macroURL !== "string") {
          return Toast.error("Can not find macro URL of email " + email);
        }
        Toast.success(`Trying to get verify email at ${email}`);
        const confirmEmailResult = (await api.confirmEmailForAddPhone({ steamId, macroURL })).data;
        if (!confirmEmailResult || confirmEmailResult.error) {
          Toast.error(confirmEmailResult?.error || "confirmEmailForAddPhone Failed");
          Toast.success(`Please confirm email URL sent to ${email}`);
        }
      }

      const confirmAddPhoneResult = (await api.sendOtpConfirmPhoneForAdd({ steamId })).data;
      if (!confirmAddPhoneResult?.success || confirmAddPhoneResult?.state !== "get_sms_code") {
        return Toast.error(confirmAddPhoneResult?.errorText || "confirmAddPhoneResult Failed");
      }

      Toast.success(`Please input opt code to confirm adding phone number(${phone})`);

      const confirmSuccess = await new Promise((resolve) => {
        useStore.getState().setConfirmCodeForAddPhoneModal({
          steamId,
          callback: resolve,
        });
      });
      useStore.getState().setConfirmCodeForAddPhoneModal(null);
      if (!confirmSuccess) {
        return;
      }
    }

    async function promptActivationCode(enableResponse) {
      const optCode = await new Promise((resolve) => {
        useStore.getState().setConfirmModalData({
          title: "Please input opt code to finalizeTwoFactor",
          fields: [
            {
              type: "Input",
              name: "opt_code",
              label: "Opt code",
            },
          ],
          handleOk({ opt_code }) {
            resolve(opt_code?.trim());
            useStore.getState().setConfirmModalData();
          },
          handleCancel() {
            resolve();
          },
        });
      });
      if (!optCode) {
        return;
      }
      const finalize2FactorResult = await api.finalizeTwoFactor({
        steamId,
        optCode,
        enableResponse,
        email,
      });
      if (!finalize2FactorResult) {
        return Toast.error("finalize2FactorResult Failed");
      }

      const finalize2FError = finalize2FactorResult.error;
      if (finalize2FError) {
        if (finalize2FError.message === "Invalid activation code") {
          Toast.error("Invalid activation code");
          await promptActivationCode(enableResponse);
        } else {
          console.error("finalize2FError", finalize2FError);
        }
      } else {
        Toast.success("Two-factor authentication enabled!");
      }
    }

    async function enableTwoFactor() {
      const enableResult = (await api.enableTwoFactor({ steamId })).data;
      if (!enableResult) {
        return Toast.error("Enable 2factor Failed");
      }
      const enable2FError = enableResult.error;
      if (enable2FError) {
        if (enable2FError.eresult === 2) {
          return Toast.error("Error: Failed to enable two-factor authentication. Do you have a phone number attached to your account?");
        } else if (enable2FError.eresult === 84) {
          Toast.error("Error: RateLimitExceeded. Try again later.");
          await sleep(10000);
          await enableTwoFactor();
        } else {
          return Toast.error("Enable 2factor Failed " + enable2FError.eresult);
        }
      } else {
        if (enableResult.response?.status !== 1) {
          return Toast.error(`Error: Status ${enableResult.response.status}`);
        } else {
          await promptActivationCode(enableResult.response);
        }
      }
    }

    await enableTwoFactor();
  }, []);

  const handleOnDisable2Factor = useCallback(async () => {
    const confirmDisable2FactorResult = await Swal.fire({
      title: `Do you want to disable two factor?`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirmed disable 2factor",
      denyButtonText: `Cancel`,
    });
    if (!confirmDisable2FactorResult.isConfirmed) {
      return;
    }

    const { selectedAccountID, accountList } = useStore.getState();

    const result = (await api.disableTwoFactor({ steamId: selectedAccountID })).data;
    if (result?.success) {
      Toast.success(`Confirmed disableTwoFactor ${accountList.find(({ steamId }) => steamId === selectedAccountID).personaName}`);
    } else {
      Toast.error(result?.error || "disableTwoFactor Failed");
    }
  }, []);

  const handleOnRemovePhoneNumber = useCallback(async () => {
    const confirmRemovePhoneResult = await Swal.fire({
      title: `Do you want to remove phone number?`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirmed remove phone",
      denyButtonText: `Cancel`,
    });
    if (!confirmRemovePhoneResult.isConfirmed) {
      return;
    }

    const { selectedAccountID } = useStore.getState();

    const { success, params, error, help_page_title } = (await api.removePhoneNumber({ steamId: selectedAccountID })).data || {};
    if (!success || !params) {
      return Toast.error(error || "Removed phone number Failed");
    }
    if (help_page_title) {
      Toast.success(help_page_title);
    }

    const optCode = await new Promise((resolve) => {
      useStore.getState().setConfirmModalData({
        title: "Please input opt code to remove phone number",
        fields: [
          {
            type: "Input",
            name: "opt_code",
            label: "Opt Code",
          },
        ],
        handleOk({ opt_code }) {
          resolve(opt_code);
          useStore.getState().setConfirmModalData();
        },
        handleCancel() {
          resolve();
        },
      });
    });
    if (!optCode || typeof optCode !== "string") {
      return;
    }

    const confirmResult = (await api.removePhoneNumberConfirmOptCode({ steamId: selectedAccountID, optCode, params })).data;
    if (confirmResult.success) {
      Toast.success("removePhoneNumber successfully");
    } else {
      Toast.error(confirmResult?.errorMsg || "removePhoneNumber Failed");
    }
    console.log(confirmResult);
  }, []);

  if (!editselectedAccount?.steamId) {
    return;
  }

  return (
    <div className="overflow-y-auto h-full">
      <div className="flex gap-5 w-full overflow-y-auto">
        <div className="flex gap-2 p-1 w-fit">
          <SteamAvatar className="max-w-20 max-h-20" avatarHash={selectedAccount.avatarHash} />
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={avatarFileList}
              onPreview={onAvatarPreview}
              beforeUpload={(file) => {
                setAvatarFileList([file]);
                uploadAvatar(selectedAccountID, file, false);
                return false;
              }}
            >
              {avatarFileList.length < 5 && "+ Upload"}
            </Upload>
          </ImgCrop>
        </div>
        <div className="flex flex-row-reverse gap-5">
          {selectedAccount.avatarHistory
            ?.filter(function (avatarHash) {
              return selectedAccount.defaultProfile?.avatarHash !== avatarHash;
            })
            ?.map((avatarHash) => (
              <AvatarHistoryItem selectPreviousAvatarLoading={selectPreviousAvatarLoading} setSelectPreviousAvatarLoading={setSelectPreviousAvatarLoading} selectedAccountID={selectedAccountID} avatarHash={avatarHash} key={avatarHash} />
            ))}
          {!!selectedAccount.defaultProfile && !!Object.keys(selectedAccount.defaultProfile).length && <AvatarHistoryItem selectPreviousAvatarLoading={selectPreviousAvatarLoading} setSelectPreviousAvatarLoading={setSelectPreviousAvatarLoading} selectedAccountID={selectedAccountID} avatarHash={selectedAccount.defaultProfile.avatarHash} key={selectedAccount.defaultProfile.avatarHash} name={selectedAccount.defaultProfile.name} className="border-1 border-solid border-green-600" />}
        </div>
      </div>
      <div className="flex gap-5 w-full overflow-y-auto">
        <div className="flex gap-2 p-1 w-fit">
          {!!selectedAccount.defaultProfile?.avatarHash && <SteamAvatar className="max-w-20 max-h-20" avatarHash={selectedAccount.defaultProfile.avatarHash} />}
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={avatarFileList}
              onPreview={onAvatarPreview}
              beforeUpload={(file) => {
                setAvatarFileList([file]);
                uploadAvatar(selectedAccountID, file, true);
                return false;
              }}
            >
              + Upload default avatar
            </Upload>
          </ImgCrop>
        </div>
      </div>
      <div>
        Name: {editselectedAccount.personaName}
        <SimpleButton
          className="my-3"
          onClick={function () {
            useStore.getState().setRenameSteamModal(selectedAccountID);
          }}
        >
          Rename
        </SimpleButton>
        {!!selectedAccount.preProfile && (
          <SimpleButton
            className="my-3"
            onClick={async function () {
              if (selectedAccount.preProfile?.personaName) {
                await api.renameUser({
                  steamId: selectedAccount.steamId,
                  name: selectedAccount.preProfile.personaName,
                });
              }
              if (selectedAccount.preProfile?.avatarHash) {
                await api.selectPreviousAvatar({
                  steamId: selectedAccount.steamId,
                  avatarHash: selectedAccount.preProfile.avatarHash,
                });
              }
            }}
          >
            Revert profile
          </SimpleButton>
        )}
      </div>
      <div>History Name: {historyName}</div>
      <div>Username: {editselectedAccount.username}</div>
      <div className="flex gap-5 items-center">
        <div>Email: {editselectedAccount.email}</div>
        <SimpleButton
          onClick={async function () {
            const result = await Swal.fire({
              title: "Submit your new email macroURL",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Submit",
              showLoaderOnConfirm: true,
            });
            if (!result.isConfirmed) {
              return;
            }
            const macroURL = result.value;
            const checkResult = await api.checkMacroURL({ macroURL });
            const newEmail = checkResult?.data?.email;
            if (newEmail) {
              Toast.success(checkResult.data.email);
            } else {
              Toast.error("None");
            }
            const updateResult = await api.updateMacroURL({
              macroURL,
              email: newEmail,
            });
            console.log("updateResult");
            console.log(updateResult);
            const changeEmailResutl = await api.changeEmail({
              steamId: selectedAccountID,
              newEmail,
            });
            console.log(changeEmailResutl);
          }}
        >
          Change Email
        </SimpleButton>
        <SimpleButton
          onClick={async function () {
            const email = editselectedAccount.email;
            const result = (await api.getSteamGuard({ email }))?.data;
            if (result && typeof result === "string") {
              Toast.success(result);
              copy(result);
            } else {
              Toast.error("None");
            }
          }}
        >
          Get steam guard
        </SimpleButton>
        <SimpleButton
          onClick={async function () {
            const email = editselectedAccount.email;
            const result = await api.checkMacroURL({ email });
            if (result?.data?.email) {
              Toast.success(result.data.email);
            } else {
              Toast.error("None");
            }
          }}
        >
          Check Macro URL
        </SimpleButton>
        <SimpleButton
          onClick={async function () {
            const email = editselectedAccount.email;
            const result = await api.getMacroURL({ email });
            if (result?.data?.startsWith?.("https://script.google.com/macros")) {
              Toast.success(result.data);
              copy(result.data);
            } else {
              Toast.error("None");
            }
          }}
        >
          Get Macro URL
        </SimpleButton>
        <Button
          onClick={function () {
            const email = editselectedAccount.email;
            Swal.fire({
              title: "Submit Macro URL for email " + email,
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Submit",
              showLoaderOnConfirm: true,
            }).then((result) => {
              if (result.isConfirmed) {
                const macroURL = result.value;
                api.updateMacroURL({ macroURL, email }).then(function (result) {
                  if (!result?.data || result.data.error) {
                    Toast.error(result?.data?.error || "Failed");
                  } else {
                    console.log(result.data);
                    Toast.success("Success");
                  }
                });
              }
            });
          }}
        >
          Update Macro URL
        </Button>
      </div>

      <div className="flex justify-between border border-solid border-t-1 border-l-0 border-r-0 mt-2 pt-2">
        <div className="w-1/2 flex flex-col gap-2">
          {!!selectedAccount.enableTwoFactor && (
            <>
              <div className="flex items-center">
                <SimpleButton
                  onClick={async function () {
                    await disableBotNatriDiscord(moment().add(30, "minutes"));
                  }}
                  menu={disableBotNatriDiscordMenu}
                >
                  Disable 30mins BOT Natri scan QR
                </SimpleButton>
                {!!selectedAccount.tempDisableBotDiscordScan && (
                  <>
                    <span>{moment(selectedAccount.tempDisableBotDiscordScan).fromNow()}</span>
                    <SimpleButton
                      onClick={async function () {
                        const result = await api.disableBotNatriScan({ steamId: selectedAccountID });
                        if (result?.data?._id) {
                          useStore.getState().updateAccounts(result?.data);
                        }
                      }}
                    >
                      Reset
                    </SimpleButton>
                  </>
                )}
              </div>

              <div className="flex items-center">
                <SimpleButton
                  onClick={async function () {
                    await enableBotNatriDiscord(getbonus_xp_timestamp_refresh().add(7, "days"));
                  }}
                  menu={enableBotNatriDiscordMenu}
                >
                  Enable BOT scan QR this dropweek
                </SimpleButton>
                {!!selectedAccount.tempEnableBotDiscordScan && (
                  <>
                    <span>{moment(selectedAccount.tempEnableBotDiscordScan).fromNow()}</span>
                    <SimpleButton
                      onClick={async function () {
                        const result = await api.enableBotNatriScan({ steamId: selectedAccountID });
                        if (result?.data?._id) {
                          useStore.getState().updateAccounts(result?.data);
                        }
                      }}
                    >
                      Reset
                    </SimpleButton>
                  </>
                )}
              </div>
            </>
          )}
          <div>
            <SimpleButton
              onClick={async function () {
                await api.fetchMyOwnProfile({ steamId: selectedAccountID });
              }}
            >
              fetch my own profile
            </SimpleButton>
          </div>
          <div>
            <SimpleButton
              onClick={async function () {
                const { clientWelcome, clientHello } = (await api.getPersonalStore({ steamId: selectedAccountID }))?.data || {};
                const personalStore = clientWelcome?.personalStore;
                if (!personalStore) {
                  return Toast.error("Failed");
                }
                const generation_time = moment(personalStore.generation_time).fromNow();
                switch (personalStore.redeemable_balance) {
                  case 0:
                    Toast.success(`claimed ${generation_time}`);
                    break;
                  case 2:
                    Toast.success(`not yet claim ${generation_time}`);
                    break;
                  default:
                    Toast.error(`Invalid redeemable_balance: ${personalStore.redeemable_balance}`);
                }
              }}
            >
              get Personal Store
            </SimpleButton>
          </div>
          {!selectedAccount.steamLevel && !selectedAccount.prime && !selectedAccount.walletBalance && (
            <div>
              <SimpleButton
                onClick={async function () {
                  const code = await Swal.fire({
                    title: "Submit Macro URL for email " + selectedAccount.username,
                    input: "text",
                    inputAttributes: {
                      autocapitalize: "off",
                    },
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    showLoaderOnConfirm: true,
                  });
                  if (!code.isConfirmed) {
                    return;
                  }
                  const walletCode = code.value;
                  const result = (await api.redeemWalletCode({ steamId: selectedAccount.steamId, walletCode }))?.data?.result;
                  if (result?.success === 1) {
                    Toast.success("Success: " + result.formattednewwalletbalance);
                    await api.fetchMyAccountInfo({ steamId: selectedAccountID });
                  } else {
                    Toast.error(result?.error || "Failed");
                  }
                }}
              >
                redeem Wallet Code
              </SimpleButton>
            </div>
          )}
          {!selectedAccount.enableTwoFactor && (
            <div>
              <SimpleButton onClick={handleOnEnable2Factor}>Enable two factor</SimpleButton>
            </div>
          )}
          {!!selectedAccount.enableTwoFactor && (
            <div>
              <SimpleButton onClick={handleOnDisable2Factor}>Disable two factor</SimpleButton>
            </div>
          )}
          <div>
            <SimpleButton onClick={handleOnRemovePhoneNumber}>Remove phone number</SimpleButton>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col">
            <div>
              <Checkbox
                checked={editselectedAccount.config?.peopleAddFriend}
                onChange={(e) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    peopleAddFriend: e.target.checked,
                  });
                  setEditSelectedAccountConfig({
                    peopleAddFriend: e.target.checked,
                  });
                }}
              >
                Using people add friend
              </Checkbox>
            </div>
            <div>
              <Checkbox
                checked={editselectedAccount.config?.followFriend}
                onChange={(e) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    followFriend: e.target.checked,
                  });
                  setEditSelectedAccountConfig({
                    followFriend: e.target.checked,
                  });
                }}
              >
                Using follow friend
              </Checkbox>
            </div>
            <div>
              <Checkbox
                checked={editselectedAccount.config?.autoChangeName}
                onChange={(e) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    autoChangeName: e.target.checked,
                  });
                  setEditSelectedAccountConfig({
                    autoChangeName: e.target.checked,
                  });
                }}
              >
                Auto Change Name
              </Checkbox>
            </div>
            <div className="flex gap-5 items-center">
              <span>State</span>
              <Select
                className="w-32"
                defaultValue="Invisible"
                value={editselectedAccount.config?.state ?? "Invisible"}
                options={["Invisible", "Online", "InGame"].map((state) => ({
                  label: state,
                  value: state,
                }))}
                onChange={(state) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    state,
                  });
                  setEditSelectedAccountConfig({
                    state,
                  });
                }}
              />
            </div>
            <div className="flex gap-5 items-center">
              <Checkbox
                checked={editselectedAccount.config?.isPrivate}
                onChange={(e) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    isPrivate: e.target.checked,
                  });
                  setEditSelectedAccountConfig({
                    isPrivate: e.target.checked,
                  });
                }}
              >
                Private Account
              </Checkbox>
              <span className="italic">(Account that only me using)</span>
            </div>
            <div>
              <Checkbox
                checked={editselectedAccount.config?.store}
                onChange={(e) => {
                  const config = {
                    store: e.target.checked,
                    ...(e.target.checked && {
                      partyRegister: false,
                      partyRegisterCirculate: false,
                      autoAddFriend: false,
                      smartAcceptFriend: false,
                    }),
                  };
                  useStore.getState().updateAccountConfig(editselectedAccount._id, config);
                  setEditSelectedAccountConfig(config);
                }}
              >
                Store Account
              </Checkbox>
            </div>
            <div className="flex items-center">
              Boost playing time
              <InputNumber
                rootClassName="w-48"
                placeholder="Duration (mins)"
                value={editselectedAccount.config?.boostPlayingTime_Duration}
                onChange={(value) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    boostPlayingTime_Duration: value,
                  });
                  setEditSelectedAccountConfig({
                    boostPlayingTime_Duration: value,
                  });
                }}
              ></InputNumber>
              <InputNumber
                rootClassName="w-48"
                placeholder="Sleep (mins)"
                value={editselectedAccount.config?.boostPlayingTime_Sleep}
                onChange={(value) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    boostPlayingTime_Sleep: value,
                  });
                  setEditSelectedAccountConfig({
                    boostPlayingTime_Sleep: value,
                  });
                }}
              ></InputNumber>
            </div>
            <div>
              <Checkbox
                checked={editselectedAccount.config?.boostPlayingTimeIdleAtNight}
                onChange={(e) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    boostPlayingTimeIdleAtNight: e.target.checked,
                  });
                  setEditSelectedAccountConfig({
                    boostPlayingTimeIdleAtNight: e.target.checked,
                  });
                }}
              >
                Boost playing time idle at night
              </Checkbox>
            </div>
            <div>
              <Checkbox
                checked={editselectedAccount.config?.partyRegister}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const config = {
                    partyRegister: checked,
                    ...(checked && {
                      partyRegisterCirculate: false,
                    }),
                  };
                  useStore.getState().updateAccountConfig(editselectedAccount._id, config);
                  setEditSelectedAccountConfig(config);
                }}
              >
                Party Register
              </Checkbox>
            </div>

            <div className="flex items-center">
              <span>partyRegisterGroup: </span>
              <Creatable
                className="w-48"
                value={partyRegisterGroupValue}
                options={displayPartyRegisterGroup}
                onCreateOption={(inputValue) => {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    partyRegisterGroup: inputValue,
                  });
                  setEditSelectedAccountConfig({
                    partyRegisterGroup: inputValue,
                  });
                }}
                onChange={function (newValue) {
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    partyRegisterGroup: newValue.value,
                  });
                  setEditSelectedAccountConfig({
                    partyRegisterGroup: newValue.value,
                  });
                }}
              />
            </div>

            <div>
              <Checkbox
                checked={editselectedAccount.config?.partyRegisterCirculate}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const config = {
                    partyRegisterCirculate: checked,
                    ...(checked && {
                      partyRegister: false,
                    }),
                  };
                  useStore.getState().updateAccountConfig(editselectedAccount._id, config);
                  setEditSelectedAccountConfig(config);
                }}
              >
                Party Register Circulate
              </Checkbox>
            </div>
            {!isAccountLimited(selectedAccount) && (
              <div>
                <Checkbox
                  checked={editselectedAccount.config?.autoAddFriend}
                  onChange={(e) => {
                    useStore.getState().updateAccountConfig(editselectedAccount._id, {
                      autoAddFriend: e.target.checked,
                    });
                    setEditSelectedAccountConfig({
                      autoAddFriend: e.target.checked,
                    });
                  }}
                >
                  Auto Add Friend
                </Checkbox>
              </div>
            )}
            {isAccountLimited(selectedAccount) && (
              <div>
                <Checkbox
                  checked={editselectedAccount.config?.smartAcceptFriend}
                  onChange={(e) => {
                    useStore.getState().updateAccountConfig(editselectedAccount._id, {
                      smartAcceptFriend: e.target.checked,
                    });
                    setEditSelectedAccountConfig({
                      smartAcceptFriend: e.target.checked,
                    });
                  }}
                >
                  Smart Accept Friend
                </Checkbox>
              </div>
            )}
            {!!selectedAccount.enableTwoFactor && (
              <div>
                <Checkbox
                  checked={editselectedAccount.config?.ignoreBotNatriScanQR}
                  onChange={(e) => {
                    useStore.getState().updateAccountConfig(editselectedAccount._id, {
                      ignoreBotNatriScanQR: e.target.checked,
                    });
                    setEditSelectedAccountConfig({
                      ignoreBotNatriScanQR: e.target.checked,
                    });
                  }}
                >
                  Ignore Bot Natri Scan QR
                </Checkbox>
              </div>
            )}
            <DiscordFriendPicker selectedUser={editselectedAccount.config?.discordBotUser} setSelectedUser={onDiscordBotUserChange} />
          </div>
          <br />
          <br />
          <Space direction="vertical" style={{ width: "100%" }}>
            <div>Players</div>
            {
              <Select
                mode="tags"
                placeholder="Please select"
                onChange={(value) => {
                  value = value.map((p) => p?.trim()).filter(Boolean);
                  useStore.getState().updateAccountConfig(editselectedAccount._id, {
                    players: value,
                  });

                  setEditSelectedAccountConfig({
                    players: value,
                  });
                }}
                style={{ width: "100%" }}
                value={editselectedAccount.config?.players}
              />
            }
          </Space>
        </div>
      </div>

      {
        <div className="py-2 flex flex-row">
          <div className="border border-solid border-l-indigo-950 p-3">
            <div className="flex gap-5 items-center">
              <h3>personalGameDataAccountInformation</h3>
              <SimpleButton
                onClick={async () => {
                  const { updateAccountPersonalGameDataAccountInformation, mergeAllAccount, accountList } = useStore.getState();
                  const accounts = mergeAllAccount ? accountList : [selectedAccount];
                  for (const account of accounts) {
                    await updateAccountPersonalGameDataAccountInformation(account.steamId);
                  }
                }}
              >
                Reload
              </SimpleButton>
            </div>
            {!ObjectUtils.isBlank(personalGameDataAccountInformation) &&
              Object.keys(personalGameDataAccountInformation).map(function (label) {
                return (
                  <div key={label}>
                    <span className="font-bold">{label}: </span>
                    <span>{formatMyInfoData(label, personalGameDataAccountInformation[label])}</span>
                  </div>
                );
              })}
          </div>
          <div className="border border-solid border-l-indigo-950">
            <div className="flex gap-5 items-center">
              <h3 className="p-3">competitiveCooldownLevel</h3>
              <SimpleButton
                onClick={async () => {
                  const { updateAccountCompetitiveCooldownLevel, mergeAllAccount, accountList } = useStore.getState();
                  const accounts = mergeAllAccount ? accountList : [selectedAccount];
                  for (const account of accounts) {
                    await updateAccountCompetitiveCooldownLevel(account.steamId);
                  }
                }}
              >
                Reload
              </SimpleButton>
            </div>
            <div className="flex w-full border-x-0 border-b-0 border-t border-solid border-b-blue-500 p-3">
              {!!competitiveCooldownLevel &&
                Object.keys(competitiveCooldownLevel)
                  .filter((type) => (Array.isArray(competitiveCooldownLevel[type]) ? competitiveCooldownLevel[type].length : competitiveCooldownLevel[type]))
                  .map((type) => (
                    <div key={type} className="px-2">
                      <span className="italic">{type}</span>
                      <span>
                        {competitiveCooldownLevel[type]?.map?.(function (row) {
                          return Object.keys(row).map(function (label) {
                            return (
                              <div key={label}>
                                <span className="font-bold">{label}:</span> {formatMyInfoData(label, row[label])}
                              </div>
                            );
                          });
                        })}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      }

      <div className="py-2">
        {!!selectedAccount?.market_unavailable?.market_warning_header && (
          <div className="border border-solid border-l-indigo-950 p-3">
            <div className="flex items-center">
              <h3>
                <img className="w-5 h-5" src="https://community.akamai.steamstatic.com/public/images/economy/market/icon_alertlistings.png" />
                {selectedAccount.market_unavailable.market_warning_header}
              </h3>
              <SimpleButton onClick={fetchMarketUnavailable}>Reload</SimpleButton>
            </div>
            <ul>
              {editselectedAccount.market_unavailable.market_restrictions?.map?.(function (label, index) {
                return <li key={index}>{label}</li>;
              })}
            </ul>
            <div>{selectedAccount.market_unavailable.market_restriction_expire}</div>
          </div>
        )}
      </div>

      <div className="py-2">
        {scanQRHistory.map(function (item, index) {
          let moment1 = moment(dateFromObjectId(item._id));
          return (
            <div key={item._id}>
              <span>{index + 1}. </span>
              {moment1.fromNow()} | {moment1.format("HH:mm")}
              <img className="w-10 h-10" src={`https://cdn.discordapp.com/avatars/${item.author.id}/${item.author.avatar}.webp`} alt={item.author.username} />
              <span>{[item.author?.username, item.author?.global_name].filter(Boolean).join(" - ")}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
