import axios from "axios";
import { API_Mapping } from "./prototype";
import _ from "lodash";
import useStore from "../store";
import { v4 } from "uuid";
import { objectIdFromDate } from "../utils";
import Toast from "../toast";
import { openQuickMessage } from "../socket";

export const apiURL = window.location.href.includes("http://localhost") ? "http://localhost:8099" : `${window.location.protocol}//${process.env.REACT_APP_API_URL}`;
window.REACT_APP_RENDER_APPS = process.env.REACT_APP_RENDER_APPS.split(",");
window.getRandomRenderApp = () => `https://${window.REACT_APP_RENDER_APPS[Math.floor(Math.random() * window.REACT_APP_RENDER_APPS.length)]}.onrender.com`;

const api = axios.create({
  baseURL: apiURL,
  headers: {
    "Bypass-Tunnel-Reminder": "1", //bypass localtunnel headers
  },
});

function formatAPIMapping(mapping, path = []) {
  for (const key in mapping) {
    if (typeof mapping[key] === "function") {
      const url = [""].concat(path).concat(key).join("/");
      mapping[key] = async (data) => {
        if (data && typeof data === "object" && !Array.isArray(data) && Object.values(data).some((v) => v instanceof File || v instanceof Blob)) {
          try {
            const formData = new FormData();
            for (const key in data) {
              if (typeof data[key] !== "undefined") {
                formData.append(key, data[key]);
              }
            }
            data = formData;
          } catch (e) {}
        }

        const res = await api.request({
          url,
          data,
          method: "POST",
        });
        return res?.data;
      };
      mapping[key]._routePath = "";
    } else {
      if (typeof mapping[key] !== "string") {
        formatAPIMapping(mapping[key], path.concat(key));
      }
    }
  }
}

formatAPIMapping(API_Mapping);

API_Mapping.messageRead = async function messageRead({ _id: messageID }) {
  if (!window.messageReadParams) {
    window.messageReadParams = [];
  }
  window.messageReadParams.push(messageID);
  if (window.messageReadTimeout) {
    clearTimeout(window.messageReadTimeout);
  }
  window.messageReadTimeout = setTimeout(async function () {
    delete window.messageReadTimeout;
    const result = (
      await api.request({
        url: "/messageRead",
        method: "POST",
        data: window.messageReadParams.splice(0, window.messageReadParams.length),
      })
    )?.data;
    return result;
  }, 1000);
};

API_Mapping.updateLineImage = async function updateLineImage({ _id, isImage }) {
  if (!window.updateLineImageParams) {
    window.updateLineImageParams = [];
  }
  _.remove(window.updateLineImageParams, function (item) {
    return item._id === _id;
  });
  window.updateLineImageParams.push({
    _id,
    isImage,
  });
  if (window.updateLineImageTimeout) {
    clearTimeout(window.updateLineImageTimeout);
  }
  window.updateLineImageTimeout = setTimeout(async function () {
    delete window.updateLineImageTimeout;
    const result = (
      await api.request({
        url: "/updateLineImage",
        method: "POST",
        data: window.updateLineImageParams.splice(0, window.updateLineImageParams.length),
      })
    )?.data;
    return result;
  }, 1000);
};

API_Mapping.sendSteamMessage = async function sendSteamMessage({ receivers, messages, myAccount }) {
  const params = formatSteamMessageParams({ receivers, messages, myAccount });
  const result = (
    await api.request({
      url: "/sendSteamMessage",
      method: "POST",
      data: params,
    })
  )?.data;
  return result;
};

function formatSteamMessageParams({ receivers, messages, myAccount }) {
  if (!Array.isArray(receivers)) {
    receivers = [receivers];
  }
  if (!Array.isArray(messages)) {
    messages = [messages];
  }

  const { friendList, addNewMessage, accountList, roomList, isShowingChat } = useStore.getState();

  let channels = [];
  let friend = null;
  for (let i = 0; i < receivers.length; i++) {
    const receiver = receivers[i];
    let room = null;
    if (receiver.room) {
      room = receiver.room;
    } else if (receiver.roomId) {
      room = roomList.find((r) => r._id === receiver.roomId);
    } else if (receiver.friendId) {
      friend = friendList.find((f) => f._id === receiver.friendId);
      room = roomList.find((r) => r.recipients.some((recipient) => recipient._id === receiver.friendId));
    } else if (receiver.friendSteamId) {
      friend = friendList.find((friend) => friend.steamId === receiver.friendSteamId);
      room = roomList.find((r) => r.recipients.some((recipient) => recipient.steamId === receiver.friendSteamId));
    }
    if (room) {
      receivers[i] = {
        room: {
          ...room,
          messages: null,
          recipients: room.recipients.map((recipient) => _.pick(recipient, ["steamId", "_id"])),
        },
      };
      channels = [room];
    }
  }

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    if (typeof message === "string") {
      messages[i] = {
        content: message,
        clientId: v4(),
      };
    } else if (typeof message === "object") {
      messages[i] = {
        ...message,
        clientId: v4(),
      };
    }
  }

  if (channels[0]) {
    const receiver = channels[0].recipients[0];
    const author = accountList.find((a) => a.friendsIDList?.includes(receiver.steamId));
    const authorId = author ? friendList.find((friend) => friend.steamId === author.steamId)?._id : null;
    const channelIds = channels.map((channel) => channel._id || channel);

    addNewMessage(
      messages.map(function (message, index) {
        const timestamp = new Date().getTime() + index * 1000;

        return {
          ...message,
          _id: objectIdFromDate(timestamp),
          channel_ids: channelIds,
          author_id: authorId,
          receiver_id: receiver._id,
          timestamp,
          isMe: true,
          local: true,
        };
      }),
    );
  }

  friend = friend || receivers[0]?.room?.recipients[0];

  if (friend && !isShowingChat) {
    const message = messages[0];
    Toast.success({
      text: `${["Send Message", friend?.personaName]
        .filter(Boolean)
        .map((txt) => `[${txt}]`)
        .join(" ")}: ${message.content}`,
      duration: 30000,
      onClick() {
        openQuickMessage({
          steamId: friend.steamId,
          friend,
        });
      },
    });
  }

  return {
    receivers,
    messages,
    myAccount,
  };
}

export default API_Mapping;
