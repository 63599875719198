import React, { useCallback, useMemo, useState } from "react";
import MyAccountPicker from "../MyAccountPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddAccountModal from "./AddAccountModal";
import _ from "lodash";
import { useAccounts } from "../store";

const PrimeUpgrade = React.memo(() => {
  const [upgradeAccountSteamIdList, setUpgradeAccountSteamIdList] = useState([]);
  const { accountList: upgradeAccountList } = useAccounts(upgradeAccountSteamIdList);
  const [selectedAccount, setSelectedAccount] = useState();
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);

  const hideAddAccountModal = useCallback(() => {
    setShowAddAccountModal(false);
  }, []);

  const handleOnAccountAdded = useCallback(
    (steamIds) => {
      hideAddAccountModal();
      if (!steamIds.length) {
        return;
      }
      setUpgradeAccountSteamIdList(function (prevState) {
        return _.uniq([...prevState, ...steamIds]);
      });
    },
    [hideAddAccountModal],
  );

  return (
    <>
      <div className="p-2">
        <div className="flex items-center">
          <MyAccountPicker setSelectedAccount={setSelectedAccount} selectedAccount={selectedAccount} data={upgradeAccountList} />
          <FontAwesomeIcon icon={faPlus} className="mx-2 hover:cursor-pointer" size="lg" onClick={() => setShowAddAccountModal(true)} />
        </div>
      </div>
      {!!showAddAccountModal && <AddAccountModal onCancel={hideAddAccountModal} onOk={handleOnAccountAdded} ignoreAccountSteamId={upgradeAccountSteamIdList} />}
    </>
  );
});
export default PrimeUpgrade;
