import classNames from "classnames";
import React, { useId } from "react";
import "./Avatar.scss";

const Avatar = React.memo(function Avatar({ width, height, diameter, src, active = false, spin = false, hash, className, circle = true }) {
  let url = null;
  if (hash) {
    url = `https://avatars.akamai.steamstatic.com/${hash === "0000000000000000000000000000000000000000" ? "fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb" : hash}_full.jpg`;
  } else {
    url = src;
  }
  url = url?.replace("https://avatars.akamai.steamstatic.com/", "https://avatars.cloudflare.steamstatic.com/").replace("https://cdn.akamai.steamstatic.com/", "https://cdn.cloudflare.steamstatic.com/");

  if (diameter) {
    width = height = diameter;
  }
  const style = {
    height,
    width,
  };
  const halfWidth = typeof width === "string" && width.endsWith?.("%") ? parseInt(width) / 2 + "%" : width / 2;
  const halfHeight = typeof height === "string" && height.endsWith?.("%") ? parseInt(height) / 2 + "%" : height / 2;
  const maskID = useId();

  return (
    <React.Fragment>
      <div
        className={classNames(className, {
          "image-circle": spin,
          contents: !spin && circle,
        })}
        style={style}
      >
        <svg className="AvatarRoot w-full h-full">
          {!!circle && (
            <mask id={maskID}>
              <circle cx={halfWidth} cy={halfHeight} fill="white" r={halfWidth} />
            </mask>
          )}
          <g mask={`url(#${maskID})`}>
            <image style={style} x={0} y={0} height="100%" preserveAspectRatio="xMidYMid slice" width="100%" xlinkHref={url || "https://i.imgur.com/1ok8iWq.jpg"} />
            {!!circle && (
              <circle
                className={classNames("mlqo0dh0 s6kb5r3f", {
                  georvekb_active: active,
                  georvekb: !active,
                })}
                cx={halfWidth}
                cy={halfHeight}
                r={halfWidth}
              />
            )}
          </g>
        </svg>
      </div>
    </React.Fragment>
  );
});
export default Avatar