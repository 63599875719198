import React, { forwardRef } from "react";
import "./ChatLineTextMine.scss";
import moment from "moment/moment";
import Avatar from "chat/Avatar";
import { Image } from "antd";
import { copy2Clipboard, isSteamImgUrl } from "utils";
import useStore, { useAccountBySteamId, useFriend } from "store";
import classNames from "classnames";

const ChatLineTextMine = React.memo(
  forwardRef(function ChatLineTextMine({ line, room, index }, ref) {
    const messageEl = isSteamImgUrl(line.content) || line.isImage ? <Image className="w-full" src={line.content} /> : <span>{line.content}</span>;
    const author = useFriend(line.author_id);
    const authorAccount = useAccountBySteamId(author?.steamId);

    return (
      <React.Fragment key={"Line" + line.timestamp}>
        <div
          className="ChatLineTextMine"
          ref={ref}
          onClick={function (event) {
            if (event.detail === 2 || event.detail === 3) {
              copy2Clipboard(line.content);
            }
          }}
        >
          <div className="flex items-center justify-end box-border pt-3 pr-3 relative overflow-x-hidden pb-2 overflow-y-hidden pl-3">
            <div className="flex justify-center items-center" onClick={() => useStore.getState().setSelectedAccountID(author.steamId)}>
              <span className="w-6 h-6 block">
                <Avatar diameter={"100%"} hash={authorAccount?.avatarHash || author?.avatarHash} />
              </span>
              <span className="text-xs leading-3">{[authorAccount?.personaName || author?.personaName, authorAccount?.username].filter(Boolean).join(" - ")}</span>
            </div>
            &nbsp;&nbsp;
            <div className="text-xs leading-3">{moment(line.timestamp).fromNow()}</div>
            &nbsp;&nbsp;
            <div
              className={classNames("text-white relative rounded-xl rounded-br-none p-2", {
                "bg-blue-500": !line.local,
                "bg-blue-300": line.local,
              })}
            >
              {messageEl}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }),
);

export default ChatLineTextMine;
