import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/reset.css";
import "toastify-js/src/toastify.css";
import "@szhsin/react-menu/dist/index.css";
import _ from "lodash";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
);

reportWebVitals();


// eslint-disable-next-line no-extend-native
Array.prototype.includesEqual = function (obj) {
    return this.some(item => _.isEqual(obj, item))
}
