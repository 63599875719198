import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getInviteName } from "socket";
import "./SendingInput.scss";
import _ from "lodash";
import { DatePicker, Dropdown, InputNumber, Select, Spin } from "antd";
import dayjs from "dayjs";
import useStore from "store";
import Toast from "toast";
import api from "api";
import { useAccountByCompare } from "../../store/UserSlice";
import { getClipboardImage } from "../../myaccount/ApproveLoginQRModal";
import { LoadingOutlined } from "@ant-design/icons";
import FriendPicker from "../../FriendPicker";
import SteamAvatar from "../../components/SteamAvatar";

const steamhappyList = ["😊", "😄", "😀", "😃", "😄", "😁", "😆", "😅", "🤣", "😂", "🤗", "🤭", ":steamhappy:"];
const whenOptions = [
  {
    value: "Ingame",
    label: <span className="text-emerald-500">Ingame</span>,
  },
  {
    value: "Reply",
    label: <span className="text-indigo-700">Reply</span>,
  },
];

export default function SendingInput({ selectedMyAccountInputRef }) {
  const ref = React.createRef();
  const [imageData, setImageData] = useState();
  const [isSendingImage, setIsSendingImage] = useState();
  const isSendingImageRef = useRef();
  const [count, setCount] = useState(1);
  const [delay, setDelay] = useState(0); //minutes
  const [scheduleAt, setScheduleAt] = useState(dayjs()); //time HH:mm
  const [whenJustInGame, setWhenJustInGame] = useState(false); //time HH:mm
  const [whenReply, setWhenReply] = useState(false); //time HH:mm
  const selectedRoomID = useStore((state) => state.selectedRoomID);
  const [selectedWatcher, setSelectedWatcher] = useState({});

  const roomList = useStore((state) => state.roomList);
  const recipientSteamId = roomList.find((r) => r._id === selectedRoomID).recipients?.[0]?.steamId;
  const accountListFriendCompareFn = useCallback(
    (account) => {
      return recipientSteamId && account.friendsIDList?.includes?.(recipientSteamId);
    },
    [recipientSteamId],
  );
  const accountListFriend = useAccountByCompare(accountListFriendCompareFn, false);

  const myAccountOptions = useMemo(() => {
    return accountListFriend.map(function (a, index) {
      return {
        label: (
          <span className="flex gap-5">
            <SteamAvatar avatarHash={a?.avatarHash} className="w-6 h-6" />
            <span>{a.username + " - " + a.personaName}</span>
          </span>
        ),
        value: a.steamId,
      };
    });
  }, [accountListFriend]);
  const [selectedMyAccountInput, setSelectedMyAccountInput] = useState(myAccountOptions[0]?.value);

  useEffect(() => {
    selectedMyAccountInputRef.current = null;
    setSelectedMyAccountInput(function (prev) {
      if (myAccountOptions.some((a) => a.value === prev)) {
        return prev;
      }
      return myAccountOptions[0]?.value;
    });
  }, [myAccountOptions]);

  const handleOnWhenOptionChange = useCallback((value) => {
    switch (value) {
      case "Ingame":
        setWhenJustInGame(true);
        setWhenReply(false);
        break;
      case "Reply":
        setWhenJustInGame(false);
        setWhenReply(true);
        break;
      default:
        setWhenJustInGame(false);
        setWhenReply(false);
    }
  }, []);

  useEffect(() => {
    api.getSteamPlayerGroup().then(function (result) {
      if (!Array.isArray(result?.data)) return;
      setSelectedWatcher(
        result.data.reduce(function (prev, current) {
          prev[current.watcherSteamId] = current.steam_player_group;
          return prev;
        }, {}),
      );
    });
  }, []);

  const handleKeyDown = useCallback(
    async (rawInput) => {
      const current = ref.current;
      const messageStr = rawInput || current.innerText?.trim();
      if (!messageStr) {
        return;
      }
      if (current) {
        current.value = "";
        current.innerText = "";
        current.focus();
      }

      let _scheduleAt = scheduleAt?.valueOf?.(); //(milliseconds)
      if (!_scheduleAt || _scheduleAt < dayjs().valueOf()) {
        if (delay) {
          _scheduleAt = dayjs().add(delay, "minutes").valueOf();
        } else {
          _scheduleAt = null;
        }
      } else {
        if (delay) {
          _scheduleAt = scheduleAt.add(delay, "minutes").valueOf();
        }
      }

      const { selectedRoomID, roomList } = useStore.getState();
      const selectedRoom = roomList.find((r) => r._id === selectedRoomID);
      if (!selectedRoom) {
        return Toast.error("Cannot find this room " + selectedRoomID);
      }

      api.sendSteamMessage({
        messages: (typeof messageStr === "string"
          ? messageStr
              .split("\n")
              .map((m) => m?.trim())
              .filter(Boolean)
          : messageStr
        ).map(function (content) {
          return {
            content,
            scheduleAt: _scheduleAt,
            whenJustInGame: !!whenJustInGame,
            whenReply: !!whenReply,
            count,
          };
        }),
        receivers: {
          room: _.omit(selectedRoom, ["messages", "last_message"]),
        },
        myAccount: selectedMyAccountInputRef.current,
      });
    },
    [ref, scheduleAt, selectedMyAccountInputRef, delay, whenJustInGame, whenReply, count],
  );

  const sendImageMessage = useCallback(async (imageData) => {
    if (!imageData || isSendingImageRef.current) return;

    const { selectedRoomID, roomList } = useStore.getState();
    const selectedRoom = roomList.find((r) => r._id === selectedRoomID);
    if (!selectedRoom) {
      return Toast.error("Cannot find this room " + selectedRoomID);
    }
    const recipient = selectedRoom.recipients[0];

    setIsSendingImage(true);
    isSendingImageRef.current = true;
    const result = (
      await api.sendImageMessage({
        friendSteamID: recipient.steamId,
        friendID: recipient._id,
        file: imageData.blob,
      })
    )?.data;

    isSendingImageRef.current = false;
    setIsSendingImage(false);
    setImageData(null);

    if (result?.success !== 1) {
      Toast.error(result?.message || "Send image message error");
    } else {
      Toast.success(result?.error || "Send image message success");
      console.log(result);
      api.fetchChatHistory({ roomId: selectedRoomID }).then(function (result) {
        useStore.getState().addNewMessage(result?.data);
      });
    }
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      if (event.shiftKey) {
        return;
      }
      switch (event.code) {
        case "Enter":
          event.preventDefault();
          if (imageData) {
            sendImageMessage(imageData);
          } else {
            handleKeyDown();
          }
          break;
        case "Escape":
          setImageData(null);
          break;
      }
    },
    [handleKeyDown, imageData, sendImageMessage],
  );

  const onClickRefuse = useCallback(async () => {
    const { selectedRoomID, roomList } = useStore.getState();
    const steamId = roomList.find((r) => r._id === selectedRoomID)?.recipients?.[0]?.steamId;
    if (!steamId) {
      return;
    }
    const inviteName = getInviteName(steamId) || _.sample(["bạn", "bạn ơi", ""]);
    const msg = `đang ${_.sample(["bận", "bắn", "chơi", "trong game", "trong trận"])} rồi ${inviteName}`.trim();
    await handleKeyDown([msg, steamhappyList[Math.floor(Math.random() * steamhappyList.length)]]);
  }, [handleKeyDown]);

  const onClickJoinCustomInstructions = useCallback(async () => {
    await handleKeyDown([`${_.sample(["Hướng dẫn", "Cách"])} ${_.sample(["vào", "chơi", "vô", "join", "tham gia"])} custom ${_.sample(["nha", "nhé"])}`, `1. Tham gia nhóm ♔⌒Natri trên steam: https://steamcommunity.com/groups/natri2099`, `2. Chuyển sang tab xếp trận riêng tư (private queue)`, `https://i.imgur.com/IlCjqyH.png`, `https://i.imgur.com/IYavKln.png`, `3. Click vào nút Join your Steam Group's Pool`, `https://i.imgur.com/15iEiBo.png`, `4. Chọn group Natri rồi nhấn submit`, `https://i.imgur.com/8IFPb5s.png`, `https://i.imgur.com/EBroUVR.png`, `Chờ 1 lúc là vào`]);
  }, [handleKeyDown]);

  const emojiList = [["❤️", "🥰", "😍", "🤩", "😘"], ["😋", "😛", "😜", "🤪", "😝"], "💯", "😎", "😭", steamhappyList, "👋", ["👍", ":steamthumbsup:"], "💲", "✔", ["ok", "oke", "oki", "okie", "okay", "okok"]].map((emoji) => (Array.isArray(emoji) ? emoji : [emoji]));

  useEffect(() => {
    const input = ref.current;
    if (!input) {
      return;
    }

    const listener = async function (evt) {
      const result = await getClipboardImage(evt);
      console.log(result);
      if (!result) {
        return;
      }
      evt.preventDefault();
      setImageData(result);
    };

    input.addEventListener("paste", listener);

    return () => {
      input.removeEventListener("paste", listener);
    };
  }, [ref]);

  return (
    <React.Fragment>
      <div className="SendingInputRoot border border-solid border-slate-300 border-l-0 border-r-0 border-b-0">
        <div>
          {!!imageData && (
            <div className="absolute flex items-center justify-center top-100 -translate-y-full z-10 border-4 border-green-500 border-solid shadow-lg shadow-cyan-500/50">
              <img
                onLoad={function (event) {
                  event.target.parentNode.style.width = event.target.width + "px";
                  event.target.parentNode.style.height = event.target.height + "px";
                }}
                src={imageData.fileAsDataURL}
                className="w-full h-full"
              />
              {!!isSendingImage && (
                <div className="absolute w-full h-full flex items-center justify-center bg-gray-200/80">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 48,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex justify-start items-center p-0.5 py-2 gap-x-2">
            <span className="flex items-center gap-2">
              <span className="text-sm">Count</span>
              <InputNumber rootClassName="w-20" value={count} onChange={(value) => setCount(value)} />
            </span>
            <span className="flex items-center gap-2">
              <span className="text-sm">Watch group</span>
              <FriendPicker
                selectedFriend={selectedWatcher[recipientSteamId]}
                setSelectedFriend={function (newValue) {
                  api.watchSteamPlayerGroup({ steam_player_group: newValue, watcherSteamId: recipientSteamId });
                  setSelectedWatcher(function (prev) {
                    return {
                      ...prev,
                      [recipientSteamId]: newValue,
                    };
                  });
                }}
              />
            </span>
            <span className="flex items-center gap-2">
              <span className="text-sm">Accounts</span>
              <Select
                className="w-48"
                options={myAccountOptions}
                value={selectedMyAccountInput}
                onChange={function (value) {
                  selectedMyAccountInputRef.current = value;
                  setSelectedMyAccountInput(value);
                }}
                disabled={myAccountOptions.length < 2}
              />
            </span>
          </div>

          <div className="flex justify-start items-center p-0.5 py-2 gap-x-2">
            <span className="flex items-center">
              <Dropdown
                placement="top"
                menu={{
                  items: ["20h today"].map((k) => ({ key: k, label: k })),
                  onClick: (e) => {
                    switch (e.key) {
                      case "20h today": {
                        setScheduleAt(dayjs("20", "hh"));
                        Toast.success("20h today");
                        break;
                      }
                      default:
                    }
                  },
                }}
                trigger={["contextMenu"]}
              >
                <span className="text-sm" onClick={() => setScheduleAt(dayjs().add(5, "minutes"))}>
                  Schedule at
                </span>
              </Dropdown>
              <DatePicker format="YYYY-MM-DD HH:mm" value={scheduleAt} onChange={(value) => setScheduleAt(value)} disableDate={(date) => date.isBefore(dayjs())} showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }} />
            </span>
            <span className="flex items-center">
              <Dropdown
                placement="top"
                menu={{
                  items: ["after 30 minutes", "after 1 hours", "after 2 hours"].map((k) => ({
                    key: k,
                    label: k,
                  })),
                  onClick: (e) => {
                    switch (e.key) {
                      case "after 30 minutes": {
                        setDelay(30);
                        break;
                      }
                      case "after 1 hours": {
                        setDelay(60);
                        break;
                      }
                      case "after 2 hours": {
                        setDelay(120);
                        break;
                      }
                      default:
                    }
                  },
                }}
                trigger={["contextMenu"]}
              >
                <span className="text-sm">Delay(minutes)</span>
              </Dropdown>
              <InputNumber rootClassName="w-20" placeholder="delay seconds" value={delay} onChange={(value) => setDelay(value)} />
            </span>
            <span>
              <Select className="w-32" placeholder="When to sent" onChange={handleOnWhenOptionChange} options={whenOptions} allowClear />
            </span>
          </div>

          <div className="flex justify-start items-center p-0.5 py-2 gap-x-2">
            {emojiList.map((e, index) => (
              <span
                key={index}
                className="cursor-pointer"
                onClick={function () {
                  handleKeyDown(_.sample(e));
                }}
              >
                {e[0]}
              </span>
            ))}
          </div>
          <div className="j83agx80 aovydwv3 pybr56ya f10w8fjw jq4qci2q">
            <div className="l9j0dhe7 buofh1pr">
              <div className="pmk7jnqg i09qtzwb j83agx80 taijpn5t bp9cbjyn tkr6xdv7 oygrvhab qpbg1qsm flx89l3n mb8dcdod lbhrjshz" style={{ left: "0px" }}>
                <input className="mkhogb32" multiple type="file" />
                <span>
                  <div aria-label="Attach a file" className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 mg4g778l pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb n00je7tq arfg74bv qs9ysxi8 k77z8yql pq6dq46d btwxx1t3 abiwlrkh p8dawk7l lzcic4wl cxgpxx05 dflh9lhu sj5x9vvc scb9dxdr">
                    <svg viewBox="0 0 36 36" height="28px" width="28px" className="a8c37x1j muag1w35 dlv3wnog enqfppq2 rl04r1d5 ms05siws hr662l2t b7h9ocf4 crt8y2ji">
                      <path d="M13.5 16.5a2 2 0 100-4 2 2 0 000 4z" fill="#0084ff" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M7 12v12a4 4 0 004 4h14a4 4 0 004-4V12a4 4 0 00-4-4H11a4 4 0 00-4 4zm18-1.5H11A1.5 1.5 0 009.5 12v9.546a.25.25 0 00.375.217L15 18.803a6 6 0 016 0l5.125 2.96a.25.25 0 00.375-.217V12a1.5 1.5 0 00-1.5-1.5z" fill="#0084ff" />
                    </svg>
                    <div className="i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s s45kfl79 emlxlaya bkmhp75w spb7xbtv" data-visualcompletion="ignore" />
                  </div>
                </span>
              </div>
              <div className="pmk7jnqg i09qtzwb j83agx80 taijpn5t bp9cbjyn tkr6xdv7 oygrvhab qpbg1qsm flx89l3n mb8dcdod awe8hfj4" style={{ left: "36px" }}>
                <span>
                  <div aria-label="Choose a sticker" className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 mg4g778l pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb n00je7tq arfg74bv qs9ysxi8 k77z8yql pq6dq46d btwxx1t3 abiwlrkh p8dawk7l lzcic4wl cxgpxx05 dflh9lhu sj5x9vvc scb9dxdr">
                    <svg viewBox="0 0 36 36" height="28px" width="28px" className="a8c37x1j muag1w35 dlv3wnog enqfppq2 rl04r1d5 ms05siws hr662l2t b7h9ocf4 crt8y2ji">
                      <path d="M8 12a4 4 0 014-4h12a4 4 0 014 4v5a1 1 0 01-1 1h-3a6 6 0 00-6 6v3a1 1 0 01-1 1h-5a4 4 0 01-4-4V12z" fill="#0084ff" />
                      <path d="M20 27c0 .89 1.077 1.33 1.707.7l5.993-5.993c.63-.63.19-1.707-.7-1.707h-3a4 4 0 00-4 4v3z" fill="#0084ff" />
                    </svg>
                    <div className="i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s s45kfl79 emlxlaya bkmhp75w spb7xbtv" data-visualcompletion="ignore" />
                  </div>
                </span>
              </div>
              <div className="pmk7jnqg i09qtzwb j83agx80 taijpn5t bp9cbjyn tkr6xdv7 oygrvhab qpbg1qsm flx89l3n mb8dcdod lxbcnve3" style={{ left: "72px" }}>
                <span>
                  <div aria-label="Choose a GIF" className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 mg4g778l pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb n00je7tq arfg74bv qs9ysxi8 k77z8yql pq6dq46d btwxx1t3 abiwlrkh p8dawk7l lzcic4wl cxgpxx05 dflh9lhu sj5x9vvc scb9dxdr">
                    <svg viewBox="0 0 36 36" height="28px" width="28px" className="a8c37x1j muag1w35 dlv3wnog enqfppq2 rl04r1d5 ms05siws hr662l2t b7h9ocf4 crt8y2ji">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 11a4 4 0 014-4h8c1.067 0 2.035.417 2.753 1.098.517.491 1.151.902 1.866.902H26a4 4 0 014 4v12a4 4 0 01-4 4h-8a3.986 3.986 0 01-2.752-1.098c-.518-.491-1.152-.902-1.866-.902H10a4 4 0 01-4-4V11zm7.865 4.908a1.948 1.948 0 00-1.321-.456c-.461.02-.918.214-1.295.576-.378.363-.65.873-.754 1.457a2.927 2.927 0 00.209 1.708c.236.52.611.915 1.046 1.14a1.87 1.87 0 001.36.152c.454-.122.88-.419 1.195-.868.098-.14.183-.291.253-.451.068-.154-.052-.316-.22-.316H12.85a.85.85 0 010-1.7h2.8c.47 0 .85.38.85.85a4.53 4.53 0 01-.803 2.593c-.527.75-1.277 1.3-2.144 1.534a3.57 3.57 0 01-2.586-.285c-.8-.414-1.43-1.107-1.811-1.947a4.628 4.628 0 01-.335-2.706 4.357 4.357 0 011.25-2.388 3.697 3.697 0 012.398-1.048 3.647 3.647 0 012.472.838.85.85 0 01-1.076 1.317zM22.7 19.6a.25.25 0 01.25-.25h2.75a.85.85 0 000-1.7h-2.75a.25.25 0 01-.25-.25v-1.45a.25.25 0 01.25-.25h3.2a.85.85 0 100-1.7h-4.3a.85.85 0 00-.85.85v6.3a.85.85 0 001.7 0V19.6zm-3.35-4.75a.85.85 0 00-1.7 0v6.3a.85.85 0 001.7 0v-6.3z"
                        fill="#0084ff"
                      />
                    </svg>
                    <div className="i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s s45kfl79 emlxlaya bkmhp75w spb7xbtv" data-visualcompletion="ignore" />
                  </div>
                </span>
              </div>
              <div className="buofh1pr j83agx80 lfvz1hxj" style={{ marginLeft: "8px" }}>
                <div className="b3i9ofy5 orhb3f3m czkt41v7 fmqxjp7s emzo65vh rq0escxv buofh1pr hpfvmrgz">
                  <div className="j83agx80 lhclo0ds bkfpd7mw">
                    <div className="j83agx80 buofh1pr nhadk0th ozuftl9m aov4n071 bi6gxh9e hpfvmrgz l9j0dhe7">
                      <div
                        id="chat_sending_input"
                        ref={ref}
                        onKeyDown={onKeyDown}
                        aria-describedby="jsc_c_16"
                        aria-label="Message"
                        className="oo9gr5id lzcic4wl l9j0dhe7 gsox5hk5 buofh1pr tw4czcav cehpxlet hpfvmrgz eg9m0zos notranslate"
                        contentEditable="true"
                        spellCheck="true"
                        style={{
                          userSelect: "text",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                        data-lexical-editor="true"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputButtons">
              <span aria-label="Join custom instructions" onClick={onClickJoinCustomInstructions} />
              <span aria-label="Refuse" onClick={onClickRefuse} />
              <span aria-label="Smile" onClick={() => handleKeyDown("https://i.imgur.com/7BB56dp.png")} />
              <span aria-label="Press Enter to send" onClick={() => handleKeyDown()} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
