import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebounce, useLocalStorage } from "@uidotdev/usehooks";
import "./SearchPeople.scss";
import SearchPeopleResult from "./SearchPeopleResult/SearchPeopleResult";
import classNames from "classnames";
import api from "api";
import useStore from "store";
import { Select } from "antd";
import Toast from "toast";
import { getPersonaName } from "../../socket";
import { StringUtils } from "alpha-common-utils/index.js";
import Swal from "sweetalert2";
import _ from "lodash";
import { InfiniteLoader, List } from "react-virtualized";

const Chat_Filter_List = ["prime", "non-prime", "unread", "sent", "empty", "mute"];
const Chat_Filter_List_Option = Chat_Filter_List.map((op) => ({
  label: op,
  value: op,
}));

export default function SearchPeople() {
  const setIsGroupChats = useStore((state) => state.setIsGroupChats);
  const isGroupChat = useStore((state) => state.isGroupChat);
  const roomList = useStore((state) => state.roomList);
  const roomFilter = useStore((state) => state.roomFilter);
  const setRoomFilter = useStore((state) => state.setRoomFilter);
  const noMoreRoomList = useStore((state) => state.noMoreRoomList);
  const tempGroup = useStore((state) => state.tempGroup);
  const [listWidth, setListWidth] = useLocalStorage("ChatRoomList_Width", 100);
  const [listHeight, setListHeight] = useLocalStorage("ChatRoomList_Height", 500);

  const [term, setTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(term, 500);

  const doCancelSearching = useCallback(() => {
    setTerm("");
    setIsSearching(false);
  }, []);

  const createNewRoom = useCallback(async (toUser) => {
    doCancelSearching();

    if (toUser.recipients) {
      useStore.getState().setSelectedRoomID(toUser._id);
    } else {
      let room = useStore.getState().roomList.find((_room) => _room.recipients.some((recipient) => recipient._id === toUser._id));
      if (!room) {
        const result = await api.findOrCreateChatRoom({
          steamId: toUser.steamId,
        });
        room = result?.data?.room;
      }
      if (room) {
        useStore.getState().updateRoomList(room, room._id);
      } else {
        Toast.error("Cannot find this room " + getPersonaName(toUser.steamId));
      }
    }
  }, []);

  const onFocus = useCallback((event) => {
    setIsSearching(true);
  }, []);

  const onBlur = useCallback((event) => {
    // doCancelSearching()
  }, []);

  const onChange = useCallback((event) => {
    setTerm(event.target.value);
  }, []);

  const searchingRoomList = useMemo(() => {
    const friendList = useStore.getState().friendList;
    let query = debouncedSearchTerm?.trim();
    if (!query) {
      return friendList;
    }
    const friendsInfo = useStore.getState().friendsInfo;
    return friendList.filter(function (room) {
      if (!query) {
        return true;
      }

      if (StringUtils.toNonAccentVietnameseLowerCase(room.realName).includes(query)) {
        return true;
      }

      if (StringUtils.toNonAccentVietnameseLowerCase(room.personaName).includes(query)) {
        return true;
      }

      if (room.steamId.includes(query)) {
        return true;
      }

      if (StringUtils.toNonAccentVietnameseLowerCase(friendsInfo[room.steamId]?.facebook).includes(query)) {
        return true;
      }

      return false;
    });
  }, [debouncedSearchTerm]);

  const currentRoomList = useMemo(() => {
    const filters = [];

    filters.push(function (room) {
      if (room._id === tempGroup?._id) {
        return true;
      }

      if (roomFilter === "empty") {
        return !room.messages?.length;
      } else if (roomFilter === "mute") {
        return room.mute;
      } else {
        if (!room.messages?.length) {
          return false;
        }
        if (room.mute) {
          return false;
        }

        switch (roomFilter) {
          case "prime":
            return room.recipients.some((recipient) => recipient.primeStatus === "prime");
          case "non-prime":
            return room.recipients.some((recipient) => recipient.primeStatus === "non-prime");
          case "unread":
            return !room.messages[0]?.isMe;
          case "sent":
            return !!room.messages[0]?.isMe;
          default:
            return true;
        }
      }
    });

    if (isGroupChat) {
      filters.push(function (room) {
        return room.recipients.length > 1 || room._id === tempGroup?._id;
      });
    } else {
      filters.push(function (room) {
        return room.recipients.length === 1 && room._id !== tempGroup?._id;
      });
    }

    return roomList.filter(function (room) {
      return filters.every((filter) => filter(room));
    });
  }, [isGroupChat, roomList, roomFilter, tempGroup]);

  useEffect(() => {
    if (!Array.isArray(currentRoomList) || currentRoomList.length > 20) {
      return;
    }
    const roomFilter = useStore.getState().roomFilter;
    let params;
    switch (roomFilter) {
      case "mute":
        params = {
          mute: true,
          empty: false,
        };
        break;
      case "empty":
        params = {
          mute: false,
          empty: true,
        };
        break;
      case "prime":
      case "non-prime":
        params = {
          prime: roomFilter,
          mute: false,
          empty: false,
        };
        break;
      case "unread":
        //unread messages
        params = {
          mute: false,
          empty: false,
        };
        break;
      default:
        params = {
          empty: false,
          mute: false,
        };
    }

    if (params) {
      useStore.setState({ noMoreRoomList: false });
      if (!window.getRoomListParams) {
        window.getRoomListParams = [];
      }
      if (window.getRoomListParams.every((_params) => !_.isEqual(_params, params))) {
        window.getRoomListParams.push(params);
        api.getRoomList(params).then(function (result) {
          _.remove(window.getRoomListParams, function (_param) {
            return _.isEqual(_param, params);
          });
          if (Array.isArray(result.data)) {
            useStore.getState().updateRoomList(result.data);
          }
        });
      }
    }
  }, [currentRoomList]);

  const list = useMemo(() => {
    if (isSearching) {
      return searchingRoomList;
    } else {
      return currentRoomList;
    }
  }, [isSearching, searchingRoomList, currentRoomList]);

  const renderRow = useCallback(
    ({ index, key, style }) => {
      const data = list[index] || {};
      const prime = data.primeStatus || useStore.getState().friendList.find(({ steamId }) => steamId === (data.steamId || data.recipients?.[0]?.steamId))?.primeStatus;
      return <SearchPeopleResult key={data._id} style={style} result={data} prime={prime} index={index} onClick={createNewRoom} />;
    },
    [createNewRoom, list],
  );

  const handleOnClickNewGroup = useCallback(() => {
    Swal.fire({
      title: "Submit your Group Name",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        useStore.getState().createTempGroup(result.value);
      }
    });
  }, []);

  useEffect(() => {
    const chatRoomListId = document.getElementById("chatRoomListId");
    if (!chatRoomListId) {
      return;
    }
    setListWidth(chatRoomListId.offsetWidth);
    setListHeight(chatRoomListId.offsetHeight);
  }, [setListHeight, setListWidth]);

  const loadMoreRows = useCallback(async ({ startIndex, stopIndex }) => {
    if (window.loadMoreRows) {
      return;
    }

    window.loadMoreRows = true;
    const { roomFilter, roomList, updateRoomList } = useStore.getState();

    let params;
    switch (roomFilter) {
      case "mute":
        params = {
          mute: true,
          empty: false,
        };
        break;
      case "empty":
        params = {
          mute: false,
          empty: true,
        };
        break;
      case "prime":
      case "non-prime":
        params = {
          prime: roomFilter,
          mute: false,
          empty: false,
        };
        break;
      case "unread":
        //unread messages
        params = {
          mute: false,
          empty: false,
        };
        break;
      default:
        params = {
          empty: false,
          mute: false,
        };
    }

    const result = await api.getRoomList({
      ignoreRoomIds: roomList.map((room) => room._id),
      ...params,
      limit: stopIndex - startIndex,
    });
    if (Array.isArray(result.data)) {
      if (result.data.length) {
        updateRoomList(result.data);
      } else {
        useStore.setState({ noMoreRoomList: true });
      }
    }
    delete window.loadMoreRows;
  }, []);

  const isRowLoaded = useCallback(
    ({ index }) => {
      return !!list[index];
    },
    [list],
  );

  return (
    <React.Fragment>
      <div className="SearchPeopleRoot">
        <div className="flex-col box-content flex w-full top-0 relative h-full min-h-full">
          <div className="items-center flex-row ml-4 z-0 justify-between flex mt-5 relative mb-3 flex-no-wrap flex-shrink-0 box-border mr-4">
            <div className="w-full">
              <div className="items-center flex-row z-0 justify-between flex relative flex-no-wrap flex-shrink-0 box-border">
                <div
                  className={classNames("rounded-full cursor-pointer items-center box-border p-2 ", {
                    "font-bold bg-sky-200 hover:bg-sky-300 text-blue-600": !isGroupChat,
                    "hover:bg-zinc-200": isGroupChat,
                  })}
                  onClick={() => setIsGroupChats(false)}
                >
                  Chats
                </div>
                <div
                  className={classNames("rounded-full cursor-pointer items-center box-border p-2 ", {
                    "font-bold bg-sky-200 hover:bg-sky-300 text-blue-600": isGroupChat,
                    "hover:bg-zinc-200": !isGroupChat,
                  })}
                  onClick={() => setIsGroupChats(true)}
                >
                  Group Chats
                </div>
                {!isGroupChat && (
                  <div>
                    <Select allowClear value={roomFilter} className="w-32" options={Chat_Filter_List_Option} onChange={setRoomFilter} placeholder="Filter"></Select>
                  </div>
                )}
                <div className="rq0escxv l9j0dhe7 du4w35lb cbu4d94t d2edcug0 hpfvmrgz aovydwv3 j83agx80 dz1kfvuc kb5gq1qc pfnyh3mw taijpn5t b0upgy8r">
                  <div className="j83agx80 pfnyh3mw">
                    <div className="ozuftl9m">
                      <div aria-label="Create New Room" className="oajrlxb2 qu0x051f esr5mh6w e9989ue4 r7d6kgcz nhd2j8a9 p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x i1ao9s8h esuyzwwr f1sip0of abiwlrkh p8dawk7l lzcic4wl bp9cbjyn s45kfl79 emlxlaya bkmhp75w spb7xbtv rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv j83agx80 taijpn5t jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso l9j0dhe7 tv7at329 thwo4zme tdjehn4e">
                        <svg viewBox="0 0 36 36" className="a8c37x1j ms05siws l3qrxjdp b7h9ocf4 moj8do2t" fill="currentColor" height={28} width={28}>
                          <path clipRule="evenodd" d="M5 13.5a4 4 0 014-4h10a4 4 0 014 4v9a4 4 0 01-4 4H9a4 4 0 01-4-4v-9zm8 0a1 1 0 112 0v3.25c0 .138.112.25.25.25h3.25a1 1 0 110 2h-3.25a.25.25 0 00-.25.25v3.25a1 1 0 11-2 0v-3.25a.25.25 0 00-.25-.25H9.5a1 1 0 110-2h3.25a.25.25 0 00.25-.25V13.5z" fillRule="evenodd" />
                          <path d="M29.552 23.393l-3.723-1.861A1.5 1.5 0 0125 20.19v-4.38a1.5 1.5 0 01.829-1.342l3.723-1.861A1 1 0 0131 13.5v9a1 1 0 01-1.448.894z" />
                        </svg>
                        <div className="i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s s45kfl79 emlxlaya bkmhp75w spb7xbtv" data-visualcompletion="ignore" />
                      </div>
                    </div>
                    <div className="ozuftl9m">
                      <div title="New message" className="no-underline pl-0 items-center mr-0 ml-0 flex pt-0 mt-0 relative cursor-pointer mb-0 pb-0 box-border pr-0 justify-center w-8 h-8" onClick={handleOnClickNewGroup}>
                        <svg viewBox="0 0 36 36" className="a8c37x1j ms05siws l3qrxjdp b7h9ocf4 rs22bh7c" fill="currentColor" height={28} width={28}>
                          <path d="M17.305 16.57a1.998 1.998 0 00-.347.467l-1.546 2.87a.5.5 0 00.678.677l2.87-1.545c.171-.093.328-.21.466-.347l8.631-8.631a1.5 1.5 0 10-2.121-2.122l-8.631 8.632z" />
                          <path d="M18 10.5a1 1 0 001-1V9a1 1 0 00-1-1h-6a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4v-6a1 1 0 00-1-1h-.5a1 1 0 00-1 1v6a1.5 1.5 0 01-1.5 1.5H12a1.5 1.5 0 01-1.5-1.5V12a1.5 1.5 0 011.5-1.5h6z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search_input_wrap">
            {isSearching && (
              <div onClick={doCancelSearching} className="thwo4zme" data-visualcompletion="ignore">
                <div className="Exit_typeahead">
                  <svg fill="currentColor" viewBox="0 0 20 20" width="1em" height="1em" className="a8c37x1j ms05siws l3qrxjdp b7h9ocf4 py1f6qlh jnigpg78 odw8uiq3">
                    <g fillRule="evenodd" transform="translate(-446 -350)">
                      <g fillRule="nonzero">
                        <path d="M100.249 201.999a1 1 0 0 0-1.415-1.415l-5.208 5.209a1 1 0 0 0 0 1.414l5.208 5.209A1 1 0 0 0 100.25 211l-4.501-4.501 4.5-4.501z" transform="translate(355 153.5)" />
                        <path d="M107.666 205.5H94.855a1 1 0 1 0 0 2h12.813a1 1 0 1 0 0-2z" transform="translate(355 153.5)" />
                      </g>
                    </g>
                  </svg>
                  <div className="i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s rnr61an3 s45kfl79 emlxlaya bkmhp75w spb7xbtv" data-visualcompletion="ignore" style={{ inset: "-8px" }} />
                </div>
              </div>
            )}
            <div className="search_input_content">
              <span className="bp9cbjyn j83agx80 g0qnabr5 hzruof5a h4z51re5">
                <svg fill="currentColor" viewBox="0 0 16 16" width="1em" height="1em" className="a8c37x1j ms05siws l3qrxjdp b7h9ocf4 py1f6qlh gl3lb2sf hhz5lgdu">
                  <g fillRule="evenodd" transform="translate(-448 -544)">
                    <g fillRule="nonzero">
                      <path d="M10.743 2.257a6 6 0 1 1-8.485 8.486 6 6 0 0 1 8.485-8.486zm-1.06 1.06a4.5 4.5 0 1 0-6.365 6.364 4.5 4.5 0 0 0 6.364-6.363z" transform="translate(448 544)" />
                      <path d="M10.39 8.75a2.94 2.94 0 0 0-.199.432c-.155.417-.23.849-.172 1.284.055.415.232.794.54 1.103a.75.75 0 0 0 1.112-1.004l-.051-.057a.39.39 0 0 1-.114-.24c-.021-.155.014-.356.09-.563.031-.081.06-.145.08-.182l.012-.022a.75.75 0 1 0-1.299-.752z" transform="translate(448 544)" />
                      <path d="M9.557 11.659c.038-.018.09-.04.15-.064.207-.077.408-.112.562-.092.08.01.143.034.198.077l.041.036a.75.75 0 0 0 1.06-1.06 1.881 1.881 0 0 0-1.103-.54c-.435-.058-.867.018-1.284.175-.189.07-.336.143-.433.2a.75.75 0 0 0 .624 1.356l.066-.027.12-.061z" transform="translate(448 544)" />
                      <path d="m13.463 15.142-.04-.044-3.574-4.192c-.599-.703.355-1.656 1.058-1.057l4.191 3.574.044.04c.058.059.122.137.182.24.249.425.249.96-.154 1.41l-.057.057c-.45.403-.986.403-1.411.154a1.182 1.182 0 0 1-.24-.182zm.617-.616.444-.444a.31.31 0 0 0-.063-.052c-.093-.055-.263-.055-.35.024l.208.232.207-.206.006.007-.22.257-.026-.024.033-.034.025.027-.257.22-.007-.007zm-.027-.415c-.078.088-.078.257-.023.35a.31.31 0 0 0 .051.063l.205-.204-.233-.209z" transform="translate(448 544)" />
                    </g>
                  </g>
                </svg>
              </span>
              <input value={term} onFocus={onFocus} onChange={onChange} onBlur={onBlur} placeholder="Search Friend" autoComplete="off" className="search_input" />

              <div className="search_delete_icon" onClick={doCancelSearching}>
                <svg fill="currentColor" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 460.775 460.775" xmlSpace="preserve">
                  <path
                    d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
	c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
	c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
	c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
	l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
	c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div id="chatRoomListId" className="box-border flex-grow flex-col flex overflow-x-hidden overflow-y-hidden">
            <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={noMoreRoomList ? list.length : list.length + 20}>
              {({ onRowsRendered, registerChild }) => <List ref={registerChild} width={listWidth} height={listHeight} rowRenderer={renderRow} onRowsRendered={onRowsRendered} rowCount={noMoreRoomList ? list.length : list.length + 20} rowHeight={73} />}
            </InfiniteLoader>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
