import { Checkbox, Modal } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import useStore from "store";
import MyAccountCard from "../components/MyAccountCard";

const AddAccountModal = ({ onCancel, onOk, ignoreAccountSteamId }) => {
  const [selectedAccounts, setSelectedAccounts] = useState({});
  const selectedAccountList = useMemo(() => {
    return Object.keys(selectedAccounts).filter((steamId) => selectedAccounts[steamId]);
  }, [selectedAccounts]);
  const accountList = useStore.getState().accountList.filter(function (account) {
    if (account.prime) {
      return false;
    }
    if (ignoreAccountSteamId.includes(account.steamId)) {
      return false;
    }
    return true;
  });

  const handleOnOk = useCallback(() => {
    onOk?.(selectedAccountList);
  }, [onOk, selectedAccountList]);

  return (
    <Modal
      width={700}
      style={{
        maxHeight: "80vh",
        maxWidth: "80vw",
      }}
      open={true}
      title="Select account to upgrade"
      onOk={handleOnOk}
      onCancel={onCancel}
    >
      <span>Account List</span>
      <div className="max-h-[70vh] overflow-y-auto relative">
        {accountList.map(function (account) {
          return (
            <div className="flex items-center gap-5 w-full" key={account.steamId}>
              <Checkbox
                checked={!!selectedAccounts[account.steamId]}
                onChange={function (e) {
                  setSelectedAccounts(function (prevState) {
                    return {
                      ...prevState,
                      [account.steamId]: e.target.checked,
                    };
                  });
                }}
              />
              <MyAccountCard steamId={account.steamId} className="w-full" />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
export default AddAccountModal;
