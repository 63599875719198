import useStore, { usePlayerState } from "../store";
import SteamAvatar from "./SteamAvatar";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { isAccountBanned } from "../utils";

const AccountPin = React.memo(function AccountPin({ account, selected }) {
  const { steamId } = account;

  const {
    playerStateList: [playerState],
  } = usePlayerState(steamId);

  let textColor = "text-black";
  if (isAccountBanned(account)) {
    textColor = "text-red-600";
  } else if (playerState?.onlineStatus === "ingame") {
    textColor = "text-green-600";
  } else if (playerState?.onlineStatus === "online") {
    textColor = "text-blue-600";
  } else {
    textColor = "text-black";
  }

  return (
    <div className="pr-5 relative flex gap-2 items-center">
      <div
        className={`cursor-default hover:bg-gray-50 relative text-sm ${textColor}`}
        onClick={function () {
          useStore.getState().setSelectedAccountID(steamId);
        }}
      >
        <SteamAvatar avatarHash={account.avatarHash} className="w-8 h-8" />
        {[account.username, account.personaName].filter(Boolean).join(" - ")}
      </div>
      <div
        className="text-slate-800 hover:text-black px-1 text-lg cursor-pointer hover:font-bold"
        title="Unpin"
        onClick={function () {
          useStore.getState().removeAccountPin(steamId);
        }}
      >
        <FontAwesomeIcon icon={faTimes} className="hover:bg-rose-50 hover:font-bold" />
      </div>

      {selected && <div className="w-full absolute left-0 right-0 bottom-0 h-0.5 bg-cyan-300"></div>}
    </div>
  );
});

export default AccountPin;
