import React, { useEffect, useRef, useState } from "react";
import { Image, Input, Modal } from "antd";
import { addListeners, removeListener, socket } from "../socket";
import useStore, { ModalType } from "../store";
import { v4 } from "uuid";
import api from "api";

function RealAddAccountModal() {
  const tempUsernameRef = useRef("TEMP_USERNAME_QR_" + v4());
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [qrUrl, setQrUrl] = useState();

  useEffect(() => {
    const listeners = addListeners({
      InvalidPassword(username) {
        if (username !== usernameRef.current.input.value?.trim()?.toLowerCase()) {
          return;
        }
        setConfirmLoading(false);
        passwordRef.current.input.focus();
      },
      authenticated({ steamId, username }) {
        if (usernameRef.current.input.value?.trim()?.toLowerCase() !== usernameRef.current.input.value?.trim()?.toLowerCase()) {
          return;
        }
        useStore.getState().hideModal(ModalType.SubmitSteamGuard);
        useStore.getState().hideModal(ModalType.AddAccount);
      },
      addMyAccountQRResponse(response) {
        if (response.username?.toLowerCase() !== tempUsernameRef.current?.toLowerCase()) {
          return;
        }
        setQrUrl(response.qrUrl);
      },
    });

    socket.addMyAccount({ qr: true, username: tempUsernameRef.current });

    return () => {
      removeListener(listeners);
    };
  }, []);

  useEffect(() => {
    usernameRef?.current?.input?.focus();
    setTimeout(function () {
      usernameRef?.current?.input?.focus();
    }, 500);
  }, []);

  function handleOK() {
    setConfirmLoading(true);
    const username = usernameRef.current.input.value?.trim()?.toLowerCase();
    const password = passwordRef.current.input.value?.trim();
    socket.addMyAccount({ username, password });
  }

  return (
    <React.Fragment>
      <Modal
        title="Add account"
        open={true}
        onOk={handleOK}
        onCancel={() => {
          api.cancelAddMyAccount({ username: tempUsernameRef.current });
          useStore.getState().hideModal(ModalType.AddAccount);
        }}
        confirmLoading={confirmLoading}
      >
        <Input
          ref={usernameRef}
          placeholder="Username"
          onKeyDown={(event) => {
            if (event.code !== "Enter") {
              return;
            }
            event.preventDefault();
            handleOK();
          }}
        />
        &nbsp;
        <Input
          ref={passwordRef}
          placeholder="Password"
          onKeyDown={(event) => {
            if (event.code !== "Enter") {
              return;
            }
            event.preventDefault();
            handleOK();
          }}
        />
        <div className="my-4 mx-auto w-full flex justify-center">
          <Image src={qrUrl} width={200} height={200} />
        </div>
      </Modal>
    </React.Fragment>
  );
}

const AddAccountModal = React.memo(function AddAccountModal() {
  const currentModal = useStore((store) => store.currentModal);
  if (!currentModal.hasOwnProperty(ModalType.AddAccount)) return;
  return <RealAddAccountModal />;
});
export default AddAccountModal;
