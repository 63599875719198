import React, { useCallback, useMemo, useState } from "react";
import moment from "moment/moment";
import { doUpdateFriendInfo, generateMenuItems } from "../utils";
import { Dropdown, Switch } from "antd";
import MenuItem from "../shared/MenuItem";
import useStore, { useAccountBySteamId, useAccounts, useIsFriend } from "store";
import { useShallow } from "zustand/react/shallow";
import classNames from "classnames";
import SimpleButton from "../shared/SimpleButton";
import Toast from "../toast";
import { faMicrophone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "api";
import { useFriendsBySteamIds } from "../store/UserSlice";
import _ from "lodash";

const menuItems = [
  MenuItem.OpenSteamProfile,
  MenuItem.CopySteamID,
  MenuItem.CopySteamProfile,
  MenuItem.CopyFriendCode,
  MenuItem.OpenMessage,
  MenuItem.SendQuickMessage,
  {
    label: "Update Friend Info",
    onClick: function (steamId) {
      doUpdateFriendInfo(steamId);
    },
  },
];
const MapMapping = {
  dustii: "https://static.wikia.nocookie.net/cswikia/images/d/db/Map_icon_de_dust2.png",
  vertigo: "https://static.wikia.nocookie.net/cswikia/images/4/46/Vertigo-logo-new.png",
  train: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_train.png",
  safehouse: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_safehouse.png",
  overpass: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_overpass.png",
  nuke: "https://static.wikia.nocookie.net/cswikia/images/e/ef/Set_nuke_2.png",
  mirage: "https://static.wikia.nocookie.net/cswikia/images/9/96/Set_mirage.png",
  lake: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_lake.png",
  inferno: "https://static.wikia.nocookie.net/cswikia/images/0/0a/CS2_inferno_logo.png",
  dust: "https://static.wikia.nocookie.net/cswikia/images/1/10/Csgo_map_icon_de_shortdust.png",
  cbble: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_cbble.png",
  canals: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_canals.png",
  cache: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_cache.png",
  bank: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_bank.png",
  aztec: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_aztec.png",
  ancient: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_de_ancient.png",
  office: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_cs_office.png",
  militia: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_cs_militia.png",
  italy: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_cs_italy.png",
  assault: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_cs_assault.png",
  baggage: "https://raw.githubusercontent.com/vgalisson/csgo-map-icons/master/80x80/collection_icon_ar_baggage.png",
  anubis: "https://static.wikia.nocookie.net/cswikia/images/f/f8/Map_icon_de_anubis.png",
};

const PlayerItem = React.memo(function PlayerItem({ player, selected }) {
  const friendsInfo = useStore((state) => state.friendsInfo);
  const matchHistoryPlayer = useStore(useShallow((state) => state.matchHistoryPlayer[player?.steamId]));
  const account = useAccountBySteamId(player?.steamId);
  const [friend] = useFriendsBySteamIds(player?.steamId);
  const isFriend = useIsFriend(player?.steamId);
  const isMyAccount = !!account;
  const name = _.uniq([account?.username, account?.personaName, friend?.personaName, matchHistoryPlayer?.name, friendsInfo[player?.steamId]?.historyDiscordName?.[0]].filter(Boolean)).join(" - ");
  const avatarHash = (isMyAccount ? account.avatarHash : matchHistoryPlayer?.avatarHash) || "fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb";

  const menu = useMemo(
    function () {
      const { handleMenuClick, items } = generateMenuItems(menuItems);
      return {
        items: items,
        onClick: (e) => handleMenuClick(e, player?.steamId),
      };
    },
    [player?.steamId],
  );

  return (
    <tr
      className={classNames("border border-solid", {
        "border-lime-500": isMyAccount,
        "border-neutral-300": !isMyAccount,
        "font-bold": selected,
      })}
    >
      <td className="player_name">
        <Dropdown menu={menu} trigger={["contextMenu"]}>
          <div className="flex justify-start items-center">
            <img width={34} height={34} src={`https://avatars.akamai.steamstatic.com/${avatarHash}_full.jpg`} />
            &nbsp;&nbsp;
            <a
              href={`https://steamcommunity.com/profiles/${player?.steamId}`}
              target="_blank"
              rel="noreferrer"
              className={classNames({
                "text-black": !isFriend && !isMyAccount,
                "text-fuchsia-600": isFriend && !isMyAccount,
                "text-sky-600": isMyAccount,
              })}
            >
              {!!friendsInfo[player?.steamId]?.mic && <FontAwesomeIcon icon={faMicrophone} />}
              {name}
            </a>
            {isMyAccount && (
              <FontAwesomeIcon
                className="pl-2 text-sky-600"
                icon={faUser}
                onClick={() => {
                  useStore.getState().setSelectedAccountID(player?.steamId);
                }}
              />
            )}
          </div>
        </Dropdown>
      </td>
      <td className="player_kill">{player.K}</td>
      <td className="player_assist">{player.A}</td>
      <td className="player_death">{player.D}</td>
    </tr>
  );
});

const MatchTeam = React.memo(function ({ players, myScore, theirScore, className }) {
  const selectedAccountID = useStore((state) => state.selectedAccountID);

  return (
    <div className={`matchPlayer ${className}`}>
      <table className="border-collapse">
        <thead>
          <tr>
            <th
              className={classNames("match_score", {
                "text-emerald-400": myScore > theirScore,
                "text-red-500": myScore < theirScore,
              })}
              colSpan="4"
            >
              {myScore}
            </th>
          </tr>
          <tr>
            <th className="player_name">Player Name</th>
            <th className="player_kill">Kill</th>
            <th className="player_assist">Assist</th>
            <th className="player_death">Death</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player) => (
            <PlayerItem player={player} key={player?.steamId} selected={selectedAccountID === player?.steamId} />
          ))}
        </tbody>
      </table>
    </div>
  );
});

const MatchBlock = React.memo(function MatchBlock({ match: { matchInfo, players, matchID, scoreboard, extras }, index, style }) {
  const { accounts } = useAccounts(players.map((p) => p.steamId));
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const myPlayAccountSteamId = players.some((p) => p.steamId === selectedAccountID) ? selectedAccountID : players.find((p) => accounts[p.steamId])?.steamId;
  const isMyTeamCt = players.findIndex((p) => p.steamId === myPlayAccountSteamId) < 5;
  const myScore = isMyTeamCt ? scoreboard[0] : scoreboard[1];
  const theirScore = isMyTeamCt ? scoreboard[1] : scoreboard[0];
  const [loading, setLoading] = useState(false);

  const newMatchInfo = useMemo(() => {
    const _matchInfo = { ...matchInfo };
    if (!_matchInfo.ranked) {
      delete _matchInfo.ranked;
    }

    _matchInfo.timeAgo = moment(_matchInfo.time).fromNow();
    _matchInfo.time = [_matchInfo.time, _matchInfo.timestamp].join(" - ");
    delete _matchInfo.timestamp;

    if (matchInfo.GOTV_Replay) {
      _matchInfo.GOTV_Replay = (
        <a href={matchInfo.GOTV_Replay} target="_blank" rel="noreferrer">
          Download
        </a>
      );
    }
    return _matchInfo;
  }, [matchInfo]);

  const matchName = useMemo(() => {
    const usernameList = players
      .filter(({ steamId }) => accounts[steamId])
      .map(({ steamId }) => accounts[steamId]?.username ?? steamId)
      .filter(Boolean)
      .join(" - ");
    return [usernameList, matchInfo.map].join(" ");
  }, [matchInfo.map, players, accounts]);

  const handleOnDeleteMatch = useCallback(async () => {
    const success = await useStore.getState().deleteMyMatchHistory(matchID);
    if (success) {
      Toast.success("Deleted " + matchName);
    } else {
      Toast.error("Delete failed " + matchName);
    }
  }, [matchID, matchName]);

  const matchTeamCT = <MatchTeam players={players.slice(0, 5)} className={isMyTeamCt ? "firstTeam" : "lastTeam"} team="ct" myScore={scoreboard[0]} theirScore={scoreboard[1]} key={matchID + "_ct"} />;

  const matchTeamT = <MatchTeam players={players.slice(5)} className={!isMyTeamCt ? "firstTeam" : "lastTeam"} team="t" myScore={scoreboard[1]} theirScore={scoreboard[0]} key={matchID + "_t"} />;
  return (
    <div
      className={classNames("matches_history_table_row", {
        "bg-red-100": myScore < theirScore,
        "bg-emerald-100": myScore > theirScore,
        "bg-neutral-200": scoreboard[0] === scoreboard[1],
      })}
      key={"MatchBlock_" + matchID}
      style={style}
    >
      <div className="w-full flex">
        <table className="matchInfo">
          <tbody>
            <tr key={index}>
              <td>
                <span
                  className={classNames(`matchInfo_label`, {
                    ranked: newMatchInfo.ranked,
                  })}
                >
                  <span className="text-xl">{index + 1}.</span>
                  <img className="w-10 h-10 ml-2" src={MapMapping[newMatchInfo.map.toLowerCase().replace(/\s*/gi, "")]} alt={newMatchInfo.map} /> {newMatchInfo.map}
                </span>
              </td>
            </tr>

            {Object.keys(newMatchInfo).map(function (info) {
              if (["ranked", "map"].includes(info)) return null;
              return (
                <tr key={info}>
                  <td>
                    <span className={`matchInfo_label ${newMatchInfo.ranked ? "ranked" : ""}`}>{info}:</span> {newMatchInfo[info]}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <SimpleButton onClick={handleOnDeleteMatch}>Delete</SimpleButton>
              </td>
            </tr>
            <tr>
              <td>
                <Switch
                  disabled={loading}
                  loading={loading}
                  defaultValue={!!extras?.isCustom}
                  onChange={async function (value) {
                    setLoading(true);
                    await api.setMatchExtrasInfo({
                      matchID: matchID,
                      extras: {
                        isCustom: value,
                      },
                    });
                    setLoading(false);
                  }}
                  checkedChildren="Custom"
                  unCheckedChildren="Non-custom"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className={classNames("flex w-[80%]")}>
          {isMyTeamCt ? (
            <>
              {matchTeamCT}
              {matchTeamT}
            </>
          ) : (
            <>
              {matchTeamT}
              {matchTeamCT}
            </>
          )}
        </div>
      </div>
    </div>
  );
});
export default MatchBlock;
