import { create } from "zustand";
import { createUserSlice, inventoryHistorySortIteratees, ModalType, useAccountBySteamId, useAccounts, useAccountsFriend, useFriend, useIsFriend, useMyParty, usePlayerState, userSlicePartialize, useSteamIds } from "./UserSlice";
import { createChatSlice, sortRoomList } from "./ChatSlice";
import { persist } from "zustand/middleware";
import _ from "lodash";
import localforage from "localforage";

const useStore = create(
  persist(
    function (set, get) {
      return {
        ...createUserSlice(set, get),
        ...createChatSlice(set, get),
      };
    },
    {
      name: "user-storage", // name of the item in the storage (must be unique)
      storage: {
        async getItem(name) {
          return localforage.getItem(name);
        },
        async setItem(name, storageValue) {
          return localforage.setItem(name, storageValue);
        },
        async removeItem(name) {
          return localforage.removeItem(name);
        },
      },
      partialize: (state) => _.pick(state, userSlicePartialize),
    },
  ),
);

useStore.getAccount = function (steamId) {
  return useStore.getState().accountList.find((a) => a.steamId === steamId);
};

export default useStore;
export { ModalType, sortRoomList, inventoryHistorySortIteratees, useAccounts, useAccountsFriend, usePlayerState, useIsFriend, useSteamIds, useFriend, useAccountBySteamId, useMyParty };
