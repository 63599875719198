import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { filterObjectInput } from "../utils";
import { Button, Dropdown, Input, Select, Space, Switch } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import useStore from "store";
import FriendGroup, { getFriendGroupSortValue } from "./FriendGroup";
import FriendGroupHeader from "./FriendGroupHeader";
import { useDebounce, useLocalStorage } from "@uidotdev/usehooks";
import { StringUtils } from "alpha-common-utils/index.js";
import MyAccountPicker from "../MyAccountPicker";
import { areArraysSoftEqual, useParty } from "../store/UserSlice";
import SimpleButton from "../shared/SimpleButton";
import api from "api";
import useMultipleFilterCriteria from "./useMultipleFilterCriteria";
import { socket } from "../socket";

const PRIORITY = {
  ingame: 2,
  online: 1,
  offline: 0,
};

const FILTER_TYPE = {
  ALL: 1,
  PRIME: 2,
  NON_PRIME: 3,
  UNKNOWN_PRIME: 4,
  ONLINE: 5,
  INGAME: 6,
};

const SORT_TYPE = {
  ALL: 1,
  PLAYING_TIME: 2,
  LAST_TIME_CHAT: 3,
  ELO: 4,
};

const ONLINE_STATUS_LIST = ["ingame", "online", "offline"];

const AVAILABLE_STATUS = ["Competitive", "Community Competitive", "Wingman", "Premier"];

const filterTypeName = (value) => Object.keys(FILTER_TYPE).find((key) => FILTER_TYPE[key] === value) || "";
const sortTypeName = (value) => Object.keys(SORT_TYPE).find((key) => SORT_TYPE[key] === value) || "";

const AvaiableOptions = ["Avaiable", "Near Avaiable", "Not Avaiable", "Deathmatch", "Casual"].map((value) => ({
  value,
  label: value,
}));

export default function FriendList() {
  const bodyRef = useRef();

  const [available, setAvailable] = useLocalStorage("FriendList_available");
  const [up2Date, setUp2Date] = useLocalStorage("FriendList_up2Date");
  const [filterQuery, setFilterQuery] = useState("");
  const debouncedFilterQuery = useDebounce(filterQuery, 500);
  const [filterType, setFilterType] = useLocalStorage("FriendList_FILTER_TYPE", FILTER_TYPE.ALL);
  const [sortType, setSortType] = useLocalStorage("FriendList_SORT_TYPE", SORT_TYPE.ALL);
  const [mySelectedAccount, setMySelectedAccount] = useState();
  const { valueObj: multipleFilterCriteriaObj, MultipleFilterCriteria } = useMultipleFilterCriteria();

  const gameFilterOptions = useGameFilterList();
  const [ingame_GameFilter, setIngame_GameFilter] = useLocalStorage("FriendList_ingame_GameFilter");

  const { friendInGroup, friendInGame, friendOnline, friendOffline, switchOnOff, setSwitchOnOff } = useDisplayFriendList(debouncedFilterQuery, multipleFilterCriteriaObj, mySelectedAccount, filterType, ingame_GameFilter, available);
  const { sortedFriendList: sortedFriendInGroup, size: sizeFriendInGroup, realSize: realSizeFriendInGroup } = useSortedFriendList(friendInGroup, "ingroup", sortType);
  const { sortedFriendList: sortedFriendInGame, size: sizeFriendInGame, realSize: realSizeFriendInGame } = useSortedFriendList(friendInGame, "ingame", sortType);
  const { sortedFriendList: sortedFriendOnline, size: sizeFriendOnline, realSize: realSizeFriendOnline } = useSortedFriendList(friendOnline, "online", sortType);
  const { sortedFriendList: sortedFriendOffline, size: sizeFriendOffline, realSize: realSizeFriendOffline } = useSortedFriendList(friendOffline, "offline", sortType);

  useEffect(() => {
    if (!bodyRef.current) return;
    bodyRef.current.style.height = `calc( 100vh - ${bodyRef.current.getBoundingClientRect().y}px )`;
  }, []);

  const handleClearInvitePlayNowMsg = useCallback(async () => {
    await api.clearInvitePlayNowMsg();
  }, []);

  return (
    <React.Fragment>
      <div className="flex gap-3 items-center px-2">
        <Space.Compact className={!!debouncedFilterQuery ? "w-2/12" : "w-1/12"}>
          <Input
            placeholder="Search..."
            rootClassName="w-full"
            value={filterQuery}
            onChange={(event) => {
              setFilterQuery(event.target.value);
            }}
            allowClear
          />
        </Space.Compact>
        <Space wrap>
          <span>Friend of MyAccount: </span>
          <MyAccountPicker selectedAccount={mySelectedAccount} setSelectedAccount={setMySelectedAccount} />
        </Space>
        <Space wrap>
          <Dropdown
            menu={{
              items: Object.keys(FILTER_TYPE).map((_filterType) => ({
                key: _filterType,
                label: _filterType,
                icon: <UserOutlined />,
              })),
              onClick: (e) => setFilterType(FILTER_TYPE[e.key]),
            }}
          >
            <Button>
              <Space>
                Filter - {filterTypeName(filterType)}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
        <Space wrap>
          <Dropdown
            menu={{
              items: Object.keys(SORT_TYPE).map((value) => ({
                key: value,
                label: value,
                icon: <UserOutlined />,
              })),
              onClick: (e) => setSortType(SORT_TYPE[e.key]),
            }}
          >
            <Button>
              <Space>
                Sort - {sortTypeName(sortType)}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
        <Space wrap>
          <MultipleFilterCriteria />
        </Space>
        <Space wrap>
          <Select placeholder="Avaiable" className="w-32" allowClear value={available} onChange={setAvailable} options={AvaiableOptions} />
        </Space>
        <Space wrap>
          <Switch
            checked={!!up2Date}
            checkedChildren="up2Date"
            unCheckedChildren="off"
            onChange={function (checked) {
              setUp2Date(checked);
              socket.up2Date(checked);
            }}
          />
        </Space>
        <Space wrap>
          <SimpleButton onClick={handleClearInvitePlayNowMsg}>Clear InvitePlayNow Msg</SimpleButton>
        </Space>
      </div>
      <div className="relative overflow-y-auto" ref={bodyRef}>
        <div className="ingroup flex flex-wrap px-2 w-full relative">
          <div className="box-border pt-5 pb-1 text-xs  gap-2 flex items-center text-blue-500 m-1">
            <FriendGroupHeader onlineState="ingroup" size={sizeFriendInGroup} realSize={realSizeFriendInGroup} defaultChecked={switchOnOff.includes("ingroup")} setSwitchOnOff={setSwitchOnOff} members={sortedFriendInGroup} />
          </div>
          {switchOnOff.includes("ingroup") && (
            <div className="flex flex-wrap px-2 w-full relative">
              {sortedFriendInGroup.map((item, index) => (
                <FriendGroup steamPlayerGroup={item.steamPlayerGroup} groupMembers={item.groupMembers} key={item.steamPlayerGroup} />
              ))}
            </div>
          )}
        </div>
        <div className="ingame flex flex-wrap px-2 w-full relative">
          <div className="box-border pt-5 pb-1 text-xs  gap-2 flex items-center text-blue-500 m-1">
            <FriendGroupHeader onlineState="ingame" size={sizeFriendInGame} realSize={realSizeFriendInGame} defaultChecked={switchOnOff.includes("ingame")} setSwitchOnOff={setSwitchOnOff} members={sortedFriendInGame} />
            <Select allowClear value={ingame_GameFilter} className="w-72" options={gameFilterOptions} onChange={setIngame_GameFilter} />
          </div>
          {switchOnOff.includes("ingame") && (
            <div className="flex flex-wrap px-2 w-full relative">
              {sortedFriendInGame.map((item, index) => (
                <FriendGroup steamPlayerGroup={item.steamPlayerGroup} groupMembers={item.groupMembers} key={item.steamPlayerGroup} />
              ))}
            </div>
          )}
        </div>
        <div className="online flex flex-wrap px-2 w-full relative">
          <div className="box-border pt-5 pb-1 text-xs  gap-2 flex items-center text-blue-500 m-1">
            <FriendGroupHeader onlineState="online" size={sizeFriendOnline} realSize={realSizeFriendOnline} defaultChecked={switchOnOff.includes("online")} setSwitchOnOff={setSwitchOnOff} members={sortedFriendOnline} />
          </div>
          {switchOnOff.includes("online") && (
            <div className="flex flex-wrap px-2 w-full relative">
              {sortedFriendOnline.map((item, index) => (
                <FriendGroup steamPlayerGroup={item.steamPlayerGroup} groupMembers={item.groupMembers} key={item.steamPlayerGroup} />
              ))}
            </div>
          )}
        </div>
        <div className="offline flex flex-wrap px-2 w-full relative">
          <div className="box-border pt-5 pb-1 text-xs  gap-2 flex items-center text-blue-500 m-1">
            <FriendGroupHeader onlineState="offline" size={sizeFriendOffline} realSize={realSizeFriendOffline} defaultChecked={switchOnOff.includes("offline")} setSwitchOnOff={setSwitchOnOff} members={sortedFriendOffline} />
          </div>
          {switchOnOff.includes("offline") && (
            <div className="flex flex-wrap px-2 w-full relative">
              {sortedFriendOffline.map((item, index) => (
                <FriendGroup steamPlayerGroup={item.steamPlayerGroup} groupMembers={item.groupMembers} key={item.steamPlayerGroup} />
              ))}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const useLocationList = () => {
  const friendList = useStore((state) => state.friendList);
  const [locationList, setLocationList] = useState([]);
  const locationOptions = useMemo(() => {
    return locationList.map((location) => ({ label: location, value: location }));
  }, [locationList]);

  useEffect(() => {
    let list = friendList.map((friend) => friend.location?.trim?.()).filter(Boolean);
    list = Array.from(new Set(list)).sort();
    list.sort((a, b) => {
      const aVietnam = a.includes("Việt Nam") || a.includes("Viet Nam");
      const bVietnam = b.includes("Việt Nam") || b.includes("Viet Nam");
      const aHoChiMinh = a.includes("Ho Chi Minh");
      const bHoChiMinh = b.includes("Ho Chi Minh");

      if (aVietnam && !bVietnam) return -1;
      if (!aVietnam && bVietnam) return 1;

      if (aHoChiMinh && !bHoChiMinh) return -1;
      if (!aHoChiMinh && bHoChiMinh) return 1;

      return 0;
    });

    setLocationList(function (prevState) {
      return _.isEqual(prevState, list) ? prevState : list;
    });
  }, [friendList]);

  return {
    locationList,
    locationOptions,
  };
};

const useGameFilterList = () => {
  const gameApp = useStore((state) => state.gameApp);
  const [gameFilterOptions, setGameFilterOptions] = useState([]);

  useEffect(() => {
    const games = Object.entries(gameApp)
      .map(function ([appId, name]) {
        return {
          value: parseInt(appId),
          label: name,
        };
      })
      .sort((a, b) => {
        if (a.value === 730) return -1;
        if (b.value === 730) return 1;
        return a.label.localeCompare(b.label);
      });
    setGameFilterOptions(function (prevState) {
      return _.isEqual(prevState, games) ? prevState : games;
    });
  }, [gameApp]);

  return gameFilterOptions;
};

const useDisplayFriendList = (debouncedFilterQuery, multipleFilterCriteriaObj, mySelectedAccount, filterType, ingame_GameFilter, available) => {
  const friendList = useStore((state) => state.friendList);
  const accountList = useStore((state) => state.accountList);
  const friendsInfo = useStore((state) => state.friendsInfo);
  const { memberPartyObj } = useParty();
  const [switchOnOff, setSwitchOnOff] = useState(["ingame"]);

  const [friendInGroup, setFriendInGroup] = useState([]);
  const [friendInGame, setFriendInGame] = useState([]);
  const [friendOnline, setFriendOnline] = useState([]);
  const [friendOffline, setFriendOffline] = useState([]);

  useEffect(() => {
    const _filterQuery = StringUtils.toNonAccentVietnameseLowerCase(debouncedFilterQuery?.trim() || "");

    const criteriaCheck = (friend) => {
      if (!friend || friend.banned) {
        return false;
      }

      const account = accountList.find((a) => a.steamId === friend.steamId);
      const onlineStatus = friend.state?.onlineStatus ?? "offline";
      const friendStateScore = friend.state?.score?.toLowerCase() || "";

      if (multipleFilterCriteriaObj["Ignore my account"] && account) {
        return false;
      }

      if (multipleFilterCriteriaObj["Ignore my boosting account"] && (account?.config?.state === "InGame" || account?.config?.store)) {
        return false;
      }

      if (mySelectedAccount && !(accountList.find(({ steamId }) => steamId === mySelectedAccount)?.friendsIDList || []).includes(friend.steamId)) {
        return false;
      }

      switch (filterType) {
        case FILTER_TYPE.PRIME:
          if (friend.primeStatus !== "prime") {
            return false;
          }
          break;
        case FILTER_TYPE.NON_PRIME:
          if (friend.primeStatus !== "non-prime") {
            return false;
          }
          break;
        case FILTER_TYPE.UNKNOWN_PRIME:
          if (friend.primeStatus) {
            return false;
          }
          break;
        case FILTER_TYPE.ONLINE:
          if (onlineStatus !== "online") {
            return false;
          }
          break;
        case FILTER_TYPE.INGAME:
          if (onlineStatus !== "ingame") {
            return false;
          }
          break;
        default:
      }

      if (!switchOnOff.includes(onlineStatus)) {
        return false;
      }

      if (onlineStatus === "ingame" && ingame_GameFilter && ingame_GameFilter != friend.state?.gameid) {
        return false;
      }

      switch (available) {
        case "Avaiable":
          if (AVAILABLE_STATUS.some((s) => friendStateScore.startsWith(s.toLowerCase()))) {
            return false;
          }
          break;
        case "Deathmatch":
          if (!friendStateScore || !friendStateScore.startsWith("Deathmatch".toLowerCase())) {
            return false;
          }
          break;
        case "Casual":
          if (!friendStateScore || !friendStateScore.startsWith("Casual".toLowerCase())) {
            return false;
          }
          break;
        case "Near Avaiable":
          const gameType = AVAILABLE_STATUS.find((s) => friendStateScore.startsWith(s.toLowerCase()));
          if (gameType) {
            if (!friendStateScore.includes("[") && !friendStateScore.includes(":") && !friendStateScore.includes("]")) {
              return false;
            }
            if (/\[\d+:\d+\]/.test(friendStateScore.replace(/\s+/g, ""))) {
              try {
                const [_firstScore, _secondScore] = friendStateScore.match(/\d+/g).map(Number);
                if (_firstScore < 10 && _secondScore < 10) {
                  return false;
                }
              } catch (e) {
                console.error("Error parsing score:", friendStateScore, e);
              }
            }
          }

          break;
        case "Not Avaiable":
          if (!friendStateScore || !AVAILABLE_STATUS.some((s) => friendStateScore.startsWith(s.toLowerCase()))) {
            return false;
          }
          break;
        default:
          break;
      }

      const myAccountFriends = accountList.filter((a) => a.friendsIDList?.includes?.(friend.steamId));

      if (multipleFilterCriteriaObj["Double Friend"] && myAccountFriends.length < 2) {
        return false;
      }

      if (multipleFilterCriteriaObj["Not friend of limited account"] && myAccountFriends.some((a) => !a.steamLevel)) {
        return false;
      }

      if (multipleFilterCriteriaObj["Mic"] && !friendsInfo[friend.steamId]?.mic) {
        return false;
      }

      if (_filterQuery) {
        const selectedFriend = _.pick(friend, ["steamId", "realName", "personaName", "historyName"]);
        if (!filterObjectInput(selectedFriend, _filterQuery)) {
          const mFriendsInfo = friendsInfo[friend.steamId];
          if (mFriendsInfo) {
            const selectedFriendInfo = _.pick(mFriendsInfo, ["inviteName", "otherNames", "discord", "historyDiscordName", "realName", "phone", "facebook"]);
            if (!filterObjectInput(selectedFriendInfo, _filterQuery)) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      return true;
    };

    const _friendInGroup = [];
    const _friendInGame = [];
    const _friendOnline = [];
    const _friendOffline = [];

    for (const friend of friendList) {
      const status = friend.state?.onlineStatus ?? "offline";

      if (memberPartyObj[friend.steamId] && switchOnOff.includes("ingroup")) {
        _friendInGroup.push(friend);
      }
      if (criteriaCheck(friend)) {
        switch (status) {
          case "ingame":
            _friendInGame.push(friend);
            break;
          case "online":
            _friendOnline.push(friend);
            break;
          case "offline":
            _friendOffline.push(friend);
            break;
        }
      }
    }

    const steamIdSortFn = (friend1, friend2) => {
      return friend1.steamId.localeCompare(friend2.steamId);
    };

    _friendInGroup.sort(steamIdSortFn);
    _friendInGame.sort(steamIdSortFn);
    _friendOnline.sort(steamIdSortFn);
    _friendOffline.sort(steamIdSortFn);

    setFriendInGroup(function (prevState) {
      return areArraysSoftEqual(prevState, _friendInGroup) ? prevState : _friendInGroup;
    });

    setFriendInGame(function (prevState) {
      return areArraysSoftEqual(prevState, _friendInGame) ? prevState : _friendInGame;
    });

    setFriendOnline(function (prevState) {
      return areArraysSoftEqual(prevState, _friendOnline) ? prevState : _friendOnline;
    });

    setFriendOffline(function (prevState) {
      return areArraysSoftEqual(prevState, _friendOffline) ? prevState : _friendOffline;
    });
  }, [accountList, available, debouncedFilterQuery, filterType, friendList, friendsInfo, ingame_GameFilter, memberPartyObj, multipleFilterCriteriaObj, mySelectedAccount, switchOnOff]);

  return { friendInGroup, friendInGame, friendOnline, friendOffline, switchOnOff, setSwitchOnOff };
};

const useSortedFriendList = (friendList, onlineStatus, sortType) => {
  const friendFollowIDs = useStore((state) => state.friendFollowIDs);
  const customLobby = useStore((state) => state.customLobby);
  const { memberPartyObj } = useParty();

  const [sortedFriendList, setSortedFriendList] = useState([]);
  const size = friendList.length;
  const realSize = useMemo(() => {
    return friendList.filter((friend) => (friend.state?.onlineStatus ?? "offline") === onlineStatus).length;
  }, [friendList, onlineStatus]);

  useEffect(() => {
    const calculateTotalPlayingTime = (group) => group.reduce((sum, member) => sum + (member?.playingTime?.minutes_played || 0), 0);
    const calculateTotalLastTimeChat = (group) => group.reduce((max, member) => Math.max(max, member.lastTimeSendMessage || 0, member.lastTimeReceiveMessage || 0), 0);
    const calculateTotalElo = (group) => group.reduce((sum, member) => sum + (member.elo || 0), 0);

    const isPiorityGroup = (group, groupName) => groupName === "0" || groupName.startsWith("_") || group.length === 1;

    const members = friendList.slice().sort(function (p1, p2) {
      return (p1.timestamp || 0) - (p2.timestamp || 0);
    });

    const groups = members.reduce((acc, member) => {
      const groupKey = onlineStatus === "ingroup" ? memberPartyObj[member.steamId] : parseInt(member.state?.steam_player_group ?? "0") || `_${member.steamId}`;
      if (!acc[groupKey]) acc[groupKey] = [];
      acc[groupKey].push(member);
      return acc;
    }, {});

    const groupNames = Object.keys(groups).sort(function (groupName1, groupName2) {
      const group1 = groups[groupName1];
      const group2 = groups[groupName2];

      const group1IsFollow = group1.some((player) => friendFollowIDs.includes(player.steamId));
      const group2IsFollow = group2.some((player) => friendFollowIDs.includes(player.steamId));
      if (group1IsFollow !== group2IsFollow) {
        return group2IsFollow ? 1 : -1;
      }
      if (!!customLobby[groupName1] !== !!customLobby[groupName2]) {
        return customLobby[groupName2] ? 1 : -1;
      }

      const isPiorityGroup1 = isPiorityGroup(group1, groupName1);
      const isPiorityGroup2 = isPiorityGroup(group2, groupName2);
      if (isPiorityGroup1 !== isPiorityGroup2) return isPiorityGroup1 ? 1 : -1;

      switch (sortType) {
        case SORT_TYPE.PLAYING_TIME:
          return calculateTotalPlayingTime(group2) - calculateTotalPlayingTime(group1);
        case SORT_TYPE.LAST_TIME_CHAT:
          return calculateTotalLastTimeChat(group2) - calculateTotalLastTimeChat(group1);
        case SORT_TYPE.ELO:
          return calculateTotalElo(group2) - calculateTotalElo(group1);
        default:
          return group1.length - group2.length;
      }
    });

    const currentGroupList = groupNames.map((steamPlayerGroup) => ({
      steamPlayerGroup,
      groupMembers: groups[steamPlayerGroup],
    }));

    const limitedGroupList = currentGroupList.slice(0, 500);

    setSortedFriendList(function (prevGroups) {
      if (prevGroups.length !== limitedGroupList.length) {
        return limitedGroupList;
      }

      for (let i = 0; i < prevGroups.length; i++) {
        if (!getFriendGroupSortValue(prevGroups[i], limitedGroupList[i])) {
          return limitedGroupList;
        }
      }
      return prevGroups;
    });
  }, [customLobby, friendFollowIDs, friendList, memberPartyObj, onlineStatus, sortType]);

  return {
    sortedFriendList,
    size,
    realSize,
  };
};
