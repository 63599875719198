import { Button } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "api";
import moment from "moment";
import useStore from "store";
import SimpleButton from "shared/SimpleButton";
import Toast from "toast";

export default function MyConfirmation() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const [confirmations, setConfirmations] = useState({});
  const [loading, setLoading] = useState({});
  const bodyRef = useRef();
  const subToolRef = useRef();

  const getAllConfirmations = useCallback(async () => {
    const _confirmation = (await api.getMyConfirmation({ steamId: selectedAccountID }))?.data?.confirmations || [];
    setConfirmations((_confirmations) => ({
      ..._confirmations,
      [selectedAccountID]: _confirmation,
    }));
  }, [selectedAccountID]);

  useEffect(() => {
    if (!confirmations[selectedAccountID]) {
      getAllConfirmations();
    }
  }, [confirmations, getAllConfirmations, selectedAccountID]);

  const confirm = useCallback(
    async (confirmation) => {
      setLoading((_loading) => ({
        ..._loading,
        [confirmation.id]: true,
      }));
      let result = await api.doConfirm(confirmation);
      if (result.error !== false && result.error !== null) {
        result = await api.doConfirm(confirmation);
      }
      if (result?.error === false || result?.error === null) {
        Toast.success("Confirmed");
      } else {
        Toast.error(typeof result.error === "string" ? result.error : "Confirm failed");
      }
      setLoading((_loading) => ({
        ..._loading,
        [confirmation.id]: false,
      }));
      const _confirmation = (await api.getMyConfirmation({ steamId: selectedAccountID }))?.data?.confirmations || [];
      setConfirmations((_confirmations) => ({
        ..._confirmations,
        [selectedAccountID]: _confirmation,
      }));
    },
    [selectedAccountID],
  );

  const deny = useCallback(
    async (confirmation) => {
      setLoading((_loading) => ({
        ..._loading,
        [confirmation.id]: true,
      }));
      let result = await api.denyConfirmation(confirmation);
      if (result.error !== false && result.error !== null) {
        result = await api.denyConfirmation(confirmation);
      }
      if (result?.error === false || result?.error === null) {
        Toast.success("Denied");
      } else {
        Toast.error(typeof result.error === "string" ? result.error : "Deny failed");
      }
      setLoading((_loading) => ({
        ..._loading,
        [confirmation.id]: false,
      }));
      const _confirmation = (await api.getMyConfirmation({ steamId: selectedAccountID }))?.data?.confirmations || [];
      setConfirmations((_confirmations) => ({
        ..._confirmations,
        [selectedAccountID]: _confirmation,
      }));
    },
    [selectedAccountID],
  );

  const confirmAll = useCallback(async () => {
    for (const confirmation of confirmations[selectedAccountID]) {
      const result = (await api.doConfirm(confirmation))?.data;
      if (result?.success) {
        Toast.success("Confirmed");
      } else {
        Toast.error(typeof result?.error === "string" ? result.error : "Confirm failed");
      }
    }

    await getAllConfirmations();
  }, [confirmations, getAllConfirmations, selectedAccountID]);

  useEffect(() => {
    if (!bodyRef.current) return;
    if (!subToolRef.current) return;
    let boundingClientRect = subToolRef.current.getBoundingClientRect();
    bodyRef.current.style.height = `calc(100vh - ${boundingClientRect.y + boundingClientRect.height + 1}px)`;
  }, [bodyRef, subToolRef]);

  return (
    <>
      <div className="MyConfirmation flex flex-col">
        <div className="MyConfirmation_Header" ref={subToolRef}>
          {!!confirmations[selectedAccountID]?.length && <SimpleButton onClick={confirmAll}>Confirm all</SimpleButton>}
          <SimpleButton onClick={getAllConfirmations}>Refresh</SimpleButton>
        </div>
        <div className="MyConfirmation_Body" ref={bodyRef}>
          {confirmations[selectedAccountID]?.map?.((confirmation) => (
            <ConfirmationItem confirmation={confirmation} confirm={confirm} deny={deny} loading={!!loading[confirmation.id]} />
          ))}
        </div>
      </div>
    </>
  );
}

const ConfirmationItem = React.memo(function ConfirmationItem({ confirmation, confirm, deny, loading }) {
  return (
    <div className="w-full flex gap-2 py-2 items-center border-solid border-b-blue-500 border-b border-l-0 border-r-0 border-t-0" key={confirmation.id}>
      <img src={confirmation.icon} className="w-14 h-auto" />
      <span className="font-bold">{confirmation.title}</span>
      <span>{confirmation.sending}</span>
      <span>{moment(confirmation.timestamp).fromNow()}</span>
      <Button loading={loading} onClick={() => confirm(confirmation)}>
        Confirm
      </Button>
      <Button loading={loading} onClick={() => deny(confirmation)}>
        Deny
      </Button>
    </div>
  );
});
