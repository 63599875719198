import React, { useCallback, useEffect, useState } from "react";
import api from "api";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import useStore, { useAccounts } from "store";
import SimpleButton from "../../shared/SimpleButton";
import Toast from "../../toast";
import { sleep } from "../../utils";
import SteamAvatar from "../../components/SteamAvatar";

export default function MyTradeOffer() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const offers = useStore((state) => state.offers);
  const [loading, setLoading] = useState(false);

  const fetchOffer = useCallback(async () => {
    setLoading(true);
    await useStore.getState().fetchOffers(selectedAccountID);
    setLoading(false);
  }, [selectedAccountID]);

  useEffect(() => {
    fetchOffer();
  }, [fetchOffer]);

  return (
    <div>
      <div>
        <SimpleButton onClick={fetchOffer}>Refesh</SimpleButton>
      </div>
      {offers[selectedAccountID]?.map((offer) => (
        <TradeOfferBlock key={offer.tradeofferid} offer={offer} />
      ))}
      {!!loading && (
        <div className="absolute left-0 top-0 right-0 bottom-0 w-full h-full">
          <div className="absolute left-0 top-0 right-0 bottom-0 w-full h-full bg-zinc-400 opacity-40" />
          <div className="flex items-center justify-center w-full h-full">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 48,
                  }}
                  spin
                />
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

const TradeOfferBlock = React.memo(function TradeOfferBlock({ offer }) {
  const { accounts } = useAccounts(offer.partner.steamId);

  const renderItems = (title, items) => {
    return (
      <div className="w-1/2 flex flex-col justify-items-start items-start justify-self-start">
        <span>
          {title} {items.length ? `(${items.length} items)` : ""}
        </span>
        <div>
          {items.map(function (item) {
            return <img alt={item.economyItem} src={item.img} className="w-15 h-15" />;
          })}
        </div>
      </div>
    );
  };

  const myItems = offer.primary.steamId === offer.partner.steamId ? offer.secondary.items : offer.primary.items;
  const theirItems = offer.primary.steamId === offer.partner.steamId ? offer.primary.items : offer.secondary.items;

  const acceptTrade = useCallback(async () => {
    const { selectedAccountID } = useStore.getState();
    const result = (
      await api.acceptTradeOffer({
        tradeOfferId: offer.tradeofferid,
        myAccountID: selectedAccountID,
        partnerSteamId: offer.partner.steamId,
        partnerName: offer.partner.name,
      })
    )?.data;
    if (result?.success) {
      Toast.success(result.success);
      useStore.getState().removeOffers(selectedAccountID, offer.tradeofferid);
      await sleep(2000);
      useStore.getState().fetchOffers(selectedAccountID);
    } else if (!result || result?.error) {
      Toast.error(result?.error ?? "Error");
    }
  }, [offer]);

  const declineTrade = useCallback(async () => {
    const { selectedAccountID } = useStore.getState();
    const result = (
      await api.declineTradeOffer({
        tradeOfferId: offer.tradeofferid,
        myAccountID: selectedAccountID,
      })
    )?.data;
    if (result?.success) {
      Toast.success(result.success);
      useStore.getState().removeOffers(selectedAccountID, offer.tradeofferid);
      await sleep(2000);
      useStore.getState().fetchOffers(selectedAccountID);
    } else if (!result || result?.error) {
      Toast.error(result?.error ?? "Error");
    }
  }, [offer]);

  return (
    <div key={offer.tradeofferid}>
      <div className="flex gap-5 items-center">
        <span>{offer.tradeofferid}</span>
        <span>
          <SteamAvatar avatarHash={offer.partner.avatarHash} alt={offer.partner.name} className="w-5 h-5" />
          <a href={"https://steamcommunity.com/profiles/" + offer.partner.steamId}>{[offer.partner.name, accounts?.[offer.partner.steamId]?.username].filter(Boolean).join(" - ")}</a>
        </span>
        <SimpleButton onClick={acceptTrade}>Accept trade</SimpleButton>
        <SimpleButton onClick={declineTrade}>Decline trade</SimpleButton>
      </div>
      <div className="flex justify-start">
        {renderItems("My Items", myItems)}
        {renderItems("Their Items", theirItems)}
      </div>
    </div>
  );
});
