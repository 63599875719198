import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import api from "api";
import Toast from "toast";
import SimpleButton from "../shared/SimpleButton";
import { GetPriceValueAsInt, sleep, v_currencyformat } from "../utils";
import SimpleAsset from "../components/SimpleAsset";
import useStore from "../store";

export function SellItemModal({ handleOk, handleCancel, assets, visible = true, defaultValue }) {
  const [displayAssets, setDisplayAssets] = useState(assets);
  const assetPriceInCents = useStore((state) => state.assetPriceInCents);
  const formRef = useRef();

  const [mySellingPrices, setMySellingPrices] = useState({});
  const setSellingPrice = useCallback((assetid, price) => {
    setMySellingPrices(function (prev) {
      return {
        ...prev,
        [assetid]: price,
      };
    });
  }, []);

  const increase100SellingPrice = useCallback((assetid) => {
    setMySellingPrices(function (prev) {
      return {
        ...prev,
        [assetid]: prev[assetid] + 10000,
      };
    });
  }, []);

  const increase500SellingPrice = useCallback((assetid) => {
    setMySellingPrices(function (prev) {
      return {
        ...prev,
        [assetid]: prev[assetid] + 50000,
      };
    });
  }, []);

  const decrease100SellingPrice = useCallback((assetid) => {
    setMySellingPrices(function (prev) {
      const newPrice = prev[assetid] - 10000;
      if (newPrice <= 0) {
        return prev;
      }
      return {
        ...prev,
        [assetid]: newPrice,
      };
    });
  }, []);

  const [loading, setLoading] = useState({});
  const [fetchingPrice, setFetchingPrice] = useState(false);
  const [soldItems, setSoldItems] = useState({});
  const successCount = useRef(0);

  useEffect(() => {
    (async () => {
      setFetchingPrice(true);
      await useStore.getState().fetchAssetPriceInCents(assets);
      setFetchingPrice(false);
    })();
  }, [assets]);

  const onFinish = useCallback(async () => {
    if (typeof handleOk === "function") {
      await handleOk(successCount.current);
    }
  }, [handleOk]);

  //sellOrList: sell list
  const sellItem = useCallback(
    async (asset, price) => {
      if (!assetPriceInCents[asset.market_hash_name] || !price) {
        return Toast.error(asset.market_hash_name + " no price");
      }

      console.log(`Selling ${asset.market_hash_name} with price ${v_currencyformat(price)}`);

      let success = false;
      setLoading((_loading) => ({
        ..._loading,
        [asset.assetid]: true,
      }));

      await sleep(500);
      const sellingResult = (
        await api.sellItem({
          steamId: asset.steamId,
          appid: asset.appid,
          amount: 1,
          contextid: parseInt(asset.contextid) || 2,
          assetid: asset.assetid,
          price: Math.round(price * 0.85),
        })
      )?.data;
      if (!sellingResult || sellingResult.success === false) {
        Toast.error(sellingResult?.message || "Selling failed");
      } else if (sellingResult?.requires_confirmation) {
        if (sellingResult.needs_mobile_confirmation) {
          Toast.success("Please confirm on Steam Mobile app");
        } else {
          Toast.success("Please confirm on email " + sellingResult?.email_domain);
        }
      } else {
        Toast.success("Selling success " + asset.market_hash_name);
        success = true;
        setSoldItems((prev) => ({
          ...prev,
          [asset.assetid]: true,
        }));
      }
      console.log(sellingResult);
      setLoading((_loading) => ({
        ..._loading,
        [asset.assetid]: false,
      }));
      return success;
    },
    [assetPriceInCents],
  );

  useEffect(() => {
    if (displayAssets?.length === 0) {
      onFinish();
    }
  }, [onFinish, displayAssets]);

  const autoSellItemUnderPrice = useCallback(
    async (minPrice, maxPrice, extraPrice) => {
      const minPriceInt = minPrice ? GetPriceValueAsInt(minPrice) : 0;
      const maxPriceInt = GetPriceValueAsInt(maxPrice);
      const extraPriceInt = GetPriceValueAsInt(extraPrice);

      if (!maxPriceInt) {
        return Toast.error(`Invalid maxPrice ${maxPrice}`);
      }

      const assets = displayAssets.filter(function (asset) {
        return assetPriceInCents[asset.market_hash_name]?.lowest_sell_order && assetPriceInCents[asset.market_hash_name]?.lowest_sell_order > minPriceInt && assetPriceInCents[asset.market_hash_name]?.lowest_sell_order < maxPriceInt;
      });

      for (const asset of assets) {
        const success = await sellItem(asset, assetPriceInCents[asset.market_hash_name].lowest_sell_order + extraPriceInt);
        if (success) {
          successCount.current++;
          setDisplayAssets((_displayAssets) => _displayAssets.filter((a) => a !== asset));
        }
      }
    },
    [displayAssets, assetPriceInCents, sellItem],
  );

  const autoSellMenu = useMemo(() => {
    return [
      {
        label: "under 1000₫",
        async onClick() {
          await autoSellItemUnderPrice("500₫", "1000₫", "100₫");
        },
      },
      {
        label: "under 5000₫",
        async onClick() {
          await autoSellItemUnderPrice("1000₫", "5000₫", "500₫");
        },
      },
      {
        label: "under 10000₫",
        async onClick() {
          await autoSellItemUnderPrice("5000₫", "10000₫", "800₫");
        },
      },
      {
        label: "under 20000₫",
        async onClick() {
          await autoSellItemUnderPrice("10000₫", "20000₫", "1000₫");
        },
      },
    ];
  }, [autoSellItemUnderPrice]);

  return (
    <React.Fragment>
      <Modal
        width={1000}
        style={{
          maxHeight: "80vh",
          maxWidth: "90vw",
        }}
        open={visible}
        title={`Selling ${assets.length === 1 ? assets[0].market_hash_name : `${assets.length} items`}`}
        onOk={() => {
          formRef.current.submit();
        }}
        onCancel={handleCancel}
      >
        <div className="flex items-center gap-5 my-5">
          {!!displayAssets?.length && (
            <>
              <SimpleButton
                onClick={async function () {
                  for (const asset of displayAssets) {
                    const price = assetPriceInCents[asset.market_hash_name]?.lowest_sell_order;
                    if (!price) {
                      continue;
                    }
                    let extraPrice = 0;
                    if (price < GetPriceValueAsInt("500₫")) {
                      extraPrice = 0;
                    } else if (price < GetPriceValueAsInt("1000₫")) {
                      extraPrice = "100₫";
                    } else if (price < GetPriceValueAsInt("5000₫")) {
                      extraPrice = "500₫";
                    } else if (price < GetPriceValueAsInt("10000₫")) {
                      extraPrice = "800₫";
                    } else if (price < GetPriceValueAsInt("20000₫")) {
                      extraPrice = "1200₫";
                    } else if (price < GetPriceValueAsInt("40000₫")) {
                      extraPrice = "2500₫";
                    } else {
                      extraPrice = "4000₫";
                    }
                    const extraPriceInt = typeof extraPrice === "string" ? GetPriceValueAsInt(extraPrice) : extraPrice;

                    const success = await sellItem(asset, price + extraPriceInt);
                    if (success) {
                      successCount.current++;
                      setDisplayAssets((_displayAssets) => _displayAssets.filter((a) => a !== asset));
                    }
                  }
                }}
              >
                Auto Sell All
              </SimpleButton>
              <SimpleButton
                disabled={fetchingPrice}
                onClick={async function () {
                  await autoSellItemUnderPrice("0₫", "500₫", "0₫");
                }}
                menu={autoSellMenu}
              >
                Sell All under 500₫
              </SimpleButton>
              <Button
                loading={fetchingPrice}
                disabled={fetchingPrice}
                onClick={async function () {
                  setFetchingPrice(true);
                  await useStore.getState().fetchAssetPriceInCents(assets);
                  setFetchingPrice(false);
                }}
              >
                Get prices
              </Button>
            </>
          )}
        </div>
        <Form className="max-w-[80vw] max-h-[70vh] overflow-y-auto" ref={formRef} onFinish={onFinish} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} layout="horizontal" autoComplete="off">
          {displayAssets.map(function (asset, index) {
            const disabled = asset.marketable !== 1 || soldItems[asset.assetid];

            return (
              <div className="py-2 border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-slate-200 hover:bg-slate-100 [&:not(:hover)]:opacity-90" key={asset.assetid + asset.market_hash_name}>
                <div className="flex items-center gap-3">
                  <SimpleAsset asset={asset} classNames="cursor-pointer hover:text-blue-600" index={index + 1} />
                  <Button disabled={!mySellingPrices[asset.assetid] || mySellingPrices[asset.assetid] < 0} onClick={() => decrease100SellingPrice(asset.assetid)}>
                    -100₫
                  </Button>
                  <Input rootClassName="w-32" value={v_currencyformat(mySellingPrices[asset.assetid])} />
                  <Button disabled={!mySellingPrices[asset.assetid] || mySellingPrices[asset.assetid] < 10000} onClick={() => increase100SellingPrice(asset.assetid)}>
                    +100₫
                  </Button>
                  <Button disabled={!mySellingPrices[asset.assetid] || mySellingPrices[asset.assetid] < 50000} onClick={() => increase500SellingPrice(asset.assetid)}>
                    +500₫
                  </Button>

                  {!disabled && (
                    <>
                      <Button
                        onClick={async function () {
                          setSellingPrice(asset.assetid, assetPriceInCents[asset.market_hash_name]?.highest_buy_order);
                        }}
                        loading={!!loading[asset.assetid]}
                      >
                        Sell at {v_currencyformat(assetPriceInCents[asset.market_hash_name]?.highest_buy_order)}
                      </Button>
                      <Button
                        onClick={async function () {
                          setSellingPrice(asset.assetid, assetPriceInCents[asset.market_hash_name]?.lowest_sell_order);
                        }}
                        loading={!!loading[asset.assetid]}
                      >
                        List at {v_currencyformat(assetPriceInCents[asset.market_hash_name]?.lowest_sell_order)}
                      </Button>
                      {!!mySellingPrices[asset.assetid] && (
                        <Button
                          onClick={async function () {
                            const success = await sellItem(asset, mySellingPrices[asset.assetid]);
                            if (success) {
                              successCount.current++;
                              setDisplayAssets((_displayAssets) => _displayAssets.filter((a) => a !== asset));
                            }
                          }}
                          loading={!!loading[asset.assetid]}
                        >
                          Sell now at {v_currencyformat(mySellingPrices[asset.assetid])}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </Form>
      </Modal>
    </React.Fragment>
  );
}
