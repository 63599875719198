import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select } from "antd";
import _ from "lodash";
import useStore, { useAccountBySteamId } from "store";
import api from "api";
import Toast from "../toast";

function RealChangeMyAccountNameModal({ steamId }) {
  const setRenameSteamModal = useStore((state) => state.setRenameSteamModal);
  const myAccount = useAccountBySteamId(steamId);
  const formRef = useRef();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newName, setNewName] = useState(myAccount?.personaName || "");
  const [delay_hour, setdelay_hour] = useState();
  const inputNewNameRef = useRef();
  const inputDelayHourRef = useRef();

  const handleCancel = useCallback(() => {
    setRenameSteamModal(null);
  }, [setRenameSteamModal]);

  const handleOk = useCallback(
    async ({ name, delay_hour }) => {
      const result = (
        await api.renameUser({
          steamId,
          name,
          delay_hour,
        })
      )?.data;
      if (result?.success === true) {
        Toast.success("Renamed to " + name);
      }
      if (result?.message) {
        Toast.error(result?.message);
      }
      console.log(result);
      setRenameSteamModal(null);
    },
    [setRenameSteamModal, steamId],
  );

  useEffect(() => {
    const timeouts = [];
    const observer = new MutationObserver(function () {
      const chat_sending_input = inputNewNameRef.current;
      if (chat_sending_input) {
        observer.disconnect();
        timeouts.forEach((t) => clearTimeout(t));
        chat_sending_input.focus();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    timeouts.push(
      setTimeout(function () {
        observer.disconnect();
      }, 10000),
    );
  }, []);

  async function onFinish() {
    if (typeof handleOk === "function") {
      setConfirmLoading(true);
      await handleOk({ name: newName, delay_hour });
      setConfirmLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Modal
        width={700}
        style={{
          maxHeight: "80vh",
          maxWidth: "80vw",
        }}
        open={true}
        title="Rename"
        onOk={() => {
          formRef.current.submit();
        }}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <Form
          className="max-w-[70vw] max-h-[70vh] overflow-y-auto"
          ref={formRef}
          initialValues={{
            name: myAccount?.personaName,
          }}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          disabled={confirmLoading}
          autoComplete="off"
        >
          <Form.Item className="w-full" label="New name">
            <div className="flex">
              <Input
                ref={inputNewNameRef}
                onKeyDown={(event) => {
                  if (event.code === "Enter") {
                    event.preventDefault();
                    onFinish();
                  } else if (event.code === "Tab") {
                    event.preventDefault();
                    inputDelayHourRef.current?.focus();
                  }
                }}
                allowClear
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
              <Select
                onChange={(e) => {
                  setNewName(e);
                }}
                options={_.uniq(myAccount?.historyName?.toReversed() || []).map((o) =>
                  typeof o === "string" || typeof o === "number"
                    ? {
                        value: o,
                        label: o,
                      }
                    : o,
                )}
                placeholder="History Name"
              ></Select>
            </div>
          </Form.Item>

          <Form.Item className="w-full" label="Revert hours">
            <div className="flex">
              <InputNumber
                ref={inputDelayHourRef}
                value={delay_hour}
                onKeyDown={(event) => {
                  if (event.code === "Enter") {
                    event.preventDefault();
                    onFinish();
                  }
                }}
                onChange={(e) => setdelay_hour(e.target.value)}
              />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export function ChangeMyAccountNameModal() {
  const steamId = useStore((state) => state.renameModal);
  if (!steamId) return;
  return <RealChangeMyAccountNameModal steamId={steamId} />;
}
