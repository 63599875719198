import "./l2p.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Checkbox } from "antd";
import copy from "copy-to-clipboard";
import useStore from "store";
import SimpleButton from "../shared/SimpleButton";
import api from "api";
import useInterval from "../useInterval";

export default function L2P() {
  const accountList = useStore((state) => state.accountList);
  const [prime, setPrime] = useState(false);
  const [nonprime, setNonPrime] = useState(true);
  const [ignoreMyAccount, setIgnoreMyAccount] = useState(true);
  const [ignoreFriend, setIgnoreFriend] = useState(true);
  const [players, setPlayers] = useState([]);
  const subToolRef = useRef();
  const bodyRef = useRef();

  const l2p = useCallback(() => {
    api
      .l2p_nonprime({
        prime: prime && nonprime ? "all" : prime ? "prime" : "nonprime",
      })
      .then(function (result) {
        const players = result.data;
        if (!(Array.isArray(players) && players.length)) {
          return;
        }
        setPlayers(function (__players) {
          const _players = __players.filter((p) => !players.some((_p) => _p.steamId === p.steamId));
          _players.unshift(...players);
          return _players;
        });
      });
  }, [prime, nonprime]);

  useInterval(function () {
    l2p();
  }, 10000);

  useEffect(() => {
    l2p();
  }, []);

  useEffect(() => {
    if (!bodyRef.current || !subToolRef.current) return;
    const subToolBoundingClientRect = subToolRef.current.getBoundingClientRect();
    bodyRef.current.style.height = `calc(100vh - ${subToolBoundingClientRect.y + subToolBoundingClientRect.height + 1}px)`;
  }, [bodyRef, subToolRef]);

  const handleOnClickClearAll = useCallback(() => {
    setPlayers([]);
  }, []);

  return (
    <div id="l2p_block">
      <div className="geader_block" ref={subToolRef}>
        <span id="numberOfPlayers">{players.length}</span>
        <Checkbox checked={prime} onChange={(e) => setPrime(e.target.checked)}>
          Prime
        </Checkbox>
        <Checkbox checked={nonprime} onChange={(e) => setNonPrime(e.target.checked)}>
          Non-Prime
        </Checkbox>
        <SimpleButton onClick={handleOnClickClearAll}>Clear All</SimpleButton>
        <Checkbox checked={ignoreMyAccount} onChange={(e) => setIgnoreMyAccount(e.target.checked)}>
          Ignore my account
        </Checkbox>
        <Checkbox checked={ignoreFriend} onChange={(e) => setIgnoreFriend(e.target.checked)}>
          Ignore friend
        </Checkbox>
      </div>
      <div className="body_block w-full overflow-y-auto" ref={bodyRef}>
        {players.map(function (player) {
          if (ignoreMyAccount && accountList.some((a) => a.steamId === player.steamId)) {
            return "";
          }
          if (ignoreFriend && accountList.some((a) => a.friendsIDList?.includes?.(player.steamId))) {
            return "";
          }
          return (
            <div style={{ padding: 5 }} key={player.steamId}>
              <Button onClick={() => copy(player.friendCode)}>Copy friend code</Button>
              ~
              <img style={{ width: 50, height: 50 }} src={"https://avatars.akamai.steamstatic.com/" + (player.avatar_hash === "0000000000000000000000000000000000000000" ? "fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb" : player.avatar_hash) + "_full.jpg"} />~
              <a href={`https://steamcommunity.com/profiles/${player.steamId}`} target="_blank" rel="noreferrer">
                {player.player_name}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
