import React, { useCallback, useMemo, useState } from "react";
import "./ChatRoomHeader.scss";
import { getInviteName, getMyName, getPersonaName } from "socket";
import { doUpdateFriendInfo, generateMenuItems, getFriendName, getQuickInviteLink, getReplyMsg, isAccountLimited, SwalSelectPlayer } from "../../utils";
import api from "api";
import _ from "lodash";
import { v4 } from "uuid";
import { Dropdown } from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import Toast from "toast";
import MenuItem from "../../shared/MenuItem";
import useStore, { useFriend, useMyParty, usePlayerState } from "store";
import { useAccountByCompare, useFriendInfo } from "../../store/UserSlice";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import RefreshIcon from "../../image/refresh.png";
import { processChatHistory } from "../Chat";

export default function ChatRoomHeader({ selectedMyAccountInputRef }) {
  const selectedRoomID = useStore((state) => state.selectedRoomID);
  const roomList = useStore((state) => state.roomList);
  const customLobby = useStore((state) => state.customLobby);
  const [loading, setLoading] = useState(false);

  const sendMessageSelectedRoom = useCallback(async (params) => {
    const { selectedRoomID, roomList } = useStore.getState();
    const selectedRoom = roomList.find((r) => r._id === selectedRoomID);
    if (!selectedRoom) {
      return Toast.error("Cannot find this room " + selectedRoomID);
    }

    return await api.sendSteamMessage({
      ...params,
      receivers: {
        room: _.omit(selectedRoom, ["messages", "last_message"]),
      },
    });
  }, []);

  const room = useMemo(
    function () {
      return selectedRoomID ? roomList?.find((r) => r?._id === selectedRoomID) : {};
    },
    [roomList, selectedRoomID],
  );

  const roomName = useMemo(
    function () {
      return room?.recipients?.map((recipient) => recipient.personaName)?.join(" - ");
    },
    [room],
  );

  const recipientSteamId = room?.recipients?.[0]?.steamId;
  const prime = room?.recipients?.[0]?.primeStatus;
  const playingTime = room?.recipients?.[0]?.playingTime;

  const {
    friendsInfoList: [friendsInfo],
  } = useFriendInfo(recipientSteamId);

  const friend = useFriend(recipientSteamId);

  const displayRoomName = useMemo(
    function () {
      const minutes_played = Math.floor(playingTime?.minutes_played / 60);
      const minutes_played_forever = Math.floor(playingTime?.minutes_played_forever / 60);
      const minutes_played_str = playingTime?.minutes_played ? ` ${minutes_played}h/${minutes_played_forever}h` : "";
      const text = (prime ? `[${prime}] ` : "") + roomName + minutes_played_str;

      return (
        <span
          className={classNames({
            italic: friend?.summary?.isLimitedAccount === 1,
          })}
        >
          {text}
        </span>
      );
    },
    [playingTime, prime, roomName, friend?.summary?.isLimitedAccount],
  );

  const customLobbyList = useMemo(() => Object.keys(customLobby), [customLobby]);
  const watchedCustomLobbyList = useMemo(
    function () {
      const list = customLobbyList.filter(function (steam_player_group) {
        return customLobby[steam_player_group].watchers.includes(recipientSteamId);
      });
      return list.length ? list : null;
    },
    [customLobby, customLobbyList, recipientSteamId],
  );
  const notWatchedCustomLobbyList = useMemo(
    function () {
      const list = customLobbyList.filter(function (steam_player_group) {
        return !customLobby[steam_player_group].watchers.includes(recipientSteamId);
      });
      return list.length ? list : null;
    },
    [customLobby, customLobbyList, recipientSteamId],
  );

  const { myParty, strangerParty } = useMyParty(recipientSteamId);

  const accountListFriendCompareFn = useCallback(
    (account) => {
      return recipientSteamId && account.friendsIDList?.includes?.(recipientSteamId);
    },
    [recipientSteamId],
  );
  const accountListFriend = useAccountByCompare(accountListFriendCompareFn);

  const accountListNotFriendCompareFn = useCallback(
    (account) => {
      return recipientSteamId && account.config?.peopleAddFriend && !account.friendsIDList?.includes?.(recipientSteamId);
    },
    [recipientSteamId],
  );
  const accountListNotFriend = useAccountByCompare(accountListNotFriendCompareFn);

  const menuItems = useMemo(
    function () {
      const items = [
        MenuItem.OpenSteamProfile,
        {
          label: "Copy",
          children: [MenuItem.CopySteamProfile, MenuItem.CopySteamID, MenuItem.CopyFriendCode],
        },
        {
          label: "Update friend info",
          onClick: doUpdateFriendInfo,
        },
        {
          label: "Invite",
          children: [MenuItem.Invite2PlayNow, MenuItem.Invite2PlayCustom, MenuItem.Invite2PlayCustom20H],
        },
      ];

      if (accountListNotFriend.length) {
        items.push({
          label: "Invite to add friend other account",
          children: accountListNotFriend.map((account) => ({
            label: account.username,
            async onClick(steamId) {
              const invite_link = await getQuickInviteLink(account);
              if (!invite_link) {
                return Toast.error("Cannot get invite link");
              }
              const content = [`${_.sample(["kết bạn", "add friend", "add", "thêm bạn"])} nick ${_.sample(["mới", "khác", "này"])} của ${await getMyName(steamId)} ${_.sample(["đi", "đê", "ik", "với"])}`, invite_link];
              sendMessageSelectedRoom({
                messages: content,
              });
              Toast.success("Send invite link to " + getPersonaName(steamId));
            },
          })),
        });
        items.push({
          label: "Send invite link of other account",
          children: accountListNotFriend.map((account) => ({
            label: account.username,
            async onClick(steamId) {
              sendMessageSelectedRoom({
                messages: await getQuickInviteLink(account),
              });
            },
          })),
        });
      }

      items.push({
        label: "Remind custom",
        children: [moment().hours(20).minutes(0).seconds(0).isAfter(moment()) ? "20h" : null, "20h Tomorrow"].filter(Boolean).map((time) => ({
          label: time,
          async onClick(steamId) {
            await api.cancelScheduleMessages({
              roomId: useStore.getState().selectedRoomID,
            });
            let remindTime = null;
            switch (time) {
              case "20h": {
                remindTime = moment().hours(20).minutes(0).seconds(0);
                break;
              }
              case "20h Tomorrow": {
                remindTime = moment().add(1, "days").hours(20).minutes(0).seconds(0);
                break;
              }
              default: {
              }
            }

            if (!remindTime) {
              return;
            }

            const nowMoment = moment();

            if (!remindTime.isAfter(nowMoment)) {
              return Toast.error("Too late");
            }

            function getMsg() {
              return [["xíu", "lát", "tý"], ["nữa", ""], [`${remindTime.format("h")}h`, ""], ["chơi", "bắn", ""], ["custom"], ["nhé", "nha", "nhớ"]]
                .map(function (m) {
                  return _.sample(Array.isArray(m) ? m : [m]);
                })
                .filter(Boolean)
                .join(" ");
            }

            //before 30 mins
            const scheduleBefore30Mins = remindTime.clone().subtract(30, "minutes");
            if (scheduleBefore30Mins.isAfter(nowMoment)) {
              sendMessageSelectedRoom({
                messages: {
                  content: getMsg(),
                  scheduleAt: scheduleBefore30Mins.valueOf(),
                  reply: getReplyMsg(),
                },
              });
            }

            //before 10 mins
            const scheduleBefore10Mins = remindTime.clone().subtract(10, "minutes");
            if (scheduleBefore10Mins.isAfter(nowMoment)) {
              sendMessageSelectedRoom({
                messages: {
                  content: getMsg(),
                  scheduleAt: scheduleBefore10Mins.valueOf(),
                  reply: getReplyMsg(),
                },
              });
            }

            //now
            const friendName = getInviteName(steamId);
            let inviteName = friendName || ["pro", ""];
            if (!Array.isArray(inviteName)) {
              inviteName = [inviteName];
            }
            sendMessageSelectedRoom({
              messages: {
                content: [["tới", "đến"], ["giờ"], ["rồi"], ["chơi", "bắn", "vào"], ["đi", "đê"], inviteName]
                  .map(function (m) {
                    return _.sample(Array.isArray(m) ? m : [m]);
                  })
                  .filter(Boolean)
                  .join(" "),
                scheduleAt: remindTime.valueOf(),
                reply: getReplyMsg(),
              },
            });

            Toast.success(`Remind custom ${friendName}`);
          },
        })),
      });

      items.push({
        label: "Cancel schedule messages",
        onClick: (steamId) => {
          api
            .cancelScheduleMessages({
              roomId: useStore.getState().selectedRoomID,
            })
            .then(function (result) {
              if (result?.data?.deletedCount === 1) {
                Toast.success(`Cancel schedule messages ${getFriendName(steamId)} successfully`);
              } else {
                Toast.error(`Cancel schedule messages ${getFriendName(steamId)} failed`);
              }
            });
        },
      });

      if (accountListFriend.length) {
        items.push({
          label: "Unfriend",
          children: accountListFriend.map(function (a) {
            const myName = a.username + " - " + a.personaName;
            const myAccountSteamId = a.steamId;
            return {
              label: (
                <span
                  className={classNames({
                    italic: isAccountLimited(a),
                  })}
                >
                  {myName}
                </span>
              ),
              key: myAccountSteamId,
              async onClick(steamId) {
                const friendName = await getFriendName(steamId);
                const confirmResult = await Swal.fire({
                  title: `Do you want to unfriend ${friendName} (${myName})`,
                  showDenyButton: true,
                  showCancelButton: true,
                  confirmButtonText: "Unfriend",
                  denyButtonText: `Cancel`,
                });
                if (!confirmResult.isConfirmed) {
                  return;
                }
                const result = (
                  await api.removeFriends({
                    myAccountID: myAccountSteamId,
                    steamIds: steamId,
                  })
                )?.data;
                console.log(result);
                if (result?.success === 1) {
                  Toast.success(`Unfriended ${friendName} (${myName})`);
                } else {
                  Toast.error(`Unfriended ${friendName} failed (${myName}) ${result?.error}`);
                }
              },
            };
          }),
        });
      }

      if (notWatchedCustomLobbyList) {
        items.push({
          label: "Watch custom lobby",
          children: notWatchedCustomLobbyList.map((steam_player_group) => ({
            label: steam_player_group,
            async onClick(steamId) {
              useStore.getState().watchCustomLobby({ steamId, steam_player_group });
            },
          })),
        });
      }
      if (watchedCustomLobbyList) {
        items.push({
          label: "Unwatch custom lobby",
          children: watchedCustomLobbyList.map((steam_player_group) => ({
            label: steam_player_group,
            async onClick(steamId) {
              useStore.getState().unwatchCustomLobby({ steamId, steam_player_group });
            },
          })),
        });
      }

      items.push({
        label: "Create party",
        async onClick(steamId) {
          Swal.fire({
            title: "Submit your Party Name",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.isConfirmed) {
              const lobby_name = result.value;
              const lobby_id = v4();
              const member_steamid = recipientSteamId;
              api.addToParty({ lobby_name, lobby_id, member_steamid }).then(function (result) {
                if (Array.isArray(result.data)) {
                  useStore.setState({ party: result.data });
                }
              });
            }
          });
        },
      });

      if (myParty.length) {
        items.push({
          label: "Remove from party",
          children: myParty.map(function ({ lobby_id, lobby_name, member_steamid, memberCount }) {
            return {
              label: `${lobby_name}${memberCount ? " (" + memberCount + ")" : ""}`,
              key: lobby_id,
              async onClick() {
                api
                  .removeFromParty({
                    lobby_id,
                    member_steamid: recipientSteamId,
                  })
                  .then(function (result) {
                    if (Array.isArray(result.data)) {
                      useStore.setState({ party: result.data });
                    }
                  });
              },
            };
          }),
        });
      }

      if (strangerParty.length) {
        items.push({
          label: "Add to party",
          children: strangerParty.map(function ({ lobby_id, lobby_name, member_steamid, memberCount }) {
            return {
              label: `${lobby_name}${memberCount ? " (" + memberCount + ")" : ""}`,
              key: lobby_id,
              async onClick() {
                api
                  .addToParty({
                    lobby_id,
                    member_steamid: recipientSteamId,
                  })
                  .then(function (result) {
                    if (Array.isArray(result.data)) {
                      useStore.setState({ party: result.data });
                    }
                  });
              },
            };
          }),
        });
      }

      items.push({
        label: "Invite to lobby CS2",
        async onClick(steamId) {
          api.invite2LobbyCs2({ steamIds: steamId });
        },
      });

      items.push({
        label: "Watch player",
        async onClick(steamId) {
          const { friendList } = useStore.getState();
          SwalSelectPlayer({
            options: _.sortBy(friendList, function (friend) {
              return friend.state?.game ? -1 : 0;
            }),
            optionLabelField: [
              "personaName",
              function (friend) {
                return friend.state?.score;
              },
            ],
            optionClassNameField: function (friend) {
              return friend.state?.game ? "text-green-600" : null;
            },
            optionValueField: "steamId",
            optionIconField: (a) => `https://avatars.akamai.steamstatic.com/${a.avatarHash}_full.jpg`,
            title: "Select player",
            key: "select_player_to_watch",
            confirmButtonText: "Select",
            callback(player) {
              if (!player) {
                return;
              }
              console.log(player);
            },
          });
        },
      });

      items.push({
        label: "Send my discord",
        async onClick() {
          const result = await api.generateDiscordInviteLink();
          const inviteLink = result?.data?.inviteLink;
          if (!inviteLink) return;

          let firstMsg = [["nick", "acc", "tài khoản", "tk", "account"], ["discord", "dis", ""], ["của", ""], getMyName(recipientSteamId), "natri2099"]
            .map((txt) => (Array.isArray(txt) ? _.sample(txt) : txt))
            .filter(Boolean)
            .join(" ")
            .trim();
          if (_.random(1, 2) > 1) {
            firstMsg = _.upperFirst(firstMsg);
          }

          await sendMessageSelectedRoom({
            messages: [firstMsg, inviteLink],
          });
        },
      });

      items.push({
        label: "Send discord link",
        async onClick() {
          const result = await api.generateDiscordInviteLink();
          const inviteLink = result?.data?.inviteLink;
          if (!inviteLink) return;
          await sendMessageSelectedRoom({
            messages: inviteLink,
          });
        },
      });

      items.push({
        label: "Send Natri steam group link",
        async onClick(steamId) {
          sendMessageSelectedRoom({
            messages: "https://steamcommunity.com/groups/natri2099",
          });
        },
      });

      return items;
    },
    [accountListFriend, accountListNotFriend, myParty, notWatchedCustomLobbyList, recipientSteamId, sendMessageSelectedRoom, strangerParty, watchedCustomLobbyList],
  );
  const { handleMenuClick, items } = useMemo(() => generateMenuItems(menuItems), [menuItems]);

  return (
    <React.Fragment>
      <div className="ChatRoomHeaderRoot">
        <div className="w-full flex justify-center gap-x-2 items-center">
          <div className="w-full flex justify-center">
            <img
              src={RefreshIcon}
              className={classNames("w-6 h-6 mr-4", {
                "animate-spin": loading,
              })}
              onClick={function () {
                setLoading(true);
                api.fetchChatHistory({ roomId: selectedRoomID }).then(function (result) {
                  setLoading(false);
                  Array.isArray(result?.data) && processChatHistory(selectedRoomID, result.data);
                });
              }}
            />
            <Dropdown
              menu={{
                items: items,
                onClick: (e) => handleMenuClick(e, room?.recipients[0].steamId),
              }}
              trigger={["contextMenu"]}
            >
              <div
                onClick={async () => {
                  useStore.setState({
                    UpdateFriendInfoModalData: {
                      steamId: recipientSteamId,
                      name: getPersonaName(recipientSteamId),
                    },
                  });
                }}
                className={classNames(`flex items-center gap-2`, {
                  "text-blue-600": friend?.state?.onlineStatus === "online",
                  "text-lime-600": friend?.state?.onlineStatus === "ingame",
                })}
              >
                {!!friendsInfo?.mic && <FontAwesomeIcon icon={faMicrophone} />}
                {displayRoomName}
                {!!friendsInfo?.inviteName && <span> - {friendsInfo?.inviteName}</span>}
                {friend?.state?.score && <span className="text-base">{friend?.state?.score}</span>}
              </div>
            </Dropdown>
          </div>
          <div className="">
            <div onClick={() => useStore.getState().hideChat()} aria-label="Close chat" className="CloseChatBtn">
              <svg className="pcgkmkmd ditlmg2l arzshmzb nvdbi5me" width="24px" height="24px" viewBox="0 0 24 24">
                <g strokeLinecap="round" strokeWidth={2} stroke="var(--disabled-icon)">
                  <line x1={6} x2={18} y1={6} y2={18} />
                  <line x1={6} x2={18} y1={18} y2={6} />
                </g>
              </svg>
              <div className="i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s rnr61an3 s45kfl79 emlxlaya bkmhp75w spb7xbtv" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
