import { Button, Dropdown } from "antd";
import React, { useCallback, useMemo, useState } from "react";

const SimpleButton = React.memo(({ onClick, menu: items, children, className, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const myItems = useMemo(() => {
    return items
      ? items.map(function (item, index) {
          item.key = index;
          return item;
        })
      : null;
  }, [items]);

  const onMenuClick = useCallback(
    async (e) => {
      setLoading(true);
      await myItems[parseInt(e.key)].onClick?.();
      setLoading(false);
    },
    [myItems],
  );

  const menu = useMemo(() => {
    return items ? { items: myItems, onClick: onMenuClick } : null;
  }, [myItems, onMenuClick]);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    await onClick?.();
    setLoading(false);
  }, [onClick]);

  const MyButton = items ? Dropdown.Button : Button;
  return (
    <>
      <MyButton menu={menu} className={`mx-1 w-auto ${className}`} disabled={loading} loading={loading} onClick={handleOnClick} type="default" {...rest}>
        {children}
      </MyButton>
    </>
  );
});
export default SimpleButton
