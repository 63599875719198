import "./FriendBlock.scss";
import React, { useMemo } from "react";
import _ from "lodash";
import Avatar from "chat/Avatar";
import classNames from "classnames";
import useStore, { useAccountsFriend, useMyParty, usePlayerState } from "store";
import MenuItem from "shared/MenuItem";
import ContextMenu from "shared/ContextMenu2";
import Swal from "sweetalert2";
import { doUpdateFriendInfo, followUser, getFriendName, isAccountLimited, unfollowUser } from "utils";
import api from "api";
import Toast from "toast";
import { v4 } from "uuid";
import EloBlock from "../../../myaccount/EloBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { useCustomLobby, useFriendInfo, useIsFollow } from "store/UserSlice";

const FriendBlock = React.memo(({ friend, following, isMyAccount }) => {
  const { steamId, avatarHash, personaName, primeStatus, historyName, state, playingTime, elo, limited_account } = friend;
  const { onlineStatus, score, numPlayers, gameid } = state || {};
  const isCSGO = gameid === 730;
  const game = useStore((state) => state.gameApp[gameid]?.name ?? "");
  const isFollow = useIsFollow(steamId);
  const { friendsInfo } = useFriendInfo(steamId);
  const { accountList: myAccounts } = useAccountsFriend(steamId);
  const { playerState: myAccountsPlayerState } = usePlayerState(myAccounts.map((a) => a.steamId));
  const { myParty, strangerParty } = useMyParty(steamId);
  const discordName = friendsInfo[steamId]?.historyDiscordName?.[0];
  const { isCustomLobby } = useCustomLobby(steamId);

  const myAccountBlock = useMemo(() => {
    if (!myAccounts.length) {
      return null;
    }
    return (
      <div className="flex justify-start items-center text-xs">
        {!!playingTime?.minutes_played && (
          <>
            <span className="text-black">
              {Math.round(playingTime.minutes_played / 60)}h/
              {Math.round(playingTime.minutes_played_forever / 60)}h
            </span>
            &nbsp;&nbsp;
          </>
        )}
        {myAccounts.map(function (_myAccount) {
          const playerState = myAccountsPlayerState[_myAccount.steamId];

          let textColor = "text-black";
          if (playerState?.onlineStatus === "ingame") {
            textColor = "text-green-600";
          } else if (playerState?.onlineStatus === "online") {
            textColor = "text-blue-600";
          }

          return (
            <span className="flex items-center" key={_myAccount.steamId}>
              <span className="w-6 h-6 block relative">
                <Avatar diameter={"100%"} hash={_myAccount.avatarHash} />
              </span>
              <span
                className={classNames(textColor, {
                  italic: isAccountLimited(_myAccount),
                })}
              >
                {_myAccount.personaName} - {_myAccount.username}
              </span>
            </span>
          );
        })}
      </div>
    );
  }, [myAccounts, myAccountsPlayerState, playingTime]);

  const playerDisplayTitle = useMemo(
    function () {
      const { playerState } = useStore.getState();
      const names = [playerState?.[steamId]?.name, personaName, friendsInfo[steamId]?.inviteName, discordName];

      if (following && Array.isArray(historyName)) {
        names.push(...historyName);
      }

      const nameStr = _.uniq(names.filter(Boolean)).join(" - ");
      const fullNames = [nameStr];
      if (limited_account) {
        fullNames.unshift("[L]");
      }

      return (
        <span>
          {!!friendsInfo[steamId]?.mic && <FontAwesomeIcon icon={faMicrophone} />}
          {fullNames.join(" ")}
        </span>
      );
    },
    [steamId, personaName, friendsInfo, discordName, following, historyName, limited_account],
  );

  const shortScore = score?.replace("Premier Competitive", `Premier`)?.replace("Competitive", `Comp`)?.replace("Inferno", `Infer`)?.replace("Mirage", `Mir`)?.replace(" - ", ` `)?.replace("Overpass", `Over`);

  const menu = useMemo(() => {
    const baseMenuItems = [
      MenuItem.OpenSteamProfile,
      {
        label: "Copy",
        children: [MenuItem.CopySteamProfile, MenuItem.CopySteamID, MenuItem.CopyMiniProfileID, MenuItem.CopyFriendCode],
      },
      {
        label: "Update Friend Info",
        onClick: function ({ steamId }) {
          doUpdateFriendInfo(steamId);
        },
      },
      {
        label: "Invite",
        children: [MenuItem.Invite2PlayNow, MenuItem.Invite2PlayCustom, MenuItem.Invite2PlayCustom20H],
      },
      MenuItem.OpenMessage,
      MenuItem.SendQuickMessage,
    ];

    const followMenuItem = isFollow
      ? {
          label: "Unfollow",
          show({ isFollow }) {
            return isFollow;
          },
          onClick: async ({ steamId }) => {
            unfollowUser(steamId);
          },
        }
      : {
          label: "Follow",
          show({ isFollow }) {
            return !isFollow;
          },
          onClick: async ({ steamId }) => {
            followUser(steamId);
          },
        };

    const additionalMenuItems = [
      followMenuItem,
      myAccounts.length > 1 && {
        label: "Unfriend",
        children: myAccounts.map(function (a) {
          const myName = a.username + " - " + a.personaName;
          const myAccountID = a.steamId;
          return {
            key: a.steamId,
            label: (
              <span
                className={classNames({
                  italic: isAccountLimited(a),
                })}
              >
                {myName}
              </span>
            ),
            async onClick({ steamId }) {
              const friendName = await getFriendName(steamId);
              const confirmResult = await Swal.fire({
                title: `Do you want to unfriend ${friendName} (${myName})`,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Unfriend",
                denyButtonText: `Cancel`,
              });
              if (!confirmResult.isConfirmed) {
                return;
              }
              const result = (await api.removeFriends({ myAccountID, steamIds: steamId }))?.data;
              if (result?.success === 1) {
                Toast.success(`Unfriended ${friendName} (${myName})`);
              } else {
                Toast.error(`Unfriended ${friendName} failed (${myName})  ${result?.error}`);
              }
            },
          };
        }),
      },
      myParty.length && {
        label: "Remove from party",
        children: myParty.map(function ({ lobby_id, lobby_name, member_steamid, memberCount }) {
          return {
            key: lobby_id,
            label: `${lobby_name}${memberCount ? " (" + memberCount + ")" : ""}`,
            async onClick() {
              api
                .removeFromParty({
                  lobby_id,
                  member_steamid: steamId,
                })
                .then(function (result) {
                  if (Array.isArray(result.data)) {
                    useStore.setState({ party: result.data });
                  }
                });
            },
          };
        }),
      },
      {
        label: "Create party",
        async onClick({ steamId }) {
          Swal.fire({
            title: "Submit your Party Name",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.isConfirmed) {
              const lobby_name = result.value;
              const lobby_id = v4();
              const member_steamid = steamId;
              api.addToParty({ lobby_name, lobby_id, member_steamid }).then(function (result) {
                if (Array.isArray(result.data)) {
                  useStore.setState({ party: result.data });
                }
              });
            }
          });
        },
      },
      strangerParty.length && {
        label: "Add to party",
        children: strangerParty.map(function ({ lobby_id, lobby_name, member_steamid, memberCount }) {
          return {
            key: lobby_id,
            label: `${lobby_name}${memberCount ? " (" + memberCount + ")" : ""}`,
            async onClick() {
              api
                .addToParty({
                  lobby_id,
                  member_steamid: steamId,
                })
                .then(function (result) {
                  if (Array.isArray(result.data)) {
                    useStore.setState({ party: result.data });
                  }
                });
            },
          };
        }),
      },
      {
        label: "Invite to lobby CS2",
        async onClick({ steamId }) {
          api.invite2LobbyCs2({ steamIds: steamId });
        },
      },
    ];

    return baseMenuItems.concat(additionalMenuItems).filter(Boolean);
  }, [isFollow, steamId, myAccounts, myParty, strangerParty]);

  return (
    <React.Fragment>
      <ContextMenu id={`DropDown_${steamId}`} menu={menu} data={friend} trigger={["contextMenu"]}>
        <div
          id={"friend_block_v2_" + steamId}
          key={"friend_block_v2_" + steamId}
          data-key={steamId}
          className={classNames(`relative selectable h-[80px] w-[500px] flex friend_block_v2 border-2 border-solid mb-1 mr-1`, {
            "text-blue-600": onlineStatus === "online",
            "text-lime-600": onlineStatus === "ingame" && isCSGO,
            "text-lime-400 opacity-90": onlineStatus === "ingame" && !isCSGO,
            "bg-emerald-200": isCustomLobby,
            "bg-white": !isCustomLobby,
            "border-r-indigo-500 border-r-8": isMyAccount,
            "border-[#0988ff]": primeStatus === "prime",
            "border-[#f87ecd]": primeStatus === "non-prime",
          })}
          // onClick={() => onCLickUpdateFriendInfo?.(steamId, realName || personaName)}
        >
          <div className="w-[80px] in-game relative">
            <Avatar className="avatar" hash={avatarHash} diameter={80} circle={false} />
            {typeof elo === "number" && !isNaN(elo) && <EloBlock elo={elo} />}
          </div>
          <div className="text-sm w-[calc(100%-80px)]">
            <span className="flex gap-1 flex-wrap">
              {!!numPlayers && numPlayers > 1 && <span>&nbsp;(+{numPlayers - 1})&nbsp;</span>}
              <span
                className={classNames({
                  italic: limited_account,
                })}
              >
                {playerDisplayTitle}
              </span>
            </span>
            {myAccountBlock}
            <span className="text-xs">
              {isCSGO ? "CS2" : game}
              {!!score && <span>&nbsp;|&nbsp;{`(${shortScore})`}</span>}
            </span>
          </div>
        </div>
      </ContextMenu>
    </React.Fragment>
  );
});

export default FriendBlock;
