import _ from "lodash";
import api from "api";
import Toast from "../toast";
import { getPersonaName } from "../socket";
import localforage from "localforage";
import { v4 } from "uuid";
import { produce } from "immer";

export const createChatSlice = function (set, get) {
  return {
    selectedRoomID: null,
    roomList: [],
    roomFilter: null,
    chatHistoryHasMore: {},
    isGroupChat: false,
    isShowingChat: false,
    minimiseChat: false,
    unreadMessageCount: 0,
    unreadMessageList: [],
    tempGroup: null,
    confirmModalData: null,
    update: (payload) => set(() => ({ ...payload })),
    setConfirmModalData: function (confirmModalData) {
      return set(() => ({ confirmModalData }));
    },
    createTempGroup: function (name) {
      const tempGroup = {
        _id: v4(),
        name: name,
        recipients: [],
        messages: [],
      };
      set(function (prev) {
        return {
          isSearching: false,
          tempGroup,
          roomList: [...prev.roomList, tempGroup],
          selectedRoomID: tempGroup._id,
        };
      });
    },
    deleteTempGroup: function () {
      set(function (prev) {
        const selectedRoomIndex = prev.roomList.findIndex((r) => r._id === prev.tempGroup._id);
        const state = {
          isSearching: false,
          tempGroup: null,
          roomList: prev.roomList.toSpliced(selectedRoomIndex, 1),
        };

        if (prev.selectedRoomID === prev.tempGroup._id) {
          state.selectedRoomID = state.roomList[0]._id;
        }

        return state;
      });
    },
    addTempGroupMember: function (steamId) {
      const friend = get().friends[steamId];
      if (!friend) {
        return;
      }

      return set(function (prev) {
        if (prev.tempGroup.recipients.every((recipient) => recipient.steamId !== steamId)) {
          const tempGroup = {
            ...prev.tempGroup,
            recipients: [
              ...prev.tempGroup.recipients,
              {
                _id: friend._id,
                steamId: friend.steamId,
                personaName: friend.personaName,
                primeStatus: friend.primeStatus,
                avatarHash: friend.avatarHash,
              },
            ],
          };

          const selectedRoomIndex = prev.roomList.findIndex((r) => r._id === prev.tempGroup._id);
          return {
            tempGroup,
            roomList: prev.roomList.toSpliced(selectedRoomIndex, 1, tempGroup),
          };
        } else {
          return prev;
        }
      });
    },
    removeTempGroupMember: function (steamId) {
      const friend = get().friends[steamId];
      if (!friend) {
        return;
      }

      return set(function (prev) {
        const recipientIndex = prev.tempGroup.recipients.findIndex(function (recipient) {
          return recipient.steamId === steamId;
        });
        if (recipientIndex === -1) {
          return prev;
        }
        const selectedRoomIndex = prev.roomList.findIndex((r) => r._id === prev.tempGroup._id);
        const tempGroup = {
          ...prev.tempGroup,
          recipients: prev.tempGroup.recipients.toSpliced(recipientIndex, 1),
        };
        return {
          tempGroup,
          roomList: prev.roomList.toSpliced(selectedRoomIndex, 1, tempGroup),
        };
      });
    },
    setChatHistoryHasMore: (roomId, hasMore) =>
      set(function (chatData) {
        return {
          hasMore: {
            ...chatData.hasMore,
            [roomId]: hasMore,
          },
        };
      }),
    updateRoomList: function (roomList, selectedRoomID) {
      if (!roomList) {
        return;
      }

      if (!Array.isArray(roomList)) {
        roomList = [roomList];
      }

      validRoomListMessages(roomList);

      set(
        produce(function (state) {
          for (const room of roomList) {
            const oldRoom = state.roomList.find((r) => r._id === room._id);
            if (oldRoom) {
              const currentMessage = room.messages.filter(function (msg) {
                return oldRoom.messages.every((m) => m._id !== msg._id);
              });
              sortMessages(oldRoom.messages);
              sortMessages(currentMessage);

              if (_.isEqual(oldRoom.messages, currentMessage)) {
                continue;
              }

              oldRoom.messages.push(...currentMessage);
              sortMessages(oldRoom.messages);
            } else {
              state.roomList.push(room);
            }
          }

          state.isSearching = false;
          state.roomList.sort(function (r1, r2) {
            const last_message_timestamp1 = r1.messages[r1.messages.length - 1]?.timestamp ?? 0;
            const last_message_timestamp2 = r2.messages[r2.messages.length - 1]?.timestamp ?? 0;
            return last_message_timestamp2 - last_message_timestamp1;
          });
          if (selectedRoomID) {
            state.selectedRoomID = selectedRoomID;
          }
        }),
      );
    },
    removeRoomID: function (roomID) {
      api.deleteChatRoom({ roomID }).then(function (result) {
        if (result?.data?.deletedCount === 1) {
          Toast.success("Delete success room " + roomID);
        } else {
          Toast.error("Delete fail room " + roomID);
        }
        set(function (prev) {
          const index = prev.roomList.findIndex((r) => r._id === roomID);
          if (index === -1) {
            return prev;
          }
          const newRoomList = prev.roomList.toSpliced(index, 1);
          localforage.setItem("roomList", newRoomList);
          return {
            isSearching: false,
            roomList: newRoomList,
            selectedRoomID: prev.selectedRoomID === roomID ? newRoomList[0]._id : prev.selectedRoomID,
          };
        });
      });
    },
    setSelectedRoomID: (roomID) =>
      set(
        produce((state) => {
          state.isSearching = false;
          if (state.tempGroup?._id === roomID) {
            state.selectedRoomID = roomID;
          } else {
            const index = state.roomList.findIndex((r) => r._id === roomID);
            if (index !== -1) {
              for (const message of state.roomList[index].messages) {
                message.read = true;
              }
              state.selectedRoomID = roomID;
            }
          }
        }),
      ),
    addNewMessage: async function (messages) {
      if (!messages) {
        return;
      }
      if (!Array.isArray(messages)) {
        messages = [messages];
      }
      if (!messages.length) {
        return;
      }

      messages = messages
        .map(function (message) {
          return (Array.isArray(message.content) ? message.content : [message.content]).map(function (content) {
            return {
              ...message,
              content,
              rooms: null,
            };
          });
        })
        .flat();

      const oldRoomList = get().roomList;
      const new_channel_ids = _.uniq(messages.map((message) => message.channel_ids).flat()).filter((channel_id) => oldRoomList.every((r) => r._id !== channel_id));
      const newRoomList = (
        await Promise.all(
          new_channel_ids.map(function (channel_id) {
            return api.getRoomList({
              roomId: channel_id,
              limit: 1,
            });
          }),
        )
      )
        .map((result) => result?.data?.[0])
        .filter(Boolean);
      if (newRoomList.length) {
        validRoomListMessages(newRoomList);
      }

      set(
        produce(function (state) {
          for (const message of messages) {
            const clientId = message.clientId;
            const channelId = message.channel_ids[0] || message.channel_ids;
            let roomIndex = state.roomList.findIndex((r) => r._id === channelId);
            if (roomIndex === -1) {
              const newRoom = newRoomList.find((r) => r._id === channelId);
              if (!newRoom) {
                continue;
              }
              state.roomList.push(newRoom);
              roomIndex = state.roomList.findIndex((r) => r._id === channelId);
            }

            const room = state.roomList[roomIndex];
            if (room.messages.some(({ _id }) => _id === message._id)) {
              continue;
            }

            const mIndex = clientId ? room.messages.findIndex((m) => m.clientId === clientId) : -1;
            if (mIndex > -1) {
              room.messages[mIndex] = message;
            } else {
              room.messages.push(message);
            }

            room.messages.sort(function (ms1, ms2) {
              const timestamp1 = ms1.timestamp || parseInt(ms1._id.substring(0, 8), 16);
              const timestamp2 = ms2.timestamp || parseInt(ms2._id.substring(0, 8), 16);
              return timestamp1 - timestamp2;
            });

            if (!room.mute && state.scamLink.every((l) => !message.content.includes(l)) && !message.isMe && !["Invited you to play a game!", "Đã mời bạn chơi một trò chơi!"].includes(message.content) && !state.isShowingChat) {
              state.unreadMessageCount++;
              state.unreadMessageList.push(message._id);
            }
          }
          state.roomList.sort(function (r1, r2) {
            const last_message_timestamp1 = r1.messages[r1.messages.length - 1]?.timestamp ?? 0;
            const last_message_timestamp2 = r2.messages[r2.messages.length - 1]?.timestamp ?? 0;
            return last_message_timestamp2 - last_message_timestamp1;
          });
        }),
      );
    },
    readMessage: function (messageID) {
      set(
        produce(function (state) {
          const index = state.unreadMessageList.indexOf(messageID);
          if (index > -1) {
            state.unreadMessageList = state.unreadMessageList.toSpliced(index, 1);
          }

          for (const room of state.roomList) {
            const index = room.messages.findIndex((m) => m._id === messageID);
            if (index === -1) {
              continue;
            }
            if (room.messages[index].read) {
              return;
            }

            api.messageRead({ _id: messageID });
            room.messages[index].read = true;
          }
        }),
      );
    },
    setIsGroupChats: (isGroupChat) =>
      set({
        isGroupChat: !!isGroupChat,
      }),
    setRoomFilter: (roomFilter) => set({ roomFilter }),
    hideChat: () =>
      set({
        isShowingChat: false,
      }),
    showChat: async function (steamId) {
      //steamId: friendId or friend steamId
      set({
        unreadMessageCount: 0,
        unreadMessageList: [],
        isShowingChat: true,
        minimiseChat: false,
      });

      if (steamId && typeof steamId === "string") {
        const { friendList, roomList } = get();
        const friend = friendList.find((friend) => friend._id === steamId || friend.steamId === steamId);
        let room = !!friend && roomList.find((room) => room.recipients.some((recipient) => recipient._id === friend._id));
        if (room) {
          set({
            selectedRoomID: room._id,
          });
        } else {
          const dbRoom = !!friend && (await api.findOrCreateChatRoom(friend))?.data?.room;
          if (dbRoom) {
            get().updateRoomList(dbRoom, dbRoom._id);
          } else {
            Toast.error("Cannot find this room " + getPersonaName(steamId));
          }
        }
      }
    },
    setChatMinimise: (minimise) =>
      set({
        minimiseChat: !!minimise,
      }),
  };
};

export function sortRoomList(list) {
  return _.sortBy(list, function (room) {
    const lastMessage = _.sortBy([room?.messages[0], room?.last_messages?.[0]].filter(Boolean), "timestamp").pop();
    if (!lastMessage?.timestamp) {
      return -parseInt(room._id.substring(0, 8), 16) * 1000;
    }
    return -lastMessage.timestamp;
  });
}

export function sortMessages(messages) {
  messages.sort(function (ms1, ms2) {
    const timestamp1 = ms1.timestamp || parseInt(ms1._id.substring(0, 8), 16);
    const timestamp2 = ms2.timestamp || parseInt(ms2._id.substring(0, 8), 16);
    return timestamp1 - timestamp2;
  });
}

export function toSortMessages(messages) {
  return _.sortBy(messages, [
    "timestamp",
    function (msg) {
      return parseInt(msg._id.substring(0, 8), 16);
    },
  ]);
}

function validRoomListMessages(rooms) {
  for (const room of rooms) {
    if (!room.messages) {
      console.log(room);
    }

    room.messages = _.uniqBy([...(room.messages || []), room.last_message].filter(Boolean), "_id");
    sortMessages(room.messages);
  }
}
