import MatchBlock from "../MatchBlock";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment/moment";
import { Select, Spin } from "antd";
import useStore from "store";
import SimpleButton from "shared/SimpleButton";
import { getbonus_xp_timestamp_refresh } from "../../utils";
import { useLocalStorage } from "@uidotdev/usehooks";
import _ from "lodash";
import MyPlayerPicker from "../../MyPlayerPicker";
import List from "react-virtualized/dist/commonjs/List";
import { LoadingOutlined } from "@ant-design/icons";

const DateRangeList = ["24 hours", "Today", "This week", "7 days", "This drop week", "30 days", "This month"];
const dateRangeOptions = DateRangeList.map((value) => ({
  label: value,
  value: value,
}));

function convertDateRange(date) {
  switch (date) {
    case "24 hours":
      return moment().subtract(24, "hours").valueOf();
    case "Today":
      return moment().startOf("day").valueOf();
    case "7 days":
      return moment().startOf("day").subtract(7, "days").valueOf();
    case "This week":
      return moment().startOf("week").valueOf();
    case "This drop week":
      return getbonus_xp_timestamp_refresh().valueOf();
    case "30 days":
      return moment().startOf("day").subtract(30, "days").valueOf();
    case "This month":
      return moment().startOf("month").valueOf();
  }
}

export default function MyMatchHistory() {
  const mergeAllAccount = useStore((state) => state.mergeAllAccount);
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const matchHistory = useStore((state) => state.matchHistory);
  const [tableFilterRank, setTableFilterRank] = useState();
  const [dateRange, setDateRange] = useLocalStorage("MyMatchHistory_dateRange", DateRangeList[0]);
  const dateRangeRef = useRef(dateRange);
  const headerToolRef = useRef();
  const historyTableRef = useRef();
  const [mapSelected, setMapSelected] = useState();
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [tableSize, setTableSize] = useLocalStorage("tableSize", { width: 400, height: 400 });
  const [loading, setLoading] = useState();

  const currentMatchHistory = useMemo(
    function () {
      return mergeAllAccount ? matchHistory : matchHistory.filter((m) => m.players.some((p) => p.steamId === selectedAccountID));
    },
    [matchHistory, mergeAllAccount, selectedAccountID],
  );

  useEffect(() => {
    setLoading(true);
    useStore
      .getState()
      .getMatchHistory(selectedAccountID, convertDateRange(dateRangeRef.current))
      .then(function () {
        setLoading(false);
      });
  }, [selectedAccountID]);

  useEffect(() => {
    if (!headerToolRef.current || !historyTableRef.current) return;
    // const clientRect = headerToolRef.current.getBoundingClientRect();
    const tableClientRect = historyTableRef.current.getBoundingClientRect();
    setTableSize({
      width: tableClientRect.width,
      height: window.innerHeight - tableClientRect.y,
    });
    // historyTableRef.current.style.marginTop = `${clientRect.height + 1}px`;
  }, [headerToolRef, historyTableRef]); //ignore setTableSize

  const rankOptions = useMemo(() => {
    const listByMap = _.groupBy(currentMatchHistory, function (match) {
      return match.matchInfo.ranked ? "Ranked" : "Unrank";
    });
    return Object.keys(listByMap).map(function (rankType) {
      const count = listByMap[rankType].length;
      return {
        label: `${rankType} (${count})`,
        value: rankType,
        count,
      };
    });
  }, [currentMatchHistory]);

  const mapOptions = useMemo(() => {
    const listByMap = _.groupBy(currentMatchHistory, "matchInfo.map");
    return Object.keys(listByMap).map(function (map) {
      const count = listByMap[map].length;
      return {
        label: `${map} (${count})`,
        value: map,
      };
    });
  }, [currentMatchHistory]);

  const selectedPlayerOptions = useMemo(() => {
    return currentMatchHistory
      .map((m) => m.players)
      .flat()
      .map((p) => p.steamId);
  }, [currentMatchHistory]);

  const displayData = useMemo(() => {
    const dateRangeValue = convertDateRange(dateRange);
    return (
      currentMatchHistory.filter(function (match) {
        if (dateRangeValue > match.timestamp) {
          return false;
        }

        if (mapSelected && match.matchInfo.map !== mapSelected) {
          return false;
        }

        if (selectedPlayer && match.players.every((p) => p.steamId !== selectedPlayer)) {
          return false;
        }

        if (tableFilterRank === "Ranked" && match.matchInfo?.ranked !== true) {
          return false;
        } else if (tableFilterRank === "Unrank" && match.matchInfo?.ranked) {
          return false;
        }
        return true;
      }) || []
    );
  }, [currentMatchHistory, dateRange, mapSelected, selectedPlayer, tableFilterRank]);

  const totalPlayTime = useMemo(
    () =>
      displayData?.reduce(function (previousValue, currentValue, currentIndex) {
        return moment
          .duration("00:" + currentValue.matchInfo.duration)
          .add(previousValue)
          .add(moment.duration("00:" + currentValue.matchInfo.waitTime));
      }, moment.duration(0)),
    [displayData],
  );

  const displayTotalPlayTime = useMemo(() => {
    return totalPlayTime > 60 * 60 ? parseInt(totalPlayTime.as("hours")) + " hours" : totalPlayTime.humanize();
  }, [totalPlayTime]);

  const loadMore = useCallback(async () => {
    const { selectedAccountID, mergeAllAccount, accountList, fetchMatchHistory } = useStore.getState();
    const startTime = convertDateRange(dateRangeRef.current);
    const steamIds = mergeAllAccount ? accountList.map(({ steamId }) => steamId) : [selectedAccountID];
    await fetchMatchHistory(steamIds, startTime);
  }, []);

  const onDateRangeChange = useCallback(
    function (e) {
      dateRangeRef.current = e;
      setDateRange(e);
    },
    [setDateRange],
  );

  const stats = useMemo(() => {
    let win = 0;
    let loss = 0;
    let draw = 0;
    const { selectedAccountID } = useStore.getState();
    for (const match of displayData) {
      const me = match.players.find((p) => p.steamId === selectedAccountID);
      if (!me) continue;
      if (match.scoreboard[0] === match.scoreboard[1]) {
        draw++;
      } else {
        const winTeam = match.scoreboard[0] > match.scoreboard[1] ? "ct" : "t";
        if (me.team === winTeam) {
          win++;
        } else {
          loss++;
        }
      }
    }

    return {
      win,
      loss,
      draw,
    };
  }, [displayData]);

  useEffect(() => {
    setLoading(true);
    useStore
      .getState()
      .getMatchHistory(mergeAllAccount ? null : useStore.getState().selectedAccountID, convertDateRange(dateRange))
      .then(function () {
        setLoading(false);
      });
  }, [mergeAllAccount, dateRange]);

  return (
    <>
      <div ref={headerToolRef} className="bg-white w-full border-b border-t-0 border-l-0 border-r-0 border-slate-200 border-solid py-1 gap-x-5 flex items-baseline">
        <table>
          <thead>
            <tr>
              <th className="font-bold">Total</th>
              {!mergeAllAccount && (
                <>
                  <th className="text-emerald-400">Win</th>
                  <th>Tire</th>
                  <th className="text-red-500">Loss</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="font-bold">{displayData.length}</th>
              {!mergeAllAccount && (
                <>
                  <th className="text-emerald-400">{stats.win}</th>
                  <th>{stats.draw}</th>
                  <th className="text-red-500">{stats.loss}</th>
                </>
              )}
            </tr>
          </tbody>
        </table>
        {!!totalPlayTime && <span>Total time: {displayTotalPlayTime}</span>}

        {mapOptions.length > 1 && (
          <span>
            <span>Map: </span>
            <Select value={mapSelected} onChange={(e) => setMapSelected(e)} className="w-32" options={mapOptions} allowClear />
          </span>
        )}

        {rankOptions.length > 1 && (
          <span>
            <span>
              <span className="text-emerald-400">{`Ranked (${rankOptions.find((r) => r.value === "Ranked").count})`}</span>
              {`/Unrank (${rankOptions.find((r) => r.value === "Unrank").count}):`}
            </span>
            <Select value={tableFilterRank} onChange={(e) => setTableFilterRank(e)} className="w-32" options={rankOptions} allowClear />
          </span>
        )}

        {selectedPlayerOptions.length > 1 && (
          <span>
            <span>Player: </span>
            <MyPlayerPicker selectedPlayer={selectedPlayer} setSelectedPlayer={setSelectedPlayer} list={selectedPlayerOptions} />
          </span>
        )}

        <span>
          <span>Date: </span>
          <Select value={dateRange || "Today"} onChange={onDateRangeChange} className="w-32" options={dateRangeOptions} allowClear />
        </span>
        <SimpleButton onClick={loadMore} type="primary">
          Load more
        </SimpleButton>
      </div>
      <div className="matches_history_table" ref={historyTableRef}>
        <List
          width={tableSize.width}
          height={tableSize.height - 20}
          rowRenderer={({ index, key, style }) => {
            const match = displayData[index];
            return <MatchBlock key={match.matchID} index={index} match={match} style={style} />;
          }}
          rowCount={displayData.length}
          rowHeight={250}
        />
        {!!loading && (
          <div className="absolute left-0 top-0 w-full h-full bg-slate-400 opacity-70 z-10 cursor-progress">
            <div className="absolute left-0 top-0 translate-x-1/2 translate-y-1/2 w-full h-full">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 48,
                    }}
                    spin
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
