import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "antd";

const FilterOption = function FilterOption({ options, value, onChange }) {
  const ref = useRef();
  const triggerRef = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const onClick = function (event) {
      if (triggerRef.current?.contains(event.target)) {
        return setOpen((prev) => !prev);
      }

      if (!ref.current || !ref.current.contains(event.target)) {
        return setOpen(false);
      }
    };

    document.addEventListener("click", onClick);

    return function () {
      document.removeEventListener("click", onClick);
    };
  }, [ref, triggerRef]);

  const onValueChange = useCallback(
    (label, value) => {
      onChange(function (prev) {
        return {
          ...prev,
          [label]: value,
        };
      });
    },
    [onChange],
  );

  const popup = useMemo(() => {
    return (
      <div className="w-full absolute left-0 right-0 top-full z-10 rounded bg-white text-sm overflow-y-auto" style={{ boxShadow: "0 2px 4px rgba(0,0,0,.2)" }}>
        {options.map(function (option) {
          return <FilterOptionItem option={option} valueList={value} onChange={onValueChange} key={option.key} />;
        })}
      </div>
    );
  }, [onValueChange, options, value]);

  const placeholder = useMemo(() => {
    const positiveOptions = options.filter(function (option) {
      return value[option.key] === 1;
    });
    if (positiveOptions.length) {
      return positiveOptions
        .map(function (option) {
          return option.key;
        })
        .join(", ");
    } else {
      return "Select...";
    }
  }, [options, value]);

  return (
    <div className="flex items-center select-none" ref={ref}>
      <div className="w-48 border border-solid border-gray-300 h-9 rounded flex items-center relative hover:border-gray-500">
        <span className="flex w-full h-full flex-row justify-between items-center px-2">
          <span ref={triggerRef} className="text-slate-400 cursor-default shrink w-full">
            {placeholder}
          </span>
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faTimes} className="text-slate-500 cursor-pointer hover:text-black" />
            <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} className="text-slate-500" />
          </div>
        </span>
        {!!open && popup}
      </div>
    </div>
  );
};
export default FilterOption;

const FilterOptionItem = React.memo(function FilterOption({ option, onChange, valueList }) {
  const label = option.label;
  const positive = option.positive || option;
  const negative = option.negative || option;

  return <TrippleState keyIndex={option.key} label={label} positive={positive} negative={negative} onChange={onChange} defaultValue={valueList[option.key] || 0} />;
});

const TrippleState = React.memo(function TrippleState({ defaultValue, onChange, keyIndex, label, positive, negative, className, style }) {
  //value: -1 0 1

  const [value, setValue] = useState(defaultValue);
  const displayLabel = useMemo(() => {
    switch (value) {
      case 1:
        return positive;
      case 0:
        return label;
      case -1:
        return negative;
    }
  }, [label, negative, positive, value]);

  return (
    <div className={`${className || ""} border-solid border-0 border-b border-zinc-200`} style={style}>
      <div
        className="relative text-gray-600 p-2 cursor-pointer hover:bg-gray-300"
        onClick={function (event) {
          event.preventDefault();
          event.stopPropagation();
          const newValue = value + 1 > 1 ? -1 : value + 1;
          onChange?.(keyIndex, newValue);
          setValue(newValue);
        }}
      >
        <Checkbox checked={value > -1} indeterminate={value === 0}>
          {displayLabel}
        </Checkbox>
      </div>
    </div>
  );
});
