import React, { useMemo } from "react";
import { Dropdown } from "antd";
import { generateMenuItems } from "utils";

const ContextMenu = React.memo(({ id, data, menu, children, ...rest }) => {
  const { handleMenuClick, items } = useMemo(() => generateMenuItems(menu, data), [menu]);

  return (
    <React.Fragment>
      <Dropdown
        key={id}
        menu={{
          items,
          onClick: (e) => handleMenuClick(e, data),
        }}
        trigger={["contextMenu"]}
        {...rest}
      >
        {children}
      </Dropdown>
    </React.Fragment>
  );
});

export default ContextMenu;
