import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FollowList from "./features/friend/FollowList";
import MyAccount from "./myaccount/MyAccount";
import "react-toastify/dist/ReactToastify.css";
import Header from "./header/Header";
import React, { lazy, Suspense, useMemo } from "react";
import FriendList from "./friendlist/FriendList";
import Chat from "./chat";
import { SimpleConfirmModal } from "./modal/SimpleConfirmModal";
import L2P from "./l2p/l2p";
import { UpdateFriendInfoModal } from "./myaccount/UpdateFriendInfoModal";
import useStore from "./store";
import { ChangeMyAccountNameModal } from "./modal/ChangeMyAccountNameModal";
import FakeFriendStateModal from "./modal/FakeFriendStateModal";
import ApproveLoginQRModal from "./myaccount/ApproveLoginQRModal";
import Loading from "./components/Loading";
import { ConfirmCodeForAddPhoneModal } from "./modal/ConfirmCodeForAddPhoneModal";

const Config = lazy(() => import("./config/Config"));
const BotLoginQRHistory = lazy(() => import("./botloginqrhistory/BotLoginQRHistory"));
const LevelUp = lazy(() => import("./levelup/LevelUp"));
const CronJob = lazy(() => import("./cronjob/CronJob"));
const Worker = lazy(() => import("./worker/Worker"));
const CheaterWatcher = lazy(() => import("./cheaterwatcher/CheaterWatcher"));
const FriendRequest = lazy(() => import("./friendrequest/FriendRequest"));
const PrimeUpgrade = lazy(() => import("./primeupgrade/PrimeUpgrade"));
const Market = lazy(() => import("./market/Market"));

function App() {
  const accountList = useStore((state) => state.accountList);
  const isShowingChat = useStore((state) => state.isShowingChat);
  const minimiseChat = useStore((state) => state.minimiseChat);
  const unreadMessageCount = useStore((state) => state.unreadMessageCount);

  const routes = useMemo(function () {
    return [
      {
        path: "",
        component: <FollowList />,
        title: "Home",
      },
      {
        path: "account",
        component: <MyAccount />,
        title: "Account",
      },
      {
        path: "friend",
        component: <FriendList />,
        title: "Friend",
      },
      {
        path: "l2p",
        component: <L2P />,
        title: "Looking to play",
      },
      {
        path: "config",
        component: <Config />,
        title: "Config",
      },
      {
        path: "botloginqrhistory",
        component: <BotLoginQRHistory />,
        title: "Bot LoginQR History",
      },
      {
        path: "worker",
        component: <Worker />,
        title: "Worker",
      },
      {
        path: "levelup",
        component: <LevelUp />,
        title: "Level Up",
      },
      {
        path: "cheaterwatcher",
        component: <CheaterWatcher />,
        title: "CheaterWatcher",
      },
      {
        path: "friendrequest",
        component: <FriendRequest />,
        title: "FriendRequest",
      },
      {
        path: "primeupgrade",
        component: <PrimeUpgrade />,
        title: "PrimeUpgrade",
      },

      {
        path: "cronjob",
        component: <CronJob />,
        title: "CronJob",
      },
      {
        path: "market",
        component: <Market />,
        title: "Market",
      },
    ];
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <div id="main_content_body">
        <Routes>
          {routes.map(function ({ component, path }) {
            return <Route path={`/${path}`} element={<Suspense fallback={<Loading />}>{component}</Suspense>} key={path} />;
          })}
        </Routes>
      </div>
      <div id="main_content_header">
        <Header routes={routes} />
      </div>
      {accountList.length > 0 ? (
        <>
          {isShowingChat && <Chat />}
          {(!isShowingChat || minimiseChat) && (
            <div className="fixed cursor-pointer right-5 bottom-5">
              {!!unreadMessageCount && <span className="text-red-500 font-bold text-xl absolute left-0 top-0 bg-slate-50 rounded-full border-slate-600 border p-px block">{unreadMessageCount}</span>}
              <svg onClick={() => useStore.getState().showChat()} viewBox="0 0 28 28" className={`toggleChatIcon w-24 h-24 ${unreadMessageCount ? "unread" : ""}`} fill="currentColor">
                <path d="M14 2.042c6.76 0 12 4.952 12 11.64S20.76 25.322 14 25.322a13.091 13.091 0 0 1-3.474-.461.956 .956 0 0 0-.641.047L7.5 25.959a.961.961 0 0 1-1.348-.849l-.065-2.134a.957.957 0 0 0-.322-.684A11.389 11.389 0 0 1 2 13.682C2 6.994 7.24 2.042 14 2.042ZM6.794 17.086a.57.57 0 0 0 .827.758l3.786-2.874a.722.722 0 0 1 .868 0l2.8 2.1a1.8 1.8 0 0 0 2.6-.481l3.525-5.592a.57.57 0 0 0-.827-.758l-3.786 2.874a.722.722 0 0 1-.868 0l-2.8-2.1a1.8 1.8 0 0 0-2.6.481Z" />
              </svg>
            </div>
          )}
        </>
      ) : null}

      <SimpleConfirmModal />
      <UpdateFriendInfoModal />
      <ConfirmCodeForAddPhoneModal />
      <ChangeMyAccountNameModal />
      <ApproveLoginQRModal />
      <FakeFriendStateModal />
    </BrowserRouter>
  );
}

export default App;
