import "./MyAccount.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, Dropdown, Input, Tabs } from "antd";
import _ from "lodash";
import { getFullWalletBalance, isAccountBanned, isAccountDropedCaseThisWeek, isAccountXpExceedThisWeek } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownAZ, faArrowUpAZ, faArrowUpZA, faDollar, faQrcode, faSignIn, faTowerBroadcast, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import MyAccountInfo from "./tabs/MyAccountInfo";
import MyInventory from "./tabs/MyInventory";
import MyInventoryHistory from "./tabs/MyInventoryHistory";
import MyMatchHistory from "./tabs/MyMatchHistory";
import MyTradeOffer from "./tabs/MyTradeOffer";
import MyFriendPending from "./tabs/MyFriendPending";
import MyConfirmation from "./tabs/MyConfirmation";
import { piority } from "../features/friend/FollowList";
import useStore, { ModalType, useAccountBySteamId, usePlayerState } from "../store";
import MyAccountItem from "./MyAccountItem";
import List from "react-virtualized/dist/commonjs/List";
import { useLocalStorage } from "@uidotdev/usehooks";
import { StringUtils } from "alpha-common-utils/index.js";
import AddAccountModal from "./AddAccountModal";
import AddSteamGuardModal from "./AddSteamGuardModal";
import SimpleButton from "../shared/SimpleButton";
import FilterOption from "./FilterOption";
import MyFriendList from "./tabs/MyFriendList";
import MyHelpRequest from "./tabs/MyHelpRequest";
import MyPurchaseHistory from "./tabs/MyPurchaseHistory";
import Market from "./tabs/Market";
import AccountPin from "../components/AccountPin";
import MyLoginHistory from "./tabs/MyLoginHistory";
import MyPersonalStore from "./tabs/MyPersonalStore";
import { areArraysSoftEqual, fetchMyAccount } from "../store/UserSlice";
import CaseIcon from "../image/case-icon.png";
import { useWindowSize } from "react-use";
import { Resizable } from "re-resizable";
import classNames from "classnames";

const MyAccountSortCriteria = [
  {
    title: "Order",
    icon: <FontAwesomeIcon className="mr-2" icon={faArrowUpZA} />,
    direction: "asc",
    sortBy: "order",
  },
  {
    title: "Playing time last week",
    icon: <FontAwesomeIcon className="mr-2" icon={faArrowUpZA} />,
    direction: "desc",
    sortBy: "totalPlayTimeLastWeek",
  },
  {
    title: "Last time case drop",
    icon: <img className="mr-2 w-5 h-auto" src={CaseIcon} />,
    direction: "desc",
    sortBy: (account) => account.lastTimeCaseDrop ?? 0,
  },
  {
    title: "Online Status",
    icon: <FontAwesomeIcon className="mr-2" icon={faTowerBroadcast} />,
    direction: "desc",
  },
  {
    title: "Friend Count",
    icon: <FontAwesomeIcon className="mr-2" icon={faUserFriends} />,
    direction: "desc",
    sortBy: (account) => account.friendsIDList?.length ?? 0,
  },
  {
    title: "Last time logged out CSGO",
    direction: "asc",
    sortBy: "personalGameDataAccountInformation.Logged_out_of_CS_GO",
  },
  {
    title: "Cooldown time",
    direction: "asc",
    sortBy: (account) => account.cooldownExpirationMs ?? 0,
  },
  {
    title: "Cooldown level",
    direction: "asc",
    sortBy: (account) => account.cooldownLevel ?? 0,
  },
  {
    title: "Last time Login QR",
    icon: <FontAwesomeIcon className="mr-2" icon={faQrcode} />,
    direction: "asc",
    sortBy: (account) => account.lastTimeLoginQR ?? 0,
  },
  {
    title: "Last time Logged On",
    icon: <FontAwesomeIcon className="mr-2" icon={faSignIn} />,
    direction: "asc",
    sortBy: (account) => account.lastTimeLoggedOn ?? 0,
  },
  {
    title: "Current XP",
    direction: "desc",
    sortBy: (account) => account.currentXp ?? 0,
  },
  {
    title: "XP Earned",
    direction: "desc",
    sortBy: (account) => account.xpEarned ?? 0,
  },
  {
    title: "CSGO Profile Rank",
    direction: "asc",
    sortBy: "CSGO_Profile_Rank",
  },
  {
    title: "Steam Level",
    direction: "desc",
    sortBy: (account) => account.steamLevel ?? 0,
  },
  {
    title: "Elo",
    direction: "desc",
    sortBy: (account) => account.elo ?? 0,
  },
  {
    title: "Wallet Balance",
    direction: "desc",
    icon: <FontAwesomeIcon className="mr-2" icon={faDollar} />,
    sortBy: getFullWalletBalance,
  },
];
const MyAccountSortCriteriaItems = MyAccountSortCriteria.map((option, index) => ({
  key: option.title,
  icon: option.icon,
  label: option.title,
}));

const reorderAccounts = (accountList, startIndex, endIndex) => {
  const list = accountList.slice();
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

function isAssetEqual(asset1, asset2) {
  const fields = ["instanceid", "classid", "id"];
  return _.isEqual(_.pick(asset1, fields), _.pick(asset2, fields));
}

function isObjectSoftEqual(obj1, obj2) {
  const _obj1 = {};
  const _obj2 = {};
  for (const key in obj1) {
    if (obj1[key] === null || obj1[key] === undefined) {
      _obj1[key] = "";
    } else {
      _obj1[key] = obj1[key];
    }
  }
  for (const key in obj2) {
    if (obj2[key] === null || obj2[key] === undefined) {
      _obj2[key] = "";
    } else {
      _obj2[key] = obj2[key];
    }
  }
  return _.isEqual(_obj1, _obj2);
}

function getFirstSibling(el) {
  if (el.previousSibling) {
    return getFirstSibling(el.previousSibling);
  } else {
    return el;
  }
}

function getLastSibling(el) {
  if (el.nextSibling) {
    return getLastSibling(el.nextSibling);
  } else {
    return el;
  }
}

function searchValueOfObject(object, query) {
  query = StringUtils.toNonAccentVietnameseLowerCase(query).replace(/\s/gi, "");
  if (typeof object === "string" && StringUtils.toNonAccentVietnameseLowerCase(object).replace(/\s/gi, "").includes(query)) {
    return true;
  } else if (Array.isArray(object)) {
    for (const item of object) {
      if (searchValueOfObject(item, query)) {
        return true;
      }
    }
  } else if (typeof object === "object") {
    for (const key in object) {
      if (searchValueOfObject(object[key], query)) {
        return true;
      }
    }
  }
  return false;
}

const useDisplayMyAccount = (notclaim, filterQuery, myAccountFilterCriteria) => {
  const [accountList, setAccountList] = useState([]);
  const storeAccountList = useStore((state) => state.accountList);

  useEffect(() => {
    const myAccounts = storeAccountList
      .filter(function (account) {
        if (notclaim) {
          return account.prime && !isAccountDropedCaseThisWeek(account) && isAccountXpExceedThisWeek(account);
        }

        if (filterQuery) {
          const accountFilter = _.pick(account, ["email", "personaName", "steamId", "username", "config.players"]); //historyName
          return searchValueOfObject(accountFilter, filterQuery);
        }

        const { VAC, Store, Dropped, Prime, Private, PremierAvaiable } = myAccountFilterCriteria;

        if ((VAC === 1 && !isAccountBanned(account)) || (VAC === -1 && isAccountBanned(account))) {
          return false;
        }

        if ((Store === 1 && !account.config?.store) || (Store === -1 && account.config?.store)) {
          return false;
        }

        const dropped = isAccountDropedCaseThisWeek(account) || isAccountXpExceedThisWeek(account);
        if ((Dropped === 1 && !dropped) || (Dropped === -1 && dropped)) {
          return false;
        }

        if ((Prime === 1 && !account.prime) || (Prime === -1 && account.prime)) {
          return false;
        }

        if ((Private === 1 && !account.config?.isPrivate) || (Private === -1 && account.config?.isPrivate)) {
          return false;
        }

        if ((PremierAvaiable === 1 && !account.premierAvaiable) || (PremierAvaiable === -1 && account.premierAvaiable)) {
          return false;
        }

        return true;
      })
      .sort(function (account1, account2) {
        return account1.steamId.localeCompare(account2.steamId);
      });

    setAccountList(function (prevState) {
      return areArraysSoftEqual(prevState, myAccounts) ? prevState : myAccounts;
    });
  }, [filterQuery, myAccountFilterCriteria, notclaim, storeAccountList]);

  return accountList;
};

const useSortedDisplayMyAccount = (displayAccountList, sortDirection, sortCriteria) => {
  const [sortedAccountList, setSortedAccountList] = useState([]);
  const { playerState } = usePlayerState(displayAccountList.map(({ steamId }) => steamId));

  useEffect(() => {
    const ASC = sortDirection ? "asc" : "desc";
    const DESC = sortDirection ? "desc" : "asc";

    const sortFn = MyAccountSortCriteria.find(({ title }) => title === sortCriteria);
    let list = displayAccountList;
    if (sortFn) {
      let { direction, sortBy } = sortFn;

      if (sortCriteria === "Online Status") {
        sortBy = function (account) {
          return piority[playerState[account.steamId]?.onlineStatus || "offline"];
        };
      }
      const sortOrder = direction === "asc" ? ASC : DESC;
      list = _.orderBy(displayAccountList, [sortBy, (a) => isAccountBanned(a), "prime"], [sortOrder, "desc", "desc"]);
    }

    setSortedAccountList(function (prevState) {
      return areArraysSoftEqual(prevState, list) ? prevState : list;
    });
  }, [displayAccountList, sortDirection, sortCriteria, playerState]);

  return sortedAccountList;
};

const useAccountFilterCriteria = () => {
  const accountList = useStore((state) => state.accountList);

  return useMemo(() => {
    let primeCount = 0;
    let nonPrimeCount = 0;
    let bannedCount = 0;
    let storeCount = 0;
    let nonStoreCount = 0;
    let droppedCount = 0;
    let notDroppedCount = 0;

    for (const account of accountList) {
      if (account.prime) {
        primeCount++;
        if (isAccountDropedCaseThisWeek(account) || isAccountXpExceedThisWeek(account)) {
          droppedCount++;
        } else if (!isAccountBanned(account)) {
          notDroppedCount++;
        }
      } else {
        nonPrimeCount++;
      }

      if (isAccountBanned(account)) {
        bannedCount++;
      }

      if (account.config?.store) {
        storeCount++;
      } else {
        nonStoreCount++;
      }
    }

    return {
      primeCount,
      nonPrimeCount,
      bannedCount,
      storeCount,
      nonStoreCount,
      droppedCount,
      notDroppedCount,
    };
  }, [accountList]);
};

export function MyAccount() {
  const setMergeAllAccount = useStore((state) => state.setMergeAllAccount);
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const myAccountFilterCriteria = useStore((state) => state.myAccountFilterCriteria);
  const setMyAccountFilterCriteria = useStore((state) => state.setMyAccountFilterCriteria);
  const accountList = useStore((state) => state.accountList);
  const selectedAccount = useAccountBySteamId(selectedAccountID);
  const accountPins = useStore((state) => state.accountPins);
  const mergeAllAccount = useStore((state) => state.mergeAllAccount);
  const [notclaim, setNotclaim] = useState();
  const { playerState } = usePlayerState(accountList.map((a) => a.steamId));

  const [sortCriteria, setSortCriteria] = useLocalStorage("MyAccount_sortCriteria");
  const [sortDirection, setSortDirection] = useLocalStorage("MyAccount_sortDirection");

  const [filterQuery, setFilterQuery] = useLocalStorage("MyAccount_filterQuery");

  const [activeKey, setActiveKey] = useLocalStorage("subtab_activeKey");
  const searchResultsRef = useRef();
  const listRef = useRef();
  const scrollToRowRef = useRef();

  const { width: innerWidth, height: innerHeight } = useWindowSize();
  const [resizing, setResizing] = useState(false);
  const [accountListWidth, setAccountListWidth] = useLocalStorage("MyAccount_accountList_width", 500);

  useEffect(() => {
    if (document.getElementById("ant_tabs_tabpane_id")) {
      return;
    }
    const activeTab = document.querySelector(".ant-tabs-tabpane.ant-tabs-tabpane-active");
    if (!activeTab) {
      return;
    }
    const styleEl = document.createElement("style");
    styleEl.setAttribute("id", "ant_tabs_tabpane_id");
    styleEl.innerHTML = `.ant-tabs-tabpane{height: calc(100vh - ${activeTab.getBoundingClientRect().y + 1}px)}`;
    activeTab.parentNode.append(styleEl);
  }, [activeKey]);

  const toggleSortDirection = useCallback(() => {
    setSortDirection((prev) => !prev);
  }, [setSortDirection]);

  const innerTabContent = useMemo(
    () =>
      [
        {
          label: "Info",
          children: <MyAccountInfo />,
        },
        {
          label: "Inventory",
          children: <MyInventory />,
        },
        {
          label: "Personal Store",
          children: <MyPersonalStore />,
          isShow: selectedAccount?.prime,
        },
        {
          label: "Inventory History",
          children: <MyInventoryHistory />,
        },
        {
          label: "Trade Offer",
          children: <MyTradeOffer />,
        },
        {
          label: `Matches History`,
          children: <MyMatchHistory />,
        },
        {
          label: `Friend List`,
          children: <MyFriendList />,
        },
        {
          label: `Friend Pending`,
          children: <MyFriendPending />,
        },
        {
          label: `Confirmation`,
          children: <MyConfirmation />,
          isShow: !!selectedAccount?.enableTwoFactor,
        },
        {
          label: `Market`,
          children: <Market />,
          isShow: selectedAccount?.limitations?.limited !== true,
        },
        {
          label: `Help Request`,
          children: <MyHelpRequest />,
        },
        {
          label: `Purchase History`,
          children: <MyPurchaseHistory />,
        },
        {
          label: `Login History`,
          children: <MyLoginHistory />,
        },
      ]
        .filter(function (tab) {
          return tab && tab.isShow !== false;
        })
        .map((tab, index) => ({ ...tab, key: tab.label })),
    [selectedAccount?.prime, selectedAccount?.enableTwoFactor, selectedAccount?.limitations?.limited],
  );

  const fullData = useDisplayMyAccount(notclaim, filterQuery, myAccountFilterCriteria);
  const displayData = useSortedDisplayMyAccount(fullData, sortDirection, sortCriteria);

  function scrollToRow(selectedAccountID) {
    const index = displayData.findIndex((a) => a.steamId === selectedAccountID);
    listRef.current?.scrollToRow(index);
  }

  scrollToRowRef.current = scrollToRow;

  useEffect(() => {
    scrollToRowRef.current?.(selectedAccountID);
    setTimeout(function () {
      scrollToRowRef.current?.(selectedAccountID);
    }, 500);
    setTimeout(function () {
      scrollToRowRef.current?.(selectedAccountID);
    }, 1000);
  }, [selectedAccountID]);

  useEffect(() => {
    fetchMyAccount();

    try {
      searchResultsRef.current.style.height = `calc(100vh - ${searchResultsRef.current.getBoundingClientRect().y + 1}px)`;
    } catch (e) {}
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
      const { selectedAccountID, setSelectedAccountID } = useStore.getState();
      switch (e.key) {
        case "ArrowDown": {
          const index = displayData.findIndex(({ steamId }) => steamId === selectedAccountID) + 1;
          setSelectedAccountID(displayData.at(index % displayData.length).steamId);
          break;
        }
        case "ArrowUp": {
          setSelectedAccountID(displayData.at(displayData.findIndex(({ steamId }) => steamId === selectedAccountID) - 1).steamId);
          break;
        }
        case "End": {
          setSelectedAccountID(displayData.at(-1).steamId);
          break;
        }
        case "Home": {
          setSelectedAccountID(displayData[0].steamId);
          break;
        }
        default: {
          console.log(e.key);
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [displayData]);

  const { primeCount, nonPrimeCount, bannedCount, storeCount, nonStoreCount, droppedCount, notDroppedCount } = useAccountFilterCriteria();

  const AccountFilterCriteria = useMemo(() => {
    return [
      {
        key: "Prime",
        label: `Prime Status`,
        positive: `${primeCount} Prime`,
        negative: `${nonPrimeCount} Non-Prime`,
      },
      {
        key: "VAC",
        label: `VAC status`,
        positive: <span className="font-bold text-rose-600">{bannedCount} VAC</span>,
        negative: (
          <span className="flex gap-2 items-center">
            <span className="font-bold">Normal</span>
            <span className="text-xs italic text-rose-600">{bannedCount} VAC</span>
          </span>
        ),
      },
      {
        key: "Store",
        label: "Store status",
        positive: `${storeCount} Store`,
        negative: `${nonStoreCount} Non-Store`,
      },
      {
        key: "Dropped",
        label: (
          <span className="flex gap-2 items-center">
            <span>Drop Status</span>(<span className="text-xs italic text-green-500">{droppedCount}</span>/<span className="text-xs italic">{notDroppedCount}</span>)
          </span>
        ),
        positive: (
          <span className="flex gap-2 items-center">
            <span className="font-bold text-green-500">{droppedCount} Drop</span>
            <span className="text-xs italic">{notDroppedCount} NotDrop</span>
          </span>
        ),
        negative: (
          <span className="flex gap-2 items-center">
            <span className="font-bold">{notDroppedCount} NotDrop</span>
            <span className="text-xs italic text-green-500">{droppedCount} Drop</span>
          </span>
        ),
      },
      {
        key: "Private",
        label: "Private status",
        positive: "Private",
        negative: "Publish",
      },
      {
        key: "PremierAvaiable",
        label: "Premier status",
        positive: "Premier avaiable",
        negative: "Premier not avaiable",
      },
    ];
  }, [primeCount, nonPrimeCount, bannedCount, storeCount, nonStoreCount, droppedCount, notDroppedCount]);

  return (
    <>
      <AddAccountModal />
      <AddSteamGuardModal />
      <div id="MyAccounts" className="w-full">
        <div className="flex gap-2 m-1 items-center">
          {accountPins.map(function (steamId) {
            return <AccountPin account={accountList.find((a) => a.steamId === steamId)} key={steamId} selected={steamId === selectedAccountID} />;
          })}
          <div className="w-48">
            <Input placeholder="Search" allowClear onChange={(e) => setFilterQuery(e.target.value)} value={filterQuery} />
          </div>
          <div>
            <span className="font-bold">Ingame CS2</span>:{" "}
            <span>
              <span className="text-green-600">{displayData.filter((a) => playerState[a.steamId]?.game === "Counter-Strike 2").length}</span>/{displayData.length}
            </span>
          </div>
          <FilterOption options={AccountFilterCriteria} onChange={setMyAccountFilterCriteria} value={myAccountFilterCriteria} />
          <div>
            <Dropdown.Button
              onClick={toggleSortDirection}
              menu={{
                items: MyAccountSortCriteriaItems,
                onClick: (e) => {
                  setSortCriteria(e.key);
                },
              }}
            >
              <span className="pr-2">Sort: {sortCriteria}</span>
              <FontAwesomeIcon icon={sortDirection ? faArrowDownAZ : faArrowUpAZ} />
            </Dropdown.Button>
          </div>
          <SimpleButton
            onClick={function () {
              useStore.getState().showModal(ModalType.AddAccount);
            }}
            type="primary"
          >
            Add New Account
          </SimpleButton>
          <SimpleButton
            onClick={async function () {
              await useStore.getState().getMyAccount();
            }}
          >
            Refresh
          </SimpleButton>
          <Checkbox checked={mergeAllAccount} onChange={(e) => setMergeAllAccount(e.target.checked)}>
            Merge All
          </Checkbox>
          <Checkbox checked={notclaim} onChange={(e) => setNotclaim(e.target.checked)}>
            Not claim
          </Checkbox>
        </div>
        <div className="relative w-full h-full flex" ref={searchResultsRef}>
          <Resizable
            className={classNames("h-full relative", {
              "overflow-y-auto": resizing,
            })}
            maxWidth={innerWidth - 200}
            minWidth={200}
            width={accountListWidth}
            onResizeStop={(e, direction, ref, d) => {
              setResizing(false);
              if (ref.offsetWidth) {
                setAccountListWidth(ref.offsetWidth);
              }
            }}
            onResizeStart={function () {
              setResizing(true);
            }}
          >
            {!!searchResultsRef.current && (
              <List
                rowCount={displayData.length}
                rowHeight={80}
                width={accountListWidth}
                height={searchResultsRef.current.offsetHeight - 5}
                ref={listRef}
                rowRenderer={({ index, style }) => {
                  const account = displayData[index];
                  return <MyAccountItem account={account} index={index} style={style} key={account._id} selected={selectedAccountID === account.steamId} pin={accountPins.includes(account.steamId)} />;
                }}
              />
            )}
          </Resizable>
          <div className={`right_menu relative`} style={{ width: `calc(100% - ${accountListWidth + 10}px)` }}>
            <Tabs activeKey={activeKey} defaultActiveKey={innerTabContent[0].key} onChange={setActiveKey} items={innerTabContent} destroyInactiveTabPane={true} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MyAccount;
