import { openQuickMessage } from "../socket";
import { copy2Clipboard, copyFriendCode, copySteamProfile, invite2PlayCustom, invite2PlayCustom20h, invitePlayNow, openSteamProfile, steamID64toSteamID32 } from "../utils";
import useStore from "store";

const MenuItem = {
  OpenMessage: {
    label: "Open message",
    onClick: async (steamId) => {
      useStore.getState().showChat(steamId);
    },
  },
  SendQuickMessage: {
    label: "Send Quick Message",
    onClick: async (steamId) => {
      openQuickMessage({
        steamId,
      });
    },
  },
  CopySteamProfile: {
    label: "Copy steam profile",
    onClick: copySteamProfile,
  },
  CopySteamID: {
    label: "Copy SteamID",
    onClick: copy2Clipboard,
  },
  CopyMiniProfileID: {
    label: "Copy MiniProfileID",
    onClick(steamId) {
      copy2Clipboard(steamID64toSteamID32(steamId));
    },
  },
  CopyFriendCode: {
    label: "Copy FriendCode",
    onClick: copyFriendCode,
  },
  OpenSteamProfile: {
    label: "Open steam profile",
    onClick: openSteamProfile,
  },
  Invite2PlayCustom: {
    label: "Invite to play custom",
    onClick: invite2PlayCustom,
  },
  Invite2PlayCustom20H: {
    label: "Invite to play custom 20h",
    onClick: invite2PlayCustom20h,
  },
  Invite2PlayNow: {
    label: "Invite play now",
    onClick: invitePlayNow,
  },
};

for (const key in MenuItem) {
    const onClick = MenuItem[key].onClick
    MenuItem[key].onClick = async function (steamId) {
        if (typeof steamId !== "string" && typeof steamId?.steamId === "string") {
            steamId = steamId.steamId
        }
        await onClick?.(steamId)
    }
}

export default MenuItem
