export const RANKS = {
  0: "Unranked",
  1: "Silver I",
  2: "Silver II",
  3: "Silver III",
  4: "Silver IV",
  5: "Silver Elite",
  6: "Silver Elite Master",
  7: "Gold Nova I",
  8: "Gold Nova II",
  9: "Gold Nova III",
  10: "Gold Nova Master",
  11: "Master Guardian I",
  12: "Master Guardian II",
  13: "Master Guardian Elite",
  14: "Distinguished Master Guardian",
  15: "Legendary Eagle",
  16: "Legendary Eagle Master",
  17: "Supreme Master First Class",
  18: "The Global Elite",
};

export const Profile_Rank = [
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/e/e2/Csgo-profile-rank-level1.png",
    rank: 1,
    name: "Recruit Rank 1",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/8/83/Csgo-profile-rank-level2.png",
    rank: 2,
    name: "Private Rank 2",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/c/c0/Csgo-profile-rank-level3.png",
    rank: 3,
    name: "Private Rank 3",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/5/50/Csgo-profile-rank-level4.png",
    rank: 4,
    name: "Private Rank 4",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/2/2d/Csgo-profile-rank-level5.png",
    rank: 5,
    name: "Corporal Rank 5",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/3/3a/Csgo-profile-rank-level6.png",
    rank: 6,
    name: "Corporal Rank 6",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/b/b9/Csgo-profile-rank-level7.png",
    rank: 7,
    name: "Corporal Rank 7",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/d/dc/Csgo-profile-rank-level8.png",
    rank: 8,
    name: "Corporal Rank 8",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/a/a8/Csgo-profile-rank-level9.png",
    rank: 9,
    name: "Sergeant Rank 9",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/5/55/Csgo-profile-rank-level10.png",
    rank: 10,
    name: "Sergeant Rank 10",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/a/aa/Csgo-profile-rank-level11.png",
    rank: 11,
    name: "Sergeant Rank 11",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/c/c8/Csgo-profile-rank-level12.png",
    rank: 12,
    name: "Sergeant Rank 12",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/1/1e/Csgo-rank-level13.png",
    rank: 13,
    name: "Master Sergeant Rank 13",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/8/86/Csgo-rank-level14.png",
    rank: 14,
    name: "Master Sergeant Rank 14",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/1/1c/Csgo-rank-level15.png",
    rank: 15,
    name: "Master Sergeant Rank 15",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/5/5b/Csgo-rank-level16.png",
    rank: 16,
    name: "Master Sergeant Rank 16",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/f/fb/Csgo-rank-level17.png",
    rank: 17,
    name: "Sergeant Major Rank 17",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/0/01/Csgo-rank-level18.png",
    rank: 18,
    name: "Sergeant Major Rank 18",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/7/72/Csgo-rank-level19.png",
    rank: 19,
    name: "Sergeant Major Rank 19",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/0/0c/Csgo-rank-level20.png",
    rank: 20,
    name: "Sergeant Major Rank 20",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/5/5a/Csgo-ranklevel21.png",
    rank: 21,
    name: "Lieutenant Rank 21",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/3/3e/Csgo-ranklevel22.png",
    rank: 22,
    name: "Lieutenant Rank 22",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/7/74/Csgo-ranklevel23.png",
    rank: 23,
    name: "Lieutenant Rank 23",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/2/20/Csgo-ranklevel24.png",
    rank: 24,
    name: "Lieutenant Rank 24",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/e/e7/Csgo-ranklevel25.png",
    rank: 25,
    name: "Captain Rank 25",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/6/67/Csgo-ranklevel26.png",
    rank: 26,
    name: "Captain Rank 26",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/b/b2/Csgo-ranklevel27.png",
    rank: 27,
    name: "Captain Rank 27",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/8/8e/Csgo-ranklevel28.png",
    rank: 28,
    name: "Captain Rank 28",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/4/45/Csgo-ranklevel29.png",
    rank: 29,
    name: "Major Rank 29",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/2/26/Csgo-ranklevel30.png",
    rank: 30,
    name: "Major Rank 30",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/6/69/Csgo-ranklevel31.png",
    rank: 31,
    name: "Major Rank 31",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/1/10/Csgo-ranklevel32.png",
    rank: 32,
    name: "Major Rank 32",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/f/fe/Csgo-ranklevel33.png",
    rank: 33,
    name: "Colonel Rank 33",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/0/09/Csgo-ranklevel34.png",
    rank: 34,
    name: "Colonel Rank 34",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/5/59/Csgo-ranklevel35.png",
    rank: 35,
    name: "Colonel Rank 35",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/6/6b/Csgo-ranklevel36.png",
    rank: 36,
    name: "Brigadier General Rank 36",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/8/8e/Csgo-ranklevel37.png",
    rank: 37,
    name: "Major General Rank 37",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/7/79/Csgo-ranklevel38.png",
    rank: 38,
    name: "Lieutenant General Rank 38",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/a/aa/Csgo-ranklevel39.png",
    rank: 39,
    name: "General Rank 39",
  },
  {
    img: "https://static.wikia.nocookie.net/cswikia/images/d/d9/Csgo-ranklevel40.png",
    rank: 40,
    name: "Global General Rank 40",
  },
];
