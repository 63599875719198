import { Resizable } from "re-resizable";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useWindowSize } from "react-use";
import { sleep } from "utils";
import { getFriendObj } from "socket";
import "./Chat.scss";
import ChatRoomHeader from "./ChatRoomHeader";
import SearchPeople from "./SearchPeople";
import InfiniteScroll from "react-infinite-scroller";
import ChatLine from "./ChatLine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import SendingInput from "./SendingInput";
import api from "api";
import useStore from "store";
import { validate as uuidValidate } from "uuid";

function getChatDefaultSize() {
  let defaultSize;
  try {
    defaultSize = JSON.parse(localStorage.getItem("ChatDefaultSize"));
  } catch (e) {}
  return defaultSize?.width
    ? defaultSize
    : {
        width: 800,
        height: 500,
      };
}

export default function Chat() {
  const selectedRoomID = useStore((state) => state.selectedRoomID);
  const chatHistoryHasMore = useStore((state) => state.chatHistoryHasMore);
  const roomList = useStore((state) => state.roomList);
  const minimiseChat = useStore((state) => state.minimiseChat);
  const bottomRef = useRef();
  const selectedMyAccountInputRef = useRef();

  const { width: innerWidth, height: innerHeight } = useWindowSize();

  const chatContentRef = useRef();

  const room = useMemo(() => {
    return roomList.find((r) => r._id === selectedRoomID);
  }, [roomList, selectedRoomID]);

  const scrollToBottom = useCallback(async () => {
    for (let i = 0; i < 5; i++) {
      bottomRef.current?.scrollIntoView();
      await sleep(100);
    }
  }, []);

  useEffect(() => {
    if (!selectedRoomID || uuidValidate(selectedRoomID)) {
      return;
    }

    api
      .getChatHistory({
        roomId: selectedRoomID,
        limit: 10,
      })
      .then(function (result) {
        Array.isArray(result?.data) && processChatHistory(selectedRoomID, result.data);
        scrollToBottom();
      });
    api.fetchChatHistory({ roomId: selectedRoomID }).then(function (result) {
      Array.isArray(result?.data) && processChatHistory(selectedRoomID, result.data);
      scrollToBottom();
    });
  }, [selectedRoomID]);

  useEffect(() => {
    const timeouts = [];
    (async () => {
      const observer = new MutationObserver(function () {
        const chat_sending_input = document.getElementById("chat_sending_input");
        if (chat_sending_input) {
          observer.disconnect();
          timeouts.forEach((t) => clearTimeout(t));
          chat_sending_input.focus();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
      timeouts.push(
        setTimeout(function () {
          observer.disconnect();
        }, 10000),
      );
    })();
    return function () {
      timeouts.forEach((t) => clearTimeout(t));
    };
  }, []);

  return (
    <React.Fragment>
      <Resizable
        style={{
          display: minimiseChat ? "none" : "inherit",
          position: "absolute",
          right: 10,
          bottom: 10,
        }}
        maxWidth={innerWidth - 100}
        minWidth={500}
        minHeight={400}
        maxHeight={innerHeight - 100}
        defaultSize={getChatDefaultSize()}
        onResizeStop={(e, direction, ref, d) => {
          if (ref.offsetWidth && ref.offsetHeight) {
            localStorage.setItem(
              "ChatDefaultSize",
              JSON.stringify({
                width: ref.offsetWidth,
                height: ref.offsetHeight,
              }),
            );
          }
        }}
      >
        <div className="ChatRoot">
          <div className="ChatRootInner">
            <div className="RoomListRoot">
              <SearchPeople />
            </div>
            <div className="RoomContentRoot flex flex-col w-full h-full">
              <div className="select-none block w-full h-12 cursor-pointer text-center font-bold p-2">
                <ChatRoomHeader selectedMyAccountInputRef={selectedMyAccountInputRef} />
              </div>
              {selectedRoomID && (
                <>
                  <div
                    style={{
                      height: "calc(100% - 240px)",
                    }}
                    className="RoomContentContent block w-full relative"
                  >
                    <div className="block w-full overflow-y-auto h-full overflow-x-hidden" ref={chatContentRef}>
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={async () => {
                          if (window.loadingMore) return;
                          window.loadingMore = true;
                          const roomId = room._id;
                          const _room = useStore.getState().roomList.find((r) => r._id === roomId);
                          if (_room?.messages?.length) {
                            let result = null;
                            try {
                              result = (
                                await api.getChatHistory({
                                  roomId: roomId,
                                  timestamp: room?.messages?.[0]?.timestamp,
                                  limit: 10,
                                })
                              )?.data;
                            } catch (e) {}
                            result && processChatHistory(roomId, result);
                          }
                          window.loadingMore = false;
                        }}
                        hasMore={chatHistoryHasMore[room._id] !== true}
                        loader={
                          <div className="loader flex items-center w-full justify-center text-blue-700 font-bold" key={0}>
                            Loading ...
                          </div>
                        }
                        useWindow={false}
                        isReverse={true}
                        getScrollParent={() => chatContentRef.current}
                      >
                        {room?.messages?.map((line, index) => (
                          <ChatLine key={line._id} room={room} line={line} index={index} />
                        ))}
                      </InfiniteScroll>
                      <div ref={bottomRef} id="Chat_Room_Content_Bottom_El" className="w-full h-px"></div>
                    </div>
                    <div
                      onClick={scrollToBottom}
                      className="border border-solid border-gray-700 rounded-full w-12 h-12 absolute -bottom-11
                 right-1 z-20 bg-slate-100 flex justify-center items-center cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                  </div>
                  <SendingInput room={room} selectedMyAccountInputRef={selectedMyAccountInputRef} />
                </>
              )}
            </div>
          </div>
        </div>
      </Resizable>
    </React.Fragment>
  );
}

export function processChatHistory(roomId, messages) {
  if (Array.isArray(messages)) {
    for (const message of messages) {
      message.author = getFriendObj(message.author_id);
      message.receiver = getFriendObj(message.receiver_id);
    }
  }
  if (!messages.length) {
    useStore.getState().setChatHistoryHasMore(roomId, false);
  } else {
    useStore.getState().addNewMessage(messages);
  }
}
