import useStore from "store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { toNonAccentVietnameseLowerCase } from "alpha-common-utils/StringUtils";
import { useDebounce } from "@uidotdev/usehooks";
import SteamAvatar from "./components/SteamAvatar";

function MyAccountPicker({ selectedAccount, setSelectedAccount, data }) {
  const onChange = useCallback((value) => setSelectedAccount(value), [setSelectedAccount]);
  const [searchTerm, setSearchTerm] = useState();
  const [options, setOptions] = useState(data || useStore.getState().accountList);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    setOptions(data || useStore.getState().accountList);
  }, [data]);

  const displayOptions = useMemo(() => {
    return options.map((a) => ({
      value: a.steamId,
      label: (
        <span className="flex gap-5">
          <SteamAvatar avatarHash={a?.avatarHash} className="w-6 h-6" />
          <span>{a.username + " - " + a.personaName}</span>
        </span>
      ),
    }));
  }, [options]);

  useEffect(() => {
    let query = debouncedSearchTerm?.trim();
    if (!query) {
      setOptions(useStore.getState().accountList);
    } else {
      query = toNonAccentVietnameseLowerCase(query);
      setOptions(
        useStore.getState().accountList.filter(function (account) {
          return Object.values(account)
            .filter((v) => v && typeof v === "string")
            .map((v) => toNonAccentVietnameseLowerCase(v))
            .some(function (v) {
              return v.indexOf(query) > -1;
            });
        }),
      );
    }
  }, [debouncedSearchTerm]);

  return <Select allowClear showSearch onSearch={setSearchTerm} filterOption={false} value={selectedAccount} className="w-64" options={displayOptions} onChange={onChange}></Select>;
}

export default React.memo(MyAccountPicker);
