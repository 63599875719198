import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import api from "api";
import useStore, { useAccountBySteamId } from "store";
import SimpleButton from "../../shared/SimpleButton";
import Toast from "toast";
import _ from "lodash";
import { getFullWalletBalance, GetPriceValueAsInt, sleep, v_currencyformat } from "../../utils";
import moment from "moment/moment";
import SimpleAsset from "../../components/SimpleAsset";
import { Checkbox, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export default function Market() {
  const selectedAccountID = useStore((state) => state.selectedAccountID);
  const selectedAccount = useAccountBySteamId(selectedAccountID);
  const [myListing, setMyListing] = useState([]);
  const [myMarketHistory, setMyMarketHistory] = useState([]);
  const leftTabRef = useRef();
  const rightTabRef = useRef();
  const subToolRef = useRef();
  const [loading, setLoading] = useState();
  const [selectedMyListingObj, setSelectedMyListingObj] = useState({});
  const selectedMyListingArr = useMemo(() => {
    return Object.keys(selectedMyListingObj).filter(function (listingid) {
      return myListing.some((item) => item.listingid === listingid);
    });
  }, [myListing, selectedMyListingObj]);

  const onSelectedListingItem = useCallback((listingid, checked) => {
    setSelectedMyListingObj((prevState) => {
      if (checked) {
        return { ...prevState, [listingid]: true };
      } else {
        const { [listingid]: _, ...newState } = prevState;
        return newState;
      }
    });
  }, []);

  const onSelectedAllListingItem = useCallback(
    (checked) => {
      setSelectedMyListingObj(() => {
        if (checked) {
          return myListing.reduce((acc, { listingid }) => {
            acc[listingid] = true;
            return acc;
          }, {});
        } else {
          return {};
        }
      });
    },
    [myListing],
  );

  const fetchListing = useCallback(async () => {
    setLoading(true);
    const steamId = useStore.getState().selectedAccountID;
    const result = await Promise.all([api.getMyListings({ steamId }), api.getMyMarketHistory({ steamId, count: 100 })]);

    const _myListing = result[0]?.data || [];
    const _myHistory = result[1]?.data || [];

    _myListing?.list?.forEach(function (item) {
      const asset = _myListing.assets.find((a) => a.id == item.itemid);
      if (asset) {
        Object.assign(item, asset);
      }
    });

    _myHistory.list?.forEach(function (item) {
      const asset = item.assetId && _myHistory.assets.find((asset) => asset.id === item.assetId);
      if (asset) {
        Object.assign(item, asset);
      }
    });

    setMyListing(
      _.sortBy(_myListing?.list || [], function (item) {
        return GetPriceValueAsInt(item.receive_price) || 0;
      }).reverse(),
    );
    setMyMarketHistory(_myHistory);

    await useStore.getState().fetchWalletBalance(selectedAccountID);

    setLoading(false);
  }, [selectedAccountID]);

  const decreaseReselling = useCallback(
    async (item, decreaseValue, isFetchListing) => {
      const sellingResult = (
        await api.sellItem({
          steamId: item.steamId,
          listingId: item.listingid,
          appid: item.appid,
          contextid: item.contextid,
          assetid: item.itemid,
          amount: 1,
          price: GetPriceValueAsInt(item.receive_price) - decreaseValue,
        })
      )?.data;
      if (!sellingResult || sellingResult.success === false) {
        Toast.error(sellingResult?.message || "Selling failed");
      } else if (sellingResult?.requires_confirmation) {
        if (sellingResult.needs_mobile_confirmation) {
          Toast.success("Please confirm on Steam Mobile app");
          Toast.success("Getting confirmations");
          const confirmation = (await api.getMyConfirmation({ steamId: item.steamId }))?.data?.confirmations?.find(function (confirmation) {
            return Math.abs(moment(confirmation.timestamp).diff(moment(), "minutes")) < 5;
          });
          if (confirmation) {
            Toast.success("Confirming on Steam Mobile app");
            await api.doConfirm(confirmation);
          } else {
            Toast.error("No confirmation found");
          }
        } else {
          Toast.success("Please confirm on email " + sellingResult?.email_domain);
        }
      } else {
        Toast.success("Selling success " + item.market_hash_name);
      }
      if (isFetchListing) {
        await fetchListing();
      }
    },
    [fetchListing],
  );

  const totalPrice = useMemo(() => {
    return v_currencyformat(
      myListing?.reduce(function (prev, current) {
        return prev + GetPriceValueAsInt(current.receive_price);
      }, 0) ?? 0,
    );
  }, [myListing]);

  useEffect(() => {
    setMyListing([]);
    setMyMarketHistory([]);
    fetchListing();
  }, [fetchListing, selectedAccountID]);

  useEffect(() => {
    if (!leftTabRef.current) return;
    if (!subToolRef.current) return;
    const leftClientRect = leftTabRef.current.getBoundingClientRect();
    const rightClientRect = rightTabRef.current.getBoundingClientRect();
    leftTabRef.current.style.height = `calc(100vh - ${leftClientRect.y + 10}px)`;
    rightTabRef.current.style.height = `calc(100vh - ${rightClientRect.y + 10}px)`;
  }, [leftTabRef, rightTabRef, subToolRef]);

  const handleOnClickRemove = useCallback(
    async (listingId, reload) => {
      const result = await api.removeMyListing({
        steamId: selectedAccountID,
        listingId,
      });
      if (reload) {
        await fetchListing();
      }
    },
    [fetchListing, selectedAccountID],
  );

  const handleOnClickRemoveListing = useCallback(async () => {
    if (!selectedMyListingArr.length) {
      return Toast.error("Select first");
    }
    const result = await api.removeMyListings({
      steamId: selectedAccountID,
      list: selectedMyListingArr,
    });
    console.log(result);
    await fetchListing();
  }, [fetchListing, selectedAccountID, selectedMyListingArr]);

  const handleOnClickDescease100Listing = useCallback(async () => {
    if (!selectedMyListingArr.length) {
      return Toast.error("Select first");
    }

    const listing = selectedMyListingArr.reduce((acc, listingid) => {
      const item = myListing.find((item) => item.listingid === listingid);
      if (item) acc.push(item);
      return acc;
    }, []);

    for (const item of listing) {
      await decreaseReselling(item, 10000, false);
      await sleep(500);
    }
    await sleep(500);
    await fetchListing();
  }, [decreaseReselling, fetchListing, myListing, selectedMyListingArr]);

  const estimateFullWalletBalance = useMemo(() => {
    const totalPriceInt = GetPriceValueAsInt(totalPrice) || 0;
    return totalPriceInt + getFullWalletBalance(selectedAccount);
  }, [selectedAccount, totalPrice]);

  return (
    <>
      <div className="MyActiveListing flex flex-col">
        <div className="MyActiveListing_Header w-full flex gap-1 items-center" ref={subToolRef}>
          <Checkbox
            indeterminate={!!selectedMyListingArr.length && selectedMyListingArr.length !== myListing.length}
            checked={!!selectedMyListingArr.length && selectedMyListingArr.length === myListing.length}
            onChange={(e) => {
              onSelectedAllListingItem(e.target.checked);
            }}
          >
            All
            <span className="italic mx-2">
              ({selectedMyListingArr.length}/{myListing.length})
            </span>
          </Checkbox>

          <Checkbox
            checked={!!selectedAccount?.autoSell}
            onChange={async function (e) {
              const result = (await api.setAutoSell({ autoSell: e.target.checked, steamId: selectedAccountID }))?.data;
              if (result._id) {
                useStore.getState().updateAccounts(result);
              }
            }}
          >
            Auto Sell
          </Checkbox>

          <SimpleButton onClick={handleOnClickRemoveListing}>Remove</SimpleButton>
          <SimpleButton onClick={handleOnClickDescease100Listing}>Decrease 100d</SimpleButton>
          <span onClick={fetchListing} className="cursor-pointer">
            <FontAwesomeIcon
              icon={faRotate}
              className={classNames("text-black hover:text-slate-400", {
                "animate-spin": loading,
              })}
            />
            <span>{Array.isArray(selectedAccount?.walletBalance) && <span className="font-bold text-xs italic text-sky-500">Wallet: {selectedAccount.walletBalance.join(" - ")}</span>}</span>
          </span>
          <span>
            Listing price: <span className="font-bold text-xs italic">{totalPrice}</span>
          </span>
          <span className="flex gap-1 items-center">
            Estimate full total price: <span className="font-bold text-xs italic">{v_currencyformat(estimateFullWalletBalance)}</span>
            {37500000 > estimateFullWalletBalance ? (
              <>
                Lacking: <span className="font-bold text-xs italic text-red-500">{v_currencyformat(37500000 - estimateFullWalletBalance)}</span>
              </>
            ) : (
              <>
                Excess: <span className="font-bold text-xs italic text-green-500">{v_currencyformat(estimateFullWalletBalance - 37500000)}</span>
              </>
            )}
          </span>
        </div>
        <div className="MyActiveListing_Body w-full">
          <div className="flex w-full pb-2">
            <div className="w-1/2 ">
              <div className="w-full text-center">
                My Active Listings (<span className="font-bold">{myListing.length}</span>)
              </div>
              <div className="overflow-y-auto" ref={leftTabRef}>
                {myListing.map?.(function (item, index) {
                  return <ListingItem item={item} key={item.listingid} index={index} remove={handleOnClickRemove} decreaseReselling={decreaseReselling} selected={selectedMyListingObj[item.listingid]} onSelected={onSelectedListingItem} />;
                })}
              </div>
            </div>
            <div className="w-1/2 ">
              <div className="w-full text-center">
                My Market History (<span className="font-bold">{myMarketHistory.list?.length || 0}</span>)
                <SimpleButton
                  onClick={async function () {
                    const result = (await api.getMyMarketHistory({ steamId: useStore.getState().selectedAccountID, start: myMarketHistory.start + myMarketHistory.pagesize, count: myMarketHistory.pagesize }))?.data;
                    if (result) {
                      result.list?.forEach(function (item) {
                        const asset = item.assetId && result.assets.find((asset) => asset.id === item.assetId);
                        if (asset) {
                          Object.assign(item, asset);
                        }
                      });

                      setMyMarketHistory(function (prev) {
                        const list = [...prev.list, ...result.list];
                        const assets = { ...prev.assets, ...result.assets };

                        return {
                          ...result,
                          list,
                          assets,
                        };
                      });
                    }
                  }}
                >
                  Load more
                </SimpleButton>
              </div>
              <div className="overflow-y-auto" ref={rightTabRef}>
                {myMarketHistory?.list?.map?.(function (item, index) {
                  return <HistoryListingItem item={item} key={item.id + item.status.toString() + item.gainOrLoss.toString()} index={index} />;
                })}
              </div>
            </div>
          </div>
          {!!loading && (
            <div className="absolute left-0 top-0 right-0 bottom-0 w-full h-full">
              <div className="absolute left-0 top-0 right-0 bottom-0 w-full h-full bg-zinc-400 opacity-40" />
              <div className="flex items-center justify-center w-full h-full">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 48,
                      }}
                      spin
                    />
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const ListingItem = React.memo(function ListingItem({ item, index, remove, decreaseReselling, selected, onSelected }) {
  return (
    <div className="w-full items-center flex flex-row gap-2 px-4 py-2 border-solid border-b-blue-500 border-b border-l-0 border-r-0 border-t-0">
      <div className="flex items-center">
        <span className="font-bold mr-2">{index + 1}. </span>
        <Checkbox
          checked={selected}
          onChange={(e) => {
            onSelected(item.listingid, e.target.checked);
          }}
        />
      </div>
      <div className="w-full items-start flex flex-col gap-2">
        <div className="flex justify-center items-center gap-2">
          <div className="flex flex-col pr-2">
            <SimpleAsset asset={item} game={item.game_name} />
            <div className="flex gap-2">
              <span>Receive: {item.receive_price}</span>
              <span>Buyer Pays: {item.buyer_pays_price}</span>
            </div>
          </div>
        </div>
        <div>
          <SimpleButton
            onClick={async function () {
              await remove(item.listingid, true);
            }}
          >
            Remove
          </SimpleButton>
          <SimpleButton onClick={async () => await decreaseReselling(item, 50000, true)}>Decrease 500d</SimpleButton>
          <SimpleButton onClick={async () => await decreaseReselling(item, 10000, true)}>Decrease 100d</SimpleButton>
        </div>
      </div>
    </div>
  );
});

export function getDateHistoryListingItem(date) {
  if (!date) return null;
  const listedOnMoment = moment(date, "D MMM", true);
  if (listedOnMoment.isValid()) {
    return listedOnMoment;
  }
  return date;
}

function formatDateHistoryListingItem(date) {
  const dataM = getDateHistoryListingItem(date);
  return moment.isMoment(dataM) ? dataM.format("DD MMM YYYY") + " ~ " + dataM.fromNow() : date;
}

export const HistoryListingItem = React.memo(function ListingItem({ item, index, className }) {
  const listedOn = formatDateHistoryListingItem(item.listedOn);
  const actedOn = formatDateHistoryListingItem(item.actedOn);

  return (
    <div className={`w-full items-start flex flex-col gap-2 py-2 border-solid border-b-blue-500 border-b border-l-0 border-r-0 border-t-0 ${className || ""}`}>
      <div className="flex justify-center items-center gap-2">
        <span className="font-bold">{index + 1}. </span>
        {!!item.gainOrLoss && <span>{item.gainOrLoss}</span>}
        <div className="flex flex-col pr-2">
          <SimpleAsset asset={item} game={item.game_name} />
          <div className="flex gap-2">
            <span>{item.price ? `Price ${v_currencyformat(item.price)}` : ""}</span>
            {!!listedOn && <span>{`listedOn ${listedOn}`}</span>}
            {!!actedOn && <span>{`actedOn ${actedOn}`}</span>}
            <span>{item.status ? `status ${item.status}` : ""}</span>
          </div>
        </div>
      </div>
    </div>
  );
});
