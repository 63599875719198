import React, { useEffect, useRef, useState } from "react";
import { Input, Modal, Spin } from "antd";
import qrcode from "../jsqrcode/index.js";
import { Decoder } from "@nuintun/qrcode";
import Toast from "toast";
import api from "api";
import useStore, { ModalType } from "../store";
import { LoadingOutlined } from "@ant-design/icons";

async function getClipboardContents() {
  try {
    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type?.startsWith("image")) {
          console.log(type);
          return await clipboardItem.getType(type);
        }
      }
    }
  } catch (err) {
    console.error(err.name, err.message);
  }
}

export async function getClipboardImage(evt) {
  let blob;
  if (evt) {
    // Get the data of clipboard
    const clipboardItems = evt.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return item.type.indexOf("image") !== -1;
    });
    if (items.length === 0) {
      return;
    }

    blob = items[0]?.getAsFile?.() || items[0].item.getAsFile();
  } else {
    blob = await getClipboardContents();
    if (!blob) {
      return;
    }
  }

  const fileAsDataURL = window.URL.createObjectURL(blob);
  return {
    blob,
    fileAsDataURL,
  };
}

async function decodeQRCodeImage(callback) {
  const image = await getClipboardImage();
  const fileAsDataURL = image?.fileAsDataURL;
  if (!fileAsDataURL) return callback(null, "Error");

  const result = await new Promise((resolve) => {
    qrcode.callback = resolve;
    qrcode.decode(fileAsDataURL);
  });
  delete qrcode.callback;
  if (result?.startsWith("http")) {
    return callback(result);
  }

  try {
    const decodeResult = await new Decoder().scan(fileAsDataURL);
    if (decodeResult?.data?.startsWith("http")) {
      return callback(decodeResult.data);
    }
  } catch (e) {}

  const response = (await api.decodeQR({ file: image.blob }))?.data;
  if (response?.success) {
    return callback(response.url);
  } else {
    return callback(null, response.error);
  }
}

function approveLoginQR({ url, steamId, authorId }) {
  console.log("approveLoginQR url", url); //https://s.team/q/1/990077074923908315
  api
    .approveLoginQR({
      url,
      steamId,
      authorId,
    })
    .then(function (result) {
      const response = result.data || {};
      if (response.success) {
        const sessionInfo = response.success.sessionInfo;
        Toast.success(`My account ${steamId} approved | ${sessionInfo.location.city} ${sessionInfo.location.state}`);
        useStore.getState().hideModal(ModalType.ApproveLoginQR);
      } else if (response.error) {
        console.error(response.error);
        Toast.error(response.error);
      } else {
        console.log("approveLoginQR result", response);
      }
    });
}

const RealApproveLoginQRModal = React.memo(function RealApproveLoginQRModal({ steamId, authorId }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!steamId) return;
    setLoading(true);
    decodeQRCodeImage(function (data, error) {
      setLoading(false);
      if (!error) {
        approveLoginQR({
          url: data,
          steamId,
          authorId,
        });
      }
    });
  }, [steamId, authorId]);

  useEffect(() => {
    inputRef.current.input.focus();

    const listener = async function (evt) {
      setLoading(true);
      decodeQRCodeImage(function (data, error) {
        setLoading(false);
        if (error) {
          Toast.error(error.title || error);
          if (error.body) {
            Toast.error(error.body);
          }
        } else {
          approveLoginQR({
            url: data,
            steamId,
            authorId,
          });
        }
      });
    };

    document.addEventListener("paste", listener);

    return () => {
      document.removeEventListener("paste", listener);
    };
  }, [steamId]);

  return (
    <React.Fragment>
      <Modal
        title="Add account using QR"
        loading={loading}
        open={true}
        onOk={() => {
          // setConfirmLoading(true)
          // const username = usernameRef.current.input.value?.trim()?.
          //     toLowerCase();
          // const password = passwordRef.current.input.value?.trim();
          // handleOk({username, password});
        }}
        onCancel={function () {
          useStore.getState().hideModal(ModalType.ApproveLoginQR);
        }}
        confirmLoading={confirmLoading}
      >
        <Input ref={inputRef} />

        {!!loading && (
          <div className="absolute left-0 top-0 w-full h-full bg-slate-400 opacity-70 z-10 cursor-progress">
            <div className="absolute left-0 top-0 translate-x-1/2 translate-y-1/2 w-full h-full">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 48,
                    }}
                    spin
                  />
                }
              />
            </div>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
});

export default function ApproveLoginQRModal() {
  const currentModal = useStore((store) => store.currentModal);
  if (!currentModal.hasOwnProperty(ModalType.ApproveLoginQR)) return;
  return <RealApproveLoginQRModal steamId={currentModal[ModalType.ApproveLoginQR]?.steamId} authorId={currentModal[ModalType.ApproveLoginQR]?.authorId} />;
}
