import Toastify from "toastify-js";

const Toast = (function () {
  function formatArgs(args) {
    let content = args;
    if (typeof args.text === "string") {
      content = args.text;
      delete args.text;
    }

    if (typeof content !== "string") {
      return;
    }

    return {
      text: content,
      duration: 5000,
      ...args,
    };
  }

  return {
    success(args) {
      args = formatArgs(args);

      if (!args) {
        return;
      }

      Toastify({
        ...args,
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
      }).showToast();
    },
    error(args) {
      args = formatArgs(args);

      if (!args) {
        return;
      }

      Toastify({
        ...args,
        style: {
          background: "linear-gradient(to right, #FF416C, #FF4B2B)",
        },
      }).showToast();
    },
    info(args) {
      args = formatArgs(args);

      if (!args) {
        return;
      }

      Toastify({
        ...args,
        style: {
          background: "linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996)",
          color: "black",
        },
      }).showToast();
    },
  };
})();

export default Toast
